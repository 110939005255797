import React, { useState, useEffect } from 'react';
import './Institute.css';
import UsersSidebar from './UsersSidebar';
import axios from 'axios'; // Import Axios library
import ReactDOM from 'react-dom';
//import DownloadFiles from '../DownloadFiles';

const Institute = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [instituteDetail, setInstituteDetail] = useState([]);
  const instituteId = localStorage.getItem("instituteId");
  const apiHostLink = localStorage.getItem("apiHostLink");
  const instituteName = localStorage.getItem("instituteName");
  const userId = localStorage.getItem("userId");
  const [states, setStates] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [receiptType, setReceiptType] = useState({ ReceiptFileType: 'regular' });
  const [whtsappChk, setWhatsappChk] = useState('');
  const DateTime = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 16);

  useEffect(() => {
    // Fetch data from backend API when component mounts
    fetchInstituteData();
    fillStates();
  }, []);

  const fetchInstituteData = async () => {
    try {
      // Replace 'your_institute_id' and 'your_institute_name' with actual values
      const response = await axios.post(`${apiHostLink}Institute_/Get_Institute_Detail`, {
        instituteId: instituteId,
        instituteName: instituteName,
        userId: userId
      });

      setInstituteDetail(response.data); // Update state with fetched data
      console.log('Fetched data:', response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fillStates = async () => {
    try {
      const response = await axios.get(`${apiHostLink}Institute_/FillStates`);

      setStates(response.data.result);
      setWhatsappChk(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const tableContainerStyle = {
    marginTop: '25px',
    overflowX: 'auto',
    overflowY: 'auto',
    height: '200px',
    backgroundColor: '#f0f0f0',
    border: '1px solid skyblue',
    width: isSidebarOpen ? 'calc(100vw - 200px)' : 'calc(100vw - 100px)', // Adjust width based on sidebar state
    transition: 'width 0.3s ease-in-out' // Transition for smooth width change
  };

  const handleInstituteClick = (instituteData) => {
    setSelectedInstitute(instituteData);
    console.log("Selected Institute Details:", instituteData);
    setSelectedState(instituteData.State);
  }

  const handleRadioChange = (e) => {
    setReceiptType({
      ...receiptType,
      ReceiptFileType: e.target.value,
    });
  };

  const BtnAppointmentObs_Click = async () => {
    try {
      const response = await axios.get(`${apiHostLink}Institute_/AppointmentObs`);
      console.log(response.data);
      alert(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const BtnStartOHIF_Click = async () => {
    try {
      const response = await axios.get(`${apiHostLink}Institute_/StartOHIF`);
      console.log(response.data);
      alert(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const btnBackUpDb_Click = async () => {
    try {
      const response = await axios.get(`${apiHostLink}Institute_/BackUpDb`, {

        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);

      const zipFileName = "pCareReportBackup_" + dateStr + ".zip";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/zip" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();

      //alert(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const menuBackup_pCareReport_Click = async () => {
    try {
      const response = await axios.get(`${apiHostLink}Institute_/menuBackup_pCareReport`, {

        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);

      const zipFileName = "pCareReportBackup_" + dateStr + ".zip";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/zip" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();

      //alert(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const files = [
    'https://sample-videos.com/zip/100mb.zip',
    'https://sample-videos.com/zip/50mb.zip',
    // Add more file URLs here
  ];

  //ReactDOM.render(<DownloadFiles files={files} />, document.getElementById('root'));

  return (
    <div style={{ width: '100vw', overflowx: 'hidden', marginLeft: isSidebarOpen ? '190px' : '65px', transition: 'margin-left 0.3s ease-in-out' }}>
      <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="table-container" style={tableContainerStyle}>
        <table className="user-table" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >Name</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >Address</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >District</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >City</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >State</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >PhoneNo</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >PinCode</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >Username</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >Password</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >RegistrationNo</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >SmsTitle</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >TempFolderName</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >DicomInstituteNames</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >FileTypeName</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsSendSMSonPatRegister</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsSendSMSBeforeAppointment</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >SendAppointmentReminderSMSBeforeMints</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsAllowHidingConcessionAndExtraRs</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsReplaceReportTagWithEmptyStr</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsShowPatientNameTitle</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >TemplateLevel</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsShowPatientIdInGrid</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsShowMsgOnRepDisp</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsShowMsgOnRepUnDisp</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsAllowPastDateEntry</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsPrintScreenShotImages</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsShowApprovedReport</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsTAT</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsSendSMSEmail</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsShowDrPerformingInGrid</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsShowDrResidentInGrid</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsShowTypistInGrid</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsLastNameMandatory</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >IsMobNoMandatory</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>IsShowStatusInGrid</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>IsShowBarcodeInLabel</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>IsShowBarcodeInReceipt</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>TspId</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>IsSendAppointmentSMSOnRegister</th>
              <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>PndtWebAddress</th>
            </tr>
          </thead>
          <tbody>
            {instituteDetail.map((item, index) => (
              <tr key={index} onClick={() => handleInstituteClick(item)} style={{ cursor: 'default', backgroundColor: selectedInstitute === item ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.Name}>{item.Name}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.Address}>{item.Address}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.District}>{item.District}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.City}>{item.City}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.State}>{item.State}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.PhoneNo}>{item.PhoneNo}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.PinCode}>{item.PinCode}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.UserName}>{item.UserName}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.Password}>{item.Password}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.UserRegNo}>{item.UserRegNo}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.SmsTitle}>{item.SmsTitle}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.TemplateFolderName}>{item.TemplateFolderName}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.DicomInstNames}>{item.DicomInstNames}</td>
                <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.ReceiptFileType}>{item.ReceiptFileType}</td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsSendSMSonPatRegister}><input type="checkbox" defaultChecked={item.IsSendSMSonPatRegister} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsSendSMSbeforeAppointment}><input type="checkbox" defaultChecked={item.IsSendSMSbeforeAppointment} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.SendAppoinmntSmsScheduleMints}>{item.SendAppoinmntSmsScheduleMints}</td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsAllowHidingConcesnExRs}><input type="checkbox" defaultChecked={item.IsAllowHidingConcesnExRs} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsReplaceReportTagWithEmptyStr}><input type="checkbox" defaultChecked={item.IsReplaceReportTagWithEmptyStr} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowPatientNameTitle}><input type="checkbox" defaultChecked={item.IsShowPatientNameTitle} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.TemplatesLevel}><input type="checkbox" defaultChecked={item.TemplatesLevel} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowPatientIdInGrid}><input type="checkbox" defaultChecked={item.IsShowPatientIdInGrid} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowPatientIdInGrid}><input type="checkbox" defaultChecked={item.IsShowMsgOnRepDisp} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowMsgOnRepUnDisp}><input type="checkbox" defaultChecked={item.IsShowMsgOnRepUnDisp} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsAllowPastDateEntry}><input type="checkbox" defaultChecked={item.IsAllowPastDateEntry} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsPrintScreenShotImages}><input type="checkbox" defaultChecked={item.IsPrintScreenShotImages} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowApprovedReport}><input type="checkbox" defaultChecked={item.IsShowApprovedReport} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsTAT}><input type="checkbox" defaultChecked={item.IsTAT} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsSendSMSEmail}><input type="checkbox" defaultChecked={item.IsSendSMSEmail} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowDrPerformingInGrid}><input type="checkbox" defaultChecked={item.IsShowDrPerformingInGrid} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowDrResidentInGrid}><input type="checkbox" defaultChecked={item.IsShowDrResidentInGrid} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowTypistInGrid}><input type="checkbox" defaultChecked={item.IsShowTypistInGrid} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsLastNameMandatory}><input type="checkbox" defaultChecked={item.IsLastNameMandatory} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsMobNoMandatory}><input type="checkbox" defaultChecked={item.IsMobNoMandatory} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowStatusInGrid}><input type="checkbox" defaultChecked={item.IsShowStatusInGrid} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowBarcodeInLabel}><input type="checkbox" defaultChecked={item.IsShowBarcodeInLabel} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsShowBarcodeInReceipt}><input type="checkbox" defaultChecked={item.IsShowBarcodeInReceipt} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.TSPId}> {item.TSPId}</td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.IsSendAppointmentSMSOnRegister}><input type="checkbox" defaultChecked={item.IsSendAppointmentSMSOnRegister} /></td>
                <td style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={item.PndtWebAddress}>{item.PndtWebAddress}</td></tr>
            ))}
          </tbody>
        </table>
      </div>


      <div style={{ display: 'flex' }}>
        <div className="table-container" style={{ marginTop: '2px', overflowX: 'auto', overflowY: 'auto', width: 'calc(35vw - 150px)', height: 'auto', backgroundColor: '#d4e3ea', border: '10px double #d4e3ea', borderRadius: '5px', transition: 'margin-left 0.3s ease-in-out' }}>
          <label style={{ display: 'flex', width: '250px' }}>Cent Name
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '40px', height: '25px', marginTop: '3px' }} value={selectedInstitute.Name}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>Address
            <textarea type="text" style={{ display: 'flex', width: '140px', marginLeft: '57px', height: '45px', marginTop: '3px' }} value={selectedInstitute.Address}></textarea>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>District
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '62px', height: '25px', marginTop: '3px' }} value={selectedInstitute.District}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>City
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '80px', height: '25px', marginTop: '3px' }} value={selectedInstitute.City}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>State
            <select style={{ display: 'flex', width: '140px', marginLeft: '72px', height: '25px', marginTop: '3px' }} value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
              {states.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>PinCode
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '55px', height: '25px', marginTop: '3px' }} value={selectedInstitute.PinCode}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>Phone No
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '45px', height: '25px', marginTop: '3px' }} value={selectedInstitute.PhoneNo}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>Email
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '70px', height: '25px', marginTop: '3px' }} value={selectedInstitute.Email}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>Pndt UserName
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '14px', height: '25px', marginTop: '3px' }} value={selectedInstitute.UserName}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>Pndt Password
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '20px', height: '25px', marginTop: '3px' }} value={selectedInstitute.Password}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>Pndt Reg. No
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '28px', height: '25px', marginTop: '3px' }} value={selectedInstitute.UserRegNo}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>Pndt Web Address
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '0px', height: '25px', marginTop: '3px' }} value={selectedInstitute.WebAddress}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>Template Folder
            <input type="text" style={{ display: 'flex', width: '140px', marginLeft: '13px', height: '25px', marginTop: '3px' }} value={selectedInstitute.TemplateFolderName}></input>
          </label>
          <label style={{ display: 'flex', width: '250px' }}>Dicom Institute (SeperatedBy #_)
            <textarea type="text" style={{ display: 'flex', width: '270px', marginLeft: '0px', height: '40px', marginTop: '3px' }} value={selectedInstitute.DicomInstNames}></textarea>
          </label>
          <button className="gradientButton" style={{ marginLeft: '5px', marginTop: '10px' }} disabled>Update</button>
        </div>
        <div className="table-container" style={{ marginTop: '2px', overflowX: 'auto', overflowY: 'auto', width: 'calc(50vw - 150px)', height: 'auto', backgroundColor: '#d4e3ea', border: '10px double #d4e3ea', borderRadius: '5px', transition: 'margin-left 0.3s ease-in-out' }}>
          <div style={{ display: 'flex' }}>
            <label style={{ display: 'flex', height: '25px', width: '200px' }}>
              Sms Title(Exact 6 Alphabets)
              <input type="text" value={selectedInstitute.SmsTitle}></input>
            </label>
            <button className="gradientButton" style={{ marginLeft: '10px', padding: '3px' }} onClick={btnBackUpDb_Click}>Backup DB</button>
            <button className="btn btn-secondary dropdown-toggle mt-2" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: '25px', marginLeft: '10px', width: '10px', fontSize: '9px' }}>
              V
            </button>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ fontSize: '12px' }}>
              <li><button className="dropdown-item" type="button" onClick={menuBackup_pCareReport_Click}>Report Backup</button></li>
            </ul>
            <button className="gradientButton" style={{ marginLeft: '10px', padding: '3px' }} onClick={BtnStartOHIF_Click}>Start OHIF</button>
            <button className="gradientButton" style={{ marginLeft: '10px', padding: '3px' }} onClick={BtnAppointmentObs_Click}>Obs Appointment</button>
          </div>

          <div style={{ display: 'flex', marginTop: '15px' }}>
            <label style={{ display: 'flex', height: '25px', width: '200px' }}>
              Report Template Level
              <select style={{ height: '25px', marginLeft: '10px', width: '40px' }}>
                <option>2</option>
                <option>3</option>
              </select>
            </label>

            <div style={{ width: 'auto', height: '30px', border: '4px double #ffffff', borderRadius: '3px' }} >
              <label style={{ marginTop: '3px', marginLeft: '0px', marginRight: '5px' }}>
                <input type="radio" name="receiptFileType" value="regular" style={{ marginLeft: '5px' }}
                  checked={selectedInstitute.ReceiptFileType === 'regular'} onChange={handleRadioChange} />Regular
                <input type="radio" name="receiptFileType" value="pdf" style={{ marginLeft: '15px' }}
                  checked={selectedInstitute.ReceiptFileType === 'pdf'} onChange={handleRadioChange} />Pdf
                <input type="radio" name="receiptFileType" value="doc" style={{ marginLeft: '15px' }}
                  checked={selectedInstitute.ReceiptFileType === 'doc'} onChange={handleRadioChange} />Doc
              </label>
            </div>
          </div>
          <div className="whatsappTo" style={{ width: '450px', height: 'auto', border: '4px double #ffffff', borderRadius: '3px', marginTop: '10px', position: 'relative' }}>
            <div style={{ position: 'absolute', top: '-15px', left: '-10px', backgroundColor: 'transparent', padding: '2px 10px', zIndex: '1', fontSize: '12px', fontWeight: '600' }}>Whatsapp To</div>
            <div style={{ marginTop: '5px', marginLeft: '10px', fontSize: '12px', fontWeight: '600' }}>
              <input type="checkbox" checked={whtsappChk.wA2PatOnRegister}></input>Patient, <b>On</b> Registration.<br />
              <input type="checkbox" checked={whtsappChk.wA2PatOnSubmitPayment}></input>Patient, <b>On</b> Submit Payment<br />
              <input type="checkbox" checked={whtsappChk.wA2RefDocOnRegister}></input>RefDoctor <b>On</b> Registration<br />
              <input type="checkbox" checked={whtsappChk.wA2PatOnAppointment}></input>Patient <b>On</b> Appointment
            </div>
          </div>

          <div className="whatsappTo" style={{ width: 'auto', height: 'auto', marginTop: '10px', display: 'flex' }}>
            <div style={{ marginTop: '5px', marginLeft: '10px', fontSize: '12px', fontWeight: '600', width: '250px' }}>
              <label><input type="checkbox" checked={selectedInstitute.IsShowPanel}></input>Panel Institute Payment</label>
              <label><input type="checkbox" checked={selectedInstitute.IsAllowHidingConcesnExRs}></input>Hide Concession & ExtraRs</label>
              <label><input type="checkbox" checked={selectedInstitute.IsReplaceReportTagWithEmptyStr}></input>Replace ReportTag With Empty String</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowPatientNameTitle}></input>Show PatientName Title</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowPatientIdInGrid}></input>Show PatientId In Grid</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowMsgOnRepDisp}></input>Show Msg On Report Dispatched</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowMsgOnRepUnDisp}></input>Show Msg On Report UnDispatched</label><br />
              <label><input type="checkbox" checked={selectedInstitute.IsAllowPastDateEntry}></input>Add Study With Past Date Entry</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowApprovedReport}></input>Show Approved Report</label>

              <div style={{ display: 'flex', width: '500px' }}>
                <label><input type="checkbox" checked={selectedInstitute.IsLastNameMandatory}></input>Mandatory LastName</label>
                <label style={{ marginLeft: '20px' }}><input type="checkbox" checked={selectedInstitute.IsMobNoMandatory}></input>MobNo</label>
              </div>
              <label><input type="checkbox" checked={selectedInstitute.IsShowDrResidentInGrid}></input>Show DrResidentInGrid</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowDrPerformingInGrid}></input>Show DrPerformingInGrid</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowTypistInGrid}></input>Show TypistInGrid</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowTechnicianInGrid}></input>Show TechnicianInGrid</label>

              <label>SetFSFolder BackUp In Minutes:
                <input type="number" style={{ width: '38px' }} min={0} defaultValue={0}></input>
              </label>
            </div>


            <div style={{ marginTop: '5px', marginLeft: '10px', fontSize: '12px', fontWeight: '600', width: '250px' }}>
              <label><input type="checkbox" checked={selectedInstitute.IsSendSMSEmail}></input>Send SMS/Email</label>
              <label><input type="checkbox" checked={selectedInstitute.IsTAT}></input>TAT</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowStatusInGrid}></input>Show StatusInGrid</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowBarcodeInLabel}></input>Barcode InLabel</label>
              <label><input type="checkbox" checked={selectedInstitute.IsShowBarcodeInReceipt}></input>Barcode InReceipt</label>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default Institute;
