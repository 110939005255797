import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./WhatsappRegistration.css";

const WhatsappRegistration = () => {
  const apiHostLink = localStorage.getItem("apiHostLink");
  const username = localStorage.getItem("username");
  const [drName, setDrName] = useState('');
  const [waNumber, setWaNumber] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const handleisWANumberValid = () => {
    if (waNumber.length < 10 || waNumber.length > 15) {
      setErrorMessage('WhatsApp Number should include 10');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleRadiologistInsert = async () => {
    setLoading(true);
    try {
      const currentDate = new Date();
      console.log("Submitting with DrName:", drName, "and WANumber:", waNumber);
      const response = await axios.post(`${window.AppSettings.ApiHostLink}InsertRadioLogist/InsertRadiologist`, {
        DrName: drName,
        Age: 0.0,
        AgeUnit: "",
        DOB: null,
        Gender: "",
        CenterName: "",
        CenterAddress: "",
        Email: "",
        WANumber: waNumber,
        InvalidWANumber: false,
        CenterPhoneNo: "",
        UsgMachineModel: "",
        NoOfPcInCenter: 0,
        UsgMachineId: 0,
        FoundBy: "",
        DrFullName: "",
        City: "",
        PinCode: "",
        State: "",
        Country: "",
        Date: currentDate
      });
      console.log("Response from server:", response);
      if (response.data) {
        setSuccessMessage("Radiologist Added Successfully...");
        setDrName('');
        setWaNumber('');

      } else {
        setErrorMessage("Failed to add radiologist. No data returned.");
      }
    } catch (error) {
      console.error('Error managing radiologist info:', error);
      setErrorMessage('Failed to add radiologist. You Have To Login First');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'Enter') {
        setShowButton((prevShowButton) => !prevShowButton);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleWaNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    if (/^\d*$/.test(value)) {
      setWaNumber(value);
    }
  };

  const handleSendDetails = (e) => {
    e.preventDefault();
    if (handleisWANumberValid()) {
      handleRadiologistInsert();
    }
  };

  return (
    <div className="reg-container">
      <form className="reg-form">
        <div className="form-group">
          <label htmlFor="username" className="reg-label" style={{ width: '100%', fontSize: '18px' }}>DrName:<br />
            <input
              type="text"
              id="username"
              value={drName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z\s.']*$/.test(value)) {
                  setDrName(value);
                }
              }}
              required
              style={{ fontSize: '20px', paddingTop: '2px', paddingBottom: '2px' }}
            />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="password" className="reg-label" style={{ width: '100%', fontSize: '18px' }}>WhatsApp Number:<br />
            <input
              type="text"
              value={waNumber}
              onChange={handleWaNumberChange}
              style={{ fontSize: '20px', paddingTop: '2px', paddingBottom: '2px', width: '130px' }}
              required
            />
          </label>
        </div>
        <button
          className="gradientButton"
          style={{ marginTop: '10px', alignContent: 'center', fontSize: '22px', height: '34px', width: '200px' }}
          onClick={handleSendDetails}
          disabled={loading || successMessage}
          title="Ctrl+Shift+Enter Button Click to RadiologistInfo Page"
        >
          {loading ? 'Sending...' : 'Send pCare Details'}
        </button>
        {showButton && (
          <button
            className="gradientButton"
            style={{ marginTop: '10px', alignContent: 'center', fontSize: '18px', height: '34px', width: 'auto' }}
            onClick={() => window.open('/radiologistInfo', '_blank')}
          >
            Go to RadiologistInfo Page
          </button>
        )}
        {successMessage && (
          <div className="success-message" style={{ marginTop: '10px', color: 'green', fontWeight: 'bold' }}>
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="error-message" style={{ marginTop: '10px', fontSize: '16px', fontWeight: '1000', color: 'maroon' }}>
            {errorMessage}
          </div>
        )}
      </form>
    </div>
  );
};

export default WhatsappRegistration;
