import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar'

export default function RadiologistInfo() {
    const instituteId = localStorage.getItem("instituteId");
    const apiHostLink = localStorage.getItem("apiHostLink");
    const [templateData, setTemplateData] = useState([]);
    const [selectedContent, setSelectedContent] = useState([]);
    const [checkedCount, setCheckedCount] = useState(0);
    const all2BeSendTags = ["1 Short PPT", "2 Detailed PPT", "3 New Customer Message", "4 Report1", "4 Report2", "4 Report3", "4 Report4", "4 Report5", "4 Report6", "5 Report Msg ColorFul"];
    var [fromDate, setFromDate] = useState(new Date());
    var [toDate, setToDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState('All');
    const [selectedAge, setSelectedAge] = useState('age');
    const [age, setAge] = useState(-1);
    const [ageType, setAgeType] = useState('');
    const [dob, setDob] = useState(new Date());
    const [selectedGender, setSelectedGender] = useState('male');
    const [filterText, setFilterText] = useState('');
    const [lastIndex, setLastIndex] = useState([]);
    const [listOfId, setListOfId] = useState([]);

    useEffect(() => {
        loadWATemplate();
        getData();
    }, []);

    useEffect(() => {
        setCheckedCount(selectedContent.length);
    }, [selectedContent]);

    const loadWATemplate = async () => {
        try {
            const response = await axios.get(`${apiHostLink}RadiologistDetails_/FillTemplates`);

            const resp = response.data;
            setTemplateData(resp);
            console.log('Template fetched: ', resp);

        } catch (error) {
            console.error('Error fetching Templates :', error);
        }
    };

    const handleCheckboxChange = (content, isChecked) => {
        setSelectedContent(prevContents => {
            if (isChecked) {
                return [...prevContents, content];
            } else {
                return prevContents.filter(c => c !== content);
            }
        });
    };

    const clearAllSelections = () => {
        setSelectedContent([]); //to clear selected template checkbox
        templateData.forEach(item => {
            const checkbox = document.getElementById(`checkbox-${item.Id}`);
            if (checkbox) {
                checkbox.checked = false;
            }
        });
    };

    const selectSpecificCheckboxes = () => {
        setSelectedContent([]);   //to clear selected template checkbox
        const newSelectedContents = [];
        templateData.forEach(item => {
            if (all2BeSendTags.includes(item.Name)) {
                const checkbox = document.getElementById(`checkbox-${item.Id}`);
                if (checkbox) {
                    checkbox.checked = true;
                }
                newSelectedContents.push(item.Content);
            }
        });
        setSelectedContent(newSelectedContents);
    };

    const showTotalCount_Click = async () => {
        try {
            const response = await axios.get(`${apiHostLink}RadiologistDetails_/MenuSendDrCount`);

            const resp = response.data;
            alert(resp);

        } catch (error) {
            console.error('Error fetching Dr Count:', error);
        }
    };

    const formatDateDDMMYYYY = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
        //const yyyy = date.getFullYear();// String(date.getFullYear());//.substring((2, 2);
        //return `${dd}-${mm}-${yyyy}`;
    };

    const getData = async (from, to, isFilterDate) => {
        if (from !== undefined && to !== undefined) {
            fromDate = from;
            toDate = to;
        }
        try {
            const response = await axios.post(`${apiHostLink}RadiologistDetails_/GetData`, {
                dpFrom: fromDate,
                dpTo: toDate,
                isFilterWithDateWise: isFilterDate
            });

            const resp = response.data;
            console.log('Data fetched: ', resp);
            setData(resp);

        } catch (error) {
            console.error('Error fetching Data:', error);
        }
    };

    // Utility function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleDoctorClick = (doctorData, index) => {
        setSelectedDoctor(doctorData);
        setLastIndex(index);
        if (doctorData.Gender === 'F') {
            setSelectedGender('female');
        } else {
            setSelectedGender('male');
        }
        if (doctorData.Age !== undefined && doctorData.Age !== null) {
            setAge(doctorData.Age);
        } else {
            setAge(-1);
        }
        console.log("Selected Doctor Details:", doctorData);
        console.log("Index is: ", index);
        console.log("Checked row ids: ", listOfId);
    };

    const handlePreviousDay = (event) => {
        event.preventDefault();
        const newFromDate = new Date(fromDate);
        const newToDate = new Date(toDate);

        newFromDate.setDate(newFromDate.getDate() - 1);
        newToDate.setDate(newToDate.getDate() - 1);

        setFromDate(newFromDate);
        setToDate(newToDate);

        getData(newFromDate, newToDate, true);
    };

    const handleTodayClick = (event) => {
        event.preventDefault();
        const today = new Date();
        setFromDate(today);
        setToDate(today);
        getData(today, today, true);
    };

    const handleNextDay = (event) => {
        event.preventDefault();
        const newFromDate = new Date(fromDate);
        const newToDate = new Date(toDate);

        newFromDate.setDate(newFromDate.getDate() + 1);
        newToDate.setDate(newToDate.getDate() + 1);

        setFromDate(newFromDate);
        setToDate(newToDate);

        getData(newFromDate, newToDate, true);
    };

    const handleAgeChange = (event) => {
        setSelectedAge(event.target.value);
    };

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
        //console.log('Current input:', event.target.value); // Optional: To see the current input in the console
    };

    const menuFilterByAddress_Click = async () => {
        if (filterText === undefined || filterText === null || filterText === '') {
            alert('Enter Text in Filter box!')
            return;
        }
        try {
            const response = await axios.post(`${apiHostLink}RadiologistDetails_/MenuFilterByAddress`, {
                filter: filterText
            });

            const resp = response.data;
            console.log('Data fetched: ', resp);
            setData(resp);
        } catch (error) {
            console.error('Error fetching Data:', error);
        }
    };

    const SearchBtn_Click = async () => {
        try {
            const response = await axios.post(`${apiHostLink}RadiologistDetails_/Search`, {
                filter: filterText,
                dpFrom: fromDate,
                dpTo: toDate,
            });

            const resp = response.data;
            console.log('Data fetched: ', resp);
            setData(resp);
        } catch (error) {
            console.error('Error fetching Search Data:', error);
        }
    };

    const handleCheckboxClick = (e, id) => {
        if (id === null || id === undefined) return; // Don't add if id is null or undefined
        const checked = e.target.checked;
        setListOfId((prevList) => {
            if (checked) {
                return [...prevList, id];
            } else {
                return prevList.filter((itemId) => itemId !== id);
            }
        });
    };

    const BtnCent2DrName_Click = async (event) => {
        var isShift = event.shiftKey
        if (!isShift) {
            alert("Do not have permission, Kindly contact Jalpa Ma'am");
            return;
        }

        if (!selectedDoctor.DrFullName) {
            alert(`DrName not computed from CenterName because Full Dr name is already present. Kindly edit manually\nDrName=${selectedDoctor.Name}\nCenterName=${selectedDoctor.CenterName}\nFullDrName=${selectedDoctor.DrFullName}`);
        }

        try {
            const response = await axios.post(`${apiHostLink}RadiologistDetails_/BtnCent2DrName`, {
                drName: selectedDoctor.Name,
                centerName: selectedDoctor.CenterName,
                drFullName: selectedDoctor.DrFullName,
                selectedIndex: lastIndex,
                id: selectedDoctor.Id,
                listOfId: listOfId
            });

            const resp = response.data;
            console.log(resp);
            getData(fromDate, toDate, true);    //To refresh Data
        } catch (error) {
            console.error('Error fetching Data:', error);
        }
    };

    const BtnClearDrName_Click = async (event) => {
        var isShift = event.shiftKey
        if (!isShift) {
            alert("Do not have permission, Kindly contact Jalpa Ma'am");
            return;
        }

        if (!selectedDoctor.DrFullName) {
            alert(`DrName not computed from CenterName because Full Dr name is already present. Kindly edit manually\nDrName=${selectedDoctor.Name}\nCenterName=${selectedDoctor.CenterName}\nFullDrName=${selectedDoctor.DrFullName}`);
        }

        try {
            const response = await axios.post(`${apiHostLink}RadiologistDetails_/BtnClearDrName`, {
                drName: selectedDoctor.Name,
                centerName: selectedDoctor.CenterName,
                drFullName: selectedDoctor.DrFullName,
                selectedIndex: lastIndex,
                id: selectedDoctor.Id,
                listOfId: listOfId
            });

            const resp = response.data;
            console.log(resp);
            getData(fromDate, toDate, true);    //To refresh Data
        } catch (error) {
            console.error('Error fetching Data:', error);
        }
    };


    

    

  

    const handleDeleteRadiologist = async () => {
        try {
          const response = await axios.post(`${apiHostLink}InsertUpdateDelRadioLogist/DeleteRadiologist`, { Id: selectedDoctor.Id });
            console.log('Deleted Radiologist successfully response:', response.data);
            alert("Deleted Radiologist successfully");
           
        } catch (error) {
            console.error('Error Deleteing radiologist:', error);
          
        }
        };

    const selectedDoctor2 = {
        RowId: -1,
        Id: selectedDoctor.Id,
        DrName: selectedDoctor.Name,
        Age: parseFloat(selectedDoctor.Age) || 0,
        AgeUnit: selectedDoctor.AgeUnit,
        Dob: selectedDoctor.Dob,
        Gender: selectedDoctor.Gender,
        CenterName: selectedDoctor.CenterName,
        CenterAddress: "Malad",
        Email: selectedDoctor.EmailId,
        WANumber: selectedDoctor.WANumber,
     
        CenterPhoneNo: selectedDoctor.CenterPhNo,
        UsgMachineModel:"null",
        NoOfPcInCenter: selectedDoctor.NoOfPCInCenter,
        UsgMachineId: 0,
        FoundBy: selectedDoctor.FoundBy,
        DrFullName: selectedDoctor.DrFullName,
        City: selectedDoctor.City,
        PinCode: selectedDoctor.PinCode,
        State: selectedDoctor.State,
        Country: selectedDoctor.Country,
        Date: selectedDoctor.Date

    };

    
    const handleUpdateRadiologist = async () => {
        try {

            
          const response = await axios.post(`${apiHostLink}InsertUpdateDelRadioLogist/UpdateRadiologist`, selectedDoctor2);
            console.log(selectedDoctor2);
            console.log('Updated Radiologist successfully response:', response.data);
            alert("Updated Radiologist successfully");
            
        } catch (error) {
            console.error('Error Updated radiologist:', error);
           
        }
    };
   

  return (
    <>
      <div style={{ position: 'relative', display: 'flex', userSelect:'none' }}> {/* Use d-flex for flexbox layout */}
        {/*Sidebar */}
        <Sidebar />
        <div style={{marginTop:'10px'} }>
          <div>
            <h4>RadiologistTable</h4>
            <div>
              <div>
                <text htmlFor="exampleInputEmail1" className="form-label" style={{ fontSize: '14px' }}>Name:</text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '-200px', width: '120px', height: '30px' }} value={selectedDoctor.Name} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, Name: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '140px' }}>
                <text htmlFor="exampleInputPassword1" className="form-label" style={{ marginRight: '7px', fontSize: '14px' }}>DrFullName: </text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.DrFullName} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, DrFullName: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '280px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '7px', fontSize: '14px' }}>Center Name </text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.CenterName} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, CenterName: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '420px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '7px', fontSize: '14px' }}>No.Of Pc's In Center </text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.NoOfPCInCenter} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, NoOfPCInCenter: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '560px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '15px', fontSize: '14px' }}>FoundBy </text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.FoundBy} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, FoundBy: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '700px' }}>
                <label style={{ display: 'inline' }}>
                                  <input type="radio" style={{ fontSize: '14px' }} name="age" value="age" checked={selectedAge === 'age'} onChange={handleAgeChange}  />
                  Age*
                </label>
                <label style={{ display: 'inline', marginLeft: '5px' }}>
                                  <input type="radio" name="dob" style={{ fontSize: '14px' }} value="dob" checked={selectedAge === 'dob'} onChange={handleAgeChange}  />
                  DOB
                </label>
                {selectedAge === 'age' && (
                  <div style={{ marginTop: '-3px' }}>
                    <input type="text" name="age" placeholder="0" style={{ display: 'inline', width: '40px', height: '20px', fontSize: '14px', border: '1px solid #cccccc', borderRadius: '4px' }}
                      min={0} value={age} onChange={(e) => setAge(e.target.value)} // Update state with input value
                      onKeyPress={(event) => {
                        // Allow only numbers and some special keys like Backspace, Delete, Arrow keys, etc.
                        const allowedChars = /[0-9]/;
                        const charCode = event.charCode;
                        const key = String.fromCharCode(charCode);
                        if (!allowedChars.test(key)) {
                          event.preventDefault();
                        }
                      }}
                    />

                    <select
                      className="form-select" style={{ display: 'inline', width: '50px', height: '20px', fontSize: '14px', marginLeft: '2px', padding: '1px', paddingLeft: '3px', border: '1px solid #cccccc' }}
                      name="age" onChange={(e) => setAgeType(formatDate(e.target.value))} >
                      <option style={{ fontSize: '10px' }}>Year</option>
                      <option style={{ fontSize: '10px' }}>Month</option>
                      <option style={{ fontSize: '10px' }}>Day</option>
                    </select>
                  </div>
                )}
                {selectedAge === 'dob' && (
                  <input className="form-control" type="date" style={{ width: '86px', height: '20px' }} name="dob" onChange={(e) => setDob(e.target.value)} />
                )}
              </div>
              <div style={{ marginTop: '-30px', marginLeft: '800px' }}>
                <label>
                  <input type="radio" style={{ fontSize: '14px' }} name="male" value="male" checked={selectedGender === 'male'} onChange={handleGenderChange} />
                  Male*
                </label>
                <label>
                  <input type="radio" style={{ fontSize: '14px' }} name="female" value="female" checked={selectedGender === 'female'} onChange={handleGenderChange} />
                  Female
                </label>
              </div>
            </div>
            <div className='mt-2'>
              <div>
                <text htmlFor="exampleInputEmail1" className="form-label" style={{ marginRight: '20px', fontSize: '14px' }}>WhatsApp Number:</text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.WANumber} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, WANumber: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '140px' }}>
                <text htmlFor="exampleInputPassword1" className="form-label" style={{ marginRight: '20px', fontSize: '14px' }}>Center Phone No</text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.CenterPhNo} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, CenterPhNo: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '280px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '15px', fontSize: '14px' }}>Email Address</text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.EmailId} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, EmailId: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '420px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '15px', fontSize: '14px' }}>Center Address:</text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.Address} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, Address: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '560px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ fontSize: '14px', marginRight: '15px', width: '70px', height: '30px' }}>City </text>
                              <input type="text" className="form-control" id="exampleInputCity" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.City} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, City: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '700px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ fontSize: '14px', marginRight: '15px', width: '70px', height: '30px' }}>Pincode </text>
                              <input type="text" className="form-control" id="exampleInputCity" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.PinCode} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, PinCode: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '840px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ fontSize: '14px', marginRight: '15px', width: '70px', height: '30px' }}>State </text>
                              <input type="text" className="form-control" id="exampleInputCity" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.State} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, State: e.target.value })} />
              </div>
            </div>
            <div className='mt-2' style={{ marginTop: '30px' }}>
              <div>
                <text htmlFor="exampleInputEmail1" className="form-label" style={{ marginRight: '20px', fontSize: '14px' }}>Usg Machine Model:</text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '130px', height: '30px' }} value={selectedDoctor.UsgMachine} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, UsgMachine: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '145px' }}>
                <text htmlFor="exampleInputEmail1" className="form-label" style={{ marginRight: '20px', fontSize: '14px' }}>Country:</text>
                              <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={selectedDoctor.Country} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, Country: e.target.value })} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '280px' }}>
                <text htmlFor="exampleInputPassword1" className="form-label" style={{ marginRight: '20px', fontSize: '14px' }}>Filter: </text>
                <input type="text" className="form-control" id="exampleInputPassword2" style={{ marginRight: '15px', width: '120px', height: '30px' }} value={filterText} onChange={handleFilterChange} />
              </div>
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginTop: '-58px', marginLeft: '402px', width: '25px', height: '28px', fontSize: '13px', paddingTop: '4px', alignItems: 'center', justifyContent: 'center', paddingLeft: '4px' }}>
                V
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><button className="dropdown-item" type="button" style={{ fontSize: '12px' }} onClick={menuFilterByAddress_Click}>Filter By Address</button></li>
              </ul>
              <div style={{ marginTop: '-78px', marginLeft: '435px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '15px', fontSize: '14px' }}>From</text>
                <input type="date" className="form-control" id="exampleInputDate" style={{ width: '120px', height: '30px' }} value={formatDateDDMMYYYY(fromDate)} onChange={(e) => setFromDate(new Date(e.target.value))} />
              </div>
              <div style={{ marginTop: '-54px', marginLeft: '570px' }}>
                <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '15px', fontSize: '14px' }}>To </text>
                <input type="date" className="form-control" id="exampleInputDate" style={{ width: '120px', height: '30px', marginRight: '15px' }} value={formatDateDDMMYYYY(toDate)} onChange={(e) => setToDate(new Date(e.target.value))} />
                <button className="btn btn-primary" style={{ marginLeft: '140px', marginTop: '-54px', fontSize: '14px', width: '70px', height: '30px' }} onClick={SearchBtn_Click}>Search</button>
                <button className="btn btn-warning" style={{ marginLeft: '20px', marginTop: '-54px', fontSize: '14px', width: '40px', height: '30px' }} onClick={handlePreviousDay}> {"<<"} </button>
                <button className="btn btn-warning" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '14px', width: '60px', height: '30px' }} onClick={handleTodayClick}> Today </button>
                <button className="btn btn-warning" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '14px', width: '40px', height: '30px' }} onClick={handleNextDay}> {">>"} </button>
              </div>
            </div>
            <div className='mt-4' style={{ marginTop: '30px' }}>
                          <button className="btn btn-success" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '60px', height: '30px', marginRight: '5px' }} > Insert </button>
                          <button className="btn btn-success" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '60px', height: '30px', marginRight: '5px' }} onClick={handleUpdateRadiologist}> Update </button>
                          <button className="btn btn-danger" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '60px', height: '30px', marginRight: '5px' }} onClick={handleDeleteRadiologist}> Delete </button>
              <button className="btn btn-primary" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '72px', height: '30px', marginRight: '5px', paddingLeft:'8px' }}> ImportCSV </button>
              <button className="btn btn-primary" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '63px', height: '30px', marginRight: '5px', paddingLeft: '8px' }}> Attribute </button>
              <button className="btn btn-primary" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '69px', height: '30px', marginRight: '5px', paddingLeft: '8px' }}> RestartWA </button>
              <button className="btn btn-primary" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '85px', height: '30px', marginRight: '5px', paddingLeft: '8px' }} onClick={BtnCent2DrName_Click}> Cent2DrN </button>
              <button className="btn btn-primary" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '85px', height: '30px', marginRight: '5px', paddingLeft: '8px' }} onClick={BtnClearDrName_Click}> ClearDrN </button>
              <button className="btn btn-primary" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '99px', height: '30px', marginRight: '5px', whiteSpace: 'nowrap' }}> Send Next </button>
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: '1px', marginTop: '-54px', fontSize: '12px', width: '40px', height: '30px', marginRight: '5px' }}>
                V
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><button className="dropdown-item" type="button" style={{ fontSize: '12px' }} onClick={showTotalCount_Click}>Show Total Dr Count</button></li>
                <li><button className="dropdown-item" type="button" style={{ fontSize: '12px' }}>CenterName Dict</button></li>
              </ul>
            </div>
            {/*<div style={{ position: 'absolute', top: '0', height: '42vh', left: '1020px', borderLeft: '2px solid black' }}></div>*/}
          </div>
          <div style={{ marginLeft: '1050px', marginTop: '-270px' }}>
            <h4> WhatsAppMsg</h4>
            <div className='mt-4'>
              <label style={{ position: 'relative', display: 'inline-block', width: '20px', height: '20px', marginRight: '14px' }}>
                <input type="checkbox" id="whatsappMsgCheckbox" name="whatsappMsgCheckbox" style={{ position: 'absolute', opacity: 0, cursor: 'pointer' }} />
                <span
                  style={{ position: 'absolute', top: 0, left: 0, width: '20px', height: '20px', backgroundColor: '#eee', border: '1px solid #ccc', color: 'red', marginTop: '5px', textAlign: 'center', lineHeight: '20px', cursor: 'pointer' }}
                  title="Clear selected WA template"
                  onClick={clearAllSelections}
                >
                  X
                </span>
              </label>
              <button className="btn btn-primary" style={{ marginRight: '5px', fontSize: '12px' }}>WA Template</button>
              <button className="btn btn-primary" style={{ marginRight: '5px', fontSize: '12px' }}>Send WA</button>
              <button className="btn btn-primary" style={{ marginRight: '5px', fontSize: '12px' }} title='Tick all common templates to send new Radiologist' onClick={selectSpecificCheckboxes}>[O]</button>
              <span style={{ marginRight: '5px', fontSize: '14px' }}>Delay Sec</span>
              <input type="text" className="form-control" style={{ marginRight: '5px', marginTop: '-40px', width: '70px', marginLeft: '330px' }} />
            </div>
            <div>
              <button className="btn btn-success mt-3" style={{ marginRight: '10px', fontSize: '12px', width: '130px' }}>Tick Sent</button>
              <button className="btn btn-success mt-3" style={{ marginRight: '5px', fontSize: '12px', width: '130px' }}>Update Sent</button>
              <h3 style={{ marginLeft: '280px', marginTop: '-23px', marginBottom: '20px', fontSize: '14px', width: '130px' }} name="countCheckboxes">{checkedCount}/{templateData.length}</h3>
            </div>

            <div style={{ display: 'flex', marginTop: '10px' }}>
              <div style={{ border: '1px solid #ccc', width: '200px', height: '150px', overflowX: 'auto', overflowY: 'auto', marginRight: '10px' }}>
                {templateData.map(item => (
                  <div key={item.Id} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <input
                      type="checkbox"
                      id={`checkbox-${item.Id}`}
                      onChange={(e) => handleCheckboxChange(item.Content, e.target.checked)}
                    />
                    <label htmlFor={`checkbox-${item.Id}`} style={{ marginLeft: '5px' }}>{item.Name}</label>
                  </div>
                ))}
              </div>
              <div style={{ border: '1px solid #ccc', width: '200px', height: '150px', overflowX: 'auto', overflowY: 'auto', marginLeft:'-5px' }}>
                {selectedContent.map((content, index) => (
                  <div key={index} style={{ marginBottom: '5px' }}>
                    <p>{content}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
{/*          <div style={{ position: 'absolute', top: '310px', width: '153vh', height: '2px', left: '200px', borderTop: '2px solid black' }}></div>
*/}          <div style={{ position: 'absolute', top: '310px', left: '75px', width: 'calc(100% - 90px)', height: 'calc(100% - 310px)', overflowX: 'auto', overflowY: 'auto' }}>
            <table style={{ borderCollapse: 'collapse', border: '1px solid black', fontSize: '13.2px' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '8px', maxWidth: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title='Checkbox'>Checkbox</th>
                  <th style={{ border: '1px solid black', padding: '8px', maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', alignItems:'center', textAlign:'center' }} title='SrNo'>SrNo</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='DoctorName'>DoctorName</th>
                  <th style={{ border: '1px solid black', padding: '8px', maxWidth: '40px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }} title='Age'>Age</th>
                  <th style={{ border: '1px solid black', padding: '8px', maxWidth: '55px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }} title='Gender'>Gender</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='CenterName'>CenterName</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='Address'>Address</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title='EmailId'>EmailId</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='WANumber'>WANumber</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', maxWidth: '30px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title='CenterPhNo'>CenterPhNo</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title='UsgMachine'>UsgMachine</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='NoOfPClnCent'>NoOfPClnCent</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='FoundBy'>FoundBy</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='DrFullName'>DrFullName</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='Date'>Date</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='City'>City</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='PinCode'>PinCode</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='State'>State</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} title='Country'>Country</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} onClick={() => handleDoctorClick(item, index)} style={{ cursor: 'default', backgroundColor: selectedDoctor === item ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                    <td style={{ border: '1px solid black', padding: '8px', width: '4px' }}><input type="checkbox"
                      onClick={(e) => e.stopPropagation()} // Prevent row click event from firing
                      onChange={(e) => handleCheckboxClick(e, item.Id)} // Assuming item has an 'id' property
                      />
                    </td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.SerialNo}</td>
                    <td style={{ border: '1px solid black', padding: '8px', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.Name}>{item.Name}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.Age}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.Gender}</td>
                    <td style={{ border: '1px solid black', padding: '8px', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.CenterName}>{item.CenterName}</td>
                    <td style={{ border: '1px solid black', padding: '8px', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.Address}>{item.Address}</td>
                    <td style={{ border: '1px solid black', padding: '8px', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.EmailId}>{item.EmailId}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} title={item.WANumber}>{item.WANumber}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} title={item.CenterPhNo}>{item.CenterPhNo}</td>
                    <td style={{ border: '1px solid black', padding: '8px', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.UsgMachine}>{item.UsgMachine}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} title={item.NoOfPCInCenter}>{item.NoOfPCInCenter}</td>
                    <td style={{ border: '1px solid black', padding: '8px', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.FoundBy}>{item.FoundBy}</td>
                    <td style={{ border: '1px solid black', padding: '8px', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.DrFullName}>{item.DrFullName}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} title={item.Date}>{formatDate(item.Date)}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} title={item.City}>{item.City}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} title={item.PinCode}>{item.PinCode}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} title={item.State}>{item.State}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} title={item.Country}>{item.Country}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
   </div>
    </>
  )
}
