import React from 'react'
import axios from 'axios';
import Sidebar from '../Sidebar'
import { useState, useEffect } from 'react';
import SystemSetting from '../CAS/SystemSetting';
import PaymentReminder from '../CAS/PaymentReminder';

export default function PcInfo() {

  const apiHostLink = localStorage.getItem("apiHostLink");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [tableData, setTableData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [sortOption, setSortOption] = useState('Using');
  const [pcCount, setPcCount] = useState(0);
  const [todayInstCount, setTodayInstCount] = useState(0);
  const [totalInstitutes, setTotalInstitutes] = useState(0);
  const [All, setAll] = useState(false);
  const [Using, setUsing] = useState(true);
  const [NotUsing, setNotUsing] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [macId, setMacId] = useState('');
  const [licenseId, setLicenseId] = useState('');
  const [isPaymentReminderOpen, setIsPaymentReminderOpen] = useState(false);
  const DateTime = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 16);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await fetch(`${apiHostLink}PR_Select_AllDistinctInstituteFromPcInfo`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setOptions(data);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(`${apiHostLink}PR_Select_PcInfoTableForInstitute?instituteName=${selectedOption}`);
      if (!response.ok) {
        throw new Error('Failed to fetch table data');
      }
      const data = await response.json();
      console.log('Fetched data:', data); // Log the fetched data for debugging
      setIsSearch(true);
      setTableData(data);
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  const handleStopImageTransferChange = (e) => {
    const newData = tableData.map((row) => ({
      ...row,
      isStopImageTransfer: e.target.checked,
    }));
    setTableData(newData);
  };

  const handleAllowNewReleaseDownload = (e) => {
    const newData = tableData.map((row) => ({
      ...row,
      allowNewReleaseDownload: e.target.checked,
    }));
    setTableData(newData);
  };

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleEditCancel = () => {
    setShowForm(false);
  }

  const handleAllChange = (e) => {
    setAll(e.target.checked);
    setUsing(false);
    setNotUsing(false);
    setSortOption("All");
  };

  const handleUsingChange = (e) => {
    setUsing(e.target.checked);
    setAll(false);
    setNotUsing(false);
    setSortOption("Using");
  };

  const handleNotUsingChange = (e) => {
    setNotUsing(e.target.checked);
    setUsing(false);
    setAll(false);
    setSortOption("NotUsing");
  };

  const handleSortBy = async () => {

    try {
      const response = await axios.get(`${apiHostLink}PcInfo_/SortBy?paymentDesc=${sortOption}`);

      const resp = response.data;
      //console.log('Fetched data:', resp);
      setIsSearch(false);
      setTableData(JSON.parse(resp.pcInfo));
      //console.log('Fetched data:', resp.pcInfo);
      setIsSearch(false);
      setTableData(JSON.parse(resp.pcInfo));
      setPcCount(resp.pcCount);
      setTodayInstCount(resp.todayInstCount);//4,80,219
      setTotalInstitutes(resp.totalInstitutes);
      //console.log('Fetched data:', resp.pcInfo);


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const [pcid, setPcid] = useState('');
  const handelRowClick = (index, rowData) => {
    setSelectedRowIndex(index);
    setSelectedRowData(rowData);
    setMacId(rowData.MACID);
    setLicenseId(rowData.LicenseId)
    setPcid(rowData.PCID)

    console.log('Selected row data ROWDATA :', rowData);
    console.log('PCID : ', rowData.PCID);
    console.log('Institute Name: ', rowData.InstituteName);
    console.log('Mac ID : ', rowData.MACID);
    console.log('LicenseId ID : ', rowData.LicenseId);
  };

  const handleSystemSettingClick = () => {
    if (!selectedRowData) {
      alert('Kindly Select InstituteName first');
      return;
    }
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };


  const handlePaymentReminderClick = () => {
    if (!selectedRowData) {
      alert('Kindly Select InstituteName first');
      return;
    }
    setIsPaymentReminderOpen(true);
  };

  const handleClosePaymentReminder = () => {
    setIsPaymentReminderOpen(false);
  };



  const [licenseDetails, setLicenseDetails] = useState(null);

  const fetchLicenseDetails = async () => {

    try {
      const response = await axios.get(`${apiHostLink}License/getLicense?licenseId=${licenseId}&pcId=${pcid}`);

      console.log(response.data);
      setLicenseDetails(response.data);

    } catch (error) {
      console.error('There was an error fetching the license details!', error);
    }
  };

  const [showLicenseDetails, setShowLicenseDetails] = useState(false);
  const [error, setError] = useState(null);

  const handleCheckboxChange = () => {
    if (!selectedRowData) {
      alert('Kindly Select InstituteName first');
      return;
    }
    const newShowLicenseDetails = !showLicenseDetails;
    setShowLicenseDetails(newShowLicenseDetails);

    if (newShowLicenseDetails) {
      fetchLicenseDetails();
    }
  };
  const handleRowClick = (index, rowData) => {
    setSelectedRowIndex(index);
    // Add any additional row click handling logic here
  };

  const btnExel_Click = async () => {
    if (!selectedOption || !tableData) return;
    try {
      const response = await axios.post(`${apiHostLink}PcInfo_/ExportExcel`, {
        instituteName: selectedOption
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Excel Created:", response.data);
      //PcInfo240724_180425"
      const zipFileName = "PcInfo" + '_' + dateStr + ".xlsx";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();

    } catch (error) {
      console.error('Error Creating Excel:', error);
      alert(error.message || 'An error occurred');
    }
  };


  return (
    <div className="container-fluid d-flex p-0" style={{ position: 'fixed', userSelect: 'none' }}> {/* Use d-flex for flexbox layout */}

      {/*Sidebar */}
      <Sidebar />

      {/* Main content */}
      <div className="bg-light flex-grow-1 p-3"> {/* Use flex-grow-1 to fill remaining space */}
        {/* Combo box and search button */}
        <div className="row mt-3" style={{ marginRight: '80px' }}>
          <div className="col-2"></div>
          <div className="col-0">
            <div className="input-group" style={{ width: '1480px' }}>
              <select
                className="form-select form-select-sm"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="">Select</option>
                {options.map((option) => (
                  <option key={option.instituteName} value={option.instituteName} >
                    {option.instituteName}
                  </option>
                ))}
              </select>

              <button className="btn btn-primary" style={{ marginRight: '5px' }} onClick={handleSearch}>Search</button>
              <button className="btn btn-secondary" style={{ marginRight: '15px' }}>Open Lic Dir</button>
              <span className="input-group-text">S:</span>
              <input type="text" className="form-control form-control-sm" style={{ marginRight: '5px' }} />
              <button className="btn btn-info" style={{ marginRight: '15px' }}>AddNewReleaseAll-Inst</button>
              <button className="btn btn-info" style={{ marginRight: '5px' }} onClick={handleSortBy}>SortByVersionNo</button>
              <span className="input-group-text">StartDate:</span>
              <input type="date" className="form-control form-control-sm" style={{ marginRight: '5px' }} />
              <span className="input-group-text">EndDate:</span>
              <input type="date" className="form-control form-control-sm" style={{ marginRight: '5px' }} />
              <button className="btn btn-info" style={{ marginRight: '10px' }}>BringMonthlyReportOfAll</button>
            </div>
            <div className="mt-3 d-flex align-items-center">
              <button className="btn btn-info" style={{ marginRight: '10px' }}>ShowMonthlyReport</button>
              <button className="btn btn-info" style={{ marginRight: '10px' }} onClick={handleSystemSettingClick}>SystemSetting</button>

              {selectedRowData && (
                <SystemSetting
                  isOpen={isPopupOpen} onClose={handleClosePopup} instituteName={selectedRowData.InstituteName} instituteName2={selectedRowData.instituteName}

                />
              )}

              <span className="input-group-text" style={{ marginRight: '5px' }}>InstId:</span>

              <select className="form-select form-select-sm me-2" style={{ width: '100px' }}>
                <option defaultValue>Select InstId</option>
                <option value="1">InstId 1</option>
                <option value="2">InstId 2</option>
                <option value="3">InstId 3</option>
              </select>
              <button className="btn btn-info" style={{ marginRight: '5px' }} onClick={btnExel_Click}>Export .Excel</button>
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginRight: '10px' }}>
                V
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><button className="dropdown-item" type="button">Import</button></li>
                <li><button className="dropdown-item" type="button">DrName in Desc</button></li>
              </ul>
              <button className="btn btn-danger" style={{ marginRight: '5px' }}>Delete</button>
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginRight: '10px' }}>
                V
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><button className="dropdown-item" type="button">Delete PCInfo + Institute</button></li>
                <li><button className="dropdown-item" type="button">Update LoggedInTime In Institute Table</button></li>
                <li><button className="dropdown-item" type="button">Show Disable New Release Inst</button></li>
              </ul>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="checkboxV" />
              </div>
              <label className="form-check-label" htmlFor="checkboxV" style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => document.getElementById("checkboxV").checked = !document.getElementById("checkboxV").checked}>
                DiffVersn
              </label>
              <span className="input-group-text">E:</span>
              <input type="text" className="form-control form-control-sm" style={{ width: '50px', marginRight: '5px' }} />
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="checkboxE" />
              </div>
              <label className="form-check-label" htmlFor="checkboxE" style={{ cursor: 'pointer', marginRight: '30px' }} onClick={() => document.getElementById("checkboxE").checked = !document.getElementById("checkboxE").checked}>
                <b>Edit Institute</b>
              </label>
              <button className="btn btn-success" style={{ width: '70px', marginRight: '5px' }}>Filter</button>
            </div>
            <div className="mt-3 d-flex align-items-center">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="checkboxA" />
              </div>
              <label className="form-check-label" htmlFor="checkboxA" style={{ cursor: 'pointer', marginRight: '50px' }} onClick={() => document.getElementById("checkboxA").checked = !document.getElementById("checkboxA").checked}>
                <b>Apply To All</b>
              </label>
              <div style={{ marginRight: '4px', marginLeft: '10px' }}>

                <input type="radio" style={{ marginRight: '4px', marginLeft: '10px' }} onChange={handleAllChange}
                  checked={All} />All
              </div>
              <div style={{ marginRight: '4px', marginLeft: '10px' }}>

                <input type="radio" style={{ marginRight: '4px' }} onChange={handleUsingChange}
                  checked={Using} />Using
              </div>
              <div style={{ marginRight: '4px', marginLeft: '10px' }}>

                <input type="radio" style={{ marginRight: '4px' }} onChange={handleNotUsingChange}
                  checked={NotUsing} />Not Using
              </div>

              {/*<div className="form-check">
                                <input className="form-check-input" type="checkbox" id="checkboxS" />
                            </div>
                            <label className="form-check-label" htmlFor="checkboxS" style={{ cursor: 'pointer', marginRight: '50px' }} onClick={() => document.getElementById("checkboxS").checked = !document.getElementById("checkboxS").checked}>
                                <b>Show Licence Details</b>
                            </label>*/}

              <div className="form-check">
                {/* <input className="form-check-input" type="checkbox" id="checkboxS" />*/}
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="checkboxS"
                  checked={showLicenseDetails}
                  onChange={handleCheckboxChange}
                />
              </div>
              {/* <label className="form-check-label" htmlFor="checkboxS" style={{ cursor: 'pointer', marginRight: '50px' }}
                                onClick={() => document.getElementById("checkboxS").checked = !document.getElementById("checkboxS").checked}>
                                <b>Show Licence Details</b>
                            </label>*/}
              <label
                className="form-check-label"
                htmlFor="checkboxS"
                style={{ cursor: 'pointer', marginRight: '50px' }}
                onClick={handleCheckboxChange}
              >
                <b>Show License Details</b>
              </label>

              <span className="input-group-text" style={{ marginRight: '5px' }}>Today Using Customer: {todayInstCount} /{totalInstitutes}</span>
              <span className="input-group-text" style={{ marginRight: '5px' }}># PC:  {pcCount}</span>
              <button className="btn btn-primary" onClick={handlePaymentReminderClick}>Payment Reminder</button>
              {selectedRowData && (
                <PaymentReminder
                  isOpen={isPaymentReminderOpen} onClose={handleClosePaymentReminder} instituteName={selectedRowData.InstituteName} instituteName2={selectedRowData.instituteName}
                />
              )}

            </div>
          </div>
        </div>

        <hr className="my-4" style={{ height: '3px', backgroundColor: 'black' }} />
        <div style={{ position: 'absolute', left: '56px', overflowX: 'auto', overflowY: 'auto', overflow: 'auto', width: 'calc(108% - 200px)', height: !showLicenseDetails ? '450px' : '200px', display: 'hidden' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black', fontSize: '6.2px', fontWeight: '50px', marginLeft: '5px' }}>
            <thead style={{ position: 'sticky', top: 0, background: '#fff', zIndex: '1', borderTop: '1px solid black' }}>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>SRNO</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>InstituteName</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>Description</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>IsServer</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>AddNewRelease</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>LicenseDays</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>LicenseFile</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>LicExpDate</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>PcLicExpDate</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>AnyDesk</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>PcUserName</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>SystemName</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>VersionTime</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>SystemBit</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>UpdateTime</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>VersionNumber</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>BringMonthlyReport</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>StartDate</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>EndDate</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>LoggedInTime</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>IsSIT</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>SITDate</th>
                <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>AllowNewRelease</th>
              </tr>
            </thead>
            <tbody>
              {tableData && tableData.map((rowData, index) => (
                <tr key={index} onClick={() => handelRowClick(index, rowData)} style={{ backgroundColor: selectedRowIndex === index ? '#d7fba0' : '', cursor: 'pointer' }}>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.srno : rowData.SrNo}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.instituteName : rowData.InstituteName}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.description : rowData.Description}</td>

                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>
                    <input
                      type="checkbox"
                      checked={isSearch ? rowData.isServer : rowData.IsServer}
                      onChange={(e) => {
                        const newData = [...tableData];
                        newData[index].isServer = e.target.checked;
                        setTableData(newData);
                      }}
                      style={{ margin: '0', padding: '0' }}
                    />
                  </td>

                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>
                    <input
                      type="text"
                      value={isSearch ? rowData.addNewRelease : rowData.AddNewRelease}
                      onChange={(e) => {
                        const newData = [...tableData];
                        newData[index].extendLicenseDays = e.target.value;
                        setTableData(newData);
                      }}
                      style={{ border: '1px solid black', padding: '5px', fontSize: '10px', width: '100%' }}
                    />
                  </td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>
                    <input
                      type="text"
                      value={isSearch ? rowData.extendLicenseDays : rowData.ExtendLicenseDays}
                      onChange={(e) => {
                        const newData = [...tableData];
                        newData[index].extendLicenseDays = e.target.value;
                        setTableData(newData);
                      }}
                      style={{ border: '1px solid black', padding: '5px', fontSize: '10px', width: '100%' }}
                    />
                  </td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>
                    <input
                      type="text"
                      value={isSearch ? rowData.licenseFile : rowData.LicenseFile}
                      onChange={(e) => {
                        const newData = [...tableData];
                        newData[index].extendLicenseDays = e.target.value;
                        setTableData(newData);
                      }}
                      style={{ border: '1px solid black', padding: '5px', fontSize: '10px', width: '100%' }}
                    />
                  </td>

                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.licExpDate : rowData.LicExpDate}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.pcLicExpDate : rowData.PcLicExpDate}</td>

                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>
                    <input
                      type="text"
                      value={isSearch ? rowData.anyDeskId : rowData.AnyDeskId}
                      onChange={(e) => {
                        const newData = [...tableData];
                        newData[index].anyDeskId = e.target.value;
                        setTableData(newData);
                      }}
                      style={{ border: '1px solid black', padding: '5px', fontSize: '10px', width: '100%' }}
                    />
                  </td>

                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.pcUserName : rowData.PcUserName}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.systemName : rowData.SystemName}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.versionTime : rowData.VersionTime}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.systemBit : rowData.SystemBit}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.updateTime : rowData.UpdateTime}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.versionNumber : rowData.VersionNumber}</td>

                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>
                    <input
                      type="checkbox"
                      checked={tableData.length > 0 && tableData.every(row => isSearch ? row.bringMonthlyReport : row.BringMonthlyReport)}
                      onChange={handleStopImageTransferChange}
                    />
                  </td>

                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.startDate : rowData.StartDate}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.endDate : rowData.EndDate}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.loggedInTime : rowData.LoggedInTime}</td>

                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>
                    <input
                      type="checkbox"
                      checked={tableData.length > 0 && tableData.every(row => isSearch ? row.isStopImageTransfer : row.IsStopImageTransfer)}
                      onChange={handleAllowNewReleaseDownload}
                    />
                  </td>

                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{isSearch ? rowData.stopImageTransfer : rowData.StopImageTransfer}</td>
                  <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>
                    <input
                      type="checkbox"
                      checked={tableData.length > 0 && tableData.every(row => isSearch ? row.allowNewReleaseDownload : row.AllowNewReleaseDownload)}
                      onChange={handleAllowNewReleaseDownload}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <div style={{ marginTop: '680px', marginLeft: '-1600px', marginBottom: '10px' }}>
          <div>
            <label htmlFor="licenseId" className="form-label me-2">License Id:-</label>
            <input
              type="text"
              id="licenseId"
              style={{ width: '200px' }}
              className="form-control form-control-sm"
              value={licenseId}
              onChange={(e) => setLicenseId(e.target.value)}
            />
          </div>
          <div >
            <label htmlFor="licenseId" className="form-label me-2">Mac Id:-</label>
            <input
              type="text"
              id="macId"
              style={{ width: '200px' }}
              className="form-control form-control-sm"
              value={macId}
              onChange={(e) => setMacId(e.target.value)} />
          </div>
        </div>
        <div style={{ marginTop: '-450px', marginLeft: '-1400px', marginBottom: '10px', height: '10px' }}>
          {showLicenseDetails && (
            <div>
              <h1>Fetched License Data</h1>
              <table style={{ height: '10px' }}>
                <thead >
                  <tr>
                    <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>Name</th>
                    <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>IsEnable</th>
                    <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>ValidDays</th>
                    <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>Created</th>
                    <th style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>Expiry</th>

                  </tr>
                </thead>
                <tbody>
                  {licenseDetails && licenseDetails.map((rowData, index) => (
                    <tr key={index} onClick={() => handleRowClick(index, rowData)} style={{ backgroundColor: selectedRowIndex === index ? '#d7fba0' : '', cursor: 'pointer' }}>
                      <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{rowData.FeatureName}</td>
                      <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>
                        <input
                          type="checkbox"
                          checked={rowData.IsEnable}
                          onChange={(e) => {
                            const newData = [...tableData];
                            newData[index].IsEnable = e.target.checked;
                            setTableData(newData);
                          }}
                          style={{ margin: '0', padding: '0' }}
                        />
                      </td>
                      <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{rowData.ValidDays}</td>
                      <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{rowData.CreationDate}</td>
                      <td style={{ border: '1px solid black', padding: '8px', fontSize: '10px' }}>{rowData.ExpiryDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

      </div>







    </div>)
}
