/*import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const PaymentReminder = ({ isOpen, onClose, instituteName, instituteName2 }) => {
   

    return isOpen ? ReactDOM.createPortal(
    
        <div
            style={{
                position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '500px', height: '500px', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', userSelect: 'none'
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <p style={{ fontSize: '20px', marginTop: "-15px", marginRight: '168px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: '150px' }}>
                    {instituteName2}
                </p>

                <p style={{ fontSize: '20px', marginTop: "-15px", marginLeft: '-450px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: '150px' }}>
                    {instituteName}
                </p>
                <button onClick={onClose} style={{ color: 'red', marginTop: '-45px', marginRight: '-19px', borderWidth: '0px', width: '40px', display: 'sticky' }}>X</button>
            </div>
            <hr style={{ marginTop: '-15px' }} />


            
               
                <h6>Message Content</h6>
                <div style={{ border: '1px solid #ccc', marginTop: '10px', height: '200px', overflowY: 'auto' }}>
                    
                </div>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                    <input type="checkbox" style={{ marginLeft: '10px' }} />
                    <label style={{ marginLeft: '5px' }}>MultiSelect</label>
            </div>

            <div style={{ display: 'flex', marginTop: '10px' }}>
                <input
                    type="input"
                    id="time"
                    style={{ width: '70px' }}
                    className="form-control form-control-sm"

                />
                <label style={{ marginLeft: '5px' }}>Interval(min)</label>
            </div>
            <div style={{ display: 'flex', marginTop: '10px' }}>
                <input
                    type="input"
                    id="time"
                    style={{ width: '70px' }}
                    className="form-control form-control-sm"
                    
                />
                <label style={{ marginLeft: '5px' }}>Wait Message In Sec</label>
            </div>
            <div style={{ display: 'flex', marginTop: '10px', marginLeft:'-20px' }}>
                    <button className='gradientButton' style={{ marginLeft: '20px' }}>Update</button>
                    <button className='gradientButton' style={{ marginLeft: '10px' }}>Clear</button>
                </div>
            </div>
       ,
        document.body
    ) : null;
};

PaymentReminder.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    instituteName: PropTypes.string.isRequired,
    instituteName2: PropTypes.string.isRequired,
};

export default PaymentReminder;
*/





import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const PaymentReminder = ({ isOpen, onClose, instituteName, instituteName2 }) => {
    const [interval, setInterval] = useState('');
    const [waitMessage, setWaitMessage] = useState('');
    const [isMultiSelect, setIsMultiSelect] = useState(false);
    const [messageContent, setMessageContent] = useState('');

    const handleClear = () => {
        setInterval('');
        setWaitMessage('');
        setIsMultiSelect(false);
        setMessageContent('');
    };

    return isOpen ? ReactDOM.createPortal(
        <div
            style={{
                position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '500px', height: '500px', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', userSelect: 'none'
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <p style={{ fontSize: '20px', marginTop: "-15px", marginRight: '168px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: '150px' }}>
                    {instituteName2}
                </p>

                <p style={{ fontSize: '20px', marginTop: "-15px", marginLeft: '-450px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: '150px' }}>
                    {instituteName}
                </p>
                <button onClick={onClose} style={{ color: 'red', marginTop: '-45px', marginRight: '-19px', borderWidth: '0px', width: '40px', display: 'sticky' }}>X</button>
            </div>
            <hr style={{ marginTop: '-15px' }} />

            <h6>Message Content</h6>
            <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                style={{ width: '100%', height: '200px', marginTop: '10px', resize: 'none', border: '1px solid #ccc' }}
            />
            <div style={{ display: 'flex', marginTop: '20px' }}>
                <input type="checkbox" checked={isMultiSelect} onChange={() => setIsMultiSelect(!isMultiSelect)} style={{ marginLeft: '10px' }} />
                <label style={{ marginLeft: '5px' }}>MultiSelect</label>
            </div>

            <div style={{ display: 'flex', marginTop: '10px' }}>
                <input
                    type="input"
                    value={interval}
                    onChange={(e) => setInterval(e.target.value)}
                    id="time"
                    style={{ width: '70px' }}
                    className="form-control form-control-sm"
                />
                <label style={{ marginLeft: '5px' }}>Interval(min)</label>
            </div>
            <div style={{ display: 'flex', marginTop: '10px' }}>
                <input
                    type="input"
                    value={waitMessage}
                    onChange={(e) => setWaitMessage(e.target.value)}
                    id="waitMessage"
                    style={{ width: '70px' }}
                    className="form-control form-control-sm"
                />
                <label style={{ marginLeft: '5px' }}>Wait Message In Sec</label>
            </div>
            <div style={{ display: 'flex', marginTop: '10px', marginLeft: '-20px' }}>
                <button className='gradientButton' style={{ marginLeft: '20px' }}>Update</button>
                <button className='gradientButton' onClick={handleClear} style={{ marginLeft: '10px' }}>Clear</button>
            </div>
        </div>,
        document.body
    ) : null;
};

PaymentReminder.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    instituteName: PropTypes.string.isRequired,
    instituteName2: PropTypes.string.isRequired,
};

export default PaymentReminder;
