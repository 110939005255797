  import React, { useState, useEffect } from 'react';
  import axios from 'axios';
  import Sidebar from './Sidebar';

  function PatientReg() {
    const instituteId = localStorage.getItem("instituteId");
    const apiHostLink = localStorage.getItem("apiHostLink");
    const userId = localStorage.getItem("userId");
    const [patients, setPatients] = useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State to manage visibility of password div
    const [selectedPatientGender, setSelectedPatientGender] = useState('');
    const [selectedPatientDOB, setSelectedPatientDOB] = useState('');
      const [patientOptions, setPatientOptions] = useState([]);
    useEffect(() => {
        fetchData();
        fetchPatientNames();
    }, [instituteId]);

    const fetchData = async () => {
      var id = parseInt(instituteId);
      try {
        const response = await axios.post(`${apiHostLink}Fill_RegPatientDetails_/PatRegDetails4Institute`, { instituteId: instituteId, userId: userId });
          setPatients(response.data);
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
      };

      

    const handleRowClick = (index, patient) => {
      setSelectedRowIndex(index);
      setSelectedPatient(patient);
      setSelectedPatientGender(patient.Gender); // Update selected patient's gender
      setSelectedPatientDOB(patient.DOB);
    };

   
    // Toggle visibility of password div based on checkbox state
    const handleBulkSMSChange = (event) => {
      setShowPassword(event.target.checked);
    };


      const fetchPatientNames = async () => {
          try {

            const response = await axios.post(`${apiHostLink}HomePage/TopNPatientNames?userId=${userId}&instituteId=${instituteId}`);
              setPatientOptions(response.data);
              console.log(response.data);
          } catch (error) {
              console.error('Error fetching data without dates:', error);
          }
      };

     

      const [buttonState, setButtonState] = useState('edit');
      //var [editPatient   , seteditPatient   ] = useState(null);
     
      const [showFormEdit, setShowFormEdit] = useState(false);





      const [editPatient, setEditPatient] = useState({
          instituteId: instituteId,

          cbSearch:'',
          mobNo: '',
          email: '',
          PatientID: '',
          patName: '',
          gender: '',
          patientIdInst: '',
          fName: '',
          address: '',
          mName: '',
          sName: '',
          age: '',
          ageUnit: '',
          area:'',
          city: '',
          newPatIdInst: '',
          modality: '',
          testName: '',
          studyTime: '',
          dob: '',
          data: '',
          Title: '',
        fileNo: '',
          userId: userId
      });

      const handleChangeEdit = (e) => {
          const { name, value } = e.target;
          setEditPatient((prevState) => ({
              ...prevState,
              [name]: value,
          }));
      };




      const handleEditButtonClick = () => {
          if (selectedPatient) {
              setEditPatient({
                  cbSearch: selectedPatient.cbSearch,
                  instituteId: selectedPatient.instituteId,
                  PatientID: selectedPatient.PatientID,
                  patName: selectedPatient.PatientName,
                  fName: selectedPatient.FName,
                  mName: selectedPatient.MName,
                  lName: selectedPatient.LName,
                  age: selectedPatient.Age,
                  ageUnit: selectedPatient.AgeUnit,
                  gender: selectedPatient.Gender,
                  mobNo: selectedPatient.MobileNo,
                  address: selectedPatient.Address,
                  email: selectedPatient.Email,
                  isPndtPatPD: selectedPatient.isPndtPatPD,//undefinde
                  data: selectedPatient.data,//undefinde
                  patInsuranceNo: selectedPatient.InsuranceNo,
                  mobileNo2: selectedPatient.MobileNo2,
                  landlineNo: selectedPatient.LandlineNo,
                  dob: selectedPatient.DOB,
                  area: selectedPatient.Area, 
                  patientTitle: selectedPatient.Title,
                fileNo: selectedPatient.FileNo,
                  userId: selectedPatient.userId
              });
              setShowFormEdit(true);
          }
      };

     

     
         
              const handleUpdatePatient = async (e) => {
                  e.preventDefault();
          try {
              
              const updatedPatientData = {
                  cbSearch:  String(""),
                      instituteId: instituteId,            
                  PatientID: String(editPatient.PatientID),
                      patName: editPatient.patName,
                      fName: editPatient.fName,
                      mName: editPatient.mName,
                      sName: editPatient.sName,
                      age: String(editPatient.age),
                  ageUnit:String(0),
                      gender: editPatient.gender,
                      mobNo: editPatient.mobNo,
                      address: editPatient.address,
                      email: editPatient.email,
                      /*IsPndtPatPD: editPatient.IsPndtPatPD,//undefinde*/
                      IsPndtPatPD: typeof editPatient.IsPndtPatPD === 'boolean' ? editPatient.IsPndtPatPD : true, // Ensure boolean
                      data: editPatient.data,//undefinde
                      patInsuranceNo: editPatient.patInsuranceNo,
                      mobileNo2: editPatient.mobileNo2,
                      landlineNo: String(editPatient.landlineNo),
                      dob: editPatient.dob,
                  area: editPatient.area,
                  patientTitle: String(""),
                fileNo: editPatient.fileNo,
                      userId: userId
                  };

              const PatientResponse = await axios.post(`${apiHostLink}EditPatient/UpdatePatients`, updatedPatientData);
              alert("Patient Updateded Successfully");
              console.log("Updatead  Successfully", PatientResponse.data);
              
          } catch (error) {
              alert("'Error updating patient");
              console.error('Error updating patient:', error);
          }
      };
      


      const handleSelectPatient = (e) => {
          setSelectedPatient(e.target.value);
      };

      const handleButtonClick = () => {
          setButtonState(buttonState === 'edit' ? 'update' : 'edit');
      };

      const handleEditPatient = () => {
          setEditMode(true);
      };


      console.log(selectedPatient);
      const handleAddTabClick = () => {
          setEditPatient(true);
      };

      const handleEditCancel2 = () => {
          setShowFormEdit(false);
      }

      const HandleViewTest = () => {

      }


      const [searchedData, setSearchedData] = useState([]);

      const handleSearch = async () => {
          try {
              /*const instituteId = 4036;*/
              const response = await axios.get(`${apiHostLink}PatientSearch/PatientSearch?instituteId=${instituteId}&patName=${selectedPatient}&userId=${userId}`);
              setPatients(response.data); // Set searched data
              console.log(response.data);
          } catch (error) {
              console.error('Error searching:', error);
          }
      };
    return (
        <div style={{ display: 'flex', userSelect: 'none', overflowY: 'hidden', overflowX: 'auto' }}>


            

        <div>
          <Sidebar />
        </div>
        <div className="dvpatreg" style={{ flex: '1', minWidth: '200px', paddingTop: '10px', paddingLeft: '4px', paddingRight: '10px', marginLeft:'5px' }}>
          <div className="divup" style={{ width: 'auto', height: 'auto', display: 'inline-block' }}>
            <div style={{ display: 'flex', backgroundColor:'#d4e3ea', padding:'10px'} }>
           {/* <label>
            Search:
              

                <button className="gradientButton" style={{ marginLeft: '10px', padding:'4px' }} >Search</button>
            </label>*/}

                        <label> <select
                            id="modalitySelect"
                            className="form-select form-select-sm me-2"
                            onChange={handleSelectPatient}
                            value={selectedPatient}
                            style={{ width: '120px', height: '30px', fontSize: '12px', paddingLeft: '1px', paddingRight: '15px' }}
                        >
                            {/* Default option */}
                            <option value=""></option>
                            {/* Populate dropdown options from patientOptions state */}
                            {patientOptions.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
              </select>
                <input
                  type="text"
                  value={selectedPatient}
                  onChange={handleSelectPatient}
                  placeholder="Search patient..."
                  className="form-control form-control-sm me-2"
                  style={{width: '105px', height: '29px', fontSize: '12px', marginTop: '-29px', position: 'fixed', border: '0px', paddingLeft:'5px', borderRadius: '4px', backgroundColor: 'transparent', paddingLeft: '1px', borderRadius: '0px'}}
                />
              </label>
                        <input
                            type="button"
                            onClick={handleSearch}
                            className="gradientButton"
                            value="🔍"
                            style={{
                                marginLeft: '0px',
                                marginTop: '2px',
                                fontSize: '16px'
                            }}
                        />

              <label style={{marginLeft:'5px'} }>By:</label>
              <label style={{ marginLeft: '5px' }}>
            <input type="radio"/>PatientId
              </label>
              <label style={{ marginLeft: '5px' }}>
                <input type="radio" />Name
              </label>
              <label style={{ marginLeft: '5px' }}>
                <input type="radio" />Mobile No.
              </label>
              <label style={{ marginLeft: '5px' }}>
                <input type="radio" />Area
              </label>
            </div>
          </div>
          <div
            style={{
              marginTop: '10px',
              width: 'auto',
              height: '250px',
              display:'flex',
              border: '1px solid skyblue',
              overflowX: 'auto',
              overflowY: 'auto'
            } }
          >
            <table>
              <thead>
                <tr>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>PatientId</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>PatientName</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Age</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>AgeUnit</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Gender</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>DOB</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Email</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>MobileNo</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>MobileNo2</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>LandlineNo</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Address</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Area</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>PinNo</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>FName</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>MName</th>
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>LName</th>
                {/*  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>RelativeName</th>*/}
                  <th style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>FileNo</th>
                </tr>
              </thead>
              <tbody>
                {patients.map((patient, index) => (
                  <tr key={patient.PatientId} onClick={() => handleRowClick(index, patient)} style={{ backgroundColor: selectedRowIndex === index ? '#d7fba0' : '', cursor: 'pointer' }}>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.PatientID}>{patient.PatientID}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.PatientName}>{patient.PatientName}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.Age}>{patient.Age}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.AgeUnit}>{patient.AgeUnit}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.Gender}>{patient.Gender}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.DOB}>{patient.DOB}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.Email}>{patient.Email}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.MobileNo}>{patient.MobileNo}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.MobileNo2}>{patient.MobileNo2}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.LandlineNo}>{patient.LandlineNo}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.Address}>{patient.Address}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.Area}>{patient.Area}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.PinNo}>{patient.PinNo}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.FName}>{patient.FName}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.MName}>{patient.MName}</td>
                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.LName}>{patient.LName}</td>
                    {/*<td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.RelativeName}>{patient.RelativeName}</td>
*/}                    <td style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={patient.PatientName}>{patient.FileNo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{marginTop:'10px', display:'flex'} }>       
            <button className="gradientButton" style={{ marginLeft: '10px', padding: '5px' }} onClick={handleEditButtonClick}>Edit Patient</button>
                    <button className="gradientButton" style={{ marginLeft: '10px', padding: '5px' }} >Add Test</button>
            <button className="gradientButton" style={{ marginLeft: '10px',padding: '5px' }} >View Test</button>
            <button className="gradientButton" style={{ marginLeft: '10px',padding: '5px' }} >Import .csv file</button>
            <button className="gradientButton" style={{ marginLeft: '10px', padding: '5px' }} >Export .csv file</button>

            {/* Check if edit mode is enabled and a patient is selected */}
            {/*{editMode && (*/}
            {/*  <div>*/}
            {/*    */}{/* Display input fields with selected patient data */}
            {/*    <input type="text" value={selectedPatient.FName} onChange={(e) => setSelectedPatient({ ...selectedPatient, PatientName: e.target.value })} />*/}
            {/*    */}{/* Add more input fields for other patient data */}
            {/*    */}{/* Add save button to save changes */}
            {/*  </div>*/}
            {/*)}*/}

            <label
              style={{ marginLeft: '10px', marginTop: '4px' }}
            >
              <input type="checkbox"
                style={{ marginRight: '3px' }}
                checked={showPassword} onChange={handleBulkSMSChange}
              ></input>Bulk SMS
            </label>
          </div>
          <div style={{ display: 'flex' }}>
            {editMode && selectedPatient && (
            <div className="edit-patient" style={{ marginTop: '10px', backgroundColor: '#d4e3ea', display: 'inline-block', width: 'auto', padding: '10px', borderRadius: '4px', border: '5px double white' }}>
           
                <div style={{ display: 'flex' }}>
                  <label>FullName<br />
                    <input
                      type="text"
                      value={selectedPatient.FName}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, FName: e.target.value })}
                      style={{ width: '100px', height: '25px' }}
                    />
                  </label>
                  <label>Middle<br />
                    <input
                      type="text"
                      value={selectedPatient.MName}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, MName: e.target.value })}
                      style={{ width: '100px', height: '25px' }}
                    />
                  </label>
                  <label>Last<br />
                    <input
                      type="text"
                      value={selectedPatient.LName}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, LName: e.target.value })}
                      style={{ width: '100px', height: '25px' }}
                    />
                  </label>
                </div>
           
            <div style={{ paddingLeft: '10px', display: 'flex', marginTop:'5px' }}>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="Male"
                        checked={selectedPatientGender === "Male"}
                        onChange={() => setSelectedPatientGender("Male")}
                      />
                      Male
                    </label>
                    <label style={{ marginTop: '5px' }}>
                      <input
                        type="radio"
                        value="Female"
                        checked={selectedPatientGender === "Female"}
                        onChange={() => setSelectedPatientGender("Female")}
                      />
                      Female
                    </label>
                  </div>
                  <label style={{ marginLeft: '10px' }}>Age<br />
                    <input
                      type="number"
                      min={0}
                      value={selectedPatient.Age}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, Age: e.target.value })}
                      style={{ width: '43px', height: '20px' }}
                    />
                    <select
                      style={{ width: '40px', marginLeft: '2px' }}
                      value={selectedPatient.AgeUnit}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, AgeUnit: e.target.value })}
                    >
                      <option>Years</option>
                      <option>Months</option>
                      <option>Days</option>
                    </select>
                  </label>

                  <label style={{ marginLeft: '10px' }}>DOB<br />
                    <input className="form-control" type="date" value={selectedPatientDOB} onChange={(e) => setSelectedPatientDOB(e.target.value)} style={{
                      width: '78px', height: '20px', paddingRight: '12px', paddingLeft: '2px', fontSize: '10px'
                    }}></input>
                  </label>
                  <label style={{ marginLeft: '10px' }}>Email ID<br />
                    <input
                      type="text"
                      value={selectedPatient.Email}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, Email: e.target.value })}
                      style={{ width: '100px', height: '25px' }}
                    />
                  </label>

            </div>
            <div style={{ display: 'flex', marginTop:'5px' }}>
                  <label style={{ marginLeft: '10px', fontSize: '12px' }}>Mobile No.1<br />
                    <input
                      type="text"
                      value={selectedPatient.MobileNo}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, MobileNo: e.target.value })}
                      style={{ width: '67px', height: '20px', fontSize: '11px', padding: '2px' }}
                    />
                  </label>
                  <label style={{ marginLeft: '10px', fontSize: '12px' }}>Mobile No.2<br />
                    <input
                      type="text"
                      value={selectedPatient.MobileNo2}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, MobileNo2: e.target.value })}
                      style={{ width: '67px', height: '20px', fontSize: '11px', padding: '2px' }}
                    />
                  </label>
                  <label style={{ marginLeft: '10px', fontSize: '12px' }}>Landline No.<br />
                    <input
                      type="text"
                      value={selectedPatient.LandlineNo}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, LandlineNo: e.target.value })}
                      style={{ width: '67px', height: '20px', fontSize: '11px', padding: '2px' }}
                    />
                  </label>

                  <label style={{ marginLeft: '10px', fontSize: '12px' }}>Insurance No.<br />
                    <input
                      type="text"
                      value={selectedPatient.InsuranceNo}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, InsuranceNo: e.target.value })}
                      style={{ width: '77px', height: '20px', fontSize: '11px', padding: '2px' }}
                    />
                  </label>

            </div>
            <div style={{ paddingLeft: '10px', display: 'flex', marginTop:'5px' }}> 
                  <label>Address<br />
                    <textarea value={selectedPatient.Address} onChange={(e) => setSelectedPatient({ ...selectedPatient, Address: e.target.value })}/>
                  </label>
              <label
                style={{
                  marginLeft: '10px',
                  marginTop: '4px',
                  padding: '5px'
                }}
              >Area<br />
                    <input type="text"
                      style={{ width: '100px', height: '25px' }}
                      value={selectedPatient.Area}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, Area: e.target.value })}
                ></input>
              </label><br/>
              <label
                style={{
                  marginLeft: '10px',
                  marginTop: '4px',
                  padding: '5px'
                }}
              >File No.<br />
                <input type="text"
                      style={{ width: '100px', height: '25px' }}
                      value={selectedPatient.FileNo}
                      onChange={(e) => setSelectedPatient({ ...selectedPatient, FileNo: e.target.value })}
                ></input>
              </label>
            </div>
                            <button className="gradientButton" style={{ marginLeft: '10px' }}>Add User</button>




                        </div>



                    )}



                    {showFormEdit && (
                        <div style={{ background: '#d4e3ea', border: '4px double white', padding: '10px', display: 'flex', flexDirection: 'row', height: '400px', width: '750px', marginBottom: '10px', marginLeft: '10px' }}>
                            <form className="form-container">
                                <div className="form-group">
                                    <h2>Edit Patient</h2>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <div className="form-group">
                                            <label>cbSearch:</label><input type="text" name="cbSearch" value={editPatient.cbSearch} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '47px' }} />
                                        </div>
                                       {/* <div className="form-group">
                                            <label>patientTitle:</label><input type="text" name="patientTitle" value={editPatient.patientTitle} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '47px' }} />
                                        </div>*/}
                                        <div className="form-group">
                                            <label>fileNo:</label><input type="text" name="fileNo" value={editPatient.fileNo} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '47px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>Patient Name:</label><input type="text" name="patName" value={editPatient.patName} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '26px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>PatientID:</label><input type="text" name="PatientID" value={editPatient.PatientID} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '10px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>ageUnit:</label><input type="text" name="ageUnit" value={editPatient.ageUnit} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '30px' }} />
                                        </div>
                                        {/*<div className="form-group">
                                            <label>instituteId:</label><input type="text" name="instituteId" value={editPatient.instituteId} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '62px' }} />
                                        </div>*/}
                                        <div className="form-group">
                                            <label>email:</label><input type="text" name="email" value={editPatient.email} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '62px' }} />
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '15px' }}>

                                        <div className="form-group">
                                            <label>gender:</label><input type="text" name="gender" value={editPatient.gender} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '35px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>data:</label><input type="text" name="data" value={editPatient.data} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '35px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>age:</label><input type="text" name="age" value={editPatient.age} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '55px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>dob:</label><input type="text" name="dob" value={editPatient.dob} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '72px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>address:</label><input type="text" name="address" value={editPatient.address} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '76px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>fName:</label><input type="text" name="fName" value={editPatient.fName} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '52px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>mName:</label><input type="text" name="mName" value={editPatient.mName} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '52px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>LName:</label><input type="text" name="sName" value={editPatient.sName} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '9px' }} />
                                        </div>

                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <div className="form-group">
                                            <label>area:</label><input type="text" name="area" value={editPatient.area} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '42px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>patInsuranceNo:</label><input type="text" name="patInsuranceNo" value={editPatient.patInsuranceNo} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '23px' }} />
                                        </div>
                                       {/* <div className="form-group">
                                            <label>isPndtPatPD:</label><input type="text" name="IsPndtPatPD" value={editPatient.IsPndtPatPD} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '23px' }} />
                                        </div>*/}

                                        <div className="form-group">
                                            <label>MobileNo:</label><input type="text" name="mobNo" value={editPatient.mobNo} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '26px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>MobileNo2:</label><input type="text" name="mobileNo2" value={editPatient.mobileNo2} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '26px' }} />
                                        </div>
                                        <div className="form-group">
                                            <label>LandLine:</label><input type="text" name="landlineNo" value={editPatient.landlineNo} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '26px' }} />
                                        </div>
                                       

                                        <div className="form-buttons">
                                            <button className="gradientButton" type="submit" style={{ marginLeft: '30px' }} onClick={handleUpdatePatient}>Edit Doctor</button>
                                            <button className="gradientButton" type="submit" style={{ marginLeft: '0px' }} onClick={handleEditCancel2}>Cancel </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    )}
            <div style={{ marginTop: '10px', backgroundColor: '#d4e3ea', display: 'inline-block', width: 'auto', padding: '10px', height: 'auto', marginLeft: '10px', borderRadius: '4px', border: '5px double white' }}>
              <label>Husband/Father Name:<br/>
                <input type="text" style={{ width: '180px', height: '25px' }}></input>
              </label>
            
              <div style={{ display: 'flex' }}>
                <label>Son:
                <table>
                  <thead style={{ position: 'sticky' }}>
                      <tr>
                        <th style={{ backgroundColor: '#f2f9fc', width: '10px', border: '1px solid slategray', fontSize:'12px' }}>Years</th>
                        <th style={{ backgroundColor: '#f2f9fc', width: '10px', border: '1px solid slategray', fontSize: '12px' }}>Months</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize:'10px' }} ></input>
                        </td>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </label>
                <label style={{ marginLeft:'5px'} }>Daughter:
                <table>
                  <thead style={{ position: 'sticky' }}>
                    <tr>
                        <th style={{ backgroundColor: '#f2f9fc', width: '30px', border: '1px solid slategray', fontSize: '12px' }}>Years</th>
                        <th style={{ backgroundColor: '#f2f9fc', width: '30px', border: '1px solid slategray', fontSize: '12px' }}>Months</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                      </tr>
                    </tbody>
                </table>
                </label>
                        </div>


                       

              <label style={{ marginTop: '10px' }}>LMP:
                <input className="form-control" type="date" style={{ width: '90px', height: '25px', fontSize:'12px', padding:'2px', paddingRight:'12px' }}></input>
              </label>
            </div>
            {showPassword && (
            <div className="password" style={{ marginTop: '10px', backgroundColor: '#d4e3ea', display: 'inline-block', width: 'auto', padding: '10px', height: '80px', marginLeft: '10px', borderRadius:'4px', border:'5px double white' }}>
              <label>Password:
                <input type="text" style={{ height: '25px', fontSize: '10px', width: '100px', marginLeft: '10px' }}></input>
              </label>
              <button className="gradientButton" style={{ marginLeft: '50px', marginTop: '5px' }} >OK</button>
              </div>
            )}
                </div>


              


            </div>

            
        </div>

      );




  }

  export default PatientReg;
