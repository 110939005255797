import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests
import Sidebar from './Sidebar';

function RefPhyReg() {
  const instituteId = localStorage.getItem("instituteId");
  const userId = localStorage.getItem("userId");
    const apiHostLink = localStorage.getItem("apiHostLink");
    const username = localStorage.getItem("username");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [doctorData, setDoctorData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]); // State to store selected rows
    const [showBulkSms, setShowBulkSms] = useState(false); // State to manage bulk SMS visibility
    const [doctorOptions, setDoctorOptions] = useState([]);
    const [selectedRadioOption, setSelectedRadioOption] = useState('name'); // 'collectedAmount' is default
    const [showForm, setShowForm] = useState(false);
    const [showFormEdit, setShowFormEdit] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedDoctorSe, setSelectedDoctorSe] = useState([]);
    const showRefPhyInd = -1;

    useEffect(() => {

        fetchDoctorData();
        fetchDoctorNames();
    }, []);
    //new test
    const handleRadioChange = (event) => {
        setSelectedRadioOption(event.target.value);
    };

    const [selectedall, setselectedall] = useState('all'); // 'collectedAmount' is default
    const handleselectedall = (event) => {
        setselectedall(event.target.value);
    };

    // Function to fetch data from backend API
    const fetchDoctorData = async () => {
        try {
            // Make a POST request to your backend API endpoint
            const response = await axios.post(`${apiHostLink}RefPhyDetails_/Get_RefPhyList_4Institute`, {
                instituteId: instituteId,
                userName: username,
                showRefPhyInd: -1,
                pfCat: "All",
              isRemovedDigitDoctorName: true,
                userId: userId
            });
            console.log(response.data);
            // Update the state with fetched data
            setDoctorData(response.data);
        } catch (error) {
            console.error('Error fetching doctor data:', error);
        }
    };

    // Function to handle row selection
    const handleRowsSelection = (index, doctor) => {
        setSelectedDoctor(doctor);
        setShowFormEdit(false);
        
    };

    const toggleBulkSms = () => {
        setShowBulkSms(!showBulkSms);
    };


    const handleAddTabClick = () => {
        setShowForm(true);
    };

    const [addDoctor, setAddDoctor] = useState({
        isUpdatingDoct: false,
        pfCatName: '',
        refPhysName: '',
        instutiteId: instituteId,
        doctorTitle: 'Dr.',
        doctorName: '',
        address: '',
        area: '',
        city: '',
        pinCode: '',
        phoneResidence: '',
        phoneOffice: '',
        byChq: false,
        emailId: '',
        center: '',
        isUpdating: false,
        dob: '',
        isGiveBoth: false,
        updatingDoctId: 123,
        specialityType: '',
        mobileNo: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log('Handling Change:', name, value);
        setAddDoctor(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleAddTest = async (e) => {
        e.preventDefault();
        try {
            const d = {
                isUpdatingDoct: addDoctor.isUpdatingDoct,
                pfCatName: addDoctor.pfCatName,
                refPhysName: addDoctor.refPhysName,
                instutiteId: addDoctor.instutiteId,
                doctorTitle: addDoctor.doctorTitle,
                address: addDoctor.address,
                area: addDoctor.area,
                city: addDoctor.city,
                pinCode: addDoctor.pinCode,
                phoneResidence: addDoctor.phoneResidence,
                phoneOffice: addDoctor.phoneOffice,
                byChq: addDoctor.byChq,
                emailId: addDoctor.emailId,
                center: addDoctor.center,
                isUpdating: addDoctor.isUpdating,
                dob: addDoctor.dob,
                isGiveBoth: addDoctor.isGiveBoth,
                updatingDoctId: addDoctor.updatingDoctId,
                specialityType: addDoctor.specialityType,
              mobileNo: addDoctor.mobileNo,
                userId: userId,
            }

            const doctorResponse = await axios.post(`${apiHostLink}AddDoctors/AddDoctor`, addDoctor);
            console.log('Doctor Added:', doctorResponse.data);
            alert('Doctor Added Successfully!');
        } catch (error) {
            console.error('Error Added doctor:', error);
            alert('Error Added doctor:', error.message);
        }
    }

    const fetchDoctorNames = async () => {
        try {

            const response = await axios.get(`${apiHostLink}RefPhyDetails_/DoctorNames?userId=${userId}&instituteId=${instituteId}`);
            setDoctorOptions(response.data);
        } catch (error) {
            console.error('Error fetching data without dates:', error);
        }
    };
 
    const [editDoctor, seteditDoctor] = useState({
        isUpdatingDoct: true,
        pfCatName: '',
        refPhysName: '',
        instutiteId: instituteId,
        Title: '',
        address: '',
        area: '',
        city: '',
        pinCode: '',
        phoneResidence: '',
        phoneOffice: '',
        byChq: false,
        emailId: '',
        center: '',
        isGiveBoth: false,
        updatingDoctId: '',
        specialityType: '',
        mobileNo: '',
        cbSearch: '',
        DoctorName: '',
        userId: userId,

    });

    const handleChangeEdit = (e) => {
        const { name, value, type, checked } = e.target;
        console.log('Handling Change:', name, value);
        seteditDoctor(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleEditButtonClick = () => {
        if (selectedDoctor) {
            seteditDoctor({
                isUpdatingDoct: true,
                cbSearch: '',
                refPhysName: selectedDoctor.refPhysName,
                updatingDoctId: selectedDoctor.DoctorId,
                pfCatName: selectedDoctor.PfCatName,
                pfCat: selectedDoctor.pfCat,
                instutiteId: instituteId,
                Title: selectedDoctor.Title,
                address: selectedDoctor.Address,
                area: selectedDoctor.Area,
                city: selectedDoctor.City,
                pinCode: selectedDoctor.PinCode,
                phoneResidence: selectedDoctor.PhoneResidence,
                phoneOffice: selectedDoctor.PhoneOffice,
                byChq: selectedDoctor.ByChq,
                emailId: selectedDoctor.EmailId,
                center: selectedDoctor.NameOfCenter,
                isGiveBoth: selectedDoctor.GiveBoth,
                specialityType: selectedDoctor.SpecialityType,
                mobileNo: selectedDoctor.MobileNo,
                doctorName: selectedDoctor.DoctorName,
                userId: userId,
            });
            setShowFormEdit(true);
        }

    };

    const handleEditTest = async (e) => {
        e.preventDefault();
        try {
            const updateDoctor = {

               


                cbSearch: editDoctor.cbSearch,
                isUpdatingDoct: editDoctor.isUpdatingDoct,
                pfCatName: editDoctor.pfCatName,
                refPhysName: editDoctor.doctorName,
                instutiteId: editDoctor.instutiteId,
                Title: editDoctor.Title,
                address: editDoctor.address,
                area: editDoctor.area,
                city: editDoctor.city,
                pinCode: editDoctor.pinCode,
                phoneResidence: editDoctor.phoneResidence,
                phoneOffice: editDoctor.phoneOffice,
                byChq: editDoctor.byChq,
                emailId: editDoctor.emailId,
                center: editDoctor.center,
                isGiveBoth: editDoctor.isGiveBoth,
                updatingDoctId: editDoctor.updatingDoctId,
                specialityType: editDoctor.specialityType,
                mobileNo: editDoctor.mobileNo,
              doctorName: editDoctor.doctorName,
                userId: editDoctor.userId,
            };

            const doctorEditResponse = await axios.post(`${apiHostLink}UpdateDoctor/Editdoctor`, updateDoctor);
            console.log('Doctor Updated:', doctorEditResponse.data);

            alert('Doctor Updated Successfully!');

        } catch (error) {
            console.error('Error updating doctor:', error);
            alert('Error updating doctor:', error.message);
        }
    };




    

    const handleDelete = async (event) => {
      const doctorId = selectedDoctor.DoctorId;
      const userId = localStorage.getItem("userId");

        try {
            const response = await axios.delete(`${apiHostLink}DeleteDoctor/DelDoctor`, {
              params: { doctorId },
                data: { userId }
            });

            alert("Doctor Deleted!", response.data);
        } catch (error) {
            alert("Error deleting Doctor");

            console.error('Error deleting doctor:', error);
        }
    };
   
    const handleSearch = async () => {
        try {

            const response = await axios.get(`${apiHostLink}Search/GetDoctorSearch?instutiteId=${instituteId}&doctorName=${selectedDoctorSe}&showRefPhyInd=${showRefPhyInd}&userId=${userId}`);
            setDoctorData(response.data);
            console.log('searched Doctor', response.data)
        } catch (error) {
            console.error('Error searching:', error);
        }
    };
    const handleSelectDoctorSe = (e) => {
        setSelectedDoctorSe(e.target.value);
    };
    
    const handleEditCancel = () => {
        setShowForm(false);
    };
    const handleEditCancel2 = () => {
        setShowForm(false);

    };

    return (
        <div style={{ display: 'flex', userSelect: 'none', overflowY: 'hidden', overflowX: 'auto' }}>
            <div>
                <Sidebar />
            </div>
            <div className="dvpatreg" style={{ flex: '1', minWidth: '200px', paddingTop: '10px', paddingLeft: '15px', paddingRight: '10px' }}>
                <div className="divup" style={{ width: 'auto', height: 'auto', display: 'flex' }}>
                    <label>
              Search:</label>
              <div>
                <input
                  type="text"
                  value={selectedDoctorSe}
                  onChange={handleSelectDoctorSe}
                  placeholder="Search Doctor..."

                style={{ position: 'fixed', width: '90px', maxHeight: '23px', marginLeft: '11px', marginTop: '1px', borderLeft: '0px', borderBottom:'0px', borderTop: '0px', borderRight:'0px', borderRadius:'5px', borderColor:'black', paddingLeft:'4px' }}
                />

                <select
                  onChange={handleSelectDoctorSe}
                  value={selectedDoctorSe}
                  style={{
                    width: '110px',
                    marginLeft: '10px',
                    borderColor:'black'
                  }}>
                  {/* Default option */}
                  <option value=""></option>
                  {/* Populate dropdown options from patientOptions state */}
                  {doctorOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </select>
              </div>    
              <input type="button" onClick={handleSearch} className="gradientButton" value="🔍" style={{ marginLeft: '10px', marginTop: '0px', fontSize: '16px' }} />
                    <label style={{ marginLeft: '15px' }}>By:</label>
                    <label style={{ marginLeft: '15px', marginTop: '5px' }}>

                        <input type="radio" style={{ marginRight: '5px' }} name="name" value="name" checked={selectedRadioOption === 'name'} onChange={handleRadioChange}></input>Name

                    </label>
                    <label style={{ marginLeft: '15px', marginTop: '5px' }}>
                        <input type="radio" style={{ marginRight: '5px' }} name="name" value="mobile" checked={selectedRadioOption === 'mobile'} onChange={handleRadioChange}></input>Mobile No.

                    </label>
                    <label style={{ marginLeft: '15px', marginTop: '5px' }}>
                        <input type="radio" style={{ marginRight: '5px' }} name="name" value="cap" checked={selectedRadioOption === 'cap'} onChange={handleRadioChange}></input>RefPhyName Capital

                    </label>
                    <label style={{ marginLeft: '15px', marginTop: '5px' }}>
                        <input type="radio" style={{ marginRight: '5px' }} name="name" value="sam" checked={selectedRadioOption === 'sam'} onChange={handleRadioChange}></input>RefPhyName Small

                    </label>
                    <label style={{ marginLeft: '15px', marginTop: '5px' }}>
                        <input type="radio" style={{ marginRight: '5px' }} name="name" value="title" checked={selectedRadioOption === 'title'} onChange={handleRadioChange}></input>RefPhyName Title

                    </label>
                </div>
                <div style={{ marginTop: '10px', width: '200px' }}>
                    <label>PFCat
                        <select style={{ width: '100px', height: '20px', marginLeft: '10px' }}>
                            <option>Default</option>
                        </select>
                    </label>
                </div>
                <div
                    style={{
              marginTop: '10px',
              height: showBulkSms ? '100px' : (showForm || showFormEdit) ? '200px' : '500px',
                        width: 'calc(100vw - 230px)',
                        display: 'flex',
                        border: '1px solid skyblue',
                        overflowX: '100%',
                        overflowY: 'auto'
                    }}
                >
                    <table>
                        <thead>
                            <tr>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Title</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>DoctorName</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Degree</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Speciality Type</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>GiveBothM</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>ByChq</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>PFCat</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>NameOfCenter</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>MobileNo</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>EmailId</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Address</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>Area</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>City</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>PinCode</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>PhoneResidence</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>PhoneOffice</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>RegNo</th>
                                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>DOB</th>

                            </tr>
                        </thead>
                        <tbody>
                            {doctorData.map((doctor, index) => (
                                <tr key={index} onClick={() => handleRowsSelection(index, doctor)} style={{ backgroundColor: selectedDoctor === doctor ? '#d7fba0' : '', cursor: 'pointer' }}>
                                    {/* Render table cells with corresponding data */}
                                    <td style={{ maxWidth: '30px' }} title={doctor.DoctorsTitle}>{doctor.Title}</td>
                                    <td style={{ maxWidth: 'auto' }} title={doctor.DoctorsName}>{doctor.DoctorName}</td>
                                    <td style={{ maxWidth: 'auto' }} title={doctor.Degree}>{doctor.Degree}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.SpecialityType}>{doctor.SpecialityType}</td>
                                    <td style={{ maxWidth: '30px', textAlign: 'center' }}><input type="checkbox" defaultChecked={doctor.GiveBoth} /></td>
                                    <td style={{ maxWidth: '30px', textAlign: 'center' }}><input type="checkbox" defaultChecked={doctor.ByChq} /></td>
                                    <td style={{ maxWidth: '30px', textAlign: 'center' }} title={doctor.PfCatName}>{doctor.PfCatName}</td>
                                    <td style={{ maxWidth: '30px', textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.NameOfCenter}>{doctor.NameOfCenter}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.MobileNo}>{doctor.MobileNo}</td>
                                    <td style={{ maxWidth: '90px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.EmailId}>{doctor.EmailId}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.Address}>{doctor.Address}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.Area}>{doctor.Area}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.City}>{doctor.City}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.PinCode}>{doctor.PinCode}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.PhoneResidence}>{doctor.PhoneResidence}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.PhoneOffice}>{doctor.PhoneOffice}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.RegNo}>{doctor.RegNo}</td>
                                    <td style={{ maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.DOB}>
                                        {doctor.DOB === '0001-01-01' ? '' : doctor.DOB}
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div style={{ display: 'flex' }}>
                    <button className="gradientButton" style={{ marginLeft: '0px', padding: '4px', marginTop: '5px' }} onClick={handleAddTabClick}>New Doctor</button>
                    <button className="gradientButton" style={{ marginLeft: '0px', padding: '4px', marginTop: '5px' }} onClick={handleEditButtonClick}>Edit Doctor</button>
                    <select style={{ width: '20px', marginLeft: "10px", height: '25px', marginTop: '5px' }}>
                        <option>Default</option>
                    </select>
                    <button className="gradientButton" style={{ marginLeft: '10px', padding: '4px', marginTop: '5px' }} onClick={handleDelete}>Delete Doctor</button>
                    <button className="gradientButton" style={{ marginLeft: '10px', padding: '4px', marginTop: '5px' }} >Import Csv</button>
                    <button className="gradientButton" style={{ marginLeft: '10px', padding: '4px', marginTop: '5px' }} >Export Csv</button>
                    <div>


                        <label style={{ marginTop: '10px', display: 'flex', marginLeft: '10px' }}>
                            <input type="checkbox" style={{ marginLeft: '5px' }}></input>Select Multiple
                        </label>
                        <label style={{ marginTop: '7px', display: 'flex', marginLeft: '10px' }}>
                            <input type="checkbox" style={{ marginLeft: '5px' }}></input>Select All
                        </label>
                    </div>
                    <button className="gradientButton" style={{ marginLeft: '10px', padding: '4px', marginTop: '5px' }} >Set PF Cat</button>
                    <button className="gradientButton" style={{ marginLeft: '10px', padding: '4px', marginTop: '5px' }} >Merge Doctors</button>
                    <label style={{ marginTop: '10px', display: 'flex', marginLeft: '10px' }}>
                        <input type="checkbox" checked={showBulkSms} onChange={toggleBulkSms} style={{ marginLeft: '5px', marginTop: '-25px', marginRight: '10px' }}></input>ShowBulk SmsUi
            </label>
                </div>
          {showBulkSms && (
            <div className="bulk-sms" style={{ backgroundColor: '#d4e3ea', width: '300px', height: 'auto', marginLeft: '65%', border: '4px double white', borderRadius: '4px', marginTop: '-50px' }}>
              <div style={{ padding: '5px', display: 'flex' }}>
                <input type="radio" style={{ marginLeft: '10px' }} name="all" value="all" checked={selectedall === 'all'} onChange={handleselectedall}></input><label>All</label>
                <input type="radio" style={{ marginLeft: '10px' }} name="all" value="selected" checked={selectedall === 'selected'} onChange={handleselectedall}></input><label>Selected</label>
                <input type="checkbox" style={{ marginLeft: '10px' }} /><label>MultiSelect</label>
              </div>
              <div style={{ padding: '5px', display: 'flex' }}>
                <textarea style={{ width: '300px', height: '70px' }}></textarea>
              </div>
              <div style={{ padding: '5px', display: 'flex' }}>
                <button className="gradientButton">Submit</button>
              </div>
            </div>
          )}
          <div style={{ display: 'flex' }}>
            {showForm && (
                        <div style={{ background: '#d4e3ea', border: '4px double white', padding: '10px', display: 'flex', height: '380px', width: '720px' }}>
                      
                            <div>
                                <h5>Add Doctor</h5>
                            <div className="form-group">
                                    <label>PfCatName:</label><input type="text" name="pfCatName" value={addDoctor.pfCatName} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '22px' }} />
                            </div>
                            <div className="form-group">
                                    <label>Doctor Name:</label><input type="text" name="refPhysName" value={addDoctor.refPhysName} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '26px' }} />
                            </div>
                            <div className="form-group">
                                    <label>InstutiteId:</label><input type="text" name="instutiteId" value={addDoctor.instutiteId} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '27px' }} />
                            </div>
                            <div className="form-group">
                                    <label>DoctorTitle:</label><input type="text" name="doctorTitle" value={addDoctor.doctorTitle} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '22px' }} />
                            </div>
                            <div className="form-group">
                                    <label>Address:</label><input type="text" name="address" value={addDoctor.address} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '38px' }} />
                            </div>
                            <div className="form-group">
                                    <label>Area:</label><input type="text" name="area" value={addDoctor.area} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '57px' }} />
                            </div>
                            <div className="form-group">
                                    <label>City:</label><input type="text" name="city" value={addDoctor.city} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '61px' }} />
                            </div>
                            <div className="form-group">
                                    <label>PinCode:</label><input type="text" name="pinCode" value={addDoctor.pinCode} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '36px' }} />
                                </div>
                                </div>
                             <div style={{marginLeft:'15px', marginTop:'40px'} }>
                            <div className="form-group">
                                    <label>PhoneResidence:</label><input type="text" name="phoneResidence" value={addDoctor.phoneResidence} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '10px' }} />
                            </div>
                            <div className="form-group">
                                    <label>PhoneOffice:</label><input type="text" name="phoneOffice" value={addDoctor.phoneOffice} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '32px' }} />
                            </div>
                            <div className="form-group">
                                    <label>ByChq:</label><input type="text" name="byChq" value={addDoctor.byChq} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '64px' }} />
                            </div>
                            <div className="form-group">
                                    <label>EmailId:</label><input type="text" name="emailId" value={addDoctor.emailId} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '59px' }} />
                            </div>
                            <div className="form-group">
                                    <label>Center:</label><input type="text" name="center" value={addDoctor.center} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '62px' }} />
                            </div>

                            <div className="form-group">
                                    <label>DOB:</label><input type="text" name="dob" value={addDoctor.dob} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '72px' }} />
                            </div>
                            <div className="form-group">
                                    <label>IsGiveBoth:</label><input type="text" name="isGiveBoth" value={addDoctor.isGiveBoth} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '40px' }} />
                            </div>

                            <div className="form-group">
                                    <label>SpecialityType:</label><input type="text" name="specialityType" value={addDoctor.specialityType} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '20px' }} />
                                </div>
                                </div>            
                             <div style={{marginLeft:'15px', marginTop:'40px'} }>
                            <div className="form-group">
                                    <label>MobileNo:</label><input type="text" name="mobileNo" value={addDoctor.mobileNo} onChange={handleChange} style={{ width: '120px', height: '25px', marginLeft: '10px' }} />
                            </div>

                            <div className="form-buttons">
                                    <button className="gradientButton" type="submit" onClick={handleAddTest} style={{ marginLeft: '70px' }}>Add Doctor</button>
                                    <button className="gradientButton" type="submit" onClick={handleEditCancel} style={{ marginLeft: '70px' }}>Cancle </button>
                            </div>
                         
                        </div>
                        </div> 
                       
            )}
            {showFormEdit && (
              <div style={{ background: '#d4e3ea', border: '4px double white', padding: '10px', display: 'flex', flexDirection: 'row', height: '380px', width: '750px', marginBottom: '10px', marginLeft: '10px' }}>
                <form className="form-container">
                  <div className="form-group">
                    <h3>Edit Doctor</h3>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <div className="form-group">
                        <label>cbSearch:</label><input type="text" name="cbSearch" value={editDoctor.cbSearch} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '47px' }} />
                      </div>
                      <div className="form-group">
                        <label>Doctor Name:</label><input type="text" name="doctorName" value={editDoctor.doctorName} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '26px' }} />
                      </div>
                      {/* <div className="form-group">
                                        <label>DcotorName:</label><input type="text" name="refPhysName" value={editDoctor.refPhysName} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '37px' }} />
                            </div>*/}

                      <div className="form-group">
                        <label>updatingDoctId:</label><input type="text" name="updatingDoctId" value={editDoctor.updatingDoctId} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '10px' }} />
                      </div>
                      <div className="form-group">
                        <label>PfCatName:</label><input type="text" name="pfCatName" value={editDoctor.pfCatName} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '34px' }} />
                      </div>
                      <div className="form-group">
                        <label>InstutiteId:</label><input type="text" name="instutiteId" value={editDoctor.instutiteId} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '40px' }} />
                      </div>
                    </div>
                    <div style={{ marginLeft: '15px' }}>

                      <div className="form-group">
                        <label>DoctorTitle:</label><input type="text" name="Title" value={editDoctor.Title} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '35px' }} />
                      </div>
                      <div className="form-group">
                        <label>Address:</label><input type="text" name="address" value={editDoctor.address} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '55px' }} />
                      </div>
                      <div className="form-group">
                        <label>Area:</label><input type="text" name="area" value={editDoctor.area} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '72px' }} />
                      </div>
                      <div className="form-group">
                        <label>City:</label><input type="text" name="city" value={editDoctor.city} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '76px' }} />
                      </div>
                      <div className="form-group">
                        <label>PinCode:</label><input type="text" name="pinCode" value={editDoctor.pinCode} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '52px' }} />
                      </div>
                      <div className="form-group">
                        <label>PhoneResidence:</label><input type="text" name="phoneResidence" value={editDoctor.phoneResidence} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '9px' }} />
                      </div>
                      <div className="form-group">
                        <label>PhoneOffice:</label><input type="text" name="phoneOffice" value={editDoctor.phoneOffice} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '30px' }} />
                      </div>
                      <div className="form-group">
                        <label>ByChq:</label><input type="text" name="byChq" value={editDoctor.byChq} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '62px' }} />
                      </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                      <div className="form-group">
                        <label>EmailId:</label><input type="text" name="emailId" value={editDoctor.emailId} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '42px' }} />
                      </div>
                      <div className="form-group">
                        <label>Center:</label><input type="text" name="center" value={editDoctor.center} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '45px' }} />
                      </div>
                      <div className="form-group">
                        <label>IsGiveBoth:</label><input type="text" name="isGiveBoth" value={editDoctor.isGiveBoth} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '23px' }} />
                      </div>
                      <div className="form-group">
                        <label>SpecialityType:</label><input type="text" name="specialityType" value={editDoctor.specialityType} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '3px' }} />
                      </div>
                      <div className="form-group">
                        <label>MobileNo:</label><input type="text" name="mobileNo" value={editDoctor.mobileNo} onChange={handleChangeEdit} style={{ width: '120px', height: '25px', marginLeft: '26px' }} />
                      </div>

                      <div className="form-buttons">
                        <button className="gradientButton" type="submit" style={{ marginLeft: '70px' }} onClick={handleEditTest}>Edit Doctor</button>
                        <button className="gradientButton" type="submit" style={{ marginLeft: '70px' }} onClick={handleEditCancel2}>Cancel </button>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            )}
          </div>                  
           </div>
        </div>     
        );
}

export default RefPhyReg;


