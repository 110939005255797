import React from "react";
import "./Registration.css";

function Registration() {

  return (
    <div className="registration-container">
      <form className="registration-form">
        <h2>Registration</h2>

        <div className="form-group">
          <label htmlFor="centerName">Center Name</label>
          <input
            className="inpt"
            type="text"
            id="centerName"
            style={{ width:'200px', marginLeft:'70px' }}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="emailId">Email Id</label>
          <input
            className="inpt"
            type="text"
            id="emailId"
            style={{ width: '200px', marginLeft: '98px' }}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobileNum">Mobile Num</label>
          <input
            className="inpt"
            type="text"
            id="mobileNum"
            style={{ width: '200px', marginLeft: '73px' }}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="drOwnerName">Dr Owner Name</label>
          <input
            className="inpt"
            type="text"
            id="drOwnerName"
            style={{ width: '200px',marginLeft:'53px' }}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="userId">User Id</label>
          <input
            className="inpt"
            type="text"
            id="userId"
            style={{ width: '200px', marginLeft:'102px' }}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            className="inpt"
            type="password"
            id="password"
            style={{ width: '200px', marginLeft:'89px' }}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            className="inpt"
            id="confirmPassword"
            style={{ width: '200px', marginLeft:'42px' }}
            required
          />
        </div>
        <button type="submit" className="btn-registration">
          Register
        </button>
      </form>
    </div>
  );
}

export default Registration;
