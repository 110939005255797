import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UserSettings = () => {
  const [userSettings, setUserSettings] = useState({
    id: 0,
    userName: '',
    backgroundColor: ''
  });
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchSettings = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://localhost:7218/api/UserSettings/get?username=${username}`);
      setUserSettings(response.data);
      console.log(response.data);
      alert(`The settings for this user are:\nUsername: ${response.data.username}\nBackground Color: ${response.data.backgroundColor}`);
    } catch (error) {
      console.error('Error fetching user settings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSettingsChange = (e) => {
    const { name, value } = e.target;
    setUserSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://localhost:7218/api/UserSettings/set', userSettings);
      alert('Settings updated successfully!');
    } catch (error) {
      console.error('Error updating settings:', error);
      alert('Failed to update settings.');
    }
  };

  return (
    <div>
      <h1>User Settings</h1>
      <div>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={handleUsernameChange}
          required
        />
        <button onClick={fetchSettings}>Fetch Settings</button>
      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="userName">User Name:</label>
            <input
              type="text"
              id="userName"
              name="userName"
              value={userSettings.userName}
              onChange={handleSettingsChange}
              required
            />
          </div>
          <div>
            <label htmlFor="backgroundColor">Background Color:</label>
            <input
              type="color"
              id="backgroundColor"
              name="backgroundColor"
              value={userSettings.backgroundColor}
              onChange={handleSettingsChange}
            />
          </div>
          <button type="submit">Save Settings</button>
        </form>
      )}
    </div>
  );
};

export default UserSettings;
