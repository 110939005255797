// UsersSidebar.js
import React from 'react';

const UsersSidebar = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <>
      <button onClick={toggleSidebar} title={isSidebarOpen ? "Close Sidebar" : "Open Sidebar"} style={{ position: 'fixed', top: '7px', left: isSidebarOpen ? '10px' : '10px', zIndex: '999', backgroundColor: 'white' }}>
        {isSidebarOpen ? '☰' : '☰'}
      </button>
      <div style={{ position: 'fixed', top: 0, left: isSidebarOpen ? 0 : '-110px', width: '160px', height: '100vh', backgroundColor: '#333', transition: 'left 0.3s ease-in-out' }}>

        {/* Navigation bar items */}
        <nav style={{ marginTop: '50px', fontSize: '18px' }}>
          <ul style={{ listStyleType: 'none', padding: 0, visibility: isSidebarOpen ? 'visible' : 'hidden', marginLeft:'10px' }}>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/Institute">Institute</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/UserList">UserList</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/OnlineUsers">OnlineUsers</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/UsersLog">UsersLog</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/PDFTitle">PDFTitle</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/PaymentOption">PaymentOption</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/Template">Template</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/Expenses">Expenses</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/DoctorReporting">DoctorReporting</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/PFSettings">PFSettings</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/PDFSettings">PDFSettings</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/LogDetails">LogDetails</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/PatientNameTitle">PatientNameTitle</a></li>
            <li className="nav-item" style={{ marginTop: '10px' }}><a href="/PanelInstitutes">PanelInstitutes</a></li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default UsersSidebar;
