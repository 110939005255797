import React, { Component } from 'react';
import { Layout } from './components/Layout';
import './custom.css';
import PcInfo from './components/CAS/PcInfo';
import SoapApi from './components/CAS/SoapApi';
import Home from './components/Home';
import Institutes from './components/CAS/Institutes';
import StudentCrud from './components/StudentCrud';
import Login from './components/Login';
import UsersTable from './components/UsersTable';
import StoredProcedures from './components/StoredProcedures';
import RadiologistInfo from './components/CAS/RadiologistInfo';
import InvoiceSummary from './components/CAS/InvoiceSummary';
import PCareHome from './components/PCareHome';
import ModalityTest from './components/modalitytest';
import Template from './components/Template';
import Expenses from './components/Expenses';
import CheckAccounts from './components/CheckAccounts';
import Institute from './components/Institute';
import UserList from './components/UserList';
import UsersSidebar from './components/UsersSidebar';
import OnlineUsers from './components/OnlineUsers';
import UsersLog from './components/UsersLog';
import PDFTitle from './components/PDFTitle';
import PaymentOption from './components/PaymentOption';
import PatientReg from './components/PatientReg';
import RefPhyReg from './components/RefPhyReg';
import DoctorsPF from './components/DoctorsPF';
import GACal from './components/GACal';
import PNDT from './components/PNDT';
import DoctorReporting from './components/DoctorReporting';
import PFSettings from './components/PFSettings';
import PDFSettings from './components/PDFSettings';
import LogDetails from './components/LogDetails';
import PatientNameTitle from './components/PatientNameTitle';
import PanelInstitutes from './components/PanelInstitutes';
import ImgViewer from './components/ImgViewer';
import ReportPdfViewer from './components/ReportPdfViewer';
import Info_Display from './components/Info_Display';
import Registration from './components/Registration';
import WhatsappRegistration from './components/CAS/WhatsappRegistration';
import UserSettingsForm from './components/UserSettingsForm';

//import MonthlySummary from './components/MonthlySummary';
//import TeamGrpUi from './components/TeamGrpUi';
//import CRM from './components/CRM';
//import GSheet from './components/GSheet';
//import WATamplateCategory from './components/WATamplateCategory';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import TelegramLogin from './components/CAS/TelegramLogin';
//import PatientNameTitle from './components/PatientNameTitle';
//import LogDetails from './components/LogDetails';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/pcinfo" element={<PcInfo />} />
        <Route path="/soapapi" element={<SoapApi />} />
        <Route path="/institutes" element={<Institutes />} />
        <Route path="/userslist" element={<UsersTable />} />
        <Route path="/storedprocedures" element={<StoredProcedures />} />
        <Route path="/telegramlogin" element={<TelegramLogin />} />
        <Route path="/radiologistinfo" element={<RadiologistInfo />} />
        <Route path="/invoicesummary" element={<InvoiceSummary />} />
        <Route path="/pcarehome" element={<PCareHome />} />
        <Route path="/modalitytest" element={<ModalityTest />} />
        <Route path="/template" element={<Template />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/checkaccounts" element={<CheckAccounts />} />
        <Route path="/institute" element={<Institute />} />
        <Route path="/userlist" element={<UserList />} />
        <Route path="/userssidebar" element={<UsersSidebar />} />
        <Route path="/onlineusers" element={<OnlineUsers />} />
        <Route path="/userslog" element={<UsersLog />} />
        <Route path="/pdftitle" element={<PDFTitle />} />
        <Route path="/paymentoption" element={<PaymentOption />} />
        <Route path="/patientreg" element={<PatientReg />} />
        <Route path="/refphyreg" element={<RefPhyReg />} />
        <Route path="/doctorspf" element={<DoctorsPF />} />
        <Route path="/gacal" element={<GACal />} />
        <Route path="/pndt" element={<PNDT />} />
        <Route path="/doctorreporting" element={<DoctorReporting />} />
        <Route path="/pfsettings" element={<PFSettings />} />
        <Route path="/pdfsettings" element={<PDFSettings />} />
        <Route path="/logdetails" element={<LogDetails />} />
        <Route path="/patientnametitle" element={<PatientNameTitle />} />
        <Route path="/panelinstitutes" element={<PanelInstitutes />} />
        <Route path="/imgViewer" element={<ImgViewer />} />
        <Route path="/reportPdfViewer" element={<ReportPdfViewer />} />
        <Route path="/info_display" element={<Info_Display />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/whatsappregistration" element={<WhatsappRegistration />} />
        <Route path="/usersettings" element={<UserSettingsForm />} />
        {/*<Route path="/monthlysummary" element={<MonthlySummary />} />*/}
        {/*<Route path="/teamgrpui" element={<TeamGrpUi />} />*/}
        {/*<Route path="/crm" element={<CRM />} />*/}
        {/*<Route path="/gsheet" element={<GSheet />} />*/}
        {/*<Route path="/watemplatecategory" element={<WATamplateCategory />} />*/}
      </Routes>
    </>
  )
}

export default App;