import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserList.css';
import UsersSidebar from './UsersSidebar';

const UserList = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [uType, setUtype] = useState([]);

  const refUserId = localStorage.getItem("userId");      // refUserId: Represents the unique identifier of the user who is currently logged in to the system.
  const userTypeData = localStorage.getItem("userType");
  const userNameData = localStorage.getItem("username");
  const instituteId = localStorage.getItem("instituteId");
  const instituteName = localStorage.getItem("instituteName");
  const apiHostLink = localStorage.getItem("apiHostLink");
  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [confirmUserPassword, setConfirmUserPassword] = useState('');
  const [userPermissions, setUserPermissions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [userType, setUserType] = useState('');
  const [emailId, setEmailId] = useState('');
  const [userId, setUserId] = useState('');   // userId: Refers to the unique identifier of the user on whom the current operation (addition, editing, updating, or deletion) is being performed.

  const [permissionString, setPermissionString] = useState('');
  const [checkBoxesState, setCheckBoxesState] = useState({}); // Checkbox state object

  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);


  const [newUserName, setNewUserName] = useState('');
  const [newName, setNewName] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newConfirmUserPassword, setNewConfirmUserPassword] = useState('');
  const [newEmailId, setNewEmailId] = useState('');
  const [newUserType, setNewUserType] = useState('');
  
  const getAllUserNames = () => {
    return userPermissions.map(user => user.UserName).join(', ');
  };

  const getAllNames = () => {
    return userPermissions.map(user => user.Name).join(', ');
  }

  const getAllEmailIds= () => {
    return userPermissions.map(user => user.EmailId).join(', ');
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    getUserList();
    getUTypes();
  }, []);

  const getUserList = async () => {

    const response = await axios.post(`${apiHostLink}UserList/UsersList`, {
      instituteId: instituteId,
      UserName: userNameData,
      UserType: userTypeData,
      UserId: userId,
    });

    const resp = response.data;
    setUserPermissions(resp);
    //console.log(resp);
  }

  const getUTypes = async () => {

    const response = await axios.get(`${apiHostLink}UserList/GetUTypes`, {userId: userId});
    const filteredUType = response.data.split(', ').filter(value => value !== 'None' && value !== 'count');   //Removed None and count

    setUtype(filteredUType);
  }

  // Function to extract unique usernames from userData
  const getUniqueUsernames = () => {
    const uniqueUsernames = [];
    userPermissions.forEach(user => {
      if (!uniqueUsernames.includes(user.UserName)) {
        uniqueUsernames.push(user.UserName);
      }
    });
    return uniqueUsernames;
  };

  const handleRowClick = (user) => {
    if (isEdit) {
      alert("Kindly save your changes first!");
      return;
    }
    setSelectedUser(user);
    // Do something with selected user, like displaying details or navigating to a different page
    console.log("Selected user:", user);
    setPermissionString(''); // Reset permission string
    setCheckBoxesState({}); // Reset checkbox state
    setFullName(user.Name);
    setUserName(user.UserName);
    setUserPassword(user.UserPassword);
    setUserType(user.UserType);
    setEmailId(user.EmailId);
    setUserId(user.UserID);
    //console.log(permissionString);


    // Fetch permissions for selected user from userPermissions array
    const selectedUserPermissions = userPermissions.filter((u) => u.UserName === user.UserName);
    //console.log("Selected user permissions:", selectedUserPermissions); // Log selected user permissions

    const newCheckBoxesState = {};
    // Set all checkboxes to false initially
    Object.keys(checkBoxesState).forEach(key => {
      newCheckBoxesState[key] = false;
    });
    // Set checkboxes to true based on selected user permissions
    selectedUserPermissions.forEach(permission => {
      if (permission && permission.PermissionName) {
        // Add null check before accessing PermissionName property
        newCheckBoxesState[permission.PermissionName.trim()] = true;
      }
    });
    console.log("Selected user permissions:", newCheckBoxesState); // Log initialized checkbox state

    setCheckBoxesState(newCheckBoxesState);

  }

  const handleNameChange = (event) => {
    setFullName(event.target.value);
    setNewName(event.target.value);
  };

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
    setNewUserName(event.target.value);
  };

  const handleUserPasswordChange = (event) => {
    setUserPassword(event.target.value);
    setNewUserPassword(event.target.value);
  };

  const handleConfirmUserPasswordChange = (event) => {
    setConfirmUserPassword(event.target.value);
    setNewConfirmUserPassword(event.target.value);
  };

  const handleEmailIdChange = (event) => {
    setEmailId(event.target.value);
    setNewEmailId(event.target.value);
  };

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value); // Update user type when selected from dropdown
    setNewUserType(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEditClick = () => {
    if (!selectedUser) {
      alert("Select a User!");
      return;
    }

    setIsEdit(true);
    setConfirmUserPassword(userPassword);

    const permissions = userPermissions.filter(user => user.UserName === selectedUser.UserName);
    const permissionNames = permissions.map(user => user.PermissionName);
    setPermissionString(permissionNames.join(', '));

    // Update checkBoxesState based on the permissions
    const initialCheckBoxesState = {};
    permissionNames.forEach(permission => {
      initialCheckBoxesState[permission] = true;
    });
    setCheckBoxesState(initialCheckBoxesState);

  };

  const handleUpdateClick = async() => {
    // Perform update operation...
    setIsEdit(false);

    if (!newUserName) {
      var name = userName;
    } else {
      name = newUserName;
    }

    if (!newUserType) {
      var type = userType;
    } else {
      type = newUserType;
    }

    if (!newUserPassword) {
      var pass = userPassword;
    } else {
      pass = newUserPassword
    }

    if (!newName) {
      var fullName = name;
    } else {
      fullName = newName;
    }

    if (!newEmailId) {
      var email = emailId;
    } else {
      email = newEmailId;
    }

    // Log checked checkboxes
    const checkedPermissions = Object.keys(checkBoxesState).filter(permission => checkBoxesState[permission]);
    console.log("Checked Permissions:", checkedPermissions);
    try {
      const response = await axios.post(`${apiHostLink}UserList/UpdateUser`, {
        instituteId: instituteId,
        UserName: name,
        UserType: type,
        UserPassword: pass,
        UserFullName: fullName,
        InstName: instituteName,
        EmailId: email,
        TSPName: "Default",
        CheckedPermissions: checkedPermissions,
        RefUserId: refUserId,
        UserId: userId
      });

      const resp = response.data;
      console.log(resp);


      setUpdateMessage("Successfully Updated User " + fullName);
      //setSelectedUser(null); // Reset selected user
      //setCheckBoxesState({}); // Reset checkbox state
      setTimeout(() => {
        setUpdateMessage(null);
      }, 5000);
    } catch (error) {
      alert(error.response.data);
      console.log(error);
    } finally {
      getUserList();
      setNewUserName('');
      setNewName('');
      setNewUserPassword('');
      setNewConfirmUserPassword('');
      setNewUserType('');
      setNewEmailId('');
    }

  };

  const handleCheckboxChange = (permission) => {
    if (isEdit) {
      // Toggle checkbox state
      setCheckBoxesState(prevState => ({
        ...prevState,
        [permission]: !prevState[permission]
      }));

      // Update permissionString when checkbox is clicked
      let updatedPermissionString = '';
      Object.keys(checkBoxesState).forEach(key => {
        if (checkBoxesState[key]) {
          updatedPermissionString += key + ', ';
        }
      });
      setPermissionString(updatedPermissionString);
    }
  };

  const handleVerifyUser = () => {
    if (selectedUser && userPassword === password) {
      setAuthenticated(true);
      setUpdateMessage("User Verified");
      setTimeout(() => {
        setUpdateMessage(null);
      }, 5000);
    } else {
      setAuthenticated(false);
      setUpdateMessage("Wrong User Password");
      setTimeout(() => {
        setUpdateMessage(null);
      }, 5000);
    }
  };  

  const handleAddUser = async() => {
    try {
      if (!newUserName || !newName || !newUserPassword || !newConfirmUserPassword || !newEmailId || !newUserType) {
        alert('Fill All Details!');
        return;
      }

      if (getAllUserNames().split(', ').includes(newUserName)) {
        alert('User Name Exists');
        return;
      }

      if (getAllNames().split(', ').includes(newName)) {
        alert('Name Exists');
        return;
      }

      if (getAllEmailIds().split(', ').includes(newEmailId)) {
        alert('EmailID Exists');
        return;
      }

      if (newUserPassword !== newConfirmUserPassword) {
        alert('Password and Confirm Password should be same!');
        return;
      }

      // Log checked checkboxes
      const checkedPermissions = Object.keys(checkBoxesState).filter(permission => checkBoxesState[permission]);
      console.log("Checked Permissions:", checkedPermissions);

      const response = await axios.post(`${apiHostLink}UserList/AddUser`, {
        instituteId: instituteId,
        UserName: newUserName,
        UserType: newUserType,
        UserPassword: newUserPassword,
        UserFullName: newName,
        InstName: instituteName,
        EmailId: newEmailId,
        TSPName: "Default",
        CheckedPermissions: checkedPermissions,
        RefUserId: refUserId,
        UserId: userId
      });

      const resp = response.data;

      if (resp === true) {
        alert("User Added Succesfully");
        getUserList();
      }
    } catch (error) {
      alert(error.response.data);
      console.log(error);
    } finally {
      setNewUserName('');
      setNewName('');
      setNewUserPassword('');
      setNewConfirmUserPassword('');
      setNewUserType('');
      setNewEmailId('');
    }
  }

  const handleDeleteUser = async () => {

    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.post(`${apiHostLink}UserList/DeleteUser`, {
        UserId: userId
      });

      const resp = response.data;
      console.log(resp);
      setUpdateMessage("Sucessfully Deleted User " + fullName);
      setTimeout(() => {
        setUpdateMessage(null);
      }, 5000);
    } catch (error) {
      alert(error.response.data);
      console.log(error);
    } finally {
      getUserList();
    }
  }

  return (
    <>
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '190px' : '40px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(100% - 180px)' : 'calc(50vw - 150px)', maxWidth: '700px', minWidth:'500px' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className={`usr-lst-tbl-container ${isSidebarOpen ? 'margin-left-expanded' : 'margin-left'}`}>          
          <div>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }} title='Name' >Name</th>
                  <th style={{ textAlign: 'center' }} title='UserName'>UserName</th>
                  {authenticated && (<th style={{ textAlign: 'center' }} title='UserPassword'>UserPassword</th>)}
                  <th style={{ textAlign: 'center' }} title='UserType'>UserType</th>
                  <th style={{ textAlign: 'center' }} title='Email ID'>EmailId</th>
                  <th style={{ textAlign: 'center' }} title='FontSize'>FontSize</th>
                </tr>
              </thead>
              <tbody>
                {getUniqueUsernames().map((UserName, index) => {
                  const user = userPermissions.find(user => user.UserName === UserName);
                  return (
                    <tr key={index} onClick={() => handleRowClick(user)} style={{ cursor: 'default', backgroundColor: selectedUser && selectedUser.UserName === user.UserName ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                      <td title={user.Name} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>{user.Name}</td>
                      <td title={user.UserName} style={{ textAlign: 'center' }}>{user.UserName}</td>
                      { authenticated && (<td title={user.UserPassword} style={{ textAlign: 'center' }}>{user.UserPassword}</td>)}
                      <td title={user.UserType} style={{ textAlign: 'center' }}>{user.UserType}</td>
                      <td title={user.EmailId} style={{ textAlign: 'center' }}>{user.EmailId}</td>
                      <td title={user.FontSize} style={{ textAlign: 'center' }}>{user.FontSize}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="table-container-2" style={{ marginLeft: isSidebarOpen ? '190px' : '80px', width: isSidebarOpen ? 'calc(100vw - 550px)' : 'calc(100vw - 550px)', height: '450px', display: 'flex', minWidth:'950px' }}>
        <div style={{ width: '240px', padding: '15px' }}>
          <label className="input-container" >Full Name
            <input type="text" style={{ marginLeft: '24px', height: '25px', width: '150px' }} value={isEdit ? fullName : newName} onChange={handleNameChange} />
          </label>
          <label className="input-container" >User Name
            <input type="text" style={{ marginLeft: '28px', height: '25px', width: '150px' }} value={isEdit ? userName : newUserName} onChange={handleUserNameChange} />
          </label>
          <label className="input-container" >Password
            <input type="password" style={{ marginLeft: '12px', height: '25px', width: '150px' }} value={isEdit ? userPassword : newUserPassword} onChange={handleUserPasswordChange} />
          </label>
          <label className="input-container" >Confirm Password
            <input type="password" style={{ marginLeft:'10px', height: '27px', width: '150px', marginTop: '5px' }} value={isEdit ? confirmUserPassword : newConfirmUserPassword} onChange={handleConfirmUserPasswordChange} />
          </label>
          <label className="input-container" >Email Id
            <input type="text" style={{ marginLeft: '30px', height: '27px', width: '150px', marginTop: '5px' }} value={isEdit ? emailId : newEmailId} onChange={handleEmailIdChange} />
          </label>
          <label className="input-container" >User Type
            <select type="text" value={isEdit ? userType : newUserType} onChange={handleUserTypeChange} style={{ marginLeft: '25px', height: '25px', width: 'auto' }} >
              {uType.map((value, index) => (
                <option key={index}>{value}</option>
              ))}
            </select>
          </label>
          <label className="input-container" >Institute Name
            <select type="text" style={{ marginLeft: '25px', height: '25px', width: 'auto' }} >
              <option>{instituteName}</option>
            </select>
          </label>
          <label className="input-container" >
            <input type="checkbox" style={{ marginLeft: '7px', marginTop: '5px', marginRight: '10px' }} />Show Patients Logins
          </label>
          <div style={{ marginTop: '10px', width: '320px', display: 'flex' }}>
            <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddUser} >Add User</button>
            <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={isEdit ? handleUpdateClick : handleEditClick} >{isEdit ? "Update" : "Edit"}</button>
            <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleDeleteUser} >Delete</button>
          </div>
          <div style={{ marginTop: '10px', width: '320px', display: 'flex' }}>
            <label className="input-container" >Password
              <input type="text" value={password} onChange={handlePasswordChange} style={{ marginLeft: '5px', height: '25px', width: '100px', marginTop: '0px' }} />
            </label>
            <button onClick={handleVerifyUser} className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '3px', paddingRight: '3px', marginTop: '5px' }} >Verify User</button>
          </div>
          {updateMessage && (
            <div style={{ marginTop: '10px', width: '320px', display: 'flex', color: 'green' }}>
              {updateMessage}
            </div>
          )}
        </div>
        <div style={{ display: 'flex', marginLeft: '130px' }}>
          <div style={{ width: '150px', height: 'auto', padding: '5px' }}>
            <label><input type="checkbox" disabled={!isEdit}></input>All</label>
            <label htmlFor="CreateReport">
              <input id="CreateReport" type="checkbox" checked={isEdit ? checkBoxesState['CreateReport'] : checkBoxesState['CreateReport']} onChange={() => handleCheckboxChange('CreateReport')} disabled={!isEdit} />
              Create Report
            </label>
            <label htmlFor="DeleteReport">
              <input id="DeleteReport" type="checkbox" checked={isEdit ? checkBoxesState['DeleteReport'] : checkBoxesState['DeleteReport']} onChange={() => handleCheckboxChange('DeleteReport')} disabled={!isEdit} />
              Delete Report
            </label>
            <label htmlFor="ReportApproval">
              <input id="ReportApproval" type="checkbox" checked={isEdit ? checkBoxesState['ReportApproval'] : checkBoxesState['ReportApproval']} onChange={() => handleCheckboxChange('ReportApproval')} disabled={!isEdit} />
              Report Approval
            </label>
            <label htmlFor="UnApproveReport">
              <input id="UnApproveReport" type="checkbox" checked={isEdit ? checkBoxesState['UnApproveReport'] : checkBoxesState['UnApproveReport']} onChange={() => handleCheckboxChange('UnApproveReport')} disabled={!isEdit} />
              UnApprove Report
            </label>
            <label htmlFor="TemplateAddEditDelete">
              <input id="TemplateAddEditDelete" type="checkbox" checked={isEdit ? checkBoxesState['TemplateAddEditDelete'] : checkBoxesState['TemplateAddEditDelete']} onChange={() => handleCheckboxChange('TemplateAddEditDelete')} disabled={!isEdit} />
              Template Add Edit Delete
            </label>
            <label htmlFor="ViewAccounting">
              <input id="ViewAccounting" type="checkbox" checked={isEdit ? checkBoxesState['ViewAccounting'] : checkBoxesState['ViewAccounting']} onChange={() => handleCheckboxChange('ViewAccounting')} disabled={!isEdit} />
              View Accounting
            </label>
            <label htmlFor="FullAccounting">
              <input id="FullAccounting" type="checkbox" checked={isEdit ? checkBoxesState['FullAccounting'] : checkBoxesState['FullAccounting']} onChange={() => handleCheckboxChange('FullAccounting')} disabled={!isEdit} />
              Full Accounting
            </label>
            <label htmlFor="AddTest">
              <input id="AddTest" type="checkbox" checked={isEdit ? checkBoxesState['AddTest'] : checkBoxesState['AddTest']} onChange={() => handleCheckboxChange('AddTest')} disabled={!isEdit} />
              Add Test
            </label>
            <label htmlFor="ShowPF">
              <input id="ShowPF" type="checkbox" checked={isEdit ? checkBoxesState['ShowPF'] : checkBoxesState['ShowPF']} onChange={() => handleCheckboxChange('ShowPF')} disabled={!isEdit} />
              Show PF
            </label>
            <label htmlFor="ShowPdfSettings">
              <input id="ShowPdfSettings" type="checkbox" checked={isEdit ? checkBoxesState['ShowPdfSettings'] : checkBoxesState['ShowPdfSettings']} onChange={() => handleCheckboxChange('ShowPdfSettings')} disabled={!isEdit} />
              Show PDF Settings
            </label>
            <label htmlFor="EditConcession">
              <input id="EditConcession" type="checkbox" checked={isEdit ? checkBoxesState['EditConcession'] : checkBoxesState['EditConcession']} onChange={() => handleCheckboxChange('EditConcession')} disabled={!isEdit} />
              Edit Concession
            </label>
            <label htmlFor="ShowDicomPrinterSetting">
              <input id="ShowDicomPrinterSetting" type="checkbox" checked={isEdit ? checkBoxesState['ShowDicomPrinterSetting'] : checkBoxesState['ShowDicomPrinterSetting']} onChange={() => handleCheckboxChange('ShowDicomPrinterSetting')} disabled={!isEdit} />
              Show Dicom Printer Setting
            </label>
            <label htmlFor="ShowExpense">
              <input id="ShowExpense" type="checkbox" checked={isEdit ? checkBoxesState['ShowExpense'] : checkBoxesState['ShowExpense']} onChange={() => handleCheckboxChange('ShowExpense')} disabled={!isEdit} />
              Show Expenses
            </label>
            <label htmlFor="DoctorAddEdit">
              <input id="DoctorAddEdit" type="checkbox" checked={isEdit ? checkBoxesState['DoctorAddEdit'] : checkBoxesState['DoctorAddEdit']} onChange={() => handleCheckboxChange('DoctorAddEdit')} disabled={!isEdit} />
              Doctor Add Edit
            </label>
            <label htmlFor="EditDoctorPFCategory">
              <input id="EditDoctorPFCategory" type="checkbox" checked={isEdit ? checkBoxesState['EditDoctorPFCategory'] : checkBoxesState['EditDoctorPFCategory']} onChange={() => handleCheckboxChange('EditDoctorPFCategory')} disabled={!isEdit} />
              Edit Doctor PF Cat
            </label>
            <label htmlFor="ShowLabSettings">
              <input id="ShowLabSettings" type="checkbox" checked={isEdit ? checkBoxesState['ShowLabSettings'] : checkBoxesState['ShowLabSettings']} onChange={() => handleCheckboxChange('ShowLabSettings')} disabled={!isEdit} />
              Show Lab Settings
            </label>
            <label htmlFor="AddEditManualPF">
              <input id="AddEditManualPF" type="checkbox" checked={isEdit ? checkBoxesState['AddEditManualPF'] : checkBoxesState['AddEditManualPF']} onChange={() => handleCheckboxChange('AddEditManualPF')} disabled={!isEdit} />
              Add Edit Manual PF
            </label>
            <label htmlFor="ShowWLWindow">
              <input id="ShowWLWindow" type="checkbox" checked={isEdit ? checkBoxesState['ShowWLWindow'] : checkBoxesState['ShowWLWindow']} onChange={() => handleCheckboxChange('ShowWLWindow')} disabled={!isEdit} />
              Show WL Window
            </label>
          </div>
          <div style={{ width: '150px', height: 'auto', marginLeft: '30px', padding: '5px' }}>
            <label htmlFor="ShowAllRegPatient">     {/*how to call this*/}
              <input id="ShowAllRegPatient" type="checkbox" checked={isEdit ? checkBoxesState['ShowAllRegPatient'] : checkBoxesState['ShowAllRegPatient']} onChange={() => handleCheckboxChange('ShowAllRegPatient')} disabled={!isEdit}></input>ShowAllRegPatient
            </label>
            <label htmlFor="CreateUsers">
              <input id="CreateUsers" type="checkbox" checked={isEdit ? checkBoxesState['CreateUsers'] : checkBoxesState['CreateUsers']} onChange={() => handleCheckboxChange('CreateUsers')} disabled={!isEdit} />
              Create Users
            </label>
            <label htmlFor="EditReport">
              <input id="EditReport" type="checkbox" checked={isEdit ? checkBoxesState['EditReport'] : checkBoxesState['EditReport']} onChange={() => handleCheckboxChange('EditReport')} disabled={!isEdit} />
              Edit Report
            </label>
            <label htmlFor="ViewReport">
              <input id="ViewReport" type="checkbox" checked={isEdit ? checkBoxesState['ViewReport'] : checkBoxesState['ViewReport']} onChange={() => handleCheckboxChange('ViewReport')} disabled={!isEdit} />
              View Report
            </label>
            <label htmlFor="DeleteStudy">
              <input id="DeleteStudy" type="checkbox" checked={isEdit ? checkBoxesState['DeleteStudy'] : checkBoxesState['DeleteStudy']} onChange={() => handleCheckboxChange('DeleteStudy')} disabled={!isEdit} />
              Delete Study
            </label>
            <label htmlFor="ModalityAddEditDelete">
              <input id="ModalityAddEditDelete" type="checkbox" checked={isEdit ? checkBoxesState['ModalityAddEditDelete'] : checkBoxesState['ModalityAddEditDelete']} onChange={() => handleCheckboxChange('ModalityAddEditDelete')} disabled={!isEdit} />
              Modality Add-Edit-Delete
            </label>
            <label htmlFor="DeleteDoctors">
              <input id="DeleteDoctors" type="checkbox" checked={isEdit ? checkBoxesState['DeleteDoctors'] : checkBoxesState['DeleteDoctors']} onChange={() => handleCheckboxChange('DeleteDoctors')} disabled={!isEdit} />
              DeleteDoctors
            </label>
            <label htmlFor="OnlyMyAccounting">
              <input id="OnlyMyAccounting" type="checkbox" checked={isEdit ? checkBoxesState['OnlyMyAccounting'] : checkBoxesState['OnlyMyAccounting']} onChange={() => handleCheckboxChange('OnlyMyAccounting')} disabled={!isEdit} />
              OnlyMyAccounting
            </label>
            <label htmlFor="ShowPaymentPanel">
              <input id="ShowPaymentPanel" type="checkbox" checked={isEdit ? checkBoxesState['ShowPaymentPanel'] : checkBoxesState['ShowPaymentPanel']} onChange={() => handleCheckboxChange('ShowPaymentPanel')} disabled={!isEdit} />
              ShowPaymentPanel
            </label>
            <label htmlFor="EditModalityTest">
              <input id="EditModalityTest" type="checkbox" checked={isEdit ? checkBoxesState['EditModalityTest'] : checkBoxesState['EditModalityTest']} onChange={() => handleCheckboxChange('EditModalityTest')} disabled={!isEdit} />
              Edit Modality/Test
            </label>
            <label htmlFor="EditStudyTime">
              <input id="EditStudyTime" type="checkbox" checked={isEdit ? checkBoxesState['EditStudyTime'] : checkBoxesState['EditStudyTime']} onChange={() => handleCheckboxChange('EditStudyTime')} disabled={!isEdit} />
              Edit StudyTime
            </label>
            <label htmlFor="ShowPFSettings">
              <input id="ShowPFSettings" type="checkbox" checked={isEdit ? checkBoxesState['ShowPFSettings'] : checkBoxesState['ShowPFSettings']} onChange={() => handleCheckboxChange('ShowPFSettings')} disabled={!isEdit} />
              Show PF Settings
            </label>
            <label htmlFor="ShowInventory">
              <input id="ShowInventory" type="checkbox" checked={isEdit ? checkBoxesState['ShowInventory'] : checkBoxesState['ShowInventory']} onChange={() => handleCheckboxChange('ShowInventory')} disabled={!isEdit} />
              Show Inventory
            </label>
            <label htmlFor="PNDTFill">
              <input id="PNDTFill" type="checkbox" checked={isEdit ? checkBoxesState['PNDTFill'] : checkBoxesState['PNDTFill']} onChange={() => handleCheckboxChange('PNDTFill')} disabled={!isEdit} />
              PNDT Fill
            </label>
            <label htmlFor="DeleteObsPatient">
              <input id="DeleteObsPatient" type="checkbox" checked={isEdit ? checkBoxesState['DeleteObsPatient'] : checkBoxesState['DeleteObsPatient']} onChange={() => handleCheckboxChange('DeleteObsPatient')} disabled={!isEdit} />
              Delete OBS Patient
            </label>
            <label htmlFor="EditDoctorAddressInPNDT">
              <input id="EditDoctorAddressInPNDT" type="checkbox" checked={isEdit ? checkBoxesState['EditDoctorAddressInPNDT'] : checkBoxesState['EditDoctorAddressInPNDT']} onChange={() => handleCheckboxChange('EditDoctorAddressInPNDT')} disabled={!isEdit} />
              EditDoctorAddressInPNDT
            </label>
            <label htmlFor="ShowHiddenTools">
              <input id="ShowHiddenTools" type="checkbox" checked={isEdit ? checkBoxesState['ShowHiddenTools'] : checkBoxesState['ShowHiddenTools']} onChange={() => handleCheckboxChange('ShowHiddenTools')} disabled={!isEdit} />
              Show HiddenTools
            </label>
            <label htmlFor="CanPatientWhatsApp">
              <input id="CanPatientWhatsApp" type="checkbox" checked={isEdit ? checkBoxesState['CanPatientWhatsApp'] : checkBoxesState['CanPatientWhatsApp']} onChange={() => handleCheckboxChange('CanPatientWhatsApp')} disabled={!isEdit} />
              Can Patient WhatsApp
            </label>
            <label htmlFor="CanDoctorWhatsApp">
              <input id="CanDoctorWhatsApp" type="checkbox" checked={isEdit ? checkBoxesState['CanDoctorWhatsApp'] : checkBoxesState['CanDoctorWhatsApp']} onChange={() => handleCheckboxChange('CanDoctorWhatsApp')} disabled={!isEdit} />
              Can Doctor WhatsApp
            </label>
          </div>
          <div style={{ width: '220px', height: 'auto', marginLeft: '30px', padding: '5px' }}>
            <label htmlFor="CanEditRefDocWhileEditPatient">
              <input id="CanEditRefDocWhileEditPatient" type="checkbox" checked={isEdit ? checkBoxesState['CanEditRefDocWhileEditPatient'] : checkBoxesState['CanEditRefDocWhileEditPatient']} onChange={() => handleCheckboxChange('CanEditRefDocWhileEditPatient')} disabled={!isEdit} />
              CanEditRefDocWhileEditPatient
            </label>
            <label htmlFor="SearchPastStudies">
              <input id="SearchPastStudies" type="checkbox" checked={isEdit ? checkBoxesState['SearchPastStudies'] : checkBoxesState['SearchPastStudies']} onChange={() => handleCheckboxChange('SearchPastStudies')} disabled={!isEdit} />
              Search PastStudies
            </label>
            <label htmlFor="ShowPastAccounting">
              <input id="ShowPastAccounting" type="checkbox" checked={isEdit ? checkBoxesState['ShowPastAccounting'] : checkBoxesState['ShowPastAccounting']} onChange={() => handleCheckboxChange('ShowPastAccounting')} disabled={!isEdit} />
              Show PastAccounting
            </label>
            <label htmlFor="Merge2Study">
              <input id="Merge2Study" type="checkbox" checked={isEdit ? checkBoxesState['Merge2Study'] : checkBoxesState['Merge2Study']} onChange={() => handleCheckboxChange('Merge2Study')} disabled={!isEdit} />
              Merge2Study
            </label>
            <label htmlFor="ShowSummaryCountLabel">
              <input id="ShowSummaryCountLabel" type="checkbox" checked={isEdit ? checkBoxesState['ShowSummaryCountLabel'] : checkBoxesState['ShowSummaryCountLabel']} onChange={() => handleCheckboxChange('ShowSummaryCountLabel')} disabled={!isEdit} />
              ShowSummaryCountLabel
            </label>
            <label htmlFor="ShowAccntPdfBtn">
              <input id="ShowAccntPdfBtn" type="checkbox" checked={isEdit ? checkBoxesState['ShowAccntPdfBtn'] : checkBoxesState['ShowAccntPdfBtn']} onChange={() => handleCheckboxChange('ShowAccntPdfBtn')} disabled={!isEdit} />
              ShowAccntPdfBtn
            </label>
          </div>
        </div>
      </div>

    </>
  );
};

export default UserList;
