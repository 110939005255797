import React, { useState } from 'react';
import UsersSidebar from './UsersSidebar';


const LogDetails = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <UsersSidebar />
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '100px' : '10px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(100vw - 50px)' : 'calc(100vw - 20px)' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <div style={{ marginLeft: '100px' }}>
          {/*XRay Setting*/}
          <div style={{ backgroundColor: '#d4e3ea', width: 'calc(100vw - 700px)', height: '80px', marginTop: '20px', marginLeft: '10px', border: '5px double white', borderRadius: '6px', display: 'flex' }}>
            <div style={{ display: 'flex', marginTop:'10px' }}>
              <label style={{marginLeft:'10px'} }>From:
                <input className="form-control" type="date" style={{ width: '90px', height: '20px', fontSize: '12px', paddingLeft: '0px', paddingRight: '12px', marginLeft: '5px' }} /></label>
              <label style={{marginLeft:'10px'} }>To:
                <input className="form-control" type="date" style={{ width: '90px', height: '20px', fontSize: '12px', paddingLeft: '0px', paddingRight: '12px', marginLeft: '5px' }} /></label>
            </div>
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <label style={{ marginLeft: '10px' }}>
                <input type="radio"/>Date</label>
              <label style={{ marginLeft: '10px' }}>
                <input type="radio" />Description</label>
              <div style={{ marginLeft: '-120px', marginTop: '25px', display:'flex' }}>
                <button className="gradientButton">Search</button>
                <input type="text" style={{ marginLeft: '10px', width:'120px', height:'25px' }} />
              </div>
            </div>
          </div>

          {/*table*/}
          <div style={{ backgroundColor: '#f0f0f0', width: '750px', height: '350px', marginTop: '30px', borderRadius: '2 px', marginLeft: '10px' }}>

            <div className="table-container" style={{ overflowX: 'auto', overflowY: 'auto', height: '350px', width: 'auto' }}>
              <table>
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr style={{ backgroundColor: '#e3f7ff' }} >
                    <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '1' }}>DateTime</th>
                    <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '2' }}>PcName</th>
                    <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '3' }}>Name</th>
                    <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '4' }}>Description</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>

          {/*Text Field*/}
          <div style={{ width: 'calc(100vw - 350px)', height: '120px', marginTop: '20px', marginLeft: '10px', border: '1px solid black', display: 'flex' }}>
            
          </div>


        </div>


      </div>
    </div>
  )
}

export default LogDetails;
