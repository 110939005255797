import React, { useState } from 'react';
import UsersSidebar from './UsersSidebar';


const PatientNameTitle = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <UsersSidebar />
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '60px' : '10px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(100vw - 50px)' : 'calc(100vw - 20px)' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <div style={{ marginLeft: '250px' }}>
          {/*table*/}
          <div style={{ backgroundColor: '#f0f0f0', width: '300px', height: '350px', marginTop: '30px', borderRadius: '2 px', marginLeft: '10px' }}>

            <div className="table-container" style={{ overflowX: 'auto', overflowY: 'auto', height: '350px', width: 'auto' }}>
              <table>
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr style={{ backgroundColor: '#e3f7ff' }} >
                    <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '3' }}>Title</th>
                    <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '4' }}>Gender</th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
          </div>

          <div style={{display:'flex', marginTop:'20px', marginLeft:'10px'} }>
            <label>Title:
              <input type="text" style={{ width:'140px', height:'25px', marginLeft:'10px' }} />
            </label>
          </div>
          <div style={{ display: 'flex', marginTop: '20px', marginLeft: '10px' }}>
          <label>Gender: </label>
            <label>Title:
              <input type="radio" style={{ marginLeft: '10px', marginRight:'5px' }} />Male
            </label>
            <label>
              <input type="radio" style={{ marginLeft: '10px', marginRight: '5px' }} />Female
            </label>
          </div>
          <div style={{marginTop:'20px', marginLeft:'10px'} }>
            <button className="gradientButton" style={{ marginLeft: '10px' }}>Add</button>
            <button className="gradientButton" style={{ marginLeft: '30px' }}>Update</button>
          </div>
        </div>


      </div>
    </div>
  )
}

export default PatientNameTitle;
