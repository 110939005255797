import React, { useState } from 'react';
import UsersSidebar from './UsersSidebar';


const PanelInstitutes = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <UsersSidebar />
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '100px' : '10px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(100vw - 50px)' : 'calc(100vw - 20px)' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <div style={{ marginLeft: '120px' }}>
          {/*table*/}
          <div style={{ backgroundColor: '#f0f0f0', width: '40vw', height: '200px', marginTop: '30px', borderRadius: '2 px', marginLeft: '0px' }}>

            <div className="table-container" style={{ overflowX: 'auto', overflowY: 'auto', height: '200px', width: 'auto' }}>
              <table>
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr style={{ backgroundColor: '#e3f7ff' }} >
                    <th style={{ width: '3vw', textAlign: 'left', paddingLeft: '10px', zIndex: '3' }}>Name</th>
                    <th style={{ width: '3vw', textAlign: 'left', paddingLeft: '10px', zIndex: '4' }}>Address</th>
                    <th style={{ width: '3vw', textAlign: 'left', paddingLeft: '10px', zIndex: '3' }}>District</th>
                    <th style={{ width: '3vw', textAlign: 'left', paddingLeft: '10px', zIndex: '4' }}>City</th>
                    <th style={{ width: '3vw', textAlign: 'left', paddingLeft: '10px', zIndex: '3' }}>State</th>
                    <th style={{ width: '3vw', textAlign: 'left', paddingLeft: '10px', zIndex: '4' }}>PinCode</th>
                    <th style={{ width: '3vw', textAlign: 'left', paddingLeft: '10px', zIndex: '3' }}>PhoneNo</th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
            <div>
            </div>
          </div>

          {/*Panel Institute Details*/}
          <div style={{display:'flex'} }>
            <div style={{ width: '25vw', minWidth: '20vw', height: 'auto', marginTop: '10px', border: '4px double white', borderRadius: '6px', backgroundColor: '#d4e3ea', padding: '10px', paddingBottom: '10px' }}>
              <label style={{ width: '300px', display: 'inline-flex' }}>PanelInstitute Name:
                <input type="text" style={{ width: '13vw', height: '25px' }} />
              </label>
              <label style={{ width: '25vw', display: 'inline-flex', marginTop: '20px' }}>Address:
                <textarea style={{ width: '13vw', height: '50px', marginLeft: '62px' }}></textarea>
              </label>
              <label style={{ width: '25vw', display: 'inline-flex' }}>Disrict:
                <input type="text" style={{ width: '13vw', height: '25px', marginLeft: '71px' }} />
              </label>
              <label style={{ width: '25vw', display: 'inline-flex' }}>City:
                <input type="text" style={{ width: '13vw', height: '25px', marginLeft: '85px' }} />
              </label>
              <label style={{ width: '25vw', height: '25px', display: 'inline-flex' }}>State:
                <input type="text" style={{ width: '13vw', marginLeft: '80px' }} />
              </label>
              <label style={{ width: '25vw', height: '25px', display: 'inline-flex' }}>Pincode:
                <input type="text" style={{ width: '13vw', marginLeft: '64px' }} />
              </label>
              <label style={{ width: '25vw', height: '25px', display: 'inline-flex' }}>PhoneNo. :
                <input type="text" style={{ width: '13vw', marginLeft: '50px' }} />
              </label>

              <button className="gradientButton" style={{ marginLeft: '100px', marginTop: '10px' }}>Add PanelInstitute</button>
            </div>

            <div style={{ width: '25vw', height: '80px', marginTop: '10px', border: '4px double white', borderRadius: '6px', backgroundColor: '#d4e3ea', padding: '1vw', paddingBottom: '10px' }}>
              <button className="gradientButton" style={{ marginLeft: '7vw', marginTop: '10px' }}>Edit PanelInstitute</button>
            </div>
          </div>

        </div>


      </div>
    </div>
  )
}

export default PanelInstitutes;
