import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';

function CheckboxesDropdown({ options, selectedOptions, handleSelectOption }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (isOpen && !event.target.closest('.dropdown')) {
        setIsOpen(false);
      }
    }
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="dropdown" style={{ position: 'relative', width: '190px', height: 'auto', marginTop: '0px', marginLeft: '4px' }}>
      <button className="btn btn-light dropdown-toggle" type="button" onClick={toggleDropdown} style={{ width: '100%', fontSize: '12px' }}>
        Select Options
      </button>
      {isOpen && (
        <div className="dropdown-menu" style={{ display: 'block', width: '100px', maxHeight: '200px', overflowY: 'auto' }}>
          {options.map((option, index) => (
            <div key={index} className="form-check">
              <input
                type="checkbox"
                id={`checkbox${index}`}
                className="form-check-input"
                value={option}
                checked={selectedOptions.includes(option)}
                onChange={() => handleSelectOption(option)}
              />
              <label htmlFor={`checkbox${index}`} className="form-check-label" style={{ marginLeft: '5px' }}>{option}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const DoctorsPF = () => {
  const instituteId = localStorage.getItem("instituteId");
  const instituteName = localStorage.getItem("instituteName");
  const apiHostLink = localStorage.getItem("apiHostLink");
  const userId = localStorage.getItem("userId");
  const [disable, setDisable] = useState(true);
  const [disable2, setDisable2] = useState(true);
  const [selectedDetailColumns, setSelectedDetailColumns] = useState('');
  const [selectedSummaryColumns, setSelectedSummaryColumns] = useState('');
  const DateTime = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 16);

  const [modality, setModality] = useState([]);
  const [pro, setPro] = useState([]);
  const [test, setTest] = useState([]);
  const [refphy2, setRefphy2] = useState([]);
  var [fromDate, setFromDate] = useState(new Date());
  var [toDate, setToDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [summaryColumns, setSummaryColumns] = useState([]);
  const [detailColumns, setDetailColumns] = useState([]);

  const [selectedModality, setSelectedModality] = useState('All');
  const [selectedPro, setSelectedPro] = useState('All');
  const [selectedTest, setSelectedTest] = useState('All');
  const [selectedRefPhy2, setSelectedRefPhy2] = useState('All');
  const [totalPfAmt, setTotalPfAmt] = useState(0);
  const [selectedUser, setSelectedUser] = useState('All');
  const [patientList, setPatientList] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState('All');
  const [selectedRadioOption, setSelectedRadioOption] = useState('OnFullAmt'); // 'collectedAmount' is default
  const [selectedOption, setSelectedOption] = useState('BearedByRadiologist'); // 'radiologist' is default
  const [selectedOrientation, setSelectedOrientation] = useState('landscape'); // 'landscape' is default
  const [startEachOnNewPage, setStartEachOnNewPage] = useState(false);
  const [createSeparatePDFEachDoc, setCreateSeparatePDFEachDoc] = useState(false);
  
  useEffect(() => {
    setDisable(false);
    setDisable2(false);
    console.log("Requeted for Initial Data");
    getData();
  }, []);


  const getData = async () => {

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/FillData`, {
        instituteId: instituteId,
        userId: userId,
      });

      const resp = response.data;
      setModality(JSON.parse(resp.modality));
      setPro(JSON.parse(resp.pro));
      setTest(JSON.parse(resp.test));
      setRefphy2(JSON.parse(resp.refPhy2));
      setSummaryColumns(resp.summaryPdfColumns);
      setDetailColumns(resp.detailPdfColumns);
      console.log("Data Response :");
      console.log(resp);
    } catch (error) {
      alert(error.data);
      console.log(error);
    }
  }

  // Create a Set to store unique test names
  const uniqueTestNames = new Set(test.map(test => test.TestName));

  // Convert the Set back to an array
  const uniqueTests = [...uniqueTestNames];

  const handleModalityChange = (event) => {
    setSelectedModality(event.target.value);
  };

  const handleProChange = (event) => {
    setSelectedPro(event.target.value);
  };

  const handleTestChange = (event) => {
    setSelectedTest(event.target.value);
  };

  const handleRefPhy2Change = (event) => {
    setSelectedRefPhy2(event.target.value);
  };

  const filteredTests = selectedModality
    ? test.filter(item => item.ModalityName === selectedModality)
    : [];

  // Check if test names are available for the selected modality
  const hasTestsForModality = filteredTests.length > 0;

  const formatDateDDMMYYYY = (date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
    //const yyyy = date.getFullYear();// String(date.getFullYear());//.substring((2, 2);
    //return `${dd}-${mm}-${yyyy}`;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const sec = String(date.getSeconds()).padStart(2, '0');
    //return `${year}-${month}-${day} T${hour}:${min}:${sec}`;
    return `${year}-${month}-${day}T${hour}:${min}:${sec}`;
  };

  const handleSearchClick = async () => {
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    const response = await axios.post(`${apiHostLink}DoctorsPF_/FillTable`, {
      instituteId: instituteId,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      modality: selectedModality,
      pro: selectedPro,
      test: selectedTest,
      drName: selectedRefPhy2,
      pf1: selectedRadioOption,      //store radio bool here
      pf2: selectedOption,
      userId: userId,
    });

    const resp = response.data;
    console.log("Server Response for Search Click ");
    console.log(resp);
    setData(resp);
    if (resp.length > 0) {
      setDisable(true);
    }
    var total = 0;
    for (var i = 0; i < resp.length; i++) {
      total += resp[i].TotalPfAmt;
    }
    setTotalPfAmt(total);
    console.log("Total PF Amt " + total);
  }

  const handleUserClick = (userData) => {
    setSelectedUser(userData);
    console.log("Selected User Details:", userData);

    ShowPatient(userData.DoctorId, userData.DoctPfCatId)
  };

  const handlePatientClick = (patientData) => {
    setSelectedPatient(patientData);
    console.log("Selected Patient Details:", patientData);
  }

  const handleRadioChange = (event) => {
    setSelectedRadioOption(event.target.value);
  };

  const handleRadioChange2 = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRadioChange3 = (event) => {
    setSelectedOrientation(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setStartEachOnNewPage(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setCreateSeparatePDFEachDoc(event.target.checked);
  };

  const ShowPatient = async (doctId, doctPfCatId) => {

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    const response = await axios.post(`${apiHostLink}DoctorsPF_/ShowPatientsForSelectedDr`, {
      instituteId: instituteId,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      pro: selectedPro,
      doctId: doctId,
      doctPfCatId: doctPfCatId,
      pf1: 'OnCollectedAmt',      //store radio bool here
      pf2: 'BearedByRadiologist',
      userId: userId,
    });

    const resp = response.data;
    console.log("Server Response for Show Patient Click ");
    console.log(resp);
    setPatientList(resp);
    setDisable2(true);
  };

  const btnDetailPDFClick = async () => {
    setDisable(false);

    if (!selectedDetailColumns) {
      alert("Kindly Select Columns");
      setDisable(true);
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    console.log(selectedDetailColumns);
    var commaSeparatedValue = selectedDetailColumns.join(',');

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/DetailPDF`, {
        instituteId: instituteId,
        instituteName: instituteName,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,      //store radio bool here
        pf2: selectedOption,
        DetailPdfColumns: commaSeparatedValue,
        totalPf: totalPfAmt,
        startEachOnNewPage: startEachOnNewPage,
        createSeparatePDFEachDoc: createSeparatePDFEachDoc,
        userId: userId
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);

      const zipFileName = "DrDetailPF" + '_' + dateStr + ".pdf";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: 'application/' + "pdf" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();

    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
      setDisable(true);
    } finally {
      setDisable(true);
    }
  };

  const btnSummaryPDFClick = async () => {
    //setDisable(false);

    if (!selectedSummaryColumns) {
      alert("Kindly Select Columns");
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    console.log(selectedSummaryColumns);
    var commaSeparatedValue = selectedSummaryColumns.join(',');

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/SummaryPDF`, {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,      //store radio bool here
        pf2: selectedOption,
        SummaryColumns: commaSeparatedValue,
        totalPf: totalPfAmt,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);
      //DrPFSummary_29_May_24_17_45_58.pdf"
      const zipFileName = "DrPFSummary" + '_' + dateStr + ".pdf";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/pdf" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();


    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
    } finally {
      //setDisable(true);
    }
  };

  const btnAllDrAddressPdfClick = async () => {
    //setDisable(false);

    if (!data) {
      alert('No records are found to show in CSV file. First click on Search button');
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/AllDrAddressPdf`, {
        instituteId: instituteId,
        instituteName: instituteName,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,      //store radio bool here
        pf2: selectedOption,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);
      //DrPFSummary_29_May_24_17_45_58.pdf"
      const zipFileName = "DrPFLable" + '_' + dateStr + ".pdf";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/pdf" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();


    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
    } finally {
      //setDisable(true);
    }
  };

  const btnDetailExcelClick = async () => {
    //setDisable(false);

    if (!data) {
      alert('No records are found to show in CSV file. First click on Search button');
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/DetailExcel`, {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption, //store radio bool here
        pf2: selectedOption,
        instituteName: instituteName,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("CSV Created:", response.data);
      const zipFileName = "DoctorPF" + '_' + dateStr + ".csv";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();

    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
    } finally {
      //setDisable(true);
    }
  };


  const btnSummaryCSVClick = async () => {
    //setDisable(false);

    if (!data) {
      alert('No records are found to show in CSV file. First click on Search button');
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);


    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/SummaryCSV`, {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,      //store radio bool here
        pf2: selectedOption,
        instituteName: instituteName,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);
      //DrPFSummary_29_May_24_17_45_58.pdf"
      const zipFileName = "DoctorPF" + '_' + dateStr + ".csv";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/csv" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();

    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
    } finally {
      //setDisable(true);
    }
  };

  const btnSingleDrDetailExcelClick = async () => {
    //setDisable(false);

    if (!data) {
      alert('No records are found to show in CSV file. First click on Search button');
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/SingleDrDetailExcel`, {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,      //store radio bool here
        pf2: selectedOption,
        instituteName: instituteName,
        doctId: selectedUser.DoctorId,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("CSV Created:", response.data);
      const csvFileName = "DoctorPF" + '_' + dateStr + ".csv";
      alert(csvFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "text/csv" });   // application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = csvFileName;
      link.click();

    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
    } finally {
      //setDisable(true);
    }
  };


  const btnSingleDrDetailPDFClick = async () => {
    //setDisable(false);

    if (!data) {
      alert('No records are found to show in CSV file. First click on Search button');
      return;
    }

    if (!selectedDetailColumns) {
      alert("Kindly Select Columns");
      //setDisable(true);
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);
    var commaSeparatedValue = selectedDetailColumns.join(',');

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/SingleDrDetailPDF`, {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,      //store radio bool here
        pf2: selectedOption,
        DetailPdfColumns: commaSeparatedValue,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);
      //DrPFSummary_29_May_24_17_45_58.pdf"
      const zipFileName = "DrPFLable" + '_' + dateStr + ".pdf";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/pdf" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();


    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
      //setDisable(true);
    } finally {
      //setDisable(true);
    }
  };

  const btnSingleDrAddressPDFClick = async () => {
    //setDisable(false);

    if (!data) {
      alert('No records are found to show in CSV file. First click on Search button');
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/SingleDrAddressPDF`, {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,      //store radio bool here
        pf2: selectedOption,
        instituteName: instituteName,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);
      //DrPFSummary_29_May_24_17_45_58.pdf"
      const zipFileName = "SingleDrAddressPDF" + '_' + dateStr + ".pdf";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/pdf" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();


    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
    } finally {
      //setDisable(true);
    }
  };

  const btnAllDrWith0PfClick = async () => {
    //setDisable(false);

    if (!data) {
      alert('No records are found to show in CSV file. First click on Search button');
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/AllDrWith0Pf`, {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,      //store radio bool here
        pf2: selectedOption,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);
      //DrPFSummary_29_May_24_17_45_58.pdf"
      const zipFileName = "AllDrWith0Pf" + '_' + dateStr + ".pdf";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/pdf" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();


    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
    } finally {
      //setDisable(true);
    }
  };

  const btnMonthlyPatientsClick = async () => {
    if (!data) {
      alert('No records are found to show in CSV file. First click on Search button');
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    const doctId = selectedUser && selectedUser.DoctorId ? selectedUser.DoctorId : -1;

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/MonthlyPatients`, {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,
        pf2: selectedOption,
        instituteName: instituteName,
        doctId: doctId,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("CSV Created:", response.data);
      const csvFileName = "MonthlyPatients" + '_' + dateStr + ".csv";
      alert(csvFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = csvFileName;
      link.click();
    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
    } finally {
      //setDisable(true);
    }
  };


  //const btnMonthlyPatientsClick = async () => {
  //  //setDisable(false);

  //  if (!data) {
  //    alert('No records are found to show in CSV file. First click on Search button');
  //    return;
  //  }

  //  const formattedFromDate = formatDate(fromDate);
  //  const formattedToDate = formatDate(toDate);

  //  try {
  //    const response = await axios.post(`${apiHostLink}DoctorsPF_/MonthlyPatients`, {
  //      instituteId: instituteId,
  //      fromDate: formattedFromDate,
  //      toDate: formattedToDate,
  //      modality: selectedModality,
  //      pro: selectedPro,
  //      test: selectedTest,
  //      drName: selectedRefPhy2,
  //      pf1: selectedRadioOption,      //store radio bool here
  //      pf2: selectedOption,
  //      instituteName: instituteName,
  //      doctId: selectedUser.DoctorId,
  //      userId: userId,
  //    }, {
  //      responseType: 'blob' // Set responseType to 'blob' to handle binary data
  //    });

  //    const resp = response.data;
  //    console.log("Server Response for Show Patient Click ");
  //    console.log(resp);

  //    const parts = DateTime.split(/[-T]/);
  //    var dateStr = parts.join("_");

  //    console.log("Pdf Created:", response.data);
  //    //DrPFSummary_29_May_24_17_45_58.pdf"
  //    const zipFileName = "MonthlyPatients" + '_' + dateStr + ".xlsx";
  //    alert(zipFileName + " file is downloading");
  //    const blob = new Blob([response.data], { type: "application/application/vnd.ms-excel" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
  //    const url = window.URL.createObjectURL(blob);
  //    const link = document.createElement('a');
  //    link.href = url;
  //    link.download = zipFileName;
  //    link.click();


  //  } catch (error) {
  //    console.error(error);
  //    alert(error.message || 'An error occurred');
  //  } finally {
  //    //setDisable(true);
  //  }
  //};



  const btnMenuModalityPFPDFClick = async () => {
    //setDisable(false);

    if (!data) {
      alert('No records are found to show in CSV file. First click on Search button');
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    try {
      const response = await axios.post(`${apiHostLink}DoctorsPF_/MenuModalityPFPDF`, {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        pro: selectedPro,
        test: selectedTest,
        drName: selectedRefPhy2,
        pf1: selectedRadioOption,      //store radio bool here
        pf2: selectedOption,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const resp = response.data;
      console.log("Server Response for Show Patient Click ");
      console.log(resp);

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      console.log("Pdf Created:", response.data);
      //DrPFSummary_29_May_24_17_45_58.pdf"
      const zipFileName = "MenuModalityPFPDF" + '_' + dateStr + ".pdf";
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: "application/pdf" });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();


    } catch (error) {
      console.error(error);
      alert(error.message || 'An error occurred');
    } finally {
      //setDisable(true);
    }
  };

  const handleSelectDetailColumns = (option) => {
    const selectedIndex = selectedDetailColumns.indexOf(option);

    if (selectedIndex === -1) {                //when ticked
      setSelectedDetailColumns([...selectedDetailColumns, option]);

      //localStorage.setItem('AccountTypeStr', JSON.stringify([...selectedPdfColumns, option]));
    } else {    //when unticked
      const updatedOptions = [...selectedDetailColumns];
      updatedOptions.splice(selectedIndex, 1);
      setSelectedDetailColumns(updatedOptions);

      //localStorage.setItem('AccountTypeStr', JSON.stringify(updatedOptions));
    }
  };

  const handleSelectSummaryColumns = (option) => {
    const selectedIndex = selectedSummaryColumns.indexOf(option);

    if (selectedIndex === -1) {                //when ticked
      setSelectedSummaryColumns([...selectedSummaryColumns, option]);

    } else {    //when unticked
      const updatedOptions = [...selectedSummaryColumns];
      updatedOptions.splice(selectedIndex, 1);
      setSelectedSummaryColumns(updatedOptions);

    }
  };

  const handlePrev = () => {
    let newFromDate = new Date(fromDate);
    let newToDate;

    if (newFromDate.getDate() === 1) {
      // Go to the 16th of the previous month
      newFromDate.setMonth(newFromDate.getMonth() - 1);
      newFromDate.setDate(16);
      newToDate = new Date(newFromDate.getFullYear(), newFromDate.getMonth() + 1, 0); // End of month
    } else {
      // Go to the 1st of the current month
      newFromDate.setDate(1);
      newToDate = new Date(newFromDate);
      newToDate.setDate(15);
    }

    setFromDate(newFromDate);
    setToDate(newToDate);
  };

  const handleNext = () => {
    let newFromDate = new Date(fromDate);
    let newToDate;

    if (newFromDate.getDate() === 1) {
      // Go to the 16th of the current month
      newFromDate.setDate(16);
      newToDate = new Date(newFromDate.getFullYear(), newFromDate.getMonth() + 1, 0); // End of month
    } else {
      // Go to the 1st of the next month
      newFromDate.setMonth(newFromDate.getMonth() + 1);
      newFromDate.setDate(1);
      newToDate = new Date(newFromDate);
      newToDate.setDate(15);
    }

    setFromDate(newFromDate);
    setToDate(newToDate);
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <Sidebar />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="header-btn" style={{ padding: '20px', display: 'flex', flexDirection: 'row', height: '50px' }}>
            <div className="div-1" style={{ display: 'flex', height: '100px', padding: '10px', flexWrap: 'wrap', width: '400px' }}>
              <label style={{ display: 'flex' }}>From
                <input className="form-control" type="date" style={{ width: '85px', height: '25px', fontSize: '11px', marginLeft: '10px', paddingRight: '14px', borderRadius: '5px' }} value={formatDateDDMMYYYY(fromDate)} onChange={(e) => setFromDate(new Date(e.target.value))} />
              </label>
              <label style={{ display: 'flex', marginLeft: '10px' }}>To
                <input className="form-control" type="date" style={{ width: '85px', height: '25px', fontSize: '11px', marginLeft: '10px', paddingRight: '14px', borderRadius: '5px' }} value={formatDateDDMMYYYY(toDate)} onChange={(e) => setToDate(new Date(e.target.value))} />
              </label>
              <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px' }} onClick={handlePrev}>Prev</button>
              <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px' }} onClick={handleNext}>Next</button>
              <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px' }} onClick={handleSearchClick} >Search</button>
              <div className="chld-div" style={{ width: 'auto', display: 'inline-flex', marginTop: '30px' }}>
                <label>PRO<br />
                  <select style={{ width: '150px' }} onChange={handleProChange}>
                    {pro.map((pro, index) => (
                      <option key={index} value={pro.Name}>{pro.Name}</option>
                    ))}
                  </select>
                </label>
                <label style={{ marginLeft: '20px' }}>RefPhy2<br />
                  <select style={{ width: '150px' }} onChange={handleRefPhy2Change}>
                    {refphy2.map((refphy2, index) => (
                      <option key={index} value={refphy2.DoctorName}>{refphy2.DoctorName}</option>
                    ))}
                  </select>
                </label>
                <label style={{ marginLeft: '20px' }}>Modality<br />
                  <select style={{ width: '150px' }} onChange={handleModalityChange}>
                    {modality.map((modality, index) => (
                      <option key={index} value={modality.ModalityName}>
                        {modality.ModalityName}
                      </option>
                    ))}
                  </select>
                </label>
                <label style={{ marginLeft: '20px' }}>Test<br />
                  <select style={{ width: '150px' }} onChange={handleTestChange}>
                    {/* Render "All" if no tests are available for the selected modality */}
                    {!hasTestsForModality && <option value="">All</option>}
                    {/* Render all unique test names if "All" is selected */}
                    {selectedModality === "All" && uniqueTests.map((testName, index) => (
                      <option key={index} value={testName}>{testName}</option>
                    ))}
                    {filteredTests.map((test, index) => (
                      <option key={index} value={test.TestName}>{test.TestName}</option>
                    ))}
                  </select>
                </label>
              </div>
            </div>

            <div className="div-1" style={{ display: 'flex', height: '100px', padding: '10px', flexWrap: 'wrap', width: '550px' }}>
              <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '4px', paddingBottom: '1px', paddingRight: '4px', display: 'flex', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? btnDetailPDFClick : undefined} disabled={!disable}>Detail PDF</button>
              <button style={{
                marginLeft: '10px', height: '20px',width:'20px', display: 'flex', justifyContent: 'center', alignItems: 'center'
              }}
                className="btn btn-secondary dropdown-toggle mt-1" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ fontSize: '12px' }}>
                <li><button className="gradientButton" style={{ display: 'flex', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? btnMenuModalityPFPDFClick : undefined} disabled={!disable}>Modalitywise PF</button></li>
              </ul>
              <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px', display: 'flex', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? btnSummaryPDFClick : undefined} disabled={!disable}>Summary Pdf</button>
              <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px', display: 'flex', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? btnAllDrAddressPdfClick : undefined} disabled={!disable}>All Dr Address PDF</button>
              <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px', display: 'flex', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? btnDetailExcelClick : undefined} disabled={!disable}>Detail Excel</button>
              <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px', display: 'flex', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? btnSummaryCSVClick : undefined} disabled={!disable}>Summary CSV</button>

              <div className="chld-div" style={{ width: 'auto', display: 'inline-flex', marginTop: '20px' }}>
                <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px', display: 'flex', opacity: disable2 ? 1 : 0.5, cursor: disable2 ? 'pointer' : 'not-allowed' }} onClick={disable2 ? btnSingleDrDetailPDFClick : undefined} disabled={!disable2}>Single Dr Detail PDF</button>
                <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px', display: 'flex', opacity: disable2 ? 1 : 0.5, cursor: disable2 ? 'pointer' : 'not-allowed' }} onClick={disable2 ? btnSingleDrAddressPDFClick : undefined} disabled={!disable2}>Single Dr Address PDF</button>
                <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px', display: 'flex', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? btnAllDrWith0PfClick : undefined} disabled={!disable}>Create PDF For All 0 Pf Dr</button>
                <button className="gradientButton" style={{ marginLeft: '10px', paddingLeft: '4px', paddingTop: '1px', paddingBottom: '1px', paddingRight: '4px', display: 'flex', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? btnMonthlyPatientsClick : undefined} disabled={!disable}>Monthly Patients</button>
              </div>
              <label style={{ marginLeft: '320px', marginTop: '25px' }}>Total PFAmount : {totalPfAmt}</label>
            </div>


            <div className="div-3" style={{ display: 'flex', flexDirection: 'column', height: '600px', paddingTop: '10px', marginLeft: '60px', width: '200px' }}>
              <button className="gradientButton" style={{
                width: '140px', display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: disable2 ? 1 : 0.5, cursor: disable2 ? 'pointer' : 'not-allowed' }} onClick={disable2 ? btnSingleDrDetailExcelClick : undefined} disabled={!disable2}>Single Dr Detail Excel</button>
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <label>
                  <input type="radio" style={{ marginRight: '4px' }} name="orientation" value="landscape" checked={selectedOrientation === 'landscape'} onChange={handleRadioChange3}/>LandScape</label>
                <label>
                  <input type="radio" style={{ marginLeft: '10px' }} name="orientation" value="portrait" checked={selectedOrientation === 'portrait'} onChange={handleRadioChange3} />Portrait
                </label>
              </div>
              <label style={{ marginTop: '10px' }}>
                <input type="checkbox" style={{ marginRight: '5px' }} checked={startEachOnNewPage} onChange={handleCheckboxChange}></input>StartEachOnNewPage
              </label>
              <label style={{ marginTop: '5px' }}>
                <input type="checkbox" style={{ marginRight: '5px' }} checked={createSeparatePDFEachDoc} onChange={handleCheckboxChange2}></input>CreateSeparatePDFEachDoc
              </label>
              <label style={{ marginTop: '5px', marginLeft: '40px' }}>Summary Columns</label>
              <CheckboxesDropdown options={summaryColumns} selectedOptions={selectedSummaryColumns} handleSelectOption={handleSelectSummaryColumns} />

              <label style={{ marginTop: '5px', marginLeft: '50px' }}>Detail Columns</label>
              <CheckboxesDropdown options={detailColumns} selectedOptions={selectedDetailColumns} handleSelectOption={handleSelectDetailColumns} />

              <div style={{ marginTop: '15px', height: 'auto', width: 'auto', backgroundColor: '#d4e3ea', padding: '10px', border: '5px double white', borderRadius: '4px' }}>
                <label style={{ marginTop: '5px' }}>
                  <input type="checkbox" style={{ marginRight: '5px' }}></input>ExcludingPending PaymentPatient
                </label>
                <label style={{ marginTop: '5px' }}>
                  <input type="checkbox" style={{ marginRight: '5px' }}></input>ExcludingConsession Patients
                </label>
                <label style={{ marginTop: '5px' }}>
                  <input type="checkbox" style={{ marginRight: '5px' }}></input>DependOnAmountPaid
                </label>
                <label style={{ marginTop: '5px' }}>
                  <input type="checkbox" style={{ marginRight: '5px' }}></input>AssignPfToRefPhy2
                </label>
              </div>
              <div style={{ marginTop: '15px', height: 'auto', width: 'auto', backgroundColor: '#d4e3ea', padding: '10px', border: '5px double white', borderRadius: '4px' }}>
                <label style={{ marginTop: '5px' }}>
                  <input type="radio" style={{ marginRight: '5px' }} name="paymentOption" value="OnFullAmt" checked={selectedRadioOption === 'OnFullAmt'} onChange={handleRadioChange}></input>On Full Test Price
                </label>
                <label style={{ marginTop: '5px' }}>
                  <input type="radio" style={{ marginRight: '5px' }} name="paymentOption" value="OnCollectedAmt" checked={selectedRadioOption === 'OnCollectedAmt'} onChange={handleRadioChange}></input>On Collected Amount
                </label>
              </div>
              <div style={{ marginTop: '15px', height: 'auto', width: 'auto', backgroundColor: '#d4e3ea', padding: '10px', border: '5px double white', borderRadius: '4px' }}>
                <label style={{ marginTop: '5px' }}>
                  <input type="radio" style={{ marginRight: '5px' }} name="bearedBy" value="BearedByRadiologist" checked={selectedOption === 'BearedByRadiologist'} onChange={handleRadioChange2}></input>Beared By Radiologist
                </label>
                <label style={{ marginTop: '5px' }}>
                  <input type="radio" style={{ marginRight: '5px' }} name="bearedBy" value="BearedByRefDoctor" checked={selectedOption === 'BearedByRefDoctor'} onChange={handleRadioChange2}></input>Beared By RefDoctor
                </label>
                <label style={{ marginTop: '5px' }}>
                  <input type="radio" style={{ marginRight: '5px' }} name="bearedBy" value="BearedByBoth" checked={selectedOption === 'BearedByBoth'} onChange={handleRadioChange2}></input>Beared By Both
                </label>
                <label style={{ marginTop: '5px' }}>
                  <input type="radio" style={{ marginRight: '5px' }} name="bearedBy" value="BearedByNarration" checked={selectedOption === 'BearedByNarration'} onChange={handleRadioChange2}></input>As Per Narration
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: '120px',
              marginLeft: '30px',
              width: '1000px',
              height: '250px',
              display: 'flex',
              border: '1px solid skyblue',
              borderRadius: '2px',
              overflowX: 'auto',
              overflowY: 'auto'
            }}
          >
            <table>
              <thead>
                <tr>
                  <th>DoctorName</th>
                  <th>DoctPfCatName</th>
                  <th>DoctArea</th>
                  <th>Address</th>
                  <th>MobileNo</th>
                  <th>PatientCount</th>
                  <th>StudyCount</th>
                  <th>TotalStudyAmt</th>
                  <th>TotalPfAmt</th>
                  <th>BalancedAmt</th>
                  <th>Degree</th>
                </tr>
              </thead>
              <tbody>
                {data.map((data, index) => (
                  <tr key={index} onClick={() => handleUserClick(data)} style={{ cursor: 'default', backgroundColor: selectedUser === data ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                    <td>{data.DoctorName}</td>
                    <td>{data.DoctPfCatName}</td>
                    <td>{data.DoctArea}</td>
                    <td>{data.Address}</td>
                    <td>{data.MobileNo}</td>
                    <td>{data.PatientCount}</td>
                    <td>{data.StudyCount}</td>
                    <td>{data.TotalStudyAmt}</td>
                    <td>{data.TotalPfAmt}</td>
                    <td>{data.BalancedAmt}</td>
                    <td>{data.Degree}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {patientList && <div>
            <label style={{ marginLeft: '30px', marginTop: '10px', fontSize: '13px' }} > Patient List For Selected Doctor:</label>
            <div
              style={{
                marginLeft: '30px',
                width: '1000px',
                height: '250px',
                display: 'flex',
                border: '1px solid skyblue',
                borderRadius: '2px',
                overflowX: 'auto',
                overflowY: 'auto'
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>PatientName</th>
                    <th>Modality</th>
                    <th>Test</th>
                    <th>TestAmt</th>
                    <th>Concession</th>
                    <th>CollectedAmt</th>
                    <th>Basis</th>
                    <th>PfValue</th>
                    <th>Pf</th>
                    <th>ManualPf</th>
                    <th>BalancedAmt</th>
                    <th>Narration</th>
                  </tr>
                </thead>
                <tbody>
                  {patientList.map((data, index) => (
                    <tr key={index} onClick={() => handlePatientClick(data)} style={{ cursor: 'default', backgroundColor: selectedPatient === data ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                      <td>{data.StudyTime}</td>
                      <td>{data.PatientName}</td>
                      <td>{data.Modality}</td>
                      <td>{data.Test}</td>
                      <td>{data.TestAmt}</td>
                      <td>{data.Concession}</td>
                      <td>{data.CollectedAmount}</td>
                      <td>{data.Basis}</td>
                      <td>{data.PfValue}</td>
                      <td>{data.Pf}</td>
                      <td>{data.ManualPf}</td>
                      <td>{data.BalancedAmt}</td>
                      <td>{data.Narration}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
};

export default DoctorsPF;
