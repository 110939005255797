import React, { useEffect, useState } from 'react';

export default function DetectKey() {
  const [isCtrl, setIsCtrl] = useState(false);
  const [isShift, setIsShift] = useState(false);
  const [isAlt, setIsAlt] = useState(false);
  const [isEnter, setIsEnter] = useState(false);
  const [isSpace, setIsSpace] = useState(false);
  const [isEsc, setIsEsc] = useState(false);
  const [isArrowUp, setIsArrowUp] = useState(false);
  const [isArrowDown, setIsArrowDown] = useState(false);
  const [isArrowLeft, setIsArrowLeft] = useState(false);
  const [isArrowRight, setIsArrowRight] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'Control':
          setIsCtrl(true);
          break;
        case 'Shift':
          setIsShift(true);
          break;
        case 'Alt':
          setIsAlt(true);
          break;
        case 'Enter':
          setIsEnter(true);
          break;
        case ' ':
          setIsSpace(true);
          break;
        case 'Escape':
          setIsEsc(true);
          break;
        case 'ArrowUp':
          setIsArrowUp(true);
          break;
        case 'ArrowDown':
          setIsArrowDown(true);
          break;
        case 'ArrowLeft':
          setIsArrowLeft(true);
          break;
        case 'ArrowRight':
          setIsArrowRight(true);
          break;
        default:
          break;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.key) {
        case 'Control':
          setIsCtrl(false);
          break;
        case 'Shift':
          setIsShift(false);
          break;
        case 'Alt':
          setIsAlt(false);
          break;
        case 'Enter':
          setIsEnter(false);
          break;
        case ' ':
          setIsSpace(false);
          break;
        case 'Escape':
          setIsEsc(false);
          break;
        case 'ArrowUp':
          setIsArrowUp(false);
          break;
        case 'ArrowDown':
          setIsArrowDown(false);
          break;
        case 'ArrowLeft':
          setIsArrowLeft(false);
          break;
        case 'ArrowRight':
          setIsArrowRight(false);
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return { isCtrl, isShift, isAlt, isEnter, isSpace, isEsc, isArrowUp, isArrowDown, isArrowLeft, isArrowRight };
}
