import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OnlineUsers.css';
import UsersSidebar from './UsersSidebar';

const OnlineUsers = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [users, setUsers] = useState([]);
  const apiHostLink = localStorage.getItem("apiHostLink");
  const username = localStorage.getItem("username");
  const instituteId = localStorage.getItem("instituteId");
  const userId = localStorage.getItem("userId");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    axios.get(`${apiHostLink}OnlineUser/OnlineUsers?instituteId=${instituteId}&userName=${username}&userId=${userId}`)
      .then(response => {
        const data = response.data;
        // Check if data is an array or a single object
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          setUsers([data]);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the online users!', error);
      });
  }, []);

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div style={{ userSelect:'none' }}>
      <UsersSidebar />
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '190px' : '40px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(60vw - 400px)' : 'calc(40vw - 200px)' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className={`onln-usr-tbl-container ${isSidebarOpen ? 'margin-left-expanded' : 'margin-left'}`}>
          <table>
            <thead>
              <tr>
                <th>Username</th>
                <th>InTime</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr
                  key={user.UserName}
                >
                  <td>{user.UserName}</td>
                  <td>{formatDateTime(user.InTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OnlineUsers;
