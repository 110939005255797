import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PDFTitle.css';
import UsersSidebar from './UsersSidebar';

export default function PDFTitle() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [color, setColor] = useState('#4169e1'); // Initial color
  const [color2, setColor2] = useState('#834658'); // Initial color
  const [color3, setColor3] = useState('#228B22'); // Initial color
  const [color4, setColor4] = useState('#8B008B'); // Initial color

  const instituteId = localStorage.getItem("instituteId");
  const apiHostLink = localStorage.getItem("apiHostLink");
  const userId = localStorage.getItem("userId");
  const [data, setData] = useState([]);
  const [updatedMessage, setUpdatedMessage] = useState('');
  const [textColor, setTextColor] = useState('green');
  const [selectedRow, setSelectedRow] = useState(null);

  const [pdfTitleUpper, setPdfTitleUpper] = useState('');
  const [pdfTitleLower, setPdfTitleLower] = useState('');

  const [upperFontSize, setUpperFontSize] = useState('');
  const [lowerFontSize, setLowerFontSize] = useState('');
  const [pdFontSize, setPdFontSize] = useState('');

  const [fontStyle, setFontStyle] = useState([]);
  const [selectedUpperFontStyle, setSelectedUpperFontStyle] = useState('');
  const [selectedLowerFontStyle, setSelectedLowerFontStyle] = useState('');
  const [selectedPDFontStyle, setSelectedPDFontStyle] = useState('');

  const [upperIndex, setUpperIndex] = useState(null);
  const [lowerIndex, setLowerIndex] = useState(null);
  const [pdIndex, setPdIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {

    try {
      const response = await axios.post(`${apiHostLink}PDFTitle_/FillPdfTitlePerInstitute`, {
        instituteId: instituteId,
        userId: userId,
      });

      const resp = response.data;
      console.log("Data Response :");
      console.log(resp);

      setData(JSON.parse(resp.data));
      const parsedFontStyles = JSON.parse(resp.fontStyle);
      setFontStyle(parsedFontStyles);

      setSelectedUpperFontStyle(parsedFontStyles[0].FontStyle);
      setSelectedLowerFontStyle(parsedFontStyles[0].FontStyle);
      setSelectedPDFontStyle(parsedFontStyles[0].FontStyle);

      setUpperIndex('0');
      setLowerIndex('0');
      setPdIndex('0');
    } catch (error) {
      alert(error.data);
      console.log(error);
    }
  }

  const handlePdfTitleUpperChange = (event) => {
    setPdfTitleUpper(event.target.value);
  };

  const handlePdfTitleLowerChange = (event) => {
    setPdfTitleLower(event.target.value);
  };

  const handleUpperFontSize = (event) => {
    setUpperFontSize(event.target.value);
  };

  const handleLowerFontSize = (event) => {
    setLowerFontSize(event.target.value);
  };

  const handlePdFontSizeChange = (event) => {
    setPdFontSize(event.target.value);
  };

  const handleUpperFontStyleChange = (event) => {
    const [index] = event.target.value.split('-');    //to get index number
    setSelectedUpperFontStyle(event.target.value);
    setUpperIndex(index);
  };

  const handleLowerFontStyleChange = (event) => {
    const [index] = event.target.value.split('-');    //to get index number
    setSelectedLowerFontStyle(event.target.value);
    setLowerIndex(index);
  };

  const handlePDFontStyleChange = (event) => {
    const [index] = event.target.value.split('-');    //to get index number
    setSelectedPDFontStyle(event.target.value);
    setPdIndex(index);
  };

  const handleRowClick = (item) => {
    setSelectedRow(item);
    console.log('Selected Row:', item); // For debugging
  };

  const getCombinedFontStyle = (index, fontStyle) => {
    return `${index}-${fontStyle}`;
  };

  const handleAddBtnClick = async () => {

    if (data.length > 0) {
      setUpdatedMessage("There should be only One Pdf Title!");
      setTextColor('red');
      setTimeout(() => {
        setUpdatedMessage(null);
      }, 5000);
      return;
    }

    const hasNonDigitUFS = !Array.from(upperFontSize).every(char => /\d/.test(char));    //condition to allow only number
    const hasNonDigitLFS = !Array.from(lowerFontSize).every(char => /\d/.test(char));
    const hasNonDigitPDFS = !Array.from(pdFontSize).every(char => /\d/.test(char));

    if (!pdfTitleUpper || !pdfTitleLower || !upperFontSize || !lowerFontSize || !pdFontSize || hasNonDigitUFS || hasNonDigitLFS || hasNonDigitPDFS) {
      setUpdatedMessage("Kindly Fill Valid Details");
      setTextColor('red');
      setTimeout(() => {
        setUpdatedMessage(null);
      }, 2000);
      return;
    }

    try {
      const response = await axios.post(`${apiHostLink}PDFTitle_/AddClick`, {
        instituteId: instituteId,
        pdfTitleUpper: pdfTitleUpper,
        pdfTitleLower: pdfTitleLower,
        upperFontSize: upperFontSize,
        lowerFontSize: lowerFontSize,
        pdFontSize: pdFontSize,
        color1: color,
        color2: color2,
        color3: color3,
        upperFontStyle: upperIndex,
        lowerFontStyle: lowerIndex,
        pdFontStyle: pdIndex,
        userId: userId,
      });

      const resp = response.data;
      console.log(resp);

      setUpdatedMessage(resp);
      getData();
      setTimeout(() => {
        setUpdatedMessage(null);
      }, 5000);
      setPdfTitleUpper('');
      setPdfTitleLower('');
      setUpperFontSize('');
      setLowerFontSize('');
      setPdFontSize('');
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }

  const handleEditBtnClick = async () => {
    if (!selectedRow) {
      setUpdatedMessage("Kindly select data");
      setTextColor('red');
      setTimeout(() => {
        setUpdatedMessage(null);
      }, 5000);
      return;
    }

    setIsEdit(true);

    setPdfTitleUpper(selectedRow.PdfTitleUpper);
    setPdfTitleLower(selectedRow.PdfTitleLower);

    setUpperFontSize(selectedRow.FontSizeTitleUpper);
    setLowerFontSize(selectedRow.FontSizeTitleLower);
    setPdFontSize(selectedRow.FontSizePatientDetails);

    setSelectedUpperFontStyle(getCombinedFontStyle(selectedRow.FontStyleTitleUpper - 1, fontStyle[selectedRow.FontStyleTitleUpper - 1].FontStyle));
    setSelectedLowerFontStyle(getCombinedFontStyle(selectedRow.FontStyleTitleLower - 1, fontStyle[selectedRow.FontStyleTitleLower - 1].FontStyle));
    setSelectedPDFontStyle(getCombinedFontStyle(selectedRow.FontStylePatientDetails - 1, fontStyle[selectedRow.FontStylePatientDetails - 1].FontStyle));

    setColor(selectedRow.FontColorTitleUpper);
    setColor2(selectedRow.FontColorTitleLower);
    setColor3(selectedRow.FontColorPatientDetails);
  }

  const handleUpdateBtnClick = async (event) => {

    if (!pdfTitleUpper) {
      const confirm = window.confirm("Do you want to set Empty PDF Title?");
      if (!confirm) {
        return;
      }
    }

    const hasNonDigitUFS = !Array.from(upperFontSize).every(char => /\d/.test(char));    //condition to allow only number
    const hasNonDigitLFS = !Array.from(lowerFontSize).every(char => /\d/.test(char));
    const hasNonDigitPDFS = !Array.from(pdFontSize).every(char => /\d/.test(char));

    if (hasNonDigitUFS || hasNonDigitLFS || hasNonDigitPDFS) {
      setUpdatedMessage("Kindly Enter correct Font size");
      setTextColor('red');
      setTimeout(() => {
        setUpdatedMessage(null);
      }, 5000);

      return;
    }

    try {
      const response = await axios.post(`${apiHostLink}PDFTitle_/UpdateClick`, {
        id: selectedRow.Id,
        instituteId: instituteId,
        pdfTitleUpper: pdfTitleUpper,
        pdfTitleLower: pdfTitleLower,
        upperFontSize: upperFontSize,
        lowerFontSize: lowerFontSize,
        pdFontSize: pdFontSize,
        color1: color,
        color2: color2,
        color3: color3,
        bgColor: color4,
        upperFontStyle: upperIndex,
        lowerFontStyle: lowerIndex,
        pdFontStyle: pdIndex,
        userId: userId
      });

      const resp = response.data;
      console.log(resp);

      setUpdatedMessage(resp);
      getData();
      setTimeout(() => {
        setUpdatedMessage(null);
      }, 5000);

      setIsEdit(false);

      setUpdatedMessage("Updated Data");
      setTimeout(() => {
        setUpdatedMessage(null);
      }, 5000);

      setPdfTitleUpper('');
      setPdfTitleLower('');
      setUpperFontSize('');
      setLowerFontSize('');
      setPdFontSize('');
      setSelectedUpperFontStyle(fontStyle[0].FontStyle);
      setSelectedLowerFontStyle(fontStyle[0].FontStyle);
      setSelectedPDFontStyle(fontStyle[0].FontStyle);
      setColor('#4169e1');
      setColor2('#834658');
      setColor3('#228B22');

    } catch (error) {
      alert(error);
      console.log(error);
    }
  }

  const handleDeleteBtnClick = async () => {

    if (!selectedRow) {
      setUpdatedMessage("Kindly select data");
      setTextColor('red');
      setTimeout(() => {
        setUpdatedMessage(null);
      }, 5000);
      return;
    }

    try {
      const response = await axios.post(`${apiHostLink}PDFTitle_/DeleteClick`, {
        instituteId: instituteId,
        id: selectedRow.Id,
        userId: userId
      });

      const resp = response.data;
      console.log(resp);

      setUpdatedMessage(resp);
      getData();
      setTextColor('green');
      setTimeout(() => {
        setUpdatedMessage(null);
      }, 5000);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }

  return (
    <>
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '180px' : '40px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(90vw - 10px)' : 'calc(98vw - 30px)' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className={`pdf-ttl-tbl-container ${isSidebarOpen ? 'margin-left-expanded' : 'margin-left'}`}>
          <table>
            <thead>
              <tr>
                <th title="PdfTitleUpper">PdfTitleUpper</th>
                <th title="PdfTitleLower">PdfTitleLower</th>
                <th title="FontSizeTitleUpper">FontSizeTitleUpper</th>
                <th title="FontStyleTitleUpper">FontStyleTitleUpper</th>
                <th title="FontColorTitleUpper">FontColorTitleUpper</th>
                <th title="FontColorTitleLower">FontColorTitleLower</th>
                <th title="FontSizeTitleLower">FontSizeTitleLower</th>
                <th title="FontStyleTitleLower">FontStyleTitleLower</th>
                <th title="FontSizePatientDetails">FontSizePatientDetails</th>
                <th title="FontStylePatientDetails">FontStylePatientDetails</th>
                <th title="FontColorPatientDetails">FontColorPatientDetails</th>
              </tr>
            </thead>
            <tbody>
              {data && data.map((item, index) => (
                <tr key={index} onClick={() => handleRowClick(item)} style={{ cursor: 'default', backgroundColor: selectedRow === item ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                  <td>{item.PdfTitleUpper}</td>
                  <td>{item.PdfTitleLower}</td>
                  <td>{item.FontSizeTitleUpper}</td>
                  <td>{item.FontStyleTitleUpper}</td>
                  <td>{item.FontColorTitleUpper}</td>
                  <td>{item.FontColorTitleLower}</td>
                  <td>{item.FontSizeTitleLower}</td>
                  <td>{item.FontStyleTitleLower}</td>
                  <td>{item.FontSizePatientDetails}</td>
                  <td>{item.FontStylePatientDetails}</td>
                  <td>{item.FontColorPatientDetails}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ marginLeft: '40px', marginTop: '10px' }}>
          <label>PdfTitle Upper: <span style={{ color: 'red' }}>* </span>
            <input className="input" type="text" style={{ width: '450px' }} value={pdfTitleUpper} onChange={handlePdfTitleUpperChange}></input>
          </label>
          <label>PdfTitle Lower: <span style={{ color: 'red' }}>* </span>
            <input className="input" type="text" style={{ width: '450px', marginTop: '5px' }} value={pdfTitleLower} onChange={handlePdfTitleLowerChange}></input>
          </label>
        </div>

        <div style={{ display: 'flex', marginLeft: '20px' }}>

          <div style={{ width: 'auto', height: '123px', marginTop: '10px', marginLeft: '20px', border: '1px solid black', borderRadius: '5px' }}>
            <div style={{ padding: '10px' }}>
              <label>FontSize: <span style={{ color: 'red' }}>* </span>
                <input className="input" type="text" style={{ width: '150px' }} value={upperFontSize} onChange={handleUpperFontSize}></input>
              </label>
              <label style={{ marginTop: '10px' }}>FontStyle: <span></span>
                <select className="input" type="text" style={{ width: '150px' }} value={selectedUpperFontStyle} onChange={handleUpperFontStyleChange}>
                  {fontStyle && fontStyle.map((style, index) => (
                    <option key={index} value={`${index}-${style.FontStyle}`} >
                      {style.FontStyle}
                    </option>
                  ))}
                </select>
              </label>
              <label style={{ marginTop: '10px' }}>Title Color:
                <input
                  id="colorPicker"
                  type="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  style={{ marginLeft: '10px', width: '100px' }}
                />
              </label>
            </div>
          </div>


          <div style={{ width: 'auto', height: '123px', marginTop: '10px', marginLeft: '20px', border: '1px solid black', borderRadius: '5px' }}>
            <div style={{ padding: '10px' }}>
              <label>FontSize: <span style={{ color: 'red' }}>* </span>
                <input className="input" type="text" style={{ width: '150px' }} value={lowerFontSize} onChange={handleLowerFontSize}></input>
              </label>
              <label style={{ marginTop: '10px' }}>FontStyle: <span></span>
                <select className="input" type="text" style={{ width: '150px' }} value={selectedLowerFontStyle} onChange={handleLowerFontStyleChange}>
                  {fontStyle && fontStyle.map((style, index) => (
                    <option key={index} value={`${index}-${style.FontStyle}`} >
                      {style.FontStyle}
                    </option>
                  ))}
                </select>
              </label>
              <label style={{ marginTop: '10px' }}>Title Color:
                <input
                  id="colorPicker"
                  type="color"
                  value={color2}
                  onChange={(e) => setColor2(e.target.value)}
                  style={{ marginLeft: '10px', width: '100px' }}
                />
              </label>
            </div>
          </div>


          <div style={{ width: 'auto', height: '123px', marginTop: '10px', marginLeft: '20px', border: '1px solid black', borderRadius: '5px' }}>
            <div style={{ padding: '10px' }}>
              <label>FontSize: <span style={{ color: 'red' }}>* </span>
                <input className="input" type="text" style={{ width: '150px' }} value={pdFontSize} onChange={handlePdFontSizeChange}></input>
              </label>
              <label style={{ marginTop: '10px' }}>FontStyle: <span></span>
                <select className="input" type="text" style={{ width: '150px' }} value={selectedPDFontStyle} onChange={handlePDFontStyleChange}>
                  {fontStyle && fontStyle.map((style, index) => (
                    <option key={index} value={`${index}-${style.FontStyle}`} >
                      {style.FontStyle}
                    </option>
                  ))}
                </select>
              </label>
              <label style={{ marginTop: '10px' }}>Title Color:
                <input
                  id="colorPicker"
                  type="color"
                  value={color3}
                  onChange={(e) => setColor3(e.target.value)}
                  style={{ marginLeft: '10px', width: '100px' }}
                />
              </label>
            </div>
          </div>
        </div>
        <label style={{ marginLeft: '40px', marginTop: '10px' }}>Border Color:
          <input
            id="colorPicker"
            type="color"
            value={color4}
            onChange={(e) => setColor4(e.target.value)}
            style={{ marginLeft: '10px', width: '100px' }}
          />
        </label>
        <div style={{ marginTop: '10px', marginLeft: '40px' }}>
          <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddBtnClick}>Add</button>
          <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={isEdit ? handleUpdateBtnClick : handleEditBtnClick} >{isEdit ? "Update" : "Edit"}</button>
          <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleDeleteBtnClick}>Delete</button>
          {updatedMessage && (
            <div style={{ marginLeft: '15px', marginTop: '10px', width: '320px', display: 'inline', color: textColor }}>{updatedMessage}</div>
          )}
        </div>

        <div style={{ marginTop: '10px', display: 'flex', marginLeft: '40px' }}>
          <label>Logo File Path:</label>
          <button className="gradientButton" style={{ marginLeft: '1px', marginTop: '5px' }} disabled>Browse</button>
        </div>

        <div style={{ marginTop: '5px', display: 'flex', marginLeft: '40px' }}>
          <label>
            <input type="checkbox"></input>
            Show Print Paper</label>
        </div>

        <div style={{ border: '3px solid black', width: '1200px', height: '120px', marginTop: '5px', marginLeft: '10px', display: 'flex' }}></div>

      </div>
    </>
  );
};
