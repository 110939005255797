import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM
import PropTypes from 'prop-types';

const SystemSetting = ({ isOpen, onClose, instituteName, instituteName2 }) => {
    return isOpen ? ReactDOM.createPortal(
        <div
            style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '500px', height: '500px',backgroundColor: 'white',padding: '20px', borderRadius: '8px',boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', userSelect: 'none'
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <p style={{ fontSize: '20px', marginTop: "-15px",marginRight:'168px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: '150px' }}>
                    {instituteName2}
                </p>

                <p style={{ fontSize: '20px', marginTop: "-15px", marginLeft: '-450px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: '150px' }}>
                    {instituteName}
                </p>
                <button onClick={onClose} style={{ color: 'red', marginTop:'-45px', marginRight:'-19px', borderWidth:'0px', width:'40px', display:'sticky' }}>X</button>
            </div>
            <hr style={{marginTop:'-15px'}} />
            
           
            <div style={{ border: '1px solid #ccc', marginTop: '10px', height: '270px', overflowY: 'auto' }}>
                <table style={{ border: '1px solid skyblue'}}>
                    <tr>
                        <th style={{ border: '2px solid skyblue' }}>Setting Name</th>
                        <th style={{ border: '2px solid skyblue' }}>PC Value</th>
                        <th style={{ border: '2px solid skyblue' }}>CAS Value</th>
                    </tr>
                    <tr>
                        <td>demo1</td>
                        <td>demo2</td>
                        <td>demo3</td>
                    </tr>
                </table>

            </div>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                    <label style={{ marginTop: '4px', fontSize:'13px' }}>Select Setting Name </label>
                    <select style={{ marginLeft: '20px', width: '250px', height:'25px' }}>
                        <option value="">Select an option</option>
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                    </select>
                </div>

                <div style={{ display: 'flex', marginTop: '10px' }}>
                    <label style={{ marginTop: '4px', fontSize: '13px' }}>Select Setting Name </label>
                    <textarea style={{ marginLeft: '20px', width: '250px' } }></textarea>
            </div>
            <div style={{  display:'flex' ,marginTop:'20px'}}>
                <button className='gradientButton' style={{marginLeft:'10px'} }>Add</button>
                <button className='gradientButton' style={{ marginLeft: '10px' }}>Update</button>
                <input type="checkbox" style={{ marginLeft: '10px' }} />
                <label style={{ marginLeft: '5px' }}>MultiSelect</label>
                <button className='gradientButton' style={{ marginLeft: '10px' }}>Export</button>
            </div>
            </div>
       ,
        document.body
    ) : null;
};

SystemSetting.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    instituteName: PropTypes.string.isRequired,
    instituteName2: PropTypes.string.isRequired,
};

export default SystemSetting;