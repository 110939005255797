import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from './Sidebar';
import "./UsersTable.css"; // Import CSS file for styling

function UsersTable() {
  const [userData, setUserData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const apiHostLink = localStorage.getItem("apiHostLink");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch UsersTable data
        const userDataResponse = await axios.get(`${apiHostLink}pCare/GetUsers`, {userId: userId});
        setUserData(userDataResponse.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const renderTable = () => {
    return (
      <table className="user-table">
        <thead>
          <tr>
            {Object.keys(userData[0]).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {userData.map((user, index) => (
            <tr key={index}>
              {Object.values(user).map((value, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <button onClick={() => setShowTable(!showTable)}>
        {showTable ? "Hide Table" : "Open Table"}
      </button>
      {showTable && userData.length > 0 && (
        <div>
          <h2>Users Table</h2>
          {renderTable()}
        </div>
      )}
    </div>
  );
}

export default UsersTable;

