import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import pndt from './PNDT.css';

function PNDT() {

  const [showInvasiveProcedure, setShowInvasiveProcedure] = useState(false);
  const [showTextarea, setShowTextarea] = useState(true);
  const [isReferred, setIsReferred] = useState(true);

  const handleShowDrDetails = (event) => {
    setIsReferred(event.target.value === 'referred');
  };

  const handleRadioChange = (event) => {
    const { value } = event.target;
    if (value === 'Yes') {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
    }
  };

  useEffect(() => {
    // Ensure "Yes" is selected by default
    setShowTextarea(true);
  }, []);

  const handleCheckboxChange = () => {
    setShowInvasiveProcedure(!showInvasiveProcedure);
  };

  return (
    <div style={{ display: 'flex', userSelect: 'none' }}>
      <div>
        <Sidebar />
      </div>
      <div className="dvpatreg"
        style={{ flex: '1', width: '200px', padding: '40px', display:'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/*Personal Details div*/}
          <div className="prsnl_dtail" style={{ width: 'auto', height: 'auto', backgroundColor: '#d4e3ea', borderRadius: '4px', border: '6px double white', padding:'10px' }}>
          {/*Header First,Middle,Last*/}
            <div style={{ display: 'flex' }}>
              <label style={{ marginLeft:'90px' }}>Fist</label>
              <label style={{ marginLeft: '120px' }}>Middle</label>
              <label style={{ marginLeft: '100px' }}>Last</label>
            </div>
            {/*Name and Input Field*/}
            <div style={{ display: 'flex', marginTop: '0px' } }>
              <label>Name
              </label>
              <input type="text" style={{ marginLeft: '50px', width: '90px', height: '25px' }} />
              <input type="text" style={{ marginLeft: '50px', width: '90px', height: '25px' }} />
              <input type="text" style={{ marginLeft: '50px', width: '90px', height: '25px' }} />
            </div>
            {/*Husband/FatherName,ContactNo.*/}
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <label>Husband/Father Name
              </label>
              <input type="text" style={{ marginLeft: '10px', width: '130px', height: '25px' }} />
              <label style={{ marginLeft: '20px' }}>Contact No.</label>
              <input type="text" style={{ marginLeft: '50px', width: '90px', height: '25px' }} maxLength="10" pattern="[0-9]*" />
            </div>
            {/*Address*/}
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <label>Address: </label>
              <textarea style={{ marginLeft: '10px', width:'370px' }}></textarea>
            </div>
            {/*Son & Daughters Table*/}
            <div style={{ display: 'flex', marginTop: '10px', marginLeft: '90px' }}>
                <label>Son:
                  <table>
                    <thead style={{ position: 'sticky' }}>
                      <tr>
                        <th style={{ backgroundColor: '#f2f9fc', width: '10px', border: '1px solid slategray', fontSize: '12px' }}>Years</th>
                        <th style={{ backgroundColor: '#f2f9fc', width: '10px', border: '1px solid slategray', fontSize: '12px' }}>Months</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </label>
                <label style={{ marginLeft: '100px' }}>Daughter:
                  <table>
                    <thead style={{ position: 'sticky' }}>
                      <tr>
                        <th style={{ backgroundColor: '#f2f9fc', width: '30px', border: '1px solid slategray', fontSize: '12px' }}>Years</th>
                        <th style={{ backgroundColor: '#f2f9fc', width: '30px', border: '1px solid slategray', fontSize: '12px' }}>Months</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '30px', fontSize: '10px', padding: '2px' }}>
                          <input type="text" style={{ height: '20px', fontSize: '10px' }} ></input>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </label>
            </div>
          </div>

          {/*Medical Details div*/}
          <div className="mdcl_dtail" style={{ width: 'auto', height: 'auto', backgroundColor: '#d4e3ea', borderRadius: '4px', border: '6px double white', display: 'flex', flexDirection:'column', padding:'10px', marginTop:'5px' }}>
            <div style={{display:'flex'} }>
            <label style={{ width:'200px' }}>Any indication for MTP as per the abnormality detected in the diagnostic procedures/tests ?</label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ padding: '10px', display: 'flex', width: '150px', height: '30px' }}>
                  <label>
                    <input type="radio" style={{ marginRight: '4px' }} name="option" value="Yes" onChange={handleRadioChange} checked={showTextarea} />
                    Yes
                  </label>
                  <label style={{ marginLeft: '10px' }}>
                    <input type="radio" style={{ marginRight: '4px' }} name="option" value="No" onChange={handleRadioChange} checked={!showTextarea} />
                    No
                  </label>
                </div>
                {showTextarea && (
                  <textarea style={{ height: '30px', width: '150px' }}></textarea>
                )}
              </div>
            <div style={{ padding: '10px', display: 'flex', width: 'auto', height: 'auto' }}>
              <label style={{ display: 'flex', flexDirection:'column', marginLeft:'10px' }}>Lmp:
                <input className="" type="date" style={{ width: '85px', height: '25px', fontSize: '11px', paddingRight: '14px', borderRadius: '5px' }} />
                </label>
              </div>
            </div>
            <div>
              <label className="procedure" style={{ padding: '10px' }}>
                <input type="checkbox" style={{ marginRight: '5px' }} onChange={handleCheckboxChange} />
                Invasive Procedure
              </label>
            </div>
            <div style={{ display: 'flex' }}>
              <div className={showInvasiveProcedure ? "Invasive_Procedure" : "Indication"}>
                {showInvasiveProcedure ? (
                  <label style={{ padding: '10px'}}>Invasive Procedure:
                    <select style={{ marginLeft: '10px', width: '150px', height: '25px' }}>
                      <option>Default</option>
                    </select>
                  </label>
                ) : (
                  <label style={{ padding: '10px' }}>Indication:
                    <select style={{ marginLeft: '10px', width: '150px', height: '25px' }}>
                      <option>1</option>
                    </select>
                  </label>
                )}
                <div style={{display:'flex', marginLeft:'10px'} }>
                  <label>Conculsion:</label>
                  <textarea style={{marginLeft:'10px'} }></textarea>
                </div>
              </div>
            </div>
          </div>
          {/*buttons*/}
          <div style={{ width: '500px', height: '100px', display:'flex' }}>
            <button className="gradientButton" style={{ marginLeft: '10px', marginTop: '5px' }} >Online PNDT Fill</button>
            <button className="gradientButton" style={{ marginLeft: '10px', marginTop: '5px' }} >FormF</button>
            <button className="gradientButton" style={{ marginLeft: '10px', marginTop: '5px' }} >PNDT Declaration Form</button>
            <button className="gradientButton" style={{ marginLeft: '10px', marginTop: '5px' }} >Update Patient</button>
          </div>
        </div>
        
        <div style={{ display: 'flex', flexDirection:'column' }}>
          {/*Doctors Detail*/}
          <div className="drs_dtail" style={{ width: '240px', height: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: '#d4e3ea', borderRadius: '4px', border: '6px double white', padding: '10px', marginLeft:'5px' }}>
            <div style={{display:'flex'} }>
            <label>
              Reporting Doctor Name:
            </label>
            <label style={{marginLeft:'40px'} }>Dr.<br></br>
              <select>
              <option>Dr. Ram Patel</option>
              </select>
              </label>
            </div>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              <label>
                <input type="radio" name="referral" value="referred" checked={isReferred} onChange={handleShowDrDetails} />
                Referred by
              </label>
              <label style={{ marginLeft: '10px' }}>
                <input type="radio" name="referral" value="self-referred" checked={!isReferred} onChange={handleShowDrDetails} />
                Self Referred
              </label>
            </div>

            <div className={isReferred ? "DrName" : "DrName hidden"} style={{ width: 'auto', height: 'auto', padding: '10px', marginTop: '10px', border: '3px double white' }}>
              <div style={{ display: 'flex' }}>
                <label>Doctor Name:</label>
                <label style={{ marginLeft: '20px' }}>Dr.</label>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ marginTop: '10px' }}>
                  <input type="checkbox" style={{ marginRight: '4px' }} />
                  IsRefPhy2
                </label>
                <select style={{ width: '100px', height: '23px', marginLeft: '15px', marginTop: '5px' }}>
                  <option>Doctor Name</option>
                </select>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ marginTop: '10px' }}>Center Name:</label>
                <textarea type="text" style={{ width: '100px', height: '25px', maxHeight: '40px', fontSize: '11px', marginLeft: '10px', marginTop: '10px' }} />
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ marginTop: '10px' }}>Address:</label>
                <textarea type="text" style={{ width: '100px', height: '25px', maxHeight: '40px', fontSize: '11px', marginLeft: '40px', marginTop: '10px' }} />
              </div>
            </div>
          </div>

          {/*Relatives Detail*/}
          <div className="reltvs_dtail" style={{ width: 'auto', height: 'auto', backgroundColor: '#d4e3ea', borderRadius: '4px', border: '6px double white', padding:'10px', marginLeft:'5px', marginTop:'5px' }}>
            <div>
              <label>Relation (if any):
                <input type="text" style={{ width:'120px', height:'25px', marginLeft:'10px', marginTop:'4px' }}></input>
              </label>
              <label>Name:
                <input type="text" style={{ width: '120px', height: '25px', marginLeft: '63px', marginTop: '4px' }}></input>
              </label>
              <label>Address:
                <textarea style={{ width: '120px', height: '40px', marginLeft: '52px', maxHeight: '50px', marginTop: '4px' }}></textarea>
              </label>
              <label>Relative Age:
                <input type="Number" style={{ width: '50px', height: '25px', marginLeft: '30px', marginTop: '4px' }}></input>
              </label>
              <label>Relative Sex:
                <input type="text" style={{ width: '120px', height: '25px', marginLeft: '32px', marginTop: '4px' }}></input>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PNDT;
