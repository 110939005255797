import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import { useNavigate } from "react-router-dom";
import './PCareHome.css';
import DetectKey from './DetectKey.js';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import CommentPopup from './CommentPopup';
import { useLocation } from 'react-router-dom';
//import { saveAs } from 'file-saver';
/*import { FaRegCopy, FaList, FaEllipsisV, FaShareAlt } from 'react-icons/fa'*/
/*import { RiSendPlaneFill, RiDeleteBin6Line } from 'react-icons/ri'*/
/*import { add } from '../../../../../../../Users/tusha/AppData/Local/Microsoft/TypeScript/5.1/node_modules/date-fns/add';*/



function ShowPaymentColumns_ChkBox_TickChanged({ CheckboxChange_Prop, showPaymentColums_Prop, setDropdownVisible }) {
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  function toggleCheckboxes() {
    setShowCheckboxes(!showCheckboxes);
    setDropdownVisible(!showCheckboxes);
  }

  function selectOption(option) {
    setSelectedOption(option);
    if (option === 'show_payment') {
      CheckboxChange_Prop();
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (showCheckboxes && !event.target.closest('.multipleSelection')) {
        setShowCheckboxes(false);
        setDropdownVisible(false);
      }
    }
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [showCheckboxes]);

  return (
    <div className="multipleSelection" style={{ position: 'relative', zIndex: '20' }}>
      <div className="selectBox" onClick={toggleCheckboxes} style={{ position: 'relative', zIndex: '20', cursor: 'pointer' }}>
        <select style={{ width: '100%', fontSize: '13px' }}>
          <option>Select options</option>
        </select>
        <div className="overSelect" style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: '20' }}></div>
      </div>

      {showCheckboxes && (
        <div id="checkBoxes" className="checkboxContainer" style={{ border: '1px solid #ccc', padding: '5px', position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
          <label htmlFor="Expand" onClick={() => selectOption('Expand')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'Expand' ? 'lightblue' : 'transparent' }}>
            Expand
          </label>
          <label htmlFor="Import" onClick={() => selectOption('Import')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'Import' ? 'lightblue' : 'transparent' }}>
            Import
          </label>
          <label htmlFor="today_dicom" onClick={() => selectOption('today_dicom')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'today_dicom' ? 'lightblue' : 'transparent' }}>
            Today Dicom
          </label>
          <label htmlFor="from_dir" onClick={() => selectOption('from_dir')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'from_dir' ? 'lightblue' : 'transparent' }}>
            From Dir
          </label>
          <label htmlFor="from_zip" onClick={() => selectOption('from_zip')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'from_zip' ? 'lightblue' : 'transparent' }}>
            From Zip
          </label>
          <label htmlFor="from_dir_zip" onClick={() => selectOption('from_dir_zip')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'from_dir_zip' ? 'lightblue' : 'transparent' }}>
            From Dir + Zip
          </label>
          <label htmlFor="dicom_import" onClick={() => selectOption('dicom_import')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'dicom_import' ? 'lightblue' : 'transparent' }}>
            Dicom Import
          </label>
          <label htmlFor="show_missing_record" onClick={() => selectOption('show_missing_record')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'show_missing_record' ? 'lightblue' : 'transparent' }}>
            Show CC Missing Record
          </label>
          <label htmlFor="import_online_registered" onClick={() => selectOption('import_online_registered')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'import_online_registered' ? 'lightblue' : 'transparent' }}>
            Import Online Registered
          </label>
          <label htmlFor="from_bigdir" onClick={() => selectOption('from_bigdir')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'from_bigdir' ? 'lightblue' : 'transparent' }}>
            From BigDir
          </label>
          <label htmlFor="correct_patient_id" onClick={() => selectOption('correct_patient_id')} style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'correct_patient_id' ? 'lightblue' : 'transparent' }}>
            Correct PatientIdInst
          </label>
          <label htmlFor="show_payment" style={{ display: 'block', cursor: 'pointer', backgroundColor: selectedOption === 'show_payment' ? 'lightblue' : 'transparent' }}>
            <input type="checkbox" id="show_payment" onChange={CheckboxChange_Prop} checked={showPaymentColums_Prop} />
            Show Payment
          </label>
        </div>
      )}
    </div>
  );
}

function ModalityFilterSelected({ modalityList, ModalityFilter_CheckChanged_Prop, setDropdownVisible }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalities, setSelectedModalities] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setDropdownVisible(!isOpen);
  };

  const handleModalitySelect = (modalityName) => {
    let updatedSelectedModalities = [...selectedModalities];

    if (modalityName === 'All') {
      if (!isAllSelected) {
        // If "All" is selected and not previously selected, set all modalities as selected
        updatedSelectedModalities = modalityList
          .filter(modality => !['NonReported', 'NonImgPrinted', 'NonReportPrinted', 'NonApproved'].includes(modality.modalityName))
          .map(modality => modality.modalityName);
        setIsAllSelected(true);
      } else {
        // If "All" is deselected, deselect all modalities
        updatedSelectedModalities = [];
        setIsAllSelected(false);
      }
    } else if (['NonReported', 'NonImgPrinted', 'NonReportPrinted', 'NonApproved'].includes(modalityName)) {
      // If one of the special options is selected or deselected
      const index = updatedSelectedModalities.indexOf(modalityName);
      if (index === -1) {
        // If it's being selected, deselect "All"
        updatedSelectedModalities = updatedSelectedModalities.filter(modality => modality !== 'All');
        updatedSelectedModalities.push(modalityName);
        setIsAllSelected(false);
      } else {
        // If it's being deselected
        updatedSelectedModalities.splice(index, 1);
      }
    } else {
      // If individual modality is selected, toggle its selection
      const index = selectedModalities.indexOf(modalityName);
      if (index === -1) {
        updatedSelectedModalities.push(modalityName);
      } else {
        updatedSelectedModalities.splice(index, 1);
      }
      // Deselect "All" if one of the special options is selected
      setIsAllSelected(false);
    }

    setSelectedModalities(updatedSelectedModalities);
    ModalityFilter_CheckChanged_Prop(updatedSelectedModalities);
  };


  const handleModalitySelect_On_KeyPressed = (modArr) => { //"Devansh, Rohan, Sunny"
    if (modArr === null || modArr.length === 0) {
      return;
    }

    let updatedSelectedModalities = [...selectedModalities];




    for (var i = 0; i < modArr.length; i++) {
      updatedSelectedModalities.push(modArr[i]);
    }

    // Deselect "All" if one of the special options is selected
    setIsAllSelected(false);


    setSelectedModalities(updatedSelectedModalities);
    ModalityFilter_CheckChanged_Prop(updatedSelectedModalities);
  };


  useEffect(() => {
    function handleKeyDown(event) {
      if (event.ctrlKey && event.shiftKey) {
        event.preventDefault();
        let modList = [];

        switch (event.key) {
          case 'A':
            modList = ['All'];
            break;
          case 'C':
            modList = ['CT', 'CT+SR', 'SR+CT+SR'];
            break;
          case 'M':
            modList = ['MRI', 'MR', 'MR+SR', 'SR+MR+SR'];
            break;
          case 'O':
            modList = ['OBS', 'Appointment OBS'];
            break;
          case 'P':
            modList = ['Pathology'];
            break;
          case 'R':
            modList = ['CR', 'CT', 'CT+SR', 'SR+CT+SR', 'Doppler', 'DX', 'DX+OT', 'DX+SR', 'MR', 'SR+MR+SR', 'MR+SR', 'MRI', 'OBS', 'OT+DX', 'PX', 'SR', 'US', 'US+SR', 'USG', 'Web'];
            break;
          case 'S':
          case 'U':
            modList = ['SR', 'US', 'US+SR', 'USG', 'OBS', 'Appointment OBS'];
            break;
          //event.preventDefault();
          case 'B':
            modList = ['CT', 'CT+SR', 'SR+CT+SR', 'MR', 'MR+SR', 'MRI', 'SR+MR+SR'];
            break;
          //case 'W':
          //  modList = ['Web'];
          //  break;
          case 'X':
            modList = ['CR', 'DX', 'DR', 'PX'];
            break;
          default:
            break;
        }

        handleModalitySelect_On_KeyPressed(modList);//'CT,MRI,USG,X-Ray'
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  useEffect(() => {
    function handleClickOutside(event) {
      if (isOpen && !event.target.closest('.dropdown')) {
        setIsOpen(false);
        setDropdownVisible(false);
      }
    }
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="dropdown" style={{ position: 'relative', left: '-40px', marginTop: '20px', width: '100px', height: '30px' }}>
      <button className="btn btn-light dropdown-toggle" type="button" onClick={toggleDropdown} style={{ width: '100%', height: '100%', textAlign: 'left', paddingLeft: '5px', fontSize: '12px' }} title={`Ctrl+ Shift+ A\n\nA = All\nC = CT\nM = MRI\nO = OBS\nP = Pathology\nR = Radiology (USG + Xray + CT + MRI)\nS or U = USG\nT = CT and MRI\nW = WebAppointment\nX = X-Ray\n\nThen, Press [Enter] to Filter`}>
        Select Modality
      </button>
      {isOpen && (
        <div className="dropdown-menu" style={{ display: 'block', width: '100%' }}>
          {modalityList.map((modality, index) => (
            <div className="form-check" key={index} style={{ marginTop: '-8px' }}>
              <input
                className="form-check-input"
                type="checkbox"
                id={`modalityCheckbox${index}`}
                checked={selectedModalities.includes(modality.modalityName) || (modality.modalityName === 'All' && isAllSelected)}
                onChange={() => handleModalitySelect(modality.modalityName)}
              />
              <label className="form-check-label" htmlFor={`modalityCheckbox${index}`}>
                {modality.modalityName}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}


export default function PCareHome() {
  const { isCtrl, isShift, isAlt, isEnter, isSpace, isEsc, isArrowUp, isArrowDown, isArrowLeft, isArrowRight } = DetectKey(); // Destructure the returned object
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [searchedData, setSearchedData] = useState([]);
  const [patientOptions, setPatientOptions] = useState([]);
  const [templateLevel, setTemplateLevel] = useState('');
  //const [imgWebLinkAndPort, setImgWebLinkAndPort] = useState('');
  //const [instituteId, setInstituteId] = useState('');
  //const [userId, setUserId] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedL1, setSelectedL1] = useState('');
  const [selectedL2, setSelectedL2] = useState('');
  const [selectedL3, setSelectedL3] = useState('');
  const [l1Options, setL1Options] = useState([]);
  const [l2Options, setL2Options] = useState([]);
  const [l3Options, setL3Options] = useState([]);
  const [drReporting, setDrReporting] = useState('');
  const [drResident, setDrResident] = useState('');
  const [typist, setTypist] = useState('');
  const [technician, setTechnician] = useState('');
  const [reportUrl, setReportUrl] = React.useState('');
  var [fromDate, setFromDate] = useState(null);
  var [toDate, setToDate] = useState(null);
  const [selectedAge, setSelectedAge] = useState('age');
  const [totalPrice, setTotalPrice] = useState('0');
  //const [todayClicked, setTodayClicked] = useState(false);
  const [reportPresent, setReportPresent] = useState(false);

  var [clickedPatient, setClickedPatient] = useState(null);

  const [modalityList, setModalityList] = useState([]);
  const [selectedModality, setSelectedModality] = useState('');
  const [selectedModalities, setSelectedModalities] = useState([]);
  const [modalityList2, setModalityList2] = useState([]);
  const [selectedModality2, setSelectedModality2] = useState('');
  const [patMobileNo, setPatMobileNo] = useState('');
  //const [patEmailId, setPatEmailId] = useState('');
  //const [patFName, setPatFName] = useState('');
  //const [patMName, setPatMName] = useState('');
  //const [patSName, setPatSName] = useState('');
  //const [patIdInst, setPatIdInst] = useState('');
  //const [patAddress, setPatAddress] = useState('');

  const [testOptions, setTestOptions] = useState([]);
  const [selectedTest, setSelectedTest] = useState('');

  const [testOptions2, setTestOptions2] = useState([]);
  const [selectedTest2, setSelectedTest2] = useState('');

  const [drReportingNameList, setDrReportingNameList] = useState([]);
  const [drResidentNameList, setDrResidentNameList] = useState([]);
  const [typistNameList, setTypistNameList] = useState([]);
  const [technicianNameList, setTechnicianNameList] = useState([]);
  const [showPaymentColumns, setShowPaymentColumns] = useState(false);
  const [showPaymentPanelDiv, setShowPaymentPanelDiv] = useState(false);
  const [showTemplateL1, setshowTemplateL1] = useState(false);

  const [reportApproved, setReportApproved] = useState(false);
  const [refPhyNameList1, setRefPhyNameList1] = useState([]);
  const [refPhy1, setRefPhy1] = useState('');

  const [refPhyNameList2, setRefPhyNameList2] = useState([]);
  const [refPhy2, setRefPhy2] = useState('');

  const [paymentOptions, setPaymentOptions] = useState([]);
  const [userName, setUserName] = useState([]);
  const [centerName, setCenterName] = useState("");

  const userType = localStorage.getItem("userType");
  const username = localStorage.getItem("username");
  const userId = localStorage.getItem("userId");
  const sessionUid = localStorage.getItem("sessionUid");

  const navigate = useNavigate();
  //const apiHostLink = 'https://localhost:7218/api/';
  const apiHostLink = localStorage.getItem("apiHostLink");
  // Adjusts the current date and time to a specific time zone offset.
  // Source: https://www.techrepublic.com/article/convert-the-local-time-to-another-time-zone-with-this-javascript/
  const todayDateTime = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 16);//write site name 
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('Cash');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [fetchedQueries, setFetchedQueries] = useState([]);
  const [comment, setComment] = useState("");
  const savedShowPayment = localStorage.getItem('showPayment');
  const [enableCreateDeleteReportBtn, setEnableCreateDeleteReportBtn] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const userPermissions = sessionStorage.getItem('userPermissions');
  const [showSrNoColumn, setShowSrNoColumn] = useState(false);
  const [buttonState, setButtonState] = useState('edit');
  const [paid, setPaid] = useState(0);
  const [pending, setPending] = useState(0);
  const [newPatIdInst, setNewPatIdInst] = useState("");
  const [modality, setModality] = useState("");
  const [test, setTest] = useState("");
  const [drPerforming, setDrPerforming] = useState("");
  const [referringPhysician1, setReferringPhysician1] = useState("");
  const [referringPhysician2, setReferringPhysician2] = useState("");
  const inputRef = useRef(null);
  const location = useLocation();
  const shouldFocus = location.state && location.state.shouldFocus;
  const [filteredDataByPatientId, setFilteredDataByPatientId] = useState([]);
  const [filteredDataByPatientIdInst, setFilteredDataByPatientIdInst] = useState([]);
  const [filteredDataByPatientMobileNo, setFilteredDataByPatientMobileNo] = useState([]);
  const [filteredDataByPatientName, setFilteredDataByPatientName] = useState([]);
  const [filteredDataByRefPhy, setFilteredDataByRefPhy] = useState([]);
  const [filteredDataByRefPhy2, setFilteredDataByRefPhy2] = useState([]);
  const [filteredDataByPatientMrdOrIp, setFilteredDataByPatientMrdOrIp] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isShowPatientNameTitle, setIsShowPatientNameTitle] = useState(false);

  //console.log(userPermissions);

  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [shouldFocus]);


  useEffect(() => {
    if (savedShowPayment) {
      setShowPaymentColumns(savedShowPayment === 'true');
    }
  }, []);

  useEffect(() => {
    // Fetch showHomeTableSerialNo from localStorage
    const showHomeTableSerialNo = localStorage.getItem("showHomeTableSerialNo");
    setShowSrNoColumn(showHomeTableSerialNo === "true");
  }, []);

  useEffect(() => {
    const showPatientNameTitle = localStorage.getItem("isShowPatientNameTitle") === "true";
    setIsShowPatientNameTitle(showPatientNameTitle);
  }, []);

  const handleButtonClick = () => {
    setButtonState(buttonState === 'edit' ? 'update' : 'edit');
  };

  //const handleDownloadCSV = () => {
  //  const csvContent = 'Hare Krishna';
  //  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //  saveAs(blob, 'HareKrishna.csv');
  //};

  const handlePatIdInstChange = (e, index) => {
    const newValue = e.target.value;
    const updatedSearchedData = [...searchedData];
    updatedSearchedData[index].patientIdInst = newValue;
    setSearchedData(updatedSearchedData);
    setNewPatIdInst(e.target.value);
  };

  const handleModalityChange = (e, index) => {
    const newValue = e.target.value;
    const updatedSearchedData = [...searchedData];
    updatedSearchedData[index].modality = newValue;
    setSearchedData(updatedSearchedData);
    fetchTestData2(newValue);
    setSelectedModality(newValue);
    setModality(e.target.value);
  };

  /**
    * Effect to update the payment data and total price when a patient is clicked.
    */
  useEffect(() => {
    if (clickedPatient) {
      setAddPaymentData(prevState => ({
        ...prevState,
        concession: clickedPatient.concession,
        extraRs: clickedPatient.extraRs
      }));
      const calculatedTotalPrice = calculateTotalPrice(clickedPatient);
      setTotalPrice(calculatedTotalPrice);
    }
  }, [clickedPatient]);

  const handleTestChangeUpdatePatient = (e, index) => {
    const newValue = e.target.value;
    const updatedSearchedData = [...searchedData];
    updatedSearchedData[index].test = newValue;
    setSearchedData(updatedSearchedData);
    setTest(e.target.value);
  };

  const handleReferringPhysicianChange = (e, index) => {
    const newValue = e.target.value;
    const updatedSearchedData = [...searchedData];
    updatedSearchedData[index].referringPhysician = newValue;
    setSearchedData(updatedSearchedData);
    setReferringPhysician1(e.target.value);
  };

  const handleRefPhy2Change = (e, index) => {
    const newValue = e.target.value;
    const updatedSearchedData = [...searchedData];
    updatedSearchedData[index].refPhy2 = newValue;
    setSearchedData(updatedSearchedData);
    setReferringPhysician2(e.target.value);
  };

  const handleDrPerformingChange = (e, index) => {
    const newValue = e.target.value;
    const updatedSearchedData = [...searchedData];
    updatedSearchedData[index].drPerforming = newValue;
    setSearchedData(updatedSearchedData);
    setDrPerforming(e.target.value);
  };

  const handleUserChange = (e, index) => {
    const newValue = e.target.value;
    const updatedSearchedData = [...searchedData];
    updatedSearchedData[index].userName = newValue;
    setSearchedData(updatedSearchedData);
  };

  const handleCommentButtonClick = async () => {

    if (!clickedPatient) {
      alert('Kindly Select Patient first');
      return;
    }

    const response = await axios.post(`${apiHostLink}Report/GetComment`, {
      StudyId: clickedPatient.studyId,
      PatientID: clickedPatient.patientId,
      isStoring: false,
      userId: userId
    });

    const ans = response.data;
    console.log(ans);

    if (!ans) return;

    console.log('Submitted comment:', ans);
    setFetchedQueries([...fetchedQueries, ans]);

    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setFetchedQueries([]);
  };

  const handleCommentSubmit = async (comment) => {
    if (!comment) {
      alert('Please enter a comment before updating.');
      return;
    }
    const response = await axios.post(`${apiHostLink}Report/GetComment`, {
      StudyId: clickedPatient.studyId,
      Comment: comment,
      PatientID: clickedPatient.patientId,
      isStoring: true,
      userId: userId
    });

    const ans = response.data;
    console.log(ans);

    if (!ans) return;
    fetchDataOnGoClick();
    alert("Updated comment succesfully");
    console.log('Submitted comment:', ans);
    setFetchedQueries([...fetchedQueries, ans]);
  };

  const handleSelectPaymentChange = (event) => {
    setSelectedPaymentOption(event.target.value);
  };

  const instituteId = localStorage.getItem("instituteId");
  const imgWebLinkAndPort = localStorage.getItem("imgWebLinkAndPort");
  const instituteName = localStorage.getItem("instituteName");

  const [allChecked, setAllChecked] = useState(false);

  const handleRowRightClick = (e, selRow) => {
    handleRowClick(selRow);

    e.preventDefault();
    setSelectedRow(selRow);
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setContextMenuVisible(true);
  };

  const hideContextMenu = () => {
    setContextMenuVisible(false);
  };

  const handleContextMenuClick = (e, data) => {
    // Handle context menu item click here
    console.log('Clicked on', data);
  };

  const [addTestData, setAddTestData] = useState({
    patIdInst: '',
    patientId: '',
    fName: '',
    mName: '',
    sName: '',
    gender: 'Female',
    age: '',
    dob: '',
    mobNo: '',
    emailId: '',
    address: '',
    LMP: '',
    isUrgentPatient: false,
    refPhy1: '',
    refPhy2: '',
    modality: '',
    cbTests: '',
    scheduledDate: '',
    instituteId: instituteId,
  });


  //useEffect(() => {
  //  const storedData = JSON.parse(localStorage.getItem('addTestData'));
  //  if (storedData) {
  //    setAddTestData(storedData);
  //  }
  //}, []);

  //// Function to update localStorage whenever addTestData changes
  //useEffect(() => {
  //  localStorage.setItem('addTestData', JSON.stringify(addTestData));
  //}, [addTestData]);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setAddTestData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleGenderChange = (e) => {
    setAddTestData(prevState => ({
      ...prevState,
      gender: e.target.value,
      LMP: e.target.value === 'Male' ? '' : prevState.LMP
    }));
  };

  const handleAgeChange = (event) => {

    setSelectedAge(event.target.value);

    const { name, value } = event.target;
    setAddTestData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  //handleAddTest
  const handleAddTest = async (e) => {
    e.preventDefault();

    var isNewPatient = (addTestData.fName !== '');

    if (isNewPatient) {
      if (!addTestData.fName) {
        alert('Enter fName');
        return; // Exit the function if validation fails
      }

      if (!addTestData.age) {
        alert('Enter age');
        return; // Exit the function if validation fails
      }
    }

    if (!drReporting) {
      alert('Enter Dr Reporting');
      return; // Exit the function if validation fails
    }

    if (!addTestData.modality) {
      alert('Enter Modality');
      return; // Exit the function if validation fails
    }

    if (!addTestData.cbTests) {
      alert('Enter Test');
      return; // Exit the function if validation fails
    }

    if (!addTestData.scheduledDate) {
      addTestData.scheduledDate = todayDateTime;
      //alert('Enter scheduledDate');
      //return; // Exit the function if validation fails
    }

    try {

      let patientId = '';

      if (isNewPatient) {
        const patientResponse = await axios.post(`${apiHostLink}Add_Patient_/AddPatientClick`, {
          patIdInst: addTestData.patIdInst,
          fName: addTestData.fName,
          mName: addTestData.mName,
          sName: addTestData.sName,
          gender: addTestData.gender,
          age: addTestData.age,
          ageUnit: addTestData.ageUnit,
          //dob: addTestData.dob,
          mobNo: addTestData.mobNo,
          emailId: addTestData.emailId,
          address: addTestData.address,
          instituteId: instituteId, //addTestData.instituteId,
          userId: userId,
        });

        console.log('Patient added:', patientResponse.data);
        patientId = patientResponse.data;
        //patientId = patientResponse.data;
        handleAddTest2(patientId, isNewPatient);
      } else if (clickedPatient != null) {
        handleAddTest2(clickedPatient.patientId, isNewPatient);
      }

      console.log('Patient ID:', patientId);

    } catch (error) {
      console.error('Error adding patient :', error);
      alert('Failed to add patient or test. Please check your input and try again.');
    }
  };

  const handleAddTest2 = async (patientId, isNewPatient) => {
    try {

      if (isNewPatient) {
        const testResponse = await axios.post(`${apiHostLink}Add_Test_/AddTestClick`, {
          /*inputPatIdInst: addTestData.inputPatIdInst,*/
          fName: addTestData.fName,
          mName: addTestData.mName,
          sName: addTestData.sName,
          patientId: patientId.toString(),
          /*LMP: addTestData.LMP,*/
          isUrgentPatient: addTestData.isUrgentPatient,
          drReporting: drReporting,
          refPhy1: addTestData.refPhy1,
          refPhy2: addTestData.refPhy2,
          modality: addTestData.modality,
          cbTests: addTestData.cbTests,
          scheduledDate: addTestData.scheduledDate,
          instituteId: instituteId,
          userId: userId,
        });

        console.log('Patient Test added:', testResponse.data);
      } else if (clickedPatient != null) {
        const testResponse = await axios.post(`${apiHostLink}Add_Test_/AddTestClick`, {
          /*inputPatIdInst: addTestData.inputPatIdInst,*/
          fName: clickedPatient.fName,
          mName: clickedPatient.mName,
          sName: clickedPatient.sName,
          patientId: patientId.toString(),
          /*LMP: addTestData.LMP,*/
          isUrgentPatient: addTestData.isUrgentPatient,
          drReporting: drReporting,
          refPhy1: addTestData.refPhy1,
          refPhy2: addTestData.refPhy2,
          modality: addTestData.modality,
          cbTests: addTestData.cbTests,
          scheduledDate: addTestData.scheduledDate,
          instituteId: instituteId,
          userId: userId,
        });

        console.log('Patient Test added:', testResponse.data);
      }

      alert('Patient added successfully!');

      fetchDataOnGoClick();   //To refresh data

      setAddTestData({
        patIdInst: '',
        patientId: '',
        fName: '',
        mName: '',
        sName: '',
        gender: '',
        age: '',
        dob: '',
        mobNo: '',
        emailId: '',
        address: '',
        LMP: '',
        isUrgentPatient: false,
        refPhy1: '',
        refPhy2: '',
        modality: '',
        cbTests: '',
        scheduledDate: '',
        instituteId: instituteId,
        userid: userId,
      });
      //handleAddPayment(); //on submit button click

    } catch (error) {
      console.error('Error adding test :', error);
      alert(error);
    }
  };

  //const fetchVisitId = async (patientId, instituteId) => {
  //  try {
  //    const response = await axios.post(`${apiHostLink}Study/GoButtonClick`, {
  //      patientId: patientId,
  //      instituteId: instituteId,
  //    });

  //    const visitId = response.data.visitId;
  //    return visitId;

  //  } catch (error) {
  //    console.error('Error fetching visitId:', error.response ? error.response.data : error.message);
  //    throw new Error('Failed to fetch visitId');
  //  }
  //};

  const [addPaymentData, setAddPaymentData] = useState({
    concession: 0.0,  //double datatype
    extraRs: 0.0,   //double datatype
    pay: 0.0,   //double datatype
    visitId: '',
    ReceiptNo: '',
    BankName: '',
    paymentType: '',
    narration: '',
    transactionId: '',
    UserId: userId,
  });

  /**
     * Handles changes to the input fields and updates the payment data state.
     */
  const handleChange2 = (e) => {
    const { name, value, type, checked } = e.target;
    setAddPaymentData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  /**
     * Submits the payment details to the server.
     */
  const handleAddPayment = async (e) => {
    e.preventDefault();

    try {
      //const visitId = clickedPatient.visitId;

      const paymentResponse = await axios.post(`${apiHostLink}ShowPaymentDetail_/SubmitPaymentDetailClick`, {
        concession: parseFloat(addPaymentData.concession),
        extraRs: parseFloat(addPaymentData.extraRs),
        pay: parseFloat(addPaymentData.pay),
        visitId: clickedPatient.visitId,
        ReceiptNo: addPaymentData.ReceiptNo,
        BankName: addPaymentData.BankName,
        paymentType: selectedPaymentOption,   //addPaymentData.paymentType
        narration: addPaymentData.narration,
        transactionId: addPaymentData.transactionId,
        UserId: userId,
      });

      console.log('Payment Submitted:', paymentResponse.data);
      alert('Payment Submitted');

      fetchDataOnGoClick();   //To refresh data
      setAddPaymentData(prevState => ({
        ...prevState,
        pay: 0.0,
      }));
      const cal = parseInt(clickedPatient.paid, 10) + parseInt(addPaymentData.pay, 10);
      setPaid(cal);
      setPending(clickedPatient.pending - addPaymentData.pay);
      // Assuming the response has a visitId property
      const visitIdFromResponse = paymentResponse.data.visitId;
      console.log('Visit ID:', visitIdFromResponse);

    } catch (error) {
      console.error('Error adding payment details:', error.response ? error.response.data : error.message);

      // Display a more user-friendly error message
      let errorMessage = 'Failed to add payment details. Please try again later.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      alert(errorMessage);
    }
  };

  //const handleAgeChange = (event) => {
  //  setSelectedAge(event.target.value);
  //};

  const showTestNames_Of_SelectedModalities = async (selectedModalities) => {
    try {
      let combinedResults = [];
      if (selectedModalities.includes('All')) {
        // Fetch test data for all modalities
        const promises = modalityList.map(async (modality) => {
          const response = await axios.get(`${apiHostLink}PR_Select_TestNameUsingModalityNameForInstitute_Controllers`, {
            params: {
              ModalityName: modality.modalityName,
              InstituteId: instituteId,
              isShowTestCb: false,
              PanelName: 'Default'
            }
          });
          return response.data;
        });

        const results = await Promise.all(promises);
        combinedResults = results.reduce((acc, cur) => acc.concat(cur), []);
      } else {
        // Fetch test data for selected modalities
        const promises = selectedModalities.map(async (modalityName) => {
          const response = await axios.get(`${apiHostLink}PR_Select_TestNameUsingModalityNameForInstitute_Controllers`, {
            params: {
              ModalityName: modalityName,
              InstituteId: instituteId,
              isShowTestCb: false,
              PanelName: 'Default'
            }
          });
          return response.data;
        });

        const results = await Promise.all(promises);
        combinedResults = results.reduce((acc, cur) => acc.concat(cur), []);
      }

      setTestOptions(combinedResults);
    } catch (error) {
      console.error('Error fetching test data:', error);
    }
  };

  const fetchTestData2 = async (selectedModality) => {
    try {
      const response = await axios.get(`${apiHostLink}ModalityAndTest_`, {
        params: {
          InstituteId: instituteId,
          panelInstituteId: 9
        }
      });

      // Filter tests based on selected modality
      const filteredTests = response.data.filter(test => test.modalityName === addTestData.modality || selectedModality);

      setTestOptions2(filteredTests);
    } catch (error) {
      console.error('Error fetching test data:', error);
    }
  };

  const fetchRefPhyNames1 = async () => {
    try {

      const response = await axios.get(`${apiHostLink}RefPhyNames_?instituteId=${instituteId}&userName=${username}`);

      setRefPhyNameList1(response.data);

    } catch (error) {
      console.error('Error fetching Modality data:', error);
    }
  };

  const fetchCenterName = async () => {
    try {

      const response = await axios.get(`${apiHostLink}CenterName_/updateLabel?instituteName=${instituteName}&userName=${username}`);

      setCenterName(response.data.centerName);
      console.log(response.data.centerName)

    } catch (error) {
      console.error('Error fetching Center Name:', error);
    }
  };

  const [instituteNamePart, userNamePart] = centerName.split('      ');

  const fetchDoctorRegistration2 = async () => {
    try {

      const response = await axios.get(`${apiHostLink}RefPhy2_?instituteId=${instituteId}&userName=${username}`);

      setRefPhyNameList2(response.data);

    } catch (error) {
      console.error('Error fetching Modality data:', error);
    }
  };

  const handle_RefPhy_Selected = (event) => {
    setRefPhy1(event.target.value);
    const { name, value } = event.target;
    setAddTestData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDrRegistrationChange2 = (event) => {
    setRefPhy2(event.target.value);
    const { name, value } = event.target;
    setAddTestData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const fetchPaymentOptions = async () => {
    try {

      const response = await axios.get(`${apiHostLink}PR_Select_FromPaymentOptions_`);

      setPaymentOptions(response.data);

    } catch (error) {
      console.error('Error fetching Payment Options:', error);
    }
  };

  const fetchUserName = async () => {
    try {

      const response = await axios.post(`${apiHostLink}User_/GetUsersList`, {
        instituteId: instituteId,
        UserName: username,
        UserType: userType,
        TspName: '',
        userId: userId
      });

      //const response = await axios.get(`${apiHostLink}User_?instituteId=${instituteId}`);

      setUserName(response.data.filter(name => name !== "All"));

    } catch (error) {
      console.error('Error fetching UserName:', error);
    }
  };

  useEffect(() => {
    if (selectedModality2) {
      fetchTestData2();
    }
  }, [selectedModality2]);

  const handleTestChange2 = (event) => {
    setSelectedTest2(event.target.value);
    const { name, value } = event.target;
    setAddTestData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const fetch_ModalityList_ForPatRegistration = async () => {
    try {
      const response = await axios.get(`${apiHostLink}ModalityAndTest_?instituteId=${instituteId}&panelInstituteId=9`);

      setModalityList2(response.data);

    } catch (error) {
      console.error('Error fetching Modality data:', error);
    }
  };

  const handleModalityChange2 = (event) => {
    setSelectedModality2(event.target.value);

    const { name, value } = event.target;
    try {
      fetchTestData2();  // Fetch test data based on selected modality
      setAddTestData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } catch (error) {
      console.error('Error handling modality change:', error);
    }
  };

  const handle_ShowPaymentChkbox_Changed = () => {
    setShowPaymentColumns(!showPaymentColumns);
    localStorage.setItem('showPayment', !showPaymentColumns);
  };

  const fetchDrReportingNames = async () => {
    try {
      if (instituteId) {
        const response = await axios.get(`${apiHostLink}PR_Select_AllDoctorReporting_?instituteId=${instituteId}`);
        setDrReportingNameList(response.data);
        console.log('Fetched doctor names:', response.data);
      } else {
        console.error('Both instituteId and specialityType are required.');
      }
    } catch (error) {
      console.error('Error fetching doctor names:', error);
    }
  };

  const fetchDrList = async () => {
    try {
      if (instituteId) {
        const response = await axios.post(`${apiHostLink}PR_Select_AllDoctorReporting_/GetDrList`, {
          instituteId: instituteId,
          userId: userId,
        });
        //console.log('Fetched doctor names:', response.data);

        // Filter for DrResident
        const drResident = response.data.filter(person => person.SpecialityType === 'DrResident').map(person => person.DoctorName);

        // Filter for Technicians
        const technicians = response.data.filter(person => person.SpecialityType === 'Technician').map(person => person.DoctorName);

        // Filter for Typists
        const typists = response.data.filter(person => person.SpecialityType === 'Typist').map(person => person.DoctorName);

        setDrResidentNameList(drResident.sort());
        setTypistNameList(typists.sort());
        setTechnicianNameList(technicians.sort());

        console.log('Sorted DrResidents:', technicianNameList);
        console.log('Sorted Technicians:', drResidentNameList);
        console.log('Sorted Typists:', typistNameList);

      } else {
        console.error('Both instituteId and specialityType are required.');
      }
    } catch (error) {
      console.error('Error fetching doctor names:', error);
    }
  }

  // Update the effect to fetch test data when the modality changes
  useEffect(() => {
    if (selectedModalities.length > 0) {
      showTestNames_Of_SelectedModalities(selectedModalities);
    } else {
      // If no modalities are selected, clear the test options
      setTestOptions([]);
    }
  }, [selectedModalities]);

  // Update the test dropdown to use testOptions state
  const handleTestChange = (event) => {
    setSelectedTest(event.target.value);
  };

  const handleLogout = () => {
    navigate("/");
    sessionStorage.removeItem('userPermissions');   //cleared userPermission data after logout
  };

  const fetchModalityData = async () => {
    try {
      // Replace 'YOUR_API_BASE_URL' with the actual base URL of your API
      const response = await axios.get(`${apiHostLink}PR_Select_AllModalityTestOfInstitute_?instituteId=${instituteId}`);

      setModalityList(response.data);
    } catch (error) {
      console.error('Error fetching Modality data:', error);
    }
  };

  const handleModalityFilter_CheckChanged = (selectedModalities) => {
    showTestNames_Of_SelectedModalities(selectedModalities);
    setSelectedModalities(selectedModalities);
  };

  useEffect(() => {
    const fetchData123 = async () => {
      if (instituteId) {
        await fetchLastDateWithStudies();
        await fetchDataWithDates();
        fetchPatientNames();
        // eslint-disable-next-line eqeqeq
        if (templateLevel == 2) {
          await fetchL2Option();
        } else if (templateLevel == 3) {
          await fetchL1Option();
        }
        fetchModalityData();
        await fetchDrReportingNames();
        await fetchDrList();
        fetch_ModalityList_ForPatRegistration();
        fetchRefPhyNames1();
        fetchDoctorRegistration2();
        fetchPaymentOptions();
        fetchUserName();
        fetchCenterName();
      }
    };
    fetchData123();

  }, [instituteId, templateLevel]);

  useEffect(() => {
    // Fetch data for the last available date with studies when the component mounts
    fetchLastDateWithStudies()
      .then(lastDate => {
        // Set fromDate and toDate to the last available date with data
        const initialDate = lastDate ? new Date(lastDate) : new Date();
        setFromDate(initialDate);
        setToDate(initialDate);
      })
      .catch(error => {
        console.error('Error fetching last date with studies:', error);
      });
  }, []);

  useEffect(() => {
    if (drReportingNameList.length > 0) {
      setDrReporting(drReportingNameList[0].doctorName);    //.toString()
    }
  }, [drReportingNameList]);

  useEffect(() => {
    if (l1Options.length > 0) {
      setSelectedL1(l1Options[0]);
    }
  }, [l1Options]);

  useEffect(() => {
    if (l2Options.length > 0) {
      setSelectedL2(l2Options[0]);
    }
  }, [l2Options]);

  //useEffect(() => {
  //  fetchDataWithDates(); // Fetch data when date changes
  //}, [fromDate, toDate]);

  const fetchPatientNames = async () => {
    try {

      const response = await axios.post(`${apiHostLink}HomePage/TopNPatientNames?userId=${userId}&instituteId=${instituteId}`);
      setPatientOptions(response.data);
    } catch (error) {
      console.error('Error fetching data without dates:', error);
    }
  };

  useEffect(() => {
    if (selectedL1) {
      fetchL2Option(selectedL1);
    }
  }, [selectedL1]);

  useEffect(() => {
    if (selectedL2) {
      fetchL3Options(selectedL2);
    }
  }, [selectedL2]);

  const fetchL1Option = async () => {
    try {

      const response = await axios.post(`${apiHostLink}HomePage/GetL1List?instituteId=${instituteId}&tempLevel=${templateLevel}`);

      setL1Options(response.data);
    } catch (error) {
      console.error('L1Option Error:', error);
      alert('L1Option Error:');
    }
  };

  const fetchL2Option = async (selectedL1) => {
    try {

      const response = await axios.post(`${apiHostLink}HomePage/GetL2List?instituteId=${instituteId}&l1=${selectedL1}&tempLevel=${templateLevel}`);

      setL2Options(response.data);
    } catch (error) {
      console.error('L2Option Error:', error);
      alert('L2Option Error:');
    }
  };

  const fetchL3Options = async (selectedL2) => {
    try {
      //const response = /*await axios.get(`${apiHostLink}PR_Select_AllModMainSubCategoryOfInstitute_
      //?instituteId = ${ instituteId }& selectedL2=${ selectedL2 }`);*/

      var link = `${apiHostLink}HomePage/GetL3List?instituteId=${instituteId}&l1=${selectedL1}&l2=${selectedL2}&tempLevel=${templateLevel}`;
      if (templateLevel == 2) {
        link = `${apiHostLink}HomePage/GetL3List?instituteId=${instituteId}&l1=${null}&l2=${selectedL2}&tempLevel=${templateLevel}`;
      }
      const response = await axios.post(link);
      setL3Options(response.data);

      setSelectedL3(response.data[0]);
    } catch (error) {
      console.error('L3Option Error:', error);
      alert('L3Option Error:');
    }
  };

  //const fetchDataL2 = async () => {
  //  try {
  //    const response = await axios.get(`${apiHostLink}PR_Select_AllModMainSubCategoryOfInstitute_?instituteId=${instituteId}`);
  //    setOptions(response.data);
  //  } catch (error) {
  //    console.error('Error fetching data:', error);
  //  }
  //};

  //const fetchL3Options = async (selectedL2) => {
  //  try {
  //    const response = await axios.get(`${apiHostLink}PR_Select_AllModMainSubCategoryOfInstitute_?instituteId=${instituteId}&selectedL2=${selectedL2}`);
  //    setL3Options(response.data);
  //  } catch (error) {
  //    console.error('Error fetching L3 options:', error);
  //  }
  //}

  const handleL1Change = (event) => {
    setSelectedL1(event.target.value);
  };

  const handleL2Change = (event) => {
    setSelectedL2(event.target.value);
  };

  const handleL3Change = (event) => {
    setSelectedL3(event.target.value);
  };

  const handleDrReporting = (event) => {
    setDrReporting(event.target.value);
  };

  const handleDrResident = (event) => { 
    setDrResident(event.target.value);
  }; 

  const handleTypist = (event) => {
    setTypist(event.target.value);
  };

  const handleTechnician = (event) => {
    setTechnician(event.target.value);
  };

  const fetchDataWithDates = async (selectedModalities) => {
    try {
      setSelectedPatient([]);
      const formattedFromDate = formatDate(fromDate); // Format from date as required
      //const toDate = new Date(fromDate);
      //toDate.setDate(toDate.getDate() + 1); // Increment date by one day
      const formattedToDate = formatDate(toDate); // Format to date as required

      //const response = await axios.get(`${apiHostLink}PR_FilterStudiesWithDates_?instituteId=${instituteId}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`);
      //https://localhost:7218/api/PR_FilterStudiesWithDates_/GetOutput
      //const response = await axios.post(`${apiHostLink}PR_FilterStudiesWithDates_/GetOutput`, {
      const response = await axios.post(`${apiHostLink}Study/GoButtonClick`, {
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        instituteId: instituteId,
        checkboxData: selectedModalities || [],
        userId: userId
      });

      setSearchedData(response.data);
      if (selectedModalities) {
        await fetchDataOnGoClick(selectedModalities);
      }
    } catch (error) {
      console.error('GoButtonClick Error:', error);
      alert('GoButtonClick Error:' + error);
    }
  };

  const fetchDataOnGoClick = async () => {
    // Fetch data based on selected modality and test
    const formattedFromDate = formatDate(fromDate);

    toDate.setDate(toDate.getDate());
    toDate.setMonth(toDate.getMonth());
    toDate.setFullYear(toDate.getFullYear());
    toDate.setHours(23, 59, 59);

    const formattedToDate = formatDate(toDate);
    const modality = selectedModalities.length > 0 ? selectedModalities.join(',') : 'All';
    const test = selectedTest || 'All';

    // Prepare checkboxData array
    let checkboxData = [...selectedModalities];

    // Handle special options
    if (selectedModalities.includes('NonReported') && selectedModalities.length === 1) {
      checkboxData = [{ modalityName: 'NonReported' }];
    } else if (selectedModalities.includes('NonImgPrinted') && selectedModalities.length === 1) {
      checkboxData = [{ modalityName: 'NonImgPrinted' }];
    } else if (selectedModalities.includes('NonReportPrinted') && selectedModalities.length === 1) {
      checkboxData = [{ modalityName: 'NonReportPrinted' }];
    } else if (selectedModalities.includes('NonApproved') && selectedModalities.length === 1) {
      checkboxData = [{ modalityName: 'NonApproved' }];
    }

    try {
      const response = await axios.post(`${apiHostLink}Study/GoButtonClick`, {
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: modality,
        test: test,
        instituteId: instituteId,
        checkboxData: checkboxData,
        userId: userId
      });

      console.log('Server Response:', response.data);

      //response.data[0].comment.("Ram Ram");

      setSearchedData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const sec = String(date.getSeconds()).padStart(2, '0');
    //return `${year}-${month}-${day} T${hour}:${min}:${sec}`;
    return `${year}-${month}-${day}T${hour}:${min}:${sec}`;
  };

  const handlePreviousDay = () => {
    fromDate.setDate(fromDate.getDate() - 1);
    toDate.setDate(toDate.getDate() - 1);

    //fetchDataWithDates(selectedModalities);
    fetchDataOnGoClick();
  };

  const handleToday = () => {
    var today = new Date();
    fromDate.setDate(today.getDate());
    fromDate.setMonth(today.getMonth());
    fromDate.setFullYear(today.getFullYear());
    fromDate.setHours(0, 0, 1);

    toDate.setDate(today.getDate());
    toDate.setMonth(today.getMonth());
    toDate.setFullYear(today.getFullYear());
    toDate.setHours(23, 59, 59);

    //fetchDataWithDates(selectedModalities);
    fetchDataOnGoClick();
  };

  const handleNextDay = () => {
    fromDate.setDate(fromDate.getDate() + 1);
    toDate.setDate(toDate.getDate() + 1);
    //fetchDataWithDates(selectedModalities);
    fetchDataOnGoClick();
  };

  function FormatDateForGrid(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const formatDateDDMMYYYY = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      return ''; // Return an empty string if date is null, undefined, not a Date object, or invalid
    }
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${apiHostLink}PR_FilterStudiesWithoutDates_ForSearch_?instituteId=${instituteId}&patientName=${selectedPatient}`);
      setSearchedData(response.data); // Set searched data
      console.log(response.data);
    } catch (error) {
      console.error('Error searching:', error);
    }
  };

  const handleSelectPatient = (e) => {
    setSelectedPatient(e.target.value);
  };


  const handleFilterByPatientId = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Filter/FilterByPatientId`, {
        patientName: "All",
        refPhy: "All",
        showAccounts: "All",
        patientId: selectedPatient,
        patientIdInst: "All",
        refPhy2: "All",
        isSearchOnPatNameTyping: true,
        superInterestingCase: "All",
        mainInterestingCase: "All",
        subInterestingCase: "All",
        isSimilarOrExactSearch: false,
        isSearchUsingMrdOrIpOpdNo: false,
        mrdNo: "",
        opdNo: "",
        patMobileNo: "All",
        sessionUid: sessionUid,
        userId: userId,
      });
      setFilteredDataByPatientId(response.data);
    } catch (error) {
      console.error('Error fetching filtered patients:', error);
    }
  };

  const handleFilterByPatientIdInst = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Filter/FilterByPatientIdinst`, {
        patientName: "All",
        refPhy: "All",
        showAccounts: "All",
        patientId: "All",
        patientIdInst: selectedPatient,
        refPhy2: "All",
        isSearchOnPatNameTyping: true,
        superInterestingCase: "All",
        mainInterestingCase: "All",
        subInterestingCase: "All",
        isSimilarOrExactSearch: false,
        isSearchUsingMrdOrIpOpdNo: false,
        mrdNo: "",
        opdNo: "",
        patMobileNo: "All",
        sessionUid: sessionUid,
        userId: userId,
      });
      setFilteredDataByPatientIdInst(response.data);
    } catch (error) {
      console.error('Error fetching filtered patients:', error);
    }
  };


  const handleFilterByPatientMobileNo = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Filter/FilterByMobileNumber`, {
        patientName: "All",
        refPhy: "All",
        showAccounts: "All",
        patientId: "All",
        patientIdInst: "All",
        refPhy2: "All",
        isSearchOnPatNameTyping: true,
        superInterestingCase: "All",
        mainInterestingCase: "All",
        subInterestingCase: "All",
        isSimilarOrExactSearch: false,
        isSearchUsingMrdOrIpOpdNo: false,
        mrdNo: "",
        opdNo: "",
        patMobileNo: selectedPatient,
        sessionUid: sessionUid,
        userId: userId,
      });
      setFilteredDataByPatientMobileNo(response.data);
    } catch (error) {
      console.error('Error fetching filtered patients:', error);
    }
  };

  const handleFilterByPatientName = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Filter/FilterByPatientName`, {
        patientName: selectedPatient,
        refPhy: "All",
        showAccounts: "All",
        patientId: "All",
        patientIdInst: "All",
        refPhy2: "All",
        isSearchOnPatNameTyping: true,
        superInterestingCase: "All",
        mainInterestingCase: "All",
        subInterestingCase: "All",
        isSimilarOrExactSearch: false,
        isSearchUsingMrdOrIpOpdNo: false,
        mrdNo: "",
        opdNo: "",
        patMobileNo: "All",
        sessionUid: sessionUid,
        userId: userId,
      });
      setFilteredDataByPatientName(response.data);
    } catch (error) {
      console.error('Error fetching filtered patients:', error);
    }
  };

  const handleFilterByRefPhy = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Filter/FilterByRefPhy`, {
        patientName: "All",
        refPhy: selectedPatient,
        showAccounts: "All",
        patientId: "All",
        patientIdInst: "All",
        refPhy2: "All",
        isSearchOnPatNameTyping: true,
        superInterestingCase: "All",
        mainInterestingCase: "All",
        subInterestingCase: "All",
        isSimilarOrExactSearch: false,
        isSearchUsingMrdOrIpOpdNo: false,
        mrdNo: "",
        opdNo: "",
        patMobileNo: "All",
        sessionUid: sessionUid,
        userId: userId,
      });
      setFilteredDataByRefPhy(response.data);
    } catch (error) {
      console.error('Error fetching filtered patients:', error);
    }
  };

  const handleGetDbConfigValue = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Filter/GetDbConfigValue`, {
        userId: userId,
        sessionUid: sessionUid,
        dbConfigName: "WLPatNameOrderForCR",
      });
      //setFilteredDataByRefPhy(response.data);
      alert(response.data);
    } catch (error) {
      console.error('Error fetching filtered patients:', error);
    }
  };

  const handleSetDbConfigValue = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Filter/SetDbConfigValue`, {
        userId: userId,
        sessionUid: sessionUid,
        dbConfigName: "WLPatNameOrderForCR",
        dbConfigValue: selectedPatient
      });
      //setFilteredDataByRefPhy(response.data);
      //alert(response.data);
    } catch (error) {
      console.error('Error fetching filtered patients:', error);
    }
  };

  const handleFilterByRefPhy2 = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Filter/FilterByRefPhy2`, {
        patientName: "All",
        refPhy: "All",
        showAccounts: "All",
        patientId: "All",
        patientIdInst: "All",
        refPhy2: selectedPatient,
        isSearchOnPatNameTyping: true,
        superInterestingCase: "All",
        mainInterestingCase: "All",
        subInterestingCase: "All",
        isSimilarOrExactSearch: false,
        isSearchUsingMrdOrIpOpdNo: false,
        mrdNo: "",
        opdNo: "",
        patMobileNo: "All",
        sessionUid: sessionUid,
        userId: userId,
      });
      setFilteredDataByRefPhy2(response.data);
    } catch (error) {
      console.error('Error fetching filtered patients:', error);
    }
  };

  const handleFilterByPatientMrdOrIp = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Filter/FilterByMrdOrIp`, {
        patientName: "All",
        refPhy: "All",
        showAccounts: "All",
        patientId: "All",
        patientIdInst: "All",
        refPhy2: "All",
        isSearchOnPatNameTyping: true,
        superInterestingCase: "All",
        mainInterestingCase: "All",
        subInterestingCase: "All",
        isSimilarOrExactSearch: false,
        isSearchUsingMrdOrlpOpdNo: true,
        mrdNo: selectedPatient,
        opdNo: selectedPatient,
        patMobileNo: "All",
        sessionUid: sessionUid,
        userId: userId,
      });
      setFilteredDataByPatientMrdOrIp(response.data);
    } catch (error) {
      console.error('Error fetching filtered patients:', error);
    }
  };

  useEffect(() => {
    setSearchedData(filteredDataByPatientId);
  }, [filteredDataByPatientId]);

  useEffect(() => {
    setSearchedData(filteredDataByPatientIdInst);
  }, [filteredDataByPatientIdInst]);

  useEffect(() => {
    setSearchedData(filteredDataByPatientMobileNo);
  }, [filteredDataByPatientMobileNo]);

  useEffect(() => {
    setSearchedData(filteredDataByPatientName);
  }, [filteredDataByPatientName]);

  useEffect(() => {
    setSearchedData(filteredDataByRefPhy);
  }, [filteredDataByRefPhy]);

  useEffect(() => {
    setSearchedData(filteredDataByRefPhy2);
  }, [filteredDataByRefPhy2]);

  useEffect(() => {
    setSearchedData(filteredDataByPatientMrdOrIp);
  }, [filteredDataByPatientMrdOrIp]);


  async function fetchLastDateWithStudies() {
    try {
      // Check if fromDate and toDate are already set
      if (!fromDate || !toDate) {
        const response = await axios.get(`${apiHostLink}PR_Select_LastStudyEnterDateTime_?instituteId=${instituteId}`);

        const lastStudyDate = response.data[0]?.date; // Get the last study date

        // Set fromDate and toDate only if they are not already set
        if (!fromDate) {
          fromDate = new Date(lastStudyDate);
          fromDate.setHours(0, 0, 1);
          setFromDate(fromDate);
        }

        if (!toDate) {
          toDate = new Date(lastStudyDate);
          toDate.setHours(23, 59, 59);
          setToDate(toDate);
        }
      }
    } catch (error) {
      console.error('LastDateWithStudies Error:', error);
    }
  };

  //const fetchLastDateWithStudiesWithDelay = () => {
  //  setTimeout(() => {
  //    fetchLastDateWithStudies();
  //  }, 30000); // Delay for 3 seconds
  //};

  //console.log('Options:', options);

  useEffect(() => {

    const savedInstTempLevel = localStorage.getItem("templateLevel");
    if (savedInstTempLevel) {
      setTemplateLevel(savedInstTempLevel);
    }

    var is3LevelTemplate = savedInstTempLevel == 3;
    setshowTemplateL1(is3LevelTemplate);

  }, []);

  const handleRowClick = (selRow) => {
    setSelectedRow(selRow);
    //setPatMobileNo(selRow.mobileNo);
    //setPatEmailId(selRow.email);
    //setPatFName(selRow.fName);
    //setPatMName(selRow.mName);
    //setPatSName(selRow.sName);
    //setPatIdInst(selRow.patientIdInst);
    //setPatAddress(selRow.address);
    const isModalityMatch = l2Options.some(option => option === selRow.modality);
    const isTestMatch = testOptions.some(option => option.testName === selRow.test);

    if (templateLevel == 3) {
      setSelectedL1(isModalityMatch ? selRow.modality : l2Options[0] || '');
      setSelectedL2(isTestMatch ? selRow.test : l2Options[0] || '');
      //setSelectedL3(l3Options[1]);//By default first: Rasik keep this line commented. because, we should not set it here.
      //let it first bring l3Options from API.
      //it is handled in fetchL3Options()
    } else {
      setSelectedL2(isModalityMatch ? selRow.modality : l2Options[0] || '');
      setSelectedL3(isTestMatch ? selRow.test : '');
    }
    if (selRow.drPerforming) {
      setDrReporting(selRow.drPerforming);
    }
    //setSelectedPatient(selRow);
    //FillPatDetailsForRow(selRow); 
    setClickedPatient(selRow);
    setPaid(selRow.paid);
    setPending(selRow.pending);
    if (selRow != null && selRow.total === -1) {
      setShowPaymentPanelDiv(false);
    } else {
      setShowPaymentPanelDiv(true);
    }

    setReportApproved(selRow.reportApproved);
    setComment(selRow.comment);
    setReportPresent(selRow.reportPresent);     //Required for Create/Open Report Button

    const rowData = {
      fName: selRow.fName,
      mName: selRow.mName,
      sName: selRow.sName,
      patientId: selRow.patientId,
      isUrgentPatient: false,
      drReporting: '',
      refPhy1: '',
      refPhy2: '',
      modality: selRow.modality,
      cbTests: selRow.test,
      scheduledDate: selRow.scheduledDate,
      instituteId: instituteId,
      UserId: userId,
    };
    localStorage.setItem('selectedRowData', JSON.stringify(rowData));

    console.log('Clicked Row Data:', selRow);
    //handleCreateReport(clickedPatient);
  };

  //const FillPatDetailsForRow = (selRow)=>{
  //  //if (selRow === null) return null;
  //  if (selRow.PatId !== "" && selRow.Total !== "") return;

  //  var row = searchedData.find(s => s.visitId === selRow.visitId && s.patientName !== "");

  //  //var visitId = selRow.VisitId;
  //  //var patRow = selRow;// dgvPatientDetails.Items.Cast < DataRowView > ().ToList().Find(r => r[MainWindDgci.VisitId].ToString() === visitId && r[MainWindDgci.PatId] !== DBNull.Value && r[MainWindDgci.Total] !== DBNull.Value);

  //  if (row === null) {
  //    return;
  //  }

  //  selRow = FillStudyPatDetails(selRow, row);
  //}

  //const FillStudyPatDetails=(selRow, patRow) =>{
  //  selRow.PatientName = patRow.PatientName;
  //  selRow.PatName = patRow.PatName;
  //  selRow.PatId = patRow.PatId;
  //  selRow.PatIdInst = patRow.PatIdInst;
  //  selRow.SrNo = patRow.SrNo;
  //  selRow.InstVisitId = patRow.InstVisitId;
  //  selRow.Age = patRow.Age;
  //  selRow.AgeUnit = patRow.AgeUnit;
  //  selRow.Gender = patRow.Gender;
  //  selRow.ExtraRs = patRow.ExtraRs;
  //  selRow.Concession = patRow.Concession;
  //  selRow.Total = patRow.Total;
  //  selRow.Paid = patRow.Paid;
  //  selRow.Pending = patRow.Pending;
  //  return selRow;
  //}

  const FillStudyPatDetails = (clickedPatient, row) => {
    return {
      ...clickedPatient,
      PatientName: row.PatientName,
      PatName: row.PatName,
      PatId: row.PatId,
      PatIdInst: row.PatIdInst,
      SrNo: row.SrNo,
      InstVisitId: row.InstVisitId,
      Age: row.Age,
      AgeUnit: row.AgeUnit,
      Gender: row.Gender,
      ExtraRs: row.ExtraRs,
      Concession: row.Concession,
      Total: row.Total,
      Paid: row.Paid,
      Pending: row.Pending
    };
  };

  const handleOpenTemplate = async () => {

    if (!selectedL2 && !selectedL3) {
      alert('Kindly first Select Template Category');
      return;
    }

    try {

      // Make a POST request to your backend API to create the Google report
      const response = await axios.post(`${apiHostLink}Report/OpenTemplate`, {
        Query: " ",
        L1: selectedL1,
        L2: selectedL2,
        L3: selectedL3,
        InstituteId: instituteId,
        sessionUid: sessionUid,
        userId: userId,
      });

      // Convert the response.data to a string
      const templateUrl = JSON.stringify(response.data.reportContent);

      // Remove the localhost and extra characters before the link
      const url = templateUrl.substring(templateUrl.indexOf('"') + 1, templateUrl.lastIndexOf('"'));
      window.open(url, '_blank');

    } catch (error) {
      console.error('OpenTemplateCrash:', error);
    }
  };

  const handleCreateReport = async () => {
    if (reportPresent) {      //reportPresent => true => ViewReport
      if (!userPermissions.includes("ViewReport")) {
        alert('You do not have permission to View the Report!');
        return;
      }
    } else {                  //reportPresent => false => CreateReport
      if (!userPermissions.includes("CreateReport")) {
        alert('You do not have permission to Create a Report!');
        return;
      }
    }

    if (!clickedPatient) {
      alert('Kindly Select Patient first');
      return;
    }
    if (!clickedPatient.reportPresent) {
      if (!selectedL2 && !selectedL3) {
        alert('Kindly first Select Template Category');
        return;
      }
    }
    if (!drReporting && !clickedPatient.reportPresent) {
      alert('Please Select DrReporting first');
      return;
    }

    setEnableCreateDeleteReportBtn(false);
    var PatientIdInst = clickedPatient.patientIdInst;
    var PatientTitle = clickedPatient.patientTitle;
    var PatientName = clickedPatient.patientName;
    var Age = clickedPatient.age;
    var AgeUnit = clickedPatient.ageUnit;
    var Gender = clickedPatient.gender;
    var StudyDate = clickedPatient.date;

    if (clickedPatient.patientName == "") {
      var row = searchedData.find(s => s.visitId == clickedPatient.visitId && s.patientName != "");
      console.log("Row data:", row);
      console.log("Clicked Patient before update:", clickedPatient);
      if (row != null) {
        //clickedPatient = row;
        //clickedPatient = FillStudyPatDetails(clickedPatient, row);
        //clickedPatient = { ...clickedPatient, ...row };

        PatientIdInst = row.patientIdInst;
        PatientTitle = row.patientTitle;
        PatientName = row.patientName;
        Age = row.age;
        AgeUnit = row.ageUnit;
        Gender = row.gender;
        StudyDate = row.date;
      }
    }

    try {

      // Make a POST request to your backend API to create the Google report
      const response = await axios.post(`${apiHostLink}Report/CreateReport`, {
        IsOpenReport: clickedPatient.reportPresent,
        PatientIdInst: PatientIdInst,
        PatientTitle: PatientTitle,
        PatientName: PatientName,
        Age: Age,
        Gender: Gender,
        Date: StudyDate,
        ageUnit: AgeUnit,

        StudyId: clickedPatient.studyId,//Do not change this
        refPhy1: clickedPatient.referringPhysician,
        refPhy2: clickedPatient.refPhy2,
        Modality: clickedPatient.modality,
        Test: clickedPatient.test,
        instituteId: instituteId,

        templateL1: selectedL1,
        templateL2: selectedL2,
        templateL3: selectedL3,
        DrReporting: drReporting,
        userId: userId,
        sessionUid: sessionUid,
        IsReportApproved: clickedPatient.reportApproved,
        userId: userId

      });
      // Convert the response.data to a string
      const reportUrl = JSON.stringify(response.data.reportContent);

      // Remove the localhost and extra characters before the link
      const url = reportUrl.substring(reportUrl.indexOf('"') + 1, reportUrl.lastIndexOf('"'));
      setReportUrl(url);
      setReportPresent(true);
      clickedPatient.reportPresent = true;    //Color change
      setReportUrl(response.data);
      //setReportPresent(true);

      //fetchDataOnGoClick();   //To refresh data
      window.open(url, '_blank');
      setEnableCreateDeleteReportBtn(true);
      console.log(response.data);
      console.log('Report present:', true);
    } catch (error) {
      //alert(error.response.data);

      if (clickedPatient.reportPresent) {//reportPresent
        if (!clickedPatient.reportApproved) {
          alert("Error Opening Report: Report file NOT Found:        Report is not Approved Yet, Dr May be in process of Typing report from Desktop => after report is approved, you will be able to Open it.");
          console.log(error);
        } else {
          alert("Error Opening Report: " + error.response.data);
        }

      } else {
        alert("Error Creating Report: " + error.response.data);
      }

      console.error('Error creating report:', error);
      setEnableCreateDeleteReportBtn(true);
    }
  };

  const btnViewImages_Click = async () => {
    if (!clickedPatient) {
      alert('Please Select Pateint first!');
      return;
    }

    try {

      const studyUid = clickedPatient.studyUid;// selectedRow[MainWindDgci.StudyUid].ToString();

      //const imgUrl = "http://star.softtact.com:3000/studylist?username=jalpa@softtact.com&password=jalpa&"
      //  + studyUid;
      //"http://" + DBConfig.ImgWebLinkAndPort + "/studylist?"
      //DBConfig.ImgWebLinkAndPort
      //const imgUrl = "http://"+DbConfigWeb.DicomServerIPAddress+":3000/viewer/" + studyUid;
      const imgUrl = imgWebLinkAndPort + studyUid; /*`http://${imgWebLinkAndPort}/viewer/` + studyUid;*/

      window.open(imgUrl, '_blank');
    } catch (error) {
      console.error('Error creating report:', error);
    }
  };

  const handleGoButtonClick = async () => {
    fetchDataOnGoClick();
    await handleUpdatePatient();
    //fetchDataWithDates(selectedModalities);
  };

  const handleDeleteReport = async () => {
    if (!userPermissions.includes("DeleteReport")) {
      alert('You do not have permission to Delete Report!');
      return;
    }

    if (!clickedPatient) {
      alert('Kindly Select Patient first');
      return;
    }
    setEnableCreateDeleteReportBtn(false);
    var patName = clickedPatient.patientName;

    if (clickedPatient.patientName == "") {
      var row = searchedData.find(s => s.visitId == clickedPatient.visitId && s.patientName != "");
      console.log("Row data:", row);
      console.log("Clicked Patient before update:", clickedPatient);
      if (row != null) {
        patName = row.patientName;
      }
    }

    try {

      // Make a POST request to your backend API to create the Google report
      const response = await axios.post(`${apiHostLink}Report/DeleteReport`, {
        PatientIdInst: clickedPatient.patientIdInst,
        StudyId: clickedPatient.studyId,
        PatientTitle: clickedPatient.patientTitle,
        PatientName: patName,
        instituteId: instituteId,
        userId: userId,
        Date: clickedPatient.date,
        sessionUid: sessionUid,
        userId: userId
      });
      // Convert the response.data to a string
      const reportUrl = JSON.stringify(response.data.reportContent);

      clickedPatient.reportPresent = false;   //Color Change
      setReportPresent(false);
      //fetchDataOnGoClick();   //To refresh data
      alert("Report Deleted Successfully");
      setEnableCreateDeleteReportBtn(true);
      console.log(response.data);
    } catch (error) {
      clickedPatient.reportPresent = false;   //Color Change
      setReportPresent(false);
      alert("Error Deleting Report: " + error.response.data);

      //TODO Sunny => Set report CheckBox Background = red
      //Change the button Text to => Create Report

      console.error('Error Deleting Report:', error);
      setEnableCreateDeleteReportBtn(true);
    }
  };

  const btnZipImages_Click = async () => {
    if (!clickedPatient) {
      alert('Kindly Select Patient first');
      return;
    }
    setIsButtonDisabled(true); // Disable the button
    try {
      const response = await axios.post(`${apiHostLink}Report/ZipImage`, {
        StudyId: clickedPatient.studyId,
        PatientName: clickedPatient.patientName,
        PatientIdInst: clickedPatient.patientIdInst,
        instituteId: instituteId,
        IsShift: isShift,
        ImagesCount: clickedPatient.images,
        StudyDir: clickedPatient.studyDir,
        instituteName: instituteName,
        userId: userId,
        sessionUid: sessionUid,
        userId: userId,
      }, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });
      // Convert the response.data to a Blob
      //alert("Zip file is created successfully");

      var dateStr = clickedPatient.date.replaceAll('-', '');
      // Extract filename from the response headers
      const zipFileName = instituteName.substr(0, 10) + '_' + clickedPatient.patientName + '_' + dateStr + '.zip';

      alert(zipFileName + " file is downloading");

      const blob = new Blob([response.data], { type: 'application/zip' });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      // Set the href attribute of the link to the temporary URL
      link.href = url;
      // Set the download attribute of the link to the desired file name
      link.download = zipFileName;
      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up by revoking the temporary URL
      //window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error creating report:', error);
      alert('Zip file not created');
    } finally {
      setIsButtonDisabled(false); // Re-enable the button
    }

  };

  const totalCount2 = searchedData.length;
  const nonEmptyPatientNames = searchedData.filter(row => row.patientName.trim() !== '');
  const totalCount1 = nonEmptyPatientNames.length;
  const imgPresentCount = searchedData.filter(row => row.images > 0).length;
  const imgZeroCheckedCount = searchedData.filter(row => row.imagesPrinted).length;
  const repCheckedCount = searchedData.filter(row => row.reportStatus).length;
  const reportWhiteBackgroundCount = searchedData.filter(row => row.reportPresent).length;
  const aprCheckedCount = searchedData.filter(row => row.reportApproved).length;
  const dispCheckedCount = searchedData.filter(row => row.reportDispatched).length;

  const handleImgCheckboxChanges = async (e, id, fieldName, studyUid) => {
    const isChecked = e.target.checked;
    const updatedImagesPrinted = isChecked ? true : false;

    try {
      const response = await axios.post(`${apiHostLink}Study/SetImgPrinted`, {
        StudyId: id,
        StudyUid: studyUid,
        ImagesPrinted: updatedImagesPrinted,
        Images: clickedPatient.images,
        UserId: userId,
      });

      if (response.status === 200) {
        const index = searchedData.findIndex(item => item.studyId === id);

        if (index !== -1) {
          searchedData[index][fieldName] = updatedImagesPrinted;
          const selectedCount = searchedData.filter(item => item.imagesPrinted).length;
          setAllChecked(selectedCount === searchedData.length);
          setSearchedData([...searchedData]);
        }
      } else {
        console.error('Failed to update ImagesPrinted');
      }
    } catch (error) {
      console.error('Error updating ImagesPrinted:', error);
    }
  };

  const handleRepCheckboxChanges = async (e, id, fieldName, studyUid) => {
    const isChecked = e.target.checked;
    const updatedReportPrinted = isChecked ? true : false;

    const response = await axios.post(`${apiHostLink}Study/SetRepPrinted`, {
      StudyId: id,
      StudyUid: studyUid,
      ReportStatus: updatedReportPrinted,
      UserId: userId,
    });

    if (response.status === 200) {
      const updatedData = searchedData.map(item => {
        if (item.studyId === id) {
          return { ...item, [fieldName]: updatedReportPrinted };
        }
        return item;
      });

      console.log('Updated data:', updatedData);
      setSearchedData(updatedData);
    } else {
      console.error('Failed to update ReportStatus');
    }
  };

  const handleRepApprCheckboxChanges = async (e, id, fieldName, studyUid) => {
    const isChecked = e.target.checked;

    if ((isChecked && !userPermissions.includes("ReportApproval")) ||
      (!isChecked && !userPermissions.includes("UnApproveReport"))) {
      alert(isChecked ? 'You do not have permission to Approve Report!' : 'You do not have permission to Unapprove Report!');
      return;
    }

    const updatedReportApproved = isChecked ? true : false;

    const response = await axios.post(`${apiHostLink}Study/SetRepApprove`, {
      StudyId: id,
      StudyUid: studyUid,
      ReportApproved: updatedReportApproved,
      UserId: userId,
    });

    if (!selRow.reportPresent) {
      alert("Report is not created / present!!!");


    // Prevent the checkbox from being checked
    e.preventDefault();
    return;
  }

    if (response.status === 200) {
      const updatedData = searchedData.map(item => {
        if (item.studyId === id) {
          return { ...item, [fieldName]: updatedReportApproved };
        }
        return item;
      });

      console.log('Updated data:', updatedData);
      setSearchedData(updatedData);
    } else {
      console.error('Failed to update ReportApproved');
    }
  };

  const handleDWCheckboxChanges = async (e, id, fieldName, studyUid) => {
    const isChecked = e.target.checked;
    if (!userPermissions.includes("CanDoctorWhatsApp")) {
      alert('You do not have permission to Doctor Whatsapp!');
      e.preventDefault();
      return;
    }

    if (!clickedPatient) {
      alert('Kindly Select Patient first');
      e.target.checked = !isChecked;
      return;
    }

    if (!clickedPatient.reportPresent) {
      if (!selectedL2 && !selectedL3) {
        alert('Kindly first Select Template Category');
        e.target.checked = !isChecked;
        return;
      }
    }

    const updatedDW = isChecked ? true : false;
    try {
      const response = await axios.post(`${apiHostLink}Study/SetDW`, {
        StudyId: id,
        StudyUid: studyUid,
        DW: updatedDW,
        ReferringPhysician: clickedPatient.referringPhysician,
        PatientId: clickedPatient.patientId,

        ReportPresent: clickedPatient.reportPresent,   //IsOpenReport
        PatientIdInst: clickedPatient.patientIdInst,
        PatientName: clickedPatient.patientName,
        Age: clickedPatient.age,
        Gender: clickedPatient.gender,
        Date: clickedPatient.date,
        AgeUnit: clickedPatient.ageUnit,

        RefPhy2: clickedPatient.refPhy2,
        Modality: clickedPatient.modality,
        Test: clickedPatient.test,
        InstituteId: instituteId,

        TemplateL1: selectedL1,
        TemplateL2: selectedL2,
        TemplateL3: selectedL3,
        DrReporting: drReporting,
        UserId: userId,
        SessionUid: sessionUid,
        ReportApproved: clickedPatient.reportApproved   //IsReportApproved
      });

      if (response.status === 200) {
        const updatedData = searchedData.map(item => {
          if (item.studyId === id) {
            return { ...item, [fieldName]: updatedDW };
          }
          return item;
        });

        console.log('Updated data:', updatedData);
        setSearchedData(updatedData);
        const statusCodeMatch = response.data.match(/"statusCode":"(\d+)"/);
        if (statusCodeMatch) {
          const statusCode = parseInt(statusCodeMatch[1], 10);
          if (statusCode !== 200) {
            alert("Whatsapp not sent via Mtalkz API");
            e.target.checked = !isChecked;
          }
        } else {
          alert(response.data);
          e.target.checked = !isChecked;
        }
        console.log(response.data);
      } else {
        alert('Failed to update DW');
        console.error(response.data);
        e.target.checked = !isChecked;
      }
    } catch (error) {
      alert(error.response.data);
      console.log(error);
    }
  };

  const handlePWCheckboxChanges = async (e, id, fieldName, studyUid) => {
    const isChecked = e.target.checked;
    if (!userPermissions.includes("CanPatientWhatsApp")) {
      alert('You do not have permission to Patient Whatsapp!');
      e.preventDefault();
      return;
    }

    if (!clickedPatient.mobileNo) {
      alert('Patient Mobile Number Not Present!');
      e.target.checked = !isChecked;
      return;
    }

    if (!clickedPatient) {
      alert('Kindly Select Patient first');
      e.target.checked = !isChecked;
      return;
    }

    if (!clickedPatient.reportPresent) {
      if (!selectedL2 && !selectedL3) {
        alert('Kindly first Select Template Category');
        e.target.checked = !isChecked;
        return;
      }
    }

    const updatedPW = isChecked ? true : false;
    try {
      const response = await axios.post(`${apiHostLink}Study/SetPW`, {
        StudyId: id,
        StudyUid: studyUid,
        PW: updatedPW,
        MobileNo: clickedPatient.mobileNo,

        ReportPresent: clickedPatient.reportPresent,   //IsOpenReport
        PatientIdInst: clickedPatient.patientIdInst,
        PatientName: clickedPatient.patientName,
        Age: clickedPatient.age,
        Gender: clickedPatient.gender,
        Date: clickedPatient.date,
        AgeUnit: clickedPatient.ageUnit,

        RefPhy2: clickedPatient.refPhy2,
        Modality: clickedPatient.modality,
        Test: clickedPatient.test,
        InstituteId: instituteId,

        TemplateL1: selectedL1,
        TemplateL2: selectedL2,
        TemplateL3: selectedL3,
        DrReporting: drReporting,
        UserId: userId,
        SessionUid: sessionUid,
        ReportApproved: clickedPatient.reportApproved   //IsReportApproved
      });

      if (response.status === 200) {
        const updatedData = searchedData.map(item => {
          if (item.studyId === id) {
            return { ...item, [fieldName]: updatedPW };
          }
          return item;
        });

        console.log('Updated data:', updatedData);
        setSearchedData(updatedData);

        const statusCodeMatch = response.data.match(/"statusCode":"(\d+)"/);
        if (statusCodeMatch) {
          const statusCode = parseInt(statusCodeMatch[1], 10);
          if (statusCode !== 200) {
            alert("Whatsapp not sent via Mtalkz API");
            e.target.checked = !isChecked;
          }
        } else {
          alert(response.data);
        }
        console.log(response.data);
      } else {
        alert('Failed to update PW');
        console.error(response.data);
        e.target.checked = !isChecked;
      }
    } catch (error) {
      alert(error.response.data);
      console.log(error);
    }
  };

  const handleReportDispatchedCheckboxChanges = async (e, id, fieldName, studyUid) => {
    const isChecked = e.target.checked;
    const updatedReportDispatchedPrinted = isChecked ? true : false;

    const response = await axios.post(`${apiHostLink}Study/SetReportDispatched`, {
      StudyId: id,
      StudyUid: studyUid,
      ReportDispatched: updatedReportDispatchedPrinted,
      UserId: userId,
    });

    if (response.status === 200) {
      const updatedData = searchedData.map(item => {
        if (item.studyId === id) {
          return { ...item, [fieldName]: updatedReportDispatchedPrinted };
        }
        return item;
      });

      console.log('Updated data:', updatedData);
      setSearchedData(updatedData);
    } else {
      console.error('Failed to update ReportDispatched');
    }
  };

  {/* Region This section is reponsible for tab to work properly while New Patient Registration*/ }

  const lastNameInput = useRef(null);
  const maleInputRef = useRef(null);
  const femaleInputRef = useRef(null);
  const ageInputRef = useRef(null);
  const refphy1Input = useRef(null);
  const refphy2Input = useRef(null);
  const modalityInput = useRef(null);
  const testInput = useRef(null);

  useEffect(() => {
    const handleTabKeyPress = (event) => {
      if (event.key === 'Tab') {
        if (document.activeElement === lastNameInput.current && !event.shiftKey) {
          maleInputRef.current.focus();
          event.preventDefault();
        } else if (document.activeElement === maleInputRef.current && event.shiftKey) {
          lastNameInput.current.focus();
          event.preventDefault();
        } else if (document.activeElement === maleInputRef.current && !event.shiftKey) {
          femaleInputRef.current.focus();
          event.preventDefault();
        } else if (document.activeElement === femaleInputRef.current && event.shiftKey) {
          maleInputRef.current.focus();
          event.preventDefault();
        } else if (document.activeElement === femaleInputRef.current && !event.shiftKey) {
          ageInputRef.current.focus();
          event.preventDefault();
        } else if (document.activeElement === ageInputRef.current && event.shiftKey) {
          femaleInputRef.current.focus();
          event.preventDefault();
        } else if (document.activeElement === refphy1Input.current && !event.shiftKey) {
          refphy2Input.current.focus();
          event.preventDefault();
        } else if (document.activeElement === refphy2Input.current && event.shiftKey) {
          refphy1Input.current.focus();
          event.preventDefault();
        } else if (document.activeElement === refphy2Input.current && !event.shiftKey) {
          modalityInput.current.focus();
          event.preventDefault();
        } else if (document.activeElement === modalityInput.current && event.shiftKey) {
          refphy2Input.current.focus();
          event.preventDefault();
        } else if (document.activeElement === modalityInput.current && !event.shiftKey) {
          testInput.current.focus();
          event.preventDefault();
        } else if (document.activeElement === testInput.current && event.shiftKey) {
          modalityInput.current.focus();
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleTabKeyPress);

    return () => {
      document.removeEventListener('keydown', handleTabKeyPress);
    };
  }, []);

  const handleUpdatePatient = async () => {
    try {
      if (buttonState === 'update') {
        const updatedPatientData = {
          instituteId: instituteId,
          statusType: clickedPatient.studyStatusType,
          patientTitle: "",
          cbSearch: "",
          mobNo: clickedPatient.mobileNo,
          email: clickedPatient.email,
          patientId: clickedPatient.patientId.toString(),
          studyId: clickedPatient.studyId.toString(),
          patName: clickedPatient.patientName,
          studyUid: clickedPatient.studyUid,
          gender: clickedPatient.gender,
          panel: "Default",
          patientIdInst: clickedPatient.patientIdInst,
          drPerforming: drPerforming || clickedPatient.drPerforming,
          refPhy1: referringPhysician1 || clickedPatient.refPhy1,
          refPhy2: referringPhysician2 || clickedPatient.refPhy2,
          fName: clickedPatient.fName,
          address: clickedPatient.address,
          mName: clickedPatient.mName || "",
          sName: clickedPatient.sName || "",
          age: clickedPatient.age.toString(),
          ageUnit: clickedPatient.ageUnit,
          newPatIdInst: newPatIdInst || clickedPatient.patientIdInst,
          modality: modality || clickedPatient.modality,
          testName: test || clickedPatient.test,
          studyTime: clickedPatient.studyTime,
          dob: clickedPatient.dob,
          lmpDate: clickedPatient.studyTime,
          isUpdateRefPhyToVisit: true,
          isUpdateDrPerformingToVisit: true,
          userId: userId,
        };

        await axios.post(`${apiHostLink}EditPatient_/UpdatePatientButton`, updatedPatientData);
      }
    } catch (error) {
      console.error('Error updating patient:', error);
    }
  };

  {/* End Region */ }

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.ctrlKey && event.shiftKey) {
        event.preventDefault();
        let buttonId = '';
        switch (event.key) {
          case 'A':
            buttonId = 'goButton';
            break;
          case 'M':
            buttonId = 'goButton';
            break;
          case 'C':
            buttonId = 'goButton';
            break;
          case 'O':
            buttonId = 'goButton';
            break;
          case 'P':
            buttonId = 'goButton';
            break;
          case 'R':
            buttonId = 'goButton';
            break;
          case 'S':
            buttonId = 'goButton';
            break;
          case 'T':
            buttonId = 'goButton';
            break;
          case 'W':
            buttonId = 'goButton';
            break;
          case 'X':
            buttonId = 'goButton';
            break;
          default:
            break;
        }
        const goButton = document.getElementById(buttonId);
        if (goButton) {
          goButton.style.border = '2px solid black';
          goButton.focus();
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handlePastStudies = async () => {
    try {
      const response = await axios.get(`${apiHostLink}PastStudies/patientPastStudy?instituteId=${instituteId}&patientId=${clickedPatient.patientId}&patIdInst=${clickedPatient.patientIdInst}`);
      setSearchedData(response.data); // Set searched data
      console.log(response.data);
    } catch (error) {
      console.error('Error searching:', error);
    }
  };

  const [isRed, setIsRed] = useState(false);
  const [selRow, setSelRow] = useState({ isMedicoLegal: false });
  const handleMedicoLegal = () => {
    setIsRed(true);
    setSelRow({ isMedicoLegal: true });
  };

  const handleModalityClick = (index) => {
    // Toggle the selected row (just an example)
    setSelRow((prev) => ({
      ...prev,
      isMedicoLegal: !prev.isMedicoLegal,
    }));
    console.log(`Row ${index} clicked`);
  };

  /**
     * Calculates the total price based on the patient's total, concession, and extraRs.
     */
  const calculateTotalPrice = (patient) => {
    const { total, concession, extraRs } = patient;
    const calculatedTotalPrice = (total + concession - extraRs).toString();
    return calculatedTotalPrice;
  };

  //View Report Button Disable
  const shouldDisableViewImagesButton = (data) => {
    return !data||!data.images || data.images.length === 0;
  };

  return (
    <>
      <div style={{ userSelect: 'none', display: 'flex' }} onClick={hideContextMenu}>
        <div style={{ display: 'flex' }}><Sidebar /></div>
        <div className="content-container" style={{ backgroundColor: 'white', display: 'inline-list-item', width: '100%' }}>
          <div style={{}}>
            <div id="main_div" >
              <label>PatientName,Ref12,Mobile</label>
              <div style={{ display: 'flex' }}>
              <div>
              <select
                id="modalitySelect"
                className="form-select form-select-sm me-2"
                onChange={handleSelectPatient}
                value={selectedPatient}
                style={{ width: '120px', height: '30px', fontSize: '12px', paddingLeft: '1px', paddingRight: '15px' }}
              >
                {/* Default option */}
                <option value=""></option>
                {/* Populate dropdown options from patientOptions state */}
                {patientOptions.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
              {/* Input field for searching */}
              <input
                type="text"
                value={selectedPatient}
                onChange={handleSelectPatient}
                placeholder="Search patient..."
                    className="form-control form-control-sm me-2"
                    style={{ position:'absolute', width: '105px', height: '29px', fontSize: '12px', marginTop: '-29px', backgroundColor: 'transparent', paddingLeft: '1px', borderRadius: '0px', border: '0px' }}
                  />
                </div>
              <input
                type="button"
                onClick={handleSearch}
                className="gradientButton"
                value="🔍"
                style={{
                  fontSize: '16px',
                  marginLeft: '-5px',
                  marginTop:'3px'
                }}
              />
              <div className="dropdown" style={{ position: 'relative', display: 'inline-block'}}>
                <button type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false"
                  style={{
                    border: '1px solid #ccc',
                    fontSize: '14px',  
                    height: '24px',   
                    width: '16px',
                    marginLeft: '4px',
                    marginTop: '2px',
                    padding:'0px'
                  }}
                >
                  v
                </button>
                <ul className={`dropdown-menu `} style={{fontSize:'13px'} }>
                  <li><button className="dropdown-item" type="button" onClick={handleFilterByPatientId}>FilterBy PatientId</button></li>
                  <li><button className="dropdown-item" type="button" onClick={handleFilterByPatientIdInst}>FilterBy PatientIdInst</button></li>
                  <li><button className="dropdown-item" type="button" onClick={handleFilterByPatientMobileNo}>FilterBy MobileNo</button></li>
                  <li><button className="dropdown-item" type="button" onClick={handleFilterByPatientName}>FilterBy PatientName</button></li>
                  <li><button className="dropdown-item" type="button" onClick={handleFilterByRefPhy}>FilterBy RefPhy</button></li>
                  <li><button className="dropdown-item" type="button" onClick={handleFilterByRefPhy2}>FilterBy RefPhy2</button></li>
                    <li><button className="dropdown-item" type="button" onClick={handleFilterByPatientMrdOrIp}>FilterBy MrdOrIp</button></li>

                    <li><button className="dropdown-item" type="button" onClick={handleGetDbConfigValue}>Get DbConfig Value</button></li>
                    <li><button className="dropdown-item" type="button" onClick={handleSetDbConfigValue}>Set DbConfig Value</button></li>
                    
                </ul>
                </div>
              </div>
            </div>

            <div className="prev-tody-nxt">
              <button className="gradientButton" style={{ position: 'relative' }} onClick={handlePreviousDay}> {"<<"} </button>
              <button className="gradientButton" style={{ position: 'relative' }} onClick={handleToday}> Today </button>
              <button className="gradientButton" style={{ position: 'relative' }} onClick={handleNextDay}> {">>"} </button>
            </div>

            <div className="mdlt-tst-frm-to-go">
              <label htmlFor="labelModality" className="form-label" style={{ width: '40px', height: '17px' }}>Modality:</label>
              <div>
                <ModalityFilterSelected
                  modalityList={modalityList}
                  ModalityFilter_CheckChanged_Prop={handleModalityFilter_CheckChanged}
                  setDropdownVisible={setDropdownVisible} // Ensure this line exists
                />

              </div>


              <label htmlFor="labelTest" className="form-label" style={{ width: '40px', height: '26.5px', marginLeft: '-25px' }}>Test:</label>
              <select id="cbTest" name="cbTest"
                className="form-select form-select-sm me-2"
                style={{ width: '100px', height: '29px', fontSize: '14px', marginTop: '20px', marginLeft: '-45px' }}
                value={selectedTest}
                onChange={handleTestChange}
              >
                <option value="">Select test...</option>
                {testOptions.map((test, index) => (
                  <option key={index} value={test.testName}>
                    {test.testName}
                  </option>
                ))}
              </select>
              <div className="frm-to-go" style={{ display: 'flex' }}>
                <label htmlFor="labelFromDate" className="form-label frm-date" style={{ width: '40px', height: '30px' }}>From:</label>
                <input
                  type="date"
                  className="form-control custom-date-input"
                  id="tbFromDate"
                  value={formatDateDDMMYYYY(fromDate)}
                  onChange={(e) => setFromDate(new Date(e.target.value))}
                />
                <label htmlFor="labelToDate" className="form-label" style={{ width: '40px', height: '30px', marginRight: '0px', paddingLeft: '4px' }}>To:</label>
                <input type="date" className="form-control custom-date-input" id="tbToDate" style={{ marginLeft: '-32px' }} value={formatDateDDMMYYYY(toDate)} onChange={(e) => setToDate(new Date(e.target.value))} />
                <button id="goButton" className="gradientButton" style={{ marginTop: '20px', marginLeft: '8px' }} onClick={handleGoButtonClick}> Go </button>
              </div>
            </div>

            <div className="pymnt-drpdwn-lgout">
              <div class="centerName">
                <div className="InstituteName">
                  <label style={{ fontSize: '14px', fontWeight: '400' }}>{instituteNamePart}</label>
                  <label style={{ fontSize: '14px', fontWeight: '400' }}>Welcome {userNamePart} !!!</label>
                </div>
                <div style={{ display: 'flex' }}>
                  {/*<input type="checkbox" id="myCheckbox" name="myCheckbox" onChange={handleCheckboxChange} checked={showPayment} style={{ marginTop: '0px' }} />*/}
                  {/*<label for="myCheckbox" style={{ marginTop: '5px', marginLeft:'2px' }}>Show Payment</label>*/}

                  {/*<button className="btn btn-success" style={{ marginLeft: '1040px', marginTop: '-50px', fontSize: '14px', width: '70px', height: '30px', paddingTop: '3px' }}> Expand </button>*/}
                  {/*<button className="btn btn-success" style={{ marginLeft: '1130px', marginTop: '-30px', fontSize: '14px', width: '70px', height: '30px', paddingTop: '3px' }}> Import </button>*/}
                  {/*<label htmlFor="dropdownMenuButton" style={{ marginTop: '50px', fontSize: '14px', cursor: 'pointer' }}></label>*/}
                  <ShowPaymentColumns_ChkBox_TickChanged
                    CheckboxChange_Prop={handle_ShowPaymentChkbox_Changed}
                    showPaymentColums_Prop={showPaymentColumns}
                    setDropdownVisible={setDropdownVisible} // Ensure this line exists
                  />
                  <button className="gradientButton" style={{ marginLeft: '5px' }} onClick={handleLogout}>Logout</button>
                </div>
              </div>
            </div>
            {/*<hr style={{ width: '1210px', marginRight: '-100px', marginTop: '20px', borderColor: 'black' }} />*/}
          </div>
          {/*position: 'absolute', */}
          <div id="contextmenu" className="table-container" style={{ marginLeft: '5px', marginTop: '10px', overflowX: 'auto', overflowY: 'auto', border: '20px light blue' }}>
            <ContextMenuTrigger id="contextmenu">
              <table>
                <thead style={{ position: dropdownVisible ? 'static' : 'sticky', top: '0' }}>
                  <tr>
                    {showSrNoColumn &&  // Conditionally render if showHomeTableSerialNo is true
                      <th style={{ textAlign: 'center', maxWidth: '30px', position: dropdownVisible ? 'static' : 'sticky' }} title="Serial Number">SrNo</th>
                    }
                    <th style={{ textAlign: 'center', maxWidth: '55px', position: dropdownVisible ? 'static' : 'sticky' }} title="PatientIdInst">PatIdInst</th>
                    {isShowPatientNameTitle &&
                      <th style={{ textAlign: 'center', maxWidth: '55px', position: dropdownVisible ? 'static' : 'sticky' }} title="PatientTitle">PatTitle</th>
                    }
                    <th style={{ textAlign: 'center', maxWidth: '125px', position: dropdownVisible ? 'static' : 'sticky' }} title="PatientName">PatientName</th>
                    <th style={{ textAlign: 'center', maxWidth: '25px', position: dropdownVisible ? 'static' : 'sticky' }} title="Age">Age</th>
                    <th style={{ textAlign: 'center', maxWidth: '25px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', position: dropdownVisible ? 'static' : 'sticky' }} title="AgeUnit">AgeUnit</th>
                    <th style={{ textAlign: 'center', maxWidth: '25px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', position: dropdownVisible ? 'static' : 'sticky' }} title="Gender" >Gender</th>
                    <th style={{ textAlign: 'center', maxWidth: '60px', position: dropdownVisible ? 'static' : 'sticky' }} title="Modality">Modality</th>
                    <th style={{ textAlign: 'center', maxWidth: '100px', position: dropdownVisible ? 'static' : 'sticky' }} title="Test Name">Test</th>
                    <th style={{ textAlign: 'center', minWidth: '24px', maxWidth: '25px', position: dropdownVisible ? 'static' : 'sticky' }} title="Images Count">#Img</th>
                    <th style={{ textAlign: 'center', maxWidth: '18px', position: dropdownVisible ? 'static' : 'sticky' }} title="Images Printed">Img</th>
                    <th style={{ textAlign: 'center', maxWidth: '18px', position: dropdownVisible ? 'static' : 'sticky' }} title="Report">Rep</th>
                    <th style={{ textAlign: 'center', maxWidth: '18px', position: dropdownVisible ? 'static' : 'sticky' }} title="Approve">Apr</th>
                    <th style={{ textAlign: 'center', maxWidth: '18px', position: dropdownVisible ? 'static' : 'sticky' }} title="WhatApp To Doctor">DW</th>
                    <th style={{ textAlign: 'center', maxWidth: '18px', position: dropdownVisible ? 'static' : 'sticky' }} title="WhatApp To Patient">PW</th>
                    <th style={{ textAlign: 'center', maxWidth: '18px', position: dropdownVisible ? 'static' : 'sticky' }} title="Dispatch">Disp</th>
                    <th style={{ textAlign: 'center', maxWidth: '30px', position: dropdownVisible ? 'static' : 'sticky' }} title="Date">Date</th>
                    <th style={{ textAlign: 'center', maxWidth: '30px', position: dropdownVisible ? 'static' : 'sticky' }} title="Time">Time</th>
                    <th style={{ maxWidth: '50px', position: dropdownVisible ? 'static' : 'sticky' }} title="Referring Physician" >ReferringPhysician</th>
                    <th style={{ maxWidth: '40px', position: dropdownVisible ? 'static' : 'sticky' }} title="Referring Physician2">RefPhy2</th>
                    {showPaymentColumns && <th style={{ padding: '1px', textAlign: 'center', maxWidth: '30px', position: dropdownVisible ? 'static' : 'sticky' }} title="TestPrice">TestPrice</th>}
                    {showPaymentColumns && <th style={{ padding: '1px', textAlign: 'center', maxWidth: '30px', position: dropdownVisible ? 'static' : 'sticky' }} title="Extra">Extra</th>}
                    {showPaymentColumns && <th style={{ padding: '1px', textAlign: 'center', maxWidth: '30px', position: dropdownVisible ? 'static' : 'sticky' }} title="Concession">Concsn</th>}
                    {showPaymentColumns && <th style={{ padding: '1px', textAlign: 'center', maxWidth: '30px', position: dropdownVisible ? 'static' : 'sticky' }} title="Total">Total</th>}
                    {showPaymentColumns && <th style={{ padding: '1px', textAlign: 'center', maxWidth: '30px', position: dropdownVisible ? 'static' : 'sticky' }} title="Paid">Paid</th>}
                    {showPaymentColumns && <th style={{ padding: '1px', textAlign: 'center', maxWidth: '30px', position: dropdownVisible ? 'static' : 'sticky' }} title="Pending">Pending</th>}
                    <th style={{ padding: '1px', maxWidth: '55px', textAlign: 'center', position: dropdownVisible ? 'static' : 'sticky' }} title="DrPerforming">DrPerforming</th>
                    <th style={{ padding: '1px', maxWidth: '55px', textAlign: 'center', position: dropdownVisible ? 'static' : 'sticky' }} title="UserName">UserName</th>
                  </tr>
                </thead>
                <tbody>
                  {searchedData.map((selRow, index) => (
                    <tr
                      key={selRow.PatientIdInst}
                      onContextMenu={(e) => handleRowRightClick(e, selRow)}
                      onClick={() => handleRowClick(selRow)}
                      style={{ backgroundColor: selectedRow === selRow ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}
                    >
                      {showSrNoColumn &&
                        <td style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: showPaymentColumns ? '55px' : '55px' }} title={selRow.srNo}>{selRow.srNo !== 0 ? selRow.srNo : ''}</td>

                      }
                      <td
                        title={selRow.patientIdInst }
                        className={selRow.comment ? "green-cell" : ""}
                        style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: showPaymentColumns ? '55px' : '55px' }}>
                        {buttonState === 'update' ? (
                          <input
                            type="text"
                            value={selRow.patientIdInst}
                            onChange={(e) => handlePatIdInstChange(e, index)}

                          />
                        ) : selRow.patientIdInst}
                      </td>
                      {isShowPatientNameTitle &&
                        <td
                          style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: showPaymentColumns ? '125px' : '125px', color: selRow.pending > 0 ? 'red' : 'inherit' }} title={selRow.patientTitle}>   {selRow.patientTitle}</td>}
                      <td
                        style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: showPaymentColumns ? '125px' : '125px', color: selRow.pending > 0 ? 'red' : 'inherit' }} title={selRow.patientName}>   {selRow.patientName}</td>
                      <td style={{ textAlign: 'center', minWidth: '24px', maxWidth: '25px' }}>{selRow.age !== -1 ? selRow.age : ''}</td>
                      <td style={{ textAlign: 'center', minWidth: '24px', maxWidth: '25px' }}>{selRow.ageUnit}</td>
                      <td style={{ textAlign: 'center', minWidth: '24px', maxWidth: '25px' }}>{selRow.gender}</td>
                      <td
                        className={selRow.isMedicoLegal ? "red-cell" : ""}

                        style={{ textAlign: 'center', whiteSpace: 'nowrap', color: isRed ? 'red' : 'inherit', overflow: 'hidden', textOverflow: 'clip', maxWidth: showPaymentColumns ? '60px' : '60px', color: isRed ? 'red' : 'inherit' }}
                        onClick={() => handleModalityClick(index)}
                      >
                        {buttonState === 'update' ? (
                          <select value={searchedData[index].modality} onChange={(e) => handleModalityChange(e, index)}>
                            {modalityList2.map((modality, i) => (
                              <option key={i} value={modality.modalityName}>{modality.modalityName}</option>
                            ))}
                          </select>
                        ) : searchedData[index].modality}
                      </td>

                      <td style={{ textAlign: 'center', maxWidth: showPaymentColumns ? '100px' : '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>
                        {buttonState === 'update' ? (
                          <select value={searchedData[index].test} onChange={(e) => handleTestChangeUpdatePatient(e, index)}>
                            {testOptions2
                              .filter(test => test.modalityName === searchedData[index].modality)
                              .flatMap((test, i) => (
                                test.testName.map((name, idx) => (
                                  <option key={idx} value={name}>{name}</option>
                                ))
                              ))
                            }
                          </select>
                        ) : searchedData[index].test}
                      </td>

                      <td style={{ textAlign: 'center', minWidth: '24px', maxWidth: '25px' }}>{selRow.images !== 0 ? selRow.images : ''} </td>
                      <td style={{ textAlign: 'center', maxWidth: '18px' }}>
                        <input
                          type="checkbox"
                          checked={allChecked || selRow.imagesPrinted}
                          onChange={(e) => handleImgCheckboxChanges(e, selRow.studyId, 'imagesPrinted', selRow.studyUid)}
                        />
                      </td>
                      <td style={{ backgroundColor: selRow.reportPresent ? 'white' : 'tomato', textAlign: 'center', maxWidth: '18px' }} >
                        <input
                          type="checkbox"
                          checked={selRow.reportStatus}
                          onChange={(e) => handleRepCheckboxChanges(e, selRow.studyId, 'reportStatus', selRow.studyUid)}
                        />
                      </td>
                      <td style={{ textAlign: 'center', maxWidth: '18px' }}>
                        <input
                          type="checkbox"
                          checked={selRow.reportApproved}
                          onChange={(e) => {
                            if (!selRow.reportPresent) {
                              alert("Report is not created yet!!");
                              e.target.checked = false; // Uncheck the checkbox if report is not present
                            } else {
                              handleRepCheckboxChanges(e, selRow.studyId, 'reportApproved');
                            }
                          }}
                        />
                      </td>
                      <td style={{ textAlign: 'center', maxWidth: '18px' }}>
                        <input
                          type="checkbox"
                          checked={selRow.wd}
                          onChange={(e) => handleDWCheckboxChanges(e, selRow.studyId, 'dw', selRow.studyUid)}
                          //disabled={!userPermissions.includes("CanDoctorWhatsApp")}
                        />
                      </td>
                      <td style={{ textAlign: 'center', maxWidth: '18px' }}>
                        <input
                          type="checkbox"
                          checked={selRow.wp}
                          onChange={(e) => handlePWCheckboxChanges(e, selRow.studyId, 'pw', selRow.studyUid)}
                          //disabled={!userPermissions.includes("CanPatientWhatsApp")}
                        />
                      </td>
                      <td style={{ textAlign: 'center', maxWidth: '18px' }}>
                        <input
                          type="checkbox"
                          checked={selRow.reportDispatched}
                          onChange={(e) => handleReportDispatchedCheckboxChanges(e, selRow.studyId, 'reportDispatched', selRow.studyUid)}
                        />
                      </td>
                      <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: showPaymentColumns ? '30px' : '30px', paddingLeft: '6px', paddingRight: '6px' }} title={selRow.date}>{FormatDateForGrid(selRow.date)}</td>
                      <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', maxWidth: showPaymentColumns ? '30px' : '30px', paddingLeft: '6px', paddingRight: '6px', textAlign: 'center' }} title={selRow.time}>{selRow.time/*.split(':').slice(0, 2).join(':')*/}</td>

                      <td style={{ textAlign: 'center', maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>
                        {buttonState === 'update' ? (
                          <select value={selRow.referringPhysician} onChange={(e) => handleReferringPhysicianChange(e, index)}>
                            {refPhyNameList1.map((refPhy, i) => (
                              /^[a-zA-Z]/.test(refPhy.doctorName) ? (
                                <option key={i} value={refPhy.doctorName}>{refPhy.doctorName}</option>
                              ) : null
                            ))}
                          </select>
                        ) : selRow.referringPhysician}
                      </td>

                      <td style={{ textAlign: 'center', maxWidth: '40px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>
                        {buttonState === 'update' ? (
                          <select value={selRow.refPhy2} onChange={(e) => handleRefPhy2Change(e, index)}>
                            {refPhyNameList2.map((refPhy, i) => (
                              /^[a-zA-Z]/.test(refPhy.doctorName) ? (
                                <option key={i} value={refPhy.doctorName}>{refPhy.doctorName}</option>
                              ) : null
                            ))}
                          </select>
                        ) : selRow.refPhy2}
                      </td>

                      {showPaymentColumns && <td style={{ textAlign: 'right', maxWidth: '30px' }}> {selRow.testPrice}</td>}
                      {showPaymentColumns && <td style={{ textAlign: 'right', maxWidth: '30px' }}> {selRow.extraRs !== -1 ? selRow.extraRs : ''}</td>}
                      {showPaymentColumns && <td style={{ textAlign: 'right', maxWidth: '30px' }}> {selRow.concession !== -1 ? selRow.concession : ''}</td>}
                      {showPaymentColumns && <td style={{ textAlign: 'right', maxWidth: '30px' }}> {selRow.total !== -1 ? selRow.total : ''}</td>}
                      {showPaymentColumns && <td style={{ textAlign: 'right', maxWidth: '30px' }}> {selRow.paid !== -1 ? selRow.paid : ''}</td>}
                      {showPaymentColumns && <td style={{ textAlign: 'right', maxWidth: '30px' }}> {selRow.pending !== -1 ? selRow.pending : ''}</td>}

                      <td style={{ textAlign: 'center', maxWidth: '55px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>
                        {buttonState === 'update' ? (
                          <select value={selRow.drPerforming} onChange={(e) => handleDrPerformingChange(e, index)}>
                            {drReportingNameList.map((doctor, i) => (
                              <option key={i} value={doctor.doctorName}>{doctor.doctorName}</option>
                            ))}
                          </select>
                        ) : selRow.drPerforming}
                      </td>

                      <td title={selRow.userName} style={{ textAlign: 'center', maxWidth: '55px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} >
                        {buttonState === 'update' ? (
                          <input
                            type="text"
                            value={selRow.userName}
                            onChange={(e) => handleUserChange(e, index)}
                          />
                        ) : selRow.userName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContextMenuTrigger>
          </div>
          {contextMenuVisible && (
            <ContextMenu id="contextmenu" className="context-menu" style={{ left: contextMenuPosition.x, top: contextMenuPosition.y }}>
              <MenuItem className="menu-item" onClick={handleContextMenuClick}>
                <span style={{ paddingLeft: '20px' }}>Open In Cc Viewer</span>
              </MenuItem>
              <MenuItem className="menu-item" onClick={handleContextMenuClick}>
                <span style={{ paddingLeft: '20px' }}>Open in Radiant Viewer</span>
              </MenuItem>
              <MenuItem className="menu-item" onClick={handleContextMenuClick}>
                <span style={{ paddingLeft: '20px' }} onClick={btnViewImages_Click}>Open in Web Viewer</span>
              </MenuItem>
              <MenuItem className="menu-item">
                <span style={{ paddingLeft: '20px' }} onClick={handlePastStudies}>Show Past Studies</span>
              </MenuItem>
              <MenuItem className="menu-item" onClick={handleContextMenuClick}>
                <span style={{ paddingLeft: '20px' }}>Send DCM To Cloud</span>
              </MenuItem>
              <MenuItem className="menu-item">
                <span style={{ paddingLeft: '20px' }}>Send DCM To PACS</span>
              </MenuItem>
              <MenuItem className="menu-item">
                <span style={{ paddingLeft: '20px' }}>Create Separate Visit</span>
              </MenuItem>
              <MenuItem className="menu-item">
                <span style={{ paddingLeft: '20px' }}>Zip Images</span>
              </MenuItem>
              <MenuItem className="menu-item">
                <span style={{ paddingLeft: '20px' }} onClick={handleMedicoLegal}>Medico Legal Case</span>
              </MenuItem>
              <MenuItem className="menu-item">
                <span style={{ paddingLeft: '20px' }}>Show Payment History</span>
              </MenuItem>
            </ContextMenu>
          )}
          <div className="Btm-rprt sticky-bottom-left">
            <div>
              <div style={{ display: 'flex' }}>
                <button className={`gradientButton ${shouldDisableViewImagesButton(clickedPatient) ? 'disabledButton' : ''}`}
                id="btnViewImages" style={{ marginRight: '6px', minWidth: '80px' }}
                disabled={shouldDisableViewImagesButton(clickedPatient)} onClick={btnViewImages_Click}>View Images</button>
              <button className="gradientButton" id="btnViewImages" style={{ marginRight: '30px', minWidth: '83px', whiteSpace: 'nowrap' }} onClick={() => {
                handleButtonClick();
                handleUpdatePatient();
              }}>
                  {buttonState === 'edit' ? 'Edit Patient' : 'Update Patient'}
                </button>
                {/*<button onClick={handleDownloadCSV}>CSV Download</button>*/}
                <div>
                  <label className="studies">Pat:{totalCount1} &nbsp; Study:{totalCount2} &nbsp; #Img:{imgPresentCount} &nbsp; Img:{imgZeroCheckedCount} <br /> Rep:{repCheckedCount} &nbsp; Prv:{reportWhiteBackgroundCount} &nbsp; Apr:{aprCheckedCount} &nbsp; Disp:{dispCheckedCount}</label>
                </div>
              </div>
              {showTemplateL1 &&
                <select id="l1Select" className="form-select form-select-sm me-2" style={{ paddingTop: '2px', width: '150px', height: '23px', fontSize: '12px' }} onChange={handleL1Change} value={selectedL1}>
                  <option value=""></option>
                  {l1Options.map((l1, index) => (
                    <option key={index} value={l1.toString()} style={{ fontSize: '12px' }}>{l1}</option>
                  ))}
                </select>
              }
              <div>

                {/*<select id="l2Select" className="form-select form-select-sm me-2 mt-2" style={{ width: '110px', height: '25px', fontSize: '12px', display: 'inline-block' }} onChange={handleL2Change} value={selectedL2}>*/}
                {/*  {l2Options*/}
                {/*    .filter(item => item.l1 === selectedL1)*/}
                {/*    .map(item => (*/}
                {/*      <optgroup key={item.l1} label={item.l1}>*/}
                {/*        {item.l2.map((option, index) => (*/}
                {/*          <option key={index} value={option}>{option}</option>*/}
                {/*        ))}*/}
                {/*      </optgroup>*/}
                {/*    ))}*/}
                {/*</select>*/}

                <select id="l2Select" className="form-select form-select-sm me-2" style={{ paddingTop: '2px', width: '150px', height: '23px', fontSize: '12px', marginTop: '10px' }} onChange={handleL2Change} value={selectedL2} title='Modality'>
                  <option value=""></option>
                  {l2Options.map((l2, index) => (
                    <option key={index} value={l2.toString()} style={{ fontSize: '12px' }}>{l2}</option>
                  ))}
                </select>
              </div>
              <div>
                <select id="l3Select" className="form-select form-select-sm me-2" style={{ paddingTop: '2px', marginTop:'5px', width: '150px', height: '23px', fontSize: '12px' }} onChange={handleL3Change} value={selectedL3} title='Test'>
                  <option value=""></option>
                  {l3Options.map((l3, index) => (
                    <option key={index} value={l3.toString()} style={{ fontSize: '12px' }}>{l3}</option>
                  ))}
                </select>

                {/*<select id="modalitySelect2" className="form-select form-select-sm me-2 mt-2" style={{ width: '110px', height: '25px', fontSize: '12px', display: 'inline-block' }} onChange={handleL3Change}>*/}
                {/*  {l3Options*/}
                {/*    .filter(item => item.l2 === selectedL2)*/}
                {/*    .map(item => (*/}
                {/*      <optgroup key={item.l2} label={item.l2}>*/}
                {/*        {item.l3.map((option, index) => (*/}
                {/*          <option key={index} value={option}>{option}</option>*/}
                {/*        ))}*/}
                {/*      </optgroup>*/}
                {/*    ))}*/}
                {/*</select>*/}

              </div>
              <div>
                <select id="drReportingSelect" className="form-select form-select-sm me-2" style={{ paddingTop: '2px', marginTop:'5px', width: '150px', height: '23px', fontSize: '12px', display: 'inline-block' }} onChange={handleDrReporting} value={drReporting} title='Dr Performing'>
                  <option value=""></option>
                  {drReportingNameList.map((doctor, index) => (
                    <option key={index} value={doctor.doctorName.toString()} style={{ fontSize: '12px' }}>{doctor.doctorName}</option>
                  ))}
                </select>
              </div>
              {drResidentNameList.length > 0 && (
                <div>
                  <select id="drResidentSelect" className="form-select form-select-sm me-2 mt-2" style={{ paddingTop: '2px', marginTop:'5px', width: '150px', height: '23px', fontSize: '12px', display: 'inline-block' }} onChange={handleDrResident} value={drResident} title='Dr Resident'>
                    {drResidentNameList.map((doctor, index) => (
                      <option key={index} value={doctor} style={{ fontSize: '12px' }}>{doctor}</option>
                    ))}
                  </select>
                </div>
              )}
              {technicianNameList.length > 0 && (
                <div>
                  <select id="technicianSelect" className="form-select form-select-sm me-2 " style={{ paddingTop: '2px', marginTop:'5px', width: '150px', height: '23px', fontSize: '12px', display: 'inline-block' }} onChange={handleTechnician} value={technician} title='Technician'>
                    {technicianNameList.map((doctor, index) => (
                      <option key={index} value={doctor} style={{ fontSize: '12px' }}>{doctor}</option>
                    ))}
                  </select>
                </div>
              )}
              {typistNameList.length > 0 && (
                <div>
                  <select id="typistSelect" className="form-select form-select-sm me-2" style={{ paddingTop: '2px', marginTop:'5px', width: '150px', height: '23px', fontSize: '12px', display: 'inline-block' }} onChange={handleTypist} value={typist} title='Typist'>
                    {typistNameList.map((doctor, index) => (
                      <option key={index} value={doctor} style={{ fontSize: '12px' }}>{doctor}</option>
                    ))}
                  </select>
                </div>
              )}
              <div>
                <button
                  className="gradientButton"
                  id="btnCreateReport"
                  style={{
                    marginRight: '6px',
                    marginTop: '5px',
                    opacity: enableCreateDeleteReportBtn ? 1 : 0.5,
                    cursor: enableCreateDeleteReportBtn ? 'pointer' : 'not-allowed'
                  }}
                  selectedPatient={selectedPatient}
                  onClick={enableCreateDeleteReportBtn ? handleCreateReport : undefined}
                > {reportApproved ? 'View Report' : (reportPresent ? 'Open Report' : 'Create Report')}
                </button>
                <button className="gradientButton" style={{
                  marginRight: '6px', top: '40px', opacity: enableCreateDeleteReportBtn && !reportApproved ? (reportPresent ? 1 : 0.5) : 0.5,
                  cursor: enableCreateDeleteReportBtn && !reportApproved ? (reportPresent ? 'pointer' : 'not-allowed') : 'not-allowed'
                }}
                  onClick={(reportPresent && enableCreateDeleteReportBtn && !reportApproved) ? handleDeleteReport : undefined} disabled={!reportPresent || !enableCreateDeleteReportBtn || reportApproved} > Delete Report </button>

                <button className="gradientButton" style={{ marginRight: '6px', marginTop: '7px', cursor: isButtonDisabled ? 'not-allowed' : 'pointer', pointerEvents: isButtonDisabled ? 'none' : 'auto', opacity: isButtonDisabled ? 0.6 : 1 }} onClick={btnZipImages_Click} title="Zip + Download Dicom Images" disabled={isButtonDisabled}>Zip Images</button>
                <button className={comment ? "gradientButton2" : "gradientButton"} style={{ marginRight: '6px', marginTop: '5px', fontSize: '11px' }} title="Add Patients History" onClick={handleCommentButtonClick}>Comments</button>

                <button type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ fontSize: '9px', height: '23px', border: '1px solid gray' }}>
                  V
                </button>

                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ fontSize: '12px' }}>
                  <li><button className="dropdown-item" type="button">PrintImg + ReportFile</button></li>
                  <li><button className="dropdown-item" type="button">Add Selected Template on Top</button></li>
                  <li><button className="dropdown-item" type="button">Add Selected Template at Cursor</button></li>
                  <li><button className="dropdown-item" type="button">Open Report In Pdf</button></li>
                  <li><button className="dropdown-item" type="button" onClick={handleOpenTemplate}>Open Template:Left Report:Right</button></li>
                  <li><button className="dropdown-item" type="button">Merge Measurement</button></li>
                  <li><button className="dropdown-item" type="button">Create Web Report</button></li>
                  <li><button className="dropdown-item" type="button" selectedPatient={selectedPatient} onClick={handleCreateReport}>Create Google Report</button></li>

                  <li><button className="dropdown-item" type="button">Import Patho Report From Email</button></li>
                </ul>
              </div>
              <div style={{display:'flex'} }>
                <button className="gradientButton" style={{ marginRight: '6px', display: 'inline-block', paddingTop: '3px', marginLeft: '1px', marginTop: '7px' }}>Close</button>
                <button className="gradientButton" style={{ marginRight: '6px', display: 'inline-block', marginTop: '5px', fontSize: '11px', width: '120px' }}>Advanced Reporting</button>
              </div>
              {clickedPatient && (
                <CommentPopup isOpen={isPopupOpen} onClose={handleClosePopup} onSubmit={handleCommentSubmit} fetchedQueries={fetchedQueries} Name={clickedPatient.patientName} initialValue={comment} />
              )}
            </div>
          </div>

        </div>
        <div className="ad-pat-paymnt">
          <div>
            <div style={{ width: '335px', height: '150px', backgroundColor: '#d4e3ea', paddingTop: '2px', padding: '8px', border: '5px double white', borderRadius: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '5px', marginTop: '-8px', display: 'flex' }}>
                  <label>ID:
                    <input ref={inputRef} type="text" style={{ width: '30px', height: '25px' }} name="patIdInst" value={addTestData.patIdInst} onChange={handleChange} /></label>
                  <label style={{ marginLeft: '5px' }}>First
                    <input type="text" style={{ width: '90px', height: '25px' }} name="fName" value={addTestData.fName} onChange={handleChange} placeholder="FirstName" required /></label>
                </div>
                <div style={{ marginRight: '5px', marginTop: '-8px' }}>
                  <label>Middle</label>
                  <input type="text" style={{ width: '90px', height: '25px' }} name="mName" value={addTestData.mName} onChange={handleChange} placeholder="MiddleName" />
                </div>
                <div style={{ marginRight: '5px', marginTop: '-8px' }}>
                  <label>Last</label>
                  <input type="text" style={{ width: '90px', height: '25px' }} name="sName" value={addTestData.sName} onChange={handleChange} placeholder="LastName" ref={lastNameInput} required />
                </div>
              </div>

              <div className="PatientRegistrationDiv" style={{ marginTop: '5px', width: '57px', height: '35px' }}>
                <label>
                  <input type="radio" name="gender" style={{ marginRight: '5px', fontSize: '11px' }} className="gender" value="Male" checked={addTestData.gender === 'Male'}
                    onChange={handleGenderChange} ref={maleInputRef} />Male
                </label>
                <label>
                  <input type="radio" name="gender" style={{ marginRight: '5px', fontSize: '11px' }} className="gender" value="Female" checked={addTestData.gender === 'Female'}
                    onChange={handleGenderChange} ref={femaleInputRef} />Female
                </label>
                <div style={{ marginLeft: '65px', marginTop: '-37px', width: '90px', height: '45px' }}>
                  <label style={{ display: 'inline' }}>
                    <input type="radio" style={{ fontSize: '10px' }} name="age" value="age" checked={selectedAge === 'age'} onChange={handleAgeChange} ref={ageInputRef} />Age*
                  </label>
                  <label style={{ display: 'inline', marginLeft: '5px' }}>
                    <input type="radio" name="age" style={{ fontSize: '10px' }} value="dob" checked={selectedAge === 'dob'} onChange={handleAgeChange} />DOB
                  </label>
                  {selectedAge === 'age' && (
                    <div style={{ marginTop: '-3px' }}>
                      <input
                        type="text" name="age" value={addTestData.age} onChange={handleChange} placeholder="0"
                        style={{ display: 'inline', width: '35px', height: '20px', fontSize: '11px', border: '1px solid #cccccc', borderRadius: '4px' }}
                        min={0}
                        onKeyPress={(event) => {
                          // Allow only numbers and some special keys like Backspace, Delete, Arrow keys, etc.
                          const allowedChars = /[0-9]/;
                          const charCode = event.charCode;
                          const key = String.fromCharCode(charCode);
                          if (!allowedChars.test(key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <select
                        className="form-select"
                        style={{ display: 'inline', width: '50px', height: '20px', fontSize: '11px', marginLeft: '2px', padding: '1px', paddingLeft: '3px', border: '1px solid #cccccc' }} name="age" value={addTestData.age} onChange={handleChange}
                      >
                        <option style={{ fontSize: '10px' }}>Year</option>
                        <option style={{ fontSize: '10px' }}>Month</option>
                        <option style={{ fontSize: '10px' }}>Day</option>
                      </select>
                    </div>
                  )}
                  {selectedAge === 'dob' && (
                    <input className="form-control" type="date" style={{ width: '86px', height: '20px' }} name="dob" value={addTestData.dob} onChange={handleChange} />
                  )}
                </div>
                <div style={{ marginLeft: '155px', marginTop: '-25px', width: '159px', display: 'flex', flexDirection: 'row' }}>
                  <label>
                    <input type="text" placeholder="Mobile No" style={{ width: '70px', height: '20px', padding: '1px', marginTop: '4px' }} name="mobNo" value={addTestData.mobNo ? addTestData.mobNo : patMobileNo} onChange={handleChange}></input>
                  </label>
                  <input type="email" placeholder="Email I'd" style={{ width: '80px', height: '20px', padding: '1px', borderRadius: '4px', marginLeft: '4px', marginTop: '4px', border: '1px solid #cccccc' }} name="emailId" value={addTestData.emailId} onChange={handleChange} ></input>
                </div>
                <div style={{ display: 'flex' }}>
                  {addTestData.gender === 'Female' && (
                    <label>
                      LMP
                      <input
                        className="custom-date-input"
                        type="date"
                        style={{ fontSize: '12px', width: '86px', height: '20px', marginLeft:'0px', marginTop:'4px' }}
                        name="LMP"
                        value={addTestData.LMP}
                        onChange={handleChange}
                      />
                    </label>
                  )}
                  <div>
                    <label
                      style={{ display: 'flex', flexDirection: 'col', width: '50px', marginLeft: '5px' }}>
                      Address</label>
                    <textarea
                      name="address" value={addTestData.addres} onChange={handleChange}
                      style={{
                        marginTop: '-13px',
                        marginLeft: '50px',
                        width: '170px',
                        height: '40px',
                        overflow: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        border: '1px solid #cccccc',
                        borderRadius: '4px'
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="AddTestDiv" style={{ width: '335px', backgroundColor: '#d4e3ea', paddingLeft: '4px', paddingBottom: '4px', border: '5px double white', borderRadius: '10px' }}>
              <div style={{ display: 'flex', marginTop: '3px' }}>
                <label style={{ display: 'flex' }}>Panel
                  <select className="form-select" style={{ width: '60px', height: '20px', fontSize: '12px', paddingTop: '0px', paddingLeft: '4px', padding: '1px', marginLeft: '2px' }}>
                    <option></option>
                    <option>Default</option>
                  </select>
                </label>

                <label style={{ marginLeft: '7px' }}>Employee Id
                  <input type="text" style={{ height: '20px', width: '90px', marginLeft: '3px' }}></input>
                </label>
                <input type="checkbox" style={{ display: 'inline', marginLeft: '7px' }} name="isUrgentPatient" checked={addTestData.isUrgentPatient} onChange={handleChange}></input>
                <label style={{ display: 'inline' }}>Urgent</label>
              </div>
              <div style={{ display: 'inline-flex' }}>
                <div style={{ marginRight: '10px' }}>
                  <label>Ref Phy1</label>
                  <select
                    className="form-select"
                    name="refPhy1"
                    style={{ width: '80px', height: '20px', padding: '1px' }}
                    value={addTestData.refPhy1}
                    onChange={handle_RefPhy_Selected}
                    ref={refphy1Input}
                  >
                    <option></option>
                    {refPhyNameList1.map((dr, index) => {
                      // Filter only the doctors' names that start with an alphabet letter
                      if (/^[a-zA-Z]/.test(dr.doctorName)) {
                        return (
                          <option key={index} value={dr.doctorName}>{dr.doctorName}</option>
                        );
                      }
                      return null; // Exclude names that don't start with an alphabet letter
                    })}
                  </select>
                </div>

                <div>
                  <label>Modality</label>
                  <select className="form-select" style={{ width:'100px', height: '21px', fontSize: '12px', paddingTop: '0px' }}
                    name="modality" id="cbModality2"
                    value={addTestData.modality} ref={modalityInput}
                    onChange={handleModalityChange2} >
                    <option value=""></option>
                    {modalityList2.map((modality, index) => (
                      <option key={index} value={modality.modalityName}>
                        {modality.modalityName}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ marginLeft: '8px' }}>
                  <label>Schedule Date & Time</label>
                  <input
                    className="custom-date-input form-control"
                    type="datetime-local"
                    style={{
                      width: '115px',
                      height: '20px',
                      fontSize: '12px',
                      border: '1px solid #cccccc',
                      paddingRight: '1px',
                      marginLeft: '0px',
                      marginTop: '0px',
                      paddingTop:'8px'
                    }}
                    name="scheduledDate"
                    value={addTestData.todayDateTime}
                    defaultValue={todayDateTime}
                    onChange={handleChange}
                    tabIndex="-1" // Set tabIndex to -1
                  />
                </div>
              </div><br></br>


              <div style={{ display: 'inline-flex' }}>
                <div style={{ marginRight: '10px' }}>
                  <label>Ref Phy2</label>
                  <select
                    className="form-select"
                    name="refPhy2"
                    style={{ width: '80px', height: '20px' }}
                    value={addTestData.refPhy2} ref={refphy2Input}
                    onChange={handleDrRegistrationChange2}
                  >
                    <option></option>
                    {refPhyNameList2.map((dr, index) => {
                      // Check if the doctor name starts with an alphabet letter
                      if (/^[a-zA-Z]/.test(dr.doctorName)) {
                        return (
                          <option key={index} value={dr.doctorName}>{dr.doctorName}</option>
                        );
                      }
                      return null; // Exclude names that don't start with an alphabet letter
                    })}
                  </select>
                </div>

                <div>
                  <label>Test</label>
                  <select id="cbTest" name="cbTests"
                    className="form-select" style={{ height: '21px', width: '90px', fontSize: '12px', paddingTop: '0px' }}
                    value={addTestData.cbTests} ref={testInput}
                    onChange={handleTestChange2}
                  >
                    <option value=""></option>
                    {testOptions2.flatMap((test, index) => (
                      test.testName.map((name, idx) => (
                        <option key={`${index}-${idx}`} value={name}>
                          {name}
                        </option>
                      ))
                    ))}
                  </select>
                </div>
                <div>
                  <button className="gradientButton" style={{ marginTop: '15px', marginLeft: '10px', width: '50px' }} onClick={handleAddTest} >Add</button>
                </div>
              </div>
            </div>
          </div>

          {showPaymentPanelDiv &&
            <div className="PaymentPanelDiv">

              <label style={{ textAlign: 'left' }} > Test Price
                <input type="text" style={{ height: '20px', marginLeft: '40px', maxWidth: '60px' }} placeholder={totalPrice} readOnly></input>
              </label>
              <label style={{ textAlign: 'left', marginTop: '5px' }} > ExtraRs
                <input type="text" placeholder="0" style={{ maxWidth: '60px', height: '20px', marginLeft: '50px' }} name="extraRs" value={addPaymentData.extraRs} onChange={handleChange2} ></input>
              </label>
              <label style={{ textAlign: 'left', marginTop: '5px' }} > Concession
                <input type="checkbox" style={{ marginLeft: '8px' }} ></input>
                <input type="text" placeholder="0" style={{ maxWidth: '60px', height: '20px', marginLeft: '10px' }} name="concession" value={addPaymentData.concession} onChange={handleChange2} ></input>
              </label>
              <label style={{ textAlign: 'left', marginTop: '5px' }} > Narration
                <input type="text" style={{ maxWidth: '90px', height: '20px', marginLeft: '10px' }} name="narration" value={addPaymentData.narration} onChange={handleChange2} ></input>
              </label>
              <label style={{ textAlign: 'left', marginTop: '5px' }} > Total
                <text style={{ width: '100px', height: '20px', marginLeft: '80px' }} >{clickedPatient.total}</text>
              </label>
              <label style={{ textAlign: 'left', marginTop: '5px' }} > Paid
                <text style={{ width: '100px', height: '20px', marginLeft: '80px' }} >{paid}</text>
              </label>
              <label style={{ textAlign: 'left', marginTop: '5px' }} > Pay
                <input type="text" placeholder="0" style={{ width: '100px', height: '20px', marginLeft: '10px' }} name="pay" value={addPaymentData.pay} onChange={handleChange2} ></input>
              </label>
              <label style={{ textAlign: 'left', marginTop: '5px' }} > Pending
                <text placeholder="0" style={{ width: '100px', height: '20px', marginLeft: '10px' }} >{pending}</text>
              </label>
              <label style={{ textAlign: 'left', display: 'flex', marginTop: '5px' }} > By:
                <select
                  className="form-select"
                  style={{ borderRadius: '2px', width: '80px', height: '20px', fontSize: '12px', padding: '1px', marginLeft: '10px' }}
                  onChange={handleSelectPaymentChange}>
                  {paymentOptions.map((option, index) => (
                    <option key={index} style={{ fontSize: '10px' }} value={option.paymentType} >
                      {option.paymentType}
                    </option>
                  ))}
                </select>
                <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddPayment} >Submit</button>
              </label>
              <label style={{ textAlign: 'left', display: 'flex', marginTop: '5px' }} > User:
                <select
                  className="form-select"
                  style={{ borderRadius: '2px', width: '100px', height: '20px', fontSize: '12px', padding: '1px', marginLeft: '10px' }}>
                  {userName.map((option, index) => (
                    <option key={index} style={{ fontSize: '10px' }} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
              <div className="card-1" style={{ display: selectedPaymentOption === 'Credit Card' || selectedPaymentOption === 'Debit Card' ? 'flex' : 'none' }}>
                <label>ReceiptNo
                  <input type="text" style={{ width: '90px', height: '20px', marginLeft: '10px', marginTop: '2px' }}></input>
                </label>
              </div>
              <div className="card-2" style={{ display: selectedPaymentOption === 'Credit Card' || selectedPaymentOption === 'Debit Card' ? 'flex' : 'none' }}>
                <label>BankName
                  <input type="text" style={{ width: '90px', height: '20px', marginLeft: '10px', marginTop: '2px' }}></input>
                </label>
              </div>
              <div className="card-3" style={{ display: selectedPaymentOption !== 'Cash' && selectedPaymentOption !== 'None' && selectedPaymentOption !== 'Credit Card' && selectedPaymentOption !== 'Debit Card' ? 'flex' : 'none' }}>
                <label>TransactionId
                  <input type="text" style={{ width: '90px', height: '20px', marginLeft: '10px', marginTop: '2px' }}></input>
                </label>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}
