import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';

export default function Sidebar() {
  const location = useLocation(); // Get current location
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  // Load selected item from localStorage on component mount
  useEffect(() => {
    const storedItem = localStorage.getItem('selectedItem');
    if (storedItem) {
      setSelectedItem(storedItem);
    }
  }, []);

  // Update selected item and store it in localStorage
  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
    // Store selected item in localStorage
    localStorage.setItem('selectedItem', itemName);
  };

  useEffect(() => {
    // Update selected item based on the current location pathname
    const pathname = location.pathname.replace('/', '');
    setSelectedItem(pathname);
  }, [location.pathname]);

  return (
    <div className="container-fluid d-flex p-0">
      {/* Sidebar */}
      <div
        className="sidebar bg-dark text-light"
        style={{
          width: isSidebarVisible ? '170px' : '55px',
          height: '100vh',
          top: 0,
          left: 0,
          fontSize: 13,
          backgroundColor: '#fff',
          color: '#fff',
          padding: '20px',
          paddingTop: '10px',
          overflowY: 'auto',
          transition: 'width 0s ease',
        }}
      >
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleSidebar}
          style={{ fontSize: '1.6rem', padding: '0.2rem 0.5rem', marginLeft: '-16px', display: 'inline' }}
          title="Menu Bar"
        >
          ☰
        </button>
        <nav className="navbar navbar-dark bg-dark" style={{ display: 'inline' }}>
          {isSidebarVisible && (
            <Link className="navbar-brand text-center w-20" to="/pcarehome">
              Home
            </Link>
          )}
        </nav>


        <ul className="nav flex-column" style={{ marginTop: '10px', marginLeft:'-20px' }}>
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link*/}
          {/*      className={`nav-link ${selectedItem === 'pcinfo' ? 'active' : ''}`}*/}
          {/*      to="/pcinfo"*/}
          {/*      onClick={() => handleItemClick('pcinfo')}*/}
          {/*    >*/}
          {/*      PcInfo*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'monthlySummary' ? 'active' : ''}`} to="#" onClick={() => handleItemClick('monthlySummary')}>*/}
          {/*      Monthly Summary*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'radiologistinfo' ? 'active' : ''}`} to="/radiologistinfo" onClick={() => handleItemClick('radiologistinfo')}>*/}
          {/*      RadiologistInfo*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'soapapi' ? 'active' : ''}`} to="/soapapi" onClick={() => handleItemClick('soapapi')}>*/}
          {/*      SoapApi*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'institutes' ? 'active' : ''}`} to="/institutes" onClick={() => handleItemClick('institutes')}>*/}
          {/*      Institutes*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'crm' ? 'active' : ''}`} to="#" onClick={() => handleItemClick('crm')}>*/}
          {/*      CRM*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'teamgrpui' ? 'active' : ''}`} to="#" onClick={() => handleItemClick('teamgrpui')}>*/}
          {/*      TeamGrpUi*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'watemplatecategory' ? 'active' : ''}`} to="#" onClick={() => handleItemClick('watemplatecategory')}>*/}
          {/*      WATemplateCategory*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'googlesheet' ? 'active' : ''}`} to="#" onClick={() => handleItemClick('googlesheet')}>*/}
          {/*      GoogleSheet*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'telegramlogin' ? 'active' : ''}`} to="/telegramlogin" onClick={() => handleItemClick('telegramlogin')}>*/}
          {/*      Telegram Login*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'invoicesummary' ? 'active' : ''}`} to="/invoicesummary" onClick={() => handleItemClick('invoicesummary')}>*/}
          {/*      Invoice Summary*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'userslist' ? 'active' : ''}`} to="/userslist" onClick={() => handleItemClick('userslist')}>*/}
          {/*      UsersList*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}
          {/*{isSidebarVisible && (*/}
          {/*  <li className="nav-item">*/}
          {/*    <Link className={`nav-link ${selectedItem === 'storedprocedures' ? 'active' : ''}`} to="/storedprocedures" onClick={() => handleItemClick('storedprocedures')}>*/}
          {/*      StoredProcedures*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*)}*/}

          <li className="nav-item" style={{ display: 'flex', flexDirection:'column' }}>
            {/* Render different content based on the visibility of the sidebar */}
            {!isSidebarVisible && (
              <Link className={`img-link ${selectedItem === 'pcarehome' ? 'active' : ''}`} to="/pcarehome" onClick={() => handleItemClick('pcarehome')} >
                <img
                  src={require('../Resources/home.png')}
                  alt="pCare Home"
                />
              </Link>
            )}
            {isSidebarVisible && (
              <Link className={`nav-link ${selectedItem === 'pcarehome' ? 'active' : ''}`} to="/pcarehome" onClick={() => handleItemClick('pcarehome')}>
                pCare Home
              </Link>
            )}
          </li>


          <li className="nav-item" style={{ display: 'flex', flexDirection: 'column' }}>
            {/* Render different content based on the visibility of the sidebar */}
            {!isSidebarVisible && (
              <Link className={`img-link ${selectedItem === 'patientreg' ? 'active' : ''}`} to="/patientreg" onClick={() => handleItemClick('patientreg')} title="Patient Registration Page">
                <img
                  src={require('../Resources/patient.png')}
                  alt="Patient Reg."
                />
              </Link>
            )}
            {isSidebarVisible && (
              <Link className={`nav-link ${selectedItem === 'patientreg' ? 'active' : ''}`} to="/patientreg" onClick={() => handleItemClick('patientreg')}>
                Patient Reg.
              </Link>
            )}
          </li>

          <li className="nav-item" style={{ display: 'flex', flexDirection: 'column' }}>
            {/* Render different content based on the visibility of the sidebar */}
            {!isSidebarVisible && (
              <Link className={`img-link ${selectedItem === 'refphyreg' ? 'active' : ''}`} to="/refphyreg" onClick={() => handleItemClick('refphyreg')} title="Referring Physician Registration">
                <img
                  src={require('../Resources/practitioner.png')}
                  alt="Patient"
                />
              </Link>
            )}
            {isSidebarVisible && (
              <Link className={`nav-link ${selectedItem === 'refphyreg' ? 'active' : ''}`} to="/refphyreg" onClick={() => handleItemClick('refphyreg')}>
                Dr Registration
              </Link>
            )}
          </li>



          <li className="nav-item" style={{ display: 'flex', flexDirection: 'column' }}>
            {/* Render different content based on the visibility of the sidebar */}
            {!isSidebarVisible && (
              <Link className={`img-link ${selectedItem === 'doctorspf' ? 'active' : ''}`} to="/doctorspf" onClick={() => handleItemClick('doctorspf')} title="Doctors Pf">
                <img
                  src={require('../Resources/fund.png')}
                  alt="Doctors PF"
                />
              </Link>
            )}
            {isSidebarVisible && (
              <Link className={`nav-link ${selectedItem === 'doctorspf' ? 'active' : ''}`} to="/doctorspf" onClick={() => handleItemClick('doctorspf')}>
                PF
              </Link>
            )}
          </li>




          <li className="nav-item" style={{ display: 'flex', flexDirection: 'column' }}>
            {/* Render different content based on the visibility of the sidebar */}
            {!isSidebarVisible && (
              <Link className={`img-link ${selectedItem === 'gacal' ? 'active' : ''}`} to="/gacal" onClick={() => handleItemClick('gacal')} title="GACalculator">
                <img
                  src={require('../Resources/gacal.png')}
                  alt="GACal"
                />
              </Link>
            )}
            {isSidebarVisible && (
              <Link className={`nav-link ${selectedItem === 'gacal' ? 'active' : ''}`} to="/gacal" onClick={() => handleItemClick('gacal')}>
                GACal
              </Link>
            )}
          </li>


          <li className="nav-item" style={{ display: 'flex', flexDirection: 'column' }}>
            {/* Render different content based on the visibility of the sidebar */}
            {!isSidebarVisible && (
              <Link className={`img-link ${selectedItem === 'pndt' ? 'active' : ''}`} to="/pndt" onClick={() => handleItemClick('pndt')} title="PNDT">
                <img
                  src={require('../Resources/pndt.png')}
                  alt="PNDT"
                />
              </Link>
            )}
            {isSidebarVisible && (
              <Link className={`nav-link ${selectedItem === 'pndt' ? 'active' : ''}`} to="/pndt" onClick={() => handleItemClick('pndt')}>
                PNDT
              </Link>
            )}
          </li>

          
          <li className="nav-item" style={{ display: 'flex', flexDirection: 'column' }}>
            {!isSidebarVisible && (
              <Link className={`img-link ${selectedItem === 'checkaccounts' ? '!active' : ''}`} to="/checkaccounts" target="_blank" rel="noopener noreferrer" >
                <img
                  src={require('../Resources/checkaccounts.png')} // Adjust the path as needed
                  alt="CheckAccounts"
                  title="Check Accounts"
                />
              </Link>
            )}
            {isSidebarVisible && (
              <Link
                className={`nav-link ${selectedItem === 'checkaccounts' ? '' : '!active'}`}
                to="/checkaccounts"
                target={!isSidebarVisible ? '_blank' : ''}
                target="_blank"
                rel={!isSidebarVisible ? 'noopener noreferrer' : ''}
                onClick={() => handleItemClick('checkaccounts')}
              >
                Check Accounts
              </Link>
            )}

          </li>

          <li className="nav-item" style={{ display: 'flex', flexDirection: 'column' }}>
            {!isSidebarVisible && (
              <Link className={`img-link ${selectedItem === 'modalitytest' ? 'active' : ''}`} to="/modalitytest" target="_blank" rel="noopener noreferrer">
                <img
                  src={require('../Resources/modality.png')} // Adjust the path as needed
                  alt="Modality"
                  title="Modality"
                />
              </Link>
            )}
            {isSidebarVisible && (
              <Link
                className={`nav-link ${selectedItem === 'modalitytest' ? '' : '!active'}`}
                to="/modalitytest"
                target="_blank"
                target={!isSidebarVisible ? '_blank' : ''}
                rel={!isSidebarVisible ? 'noopener noreferrer' : ''}
              >
                Modality
              </Link>
            )}

          </li>

          <li className="nav-item">
            {!isSidebarVisible && (
              <Link className={`img-link ${selectedItem === 'institute' ? 'active' : ''}`} to="/institute" target="_blank" rel="noopener noreferrer">
                <img
                  src={require('../Resources/admin_Light.png')} // Adjust the path as needed
                  alt="PFSettings"
                  title="PFSettings"
                />
              </Link>
            )}
            {isSidebarVisible && (
              <div className="dropdown">
                <button
                  className={`nav-link dropdown-toggle ${selectedItem === 'admintools' ? 'active' : ''}`}
                  onClick={() => setDropdownVisible(!isDropdownVisible)}
                  id="adminDropdown"
                  aria-haspopup="true"
                  aria-expanded={isDropdownVisible ? 'true' : 'false'}
                  style={{textAlign:'center', marginLeft:'20px'} }
                >
                  Admin Tools
                </button>
                <div className={`dropdown-menu ${isDropdownVisible ? 'show' : ''}`} aria-labelledby="adminDropdown" style={{ backgroundColor: 'darkgray', borderRadius: '10px' }}>
                  <Link className="dropdown-item" to="/institute" onClick={(e) => { handleItemClick('institute'); }} target="_blank">
                    Institute
                  </Link>
                  <Link className="dropdown-item" to="/UserList" onClick={(e) => { handleItemClick('UserList');  }} target="_blank">
                    UserList
                  </Link>
                  <Link className="dropdown-item" to="/OnlineUsers" onClick={(e) => { handleItemClick('OnlineUsers'); }} target="_blank">
                    OnlineUsers
                  </Link>
                  <Link className="dropdown-item" to="/UsersLog" onClick={(e) => { handleItemClick('UsersLog'); }} target="_blank">
                    UsersLog
                  </Link>
                  <Link className="dropdown-item" to="/PDFTitle" onClick={(e) => { handleItemClick('PDFTitle');  }} target="_blank">
                    PDFTitle
                  </Link>
                  <Link className="dropdown-item" to="/PaymentOption" onClick={(e) => { handleItemClick('PaymentOption'); }} target="_blank">
                    PaymentOption
                  </Link>
                  <Link className="dropdown-item" to="/Template" onClick={(e) => { handleItemClick('Template'); }} target="_blank">
                    Template
                  </Link>
                  <Link className="dropdown-item" to="/Expenses" onClick={(e) => { handleItemClick('Expenses'); }} target="_blank">
                    Expenses
                  </Link>
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
