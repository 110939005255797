import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM
import PropTypes from 'prop-types';

const CommentPopup = ({ isOpen, onClose, onSubmit, fetchedQueries, Name, initialValue }) => {
  const [comment, setComment] = useState('');
  const [initialComment, setInitialComment] = useState('');

  useEffect(() => {
    setInitialComment(initialValue || ''); // Set initialComment to initialValue if present, otherwise set it to an empty string
  }, [initialValue]);

  useEffect(() => {
    setComment(initialComment); // Set comment to initialComment when it changes
  }, [initialComment]);

  const handleSubmit = () => {
    onSubmit(comment);
    setComment('');
  };

  return isOpen ? ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: '500px',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <p>Patient Name: {Name}</p>
        <button onClick={onClose} style={{ color: 'red' }}>X</button>
      </div>
      <hr />
      <h6>Current Study</h6>

      <input
        type="text"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Enter your comment"
        style={{ marginBottom: '10px' }}
      />
      <br />
      <button onClick={handleSubmit} className='gradientButton'>Update</button>
      <br></br>
      <br></br>
      <h6>All Study</h6>
      <div style={{ border: '1px solid #ccc', marginTop: '10px', height: '200px', overflowY: 'auto' }}>
        {fetchedQueries.map((query, index) => (
          <p key={index}>{query}</p>
        ))}
      </div>
    </div>,
    document.body
  ) : null;
};

CommentPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchedQueries: PropTypes.array.isRequired,
  Name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
};

export default CommentPopup;
