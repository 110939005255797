//import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar';
import React, { useState, useEffect } from 'react';

export default function Institutes() {
  const [institutes, setInstitutes] = useState([]);
  const apiHostLink = localStorage.getItem("apiHostLink");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiHostLink}pCareUsers/GetInstituteNames`, {userId: userId});
      const data = await response.json();
      setInstitutes(data);
    } catch (error) {
      console.error('Error fetching institutes data:', error);
    }
  };

  return (
    <>
      <div style={{ position: 'relative', display: 'flex'}}>
        <div style={{ position: 'fixed' }}>
          <Sidebar />
        </div>
        <div style={{ position: 'absolute', top: 0, left: '200px', marginLeft: '10px', marginTop: '10px' }}> {/* Adjust left position according to the width of the sidebar */}
          <div style={{ marginBottom: '10px' }}>
            <button className="btn btn-primary btn-sm me-2 ms-0">Institute</button>
            <button className="btn btn-primary btn-sm me-2" style={{ marginLeft: '0px' }}>Users List</button>
            <button className="btn btn-primary btn-sm me-2" style={{ marginLeft: '0px' }}>OnlineUsers</button>
            <button className="btn btn-primary btn-sm me-2" style={{ marginLeft: '0px' }}>Users Log</button>
            <button className="btn btn-primary btn-sm me-2" style={{ marginLeft: '0px' }}>Firewall Rule</button>
          </div>
          <div className="container mb-3" style={{ zIndex: '100', position: 'absolute', top: '40px', left: '1px', width: '450px', height: '1300px', overflowX: 'auto', overflowY: 'auto' }}>
            <table className="table" style={{  borderCollapse: 'collapse', border: '1px solid black'}}>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Address</th>
                </tr>
              </thead>
              <tbody>
                {institutes.map((institute, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{institute}</td>
                    <td>{/* You need to retrieve address from backend and place it here */}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
            <div style={{ position: 'absolute', top: '0', bottom: '0', left: '100%', borderLeft: '5px solid black' }}></div>
            <div style={{ position: 'absolute', top: 0, left: '200px', marginLeft: '280px', marginTop: '10px' }}>
              <form>
                <h3 style={{ display: 'inline', whiteSpace: 'nowrap', fontSize: '16px' }}>Institute Details</h3>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Name</label>
                  <input type="text" className="form-control" id="name" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Address</label>
                  <input type="text" className="form-control" id="address" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>City</label>
                  <input type="text" className="form-control" id="city" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>District</label>
                  <input type="text" className="form-control" id="city" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="state" className="form-label" style={{ marginRight: '10px' }}>State:</label>
                  <select className="form-select" id="state">
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    {/* Add more states as needed */}
                  </select>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="state" className="form-label" style={{ marginRight: '10px' }}>Country:</label>
                  <select className="form-select" id="state">
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    {/* Add more states as needed */}
                  </select>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Pin Code</label>
                  <input type="text" className="form-control" id="city" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Short Name</label>
                  <input type="text" className="form-control" id="city" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Client Institute Name</label>
                  <input type="text" className="form-control" id="city" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Template Folder</label>
                  <input type="text" className="form-control" id="city" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>SMS WebLink</label>
                  <input type="text" className="form-control" id="city" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>SMS AuthKey</label>
                  <input type="text" className="form-control" id="city" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Minimum Charges</label>
                  <input type="text" className="form-control" id="city" />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <button className="btn btn-primary" style={{ marginRight: '10px' }}>Add</button>
                  <button className="btn btn-primary" style={{ marginRight: '10px' }}>Update</button>
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginRight: '10px' }}>
                    V
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li><button className="dropdown-item" type="button">Update Payment Desc only</button></li>
                  </ul>
                  <button className="btn btn-danger" style={{ marginRight: '10px' }}>Delete</button>
                  <select className="form-select" style={{ marginRight: '10px' }}>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                  </select>
                </div>
                <div>
                  Inst# = 1081
                </div>
                <div style={{ marginTop: '10px' }}>
                  Online #Inst=433
                </div>
                <div className="form-check mt-2">
                  <input className="form-check-input" type="checkbox" id="checkbox1" />
                  <label className="form-check-label" htmlFor="checkbox1">
                    Select Multiple
                  </label>
                </div>
                <div className="input-group mt-2" >
                  <select className="form-select">
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    {/* Add more options as needed */}
                  </select>
                  <button className="btn btn-primary" type="button">Search</button>
                </div>
              </form>
              <div style={{ position: 'absolute', top: '0', bottom: '0', left: '100%' }}></div>
              <div style={{ position: 'absolute', top: 0, left: '200px', marginLeft: '170px', marginTop: '1px' }}>
                <form>
                  <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="textBox" className="form-label" style={{ marginRight: '-10px' }}>Sms Title(Exact 6 Alphabets)</label>
                    <input type="text" className="form-control" id="textBox" style={{ width: '200px' }} />
                  </div>
                  <div className="mb-1">
                    <button className="btn btn-primary" style={{ marginRight: '5px' }}>Backup CAS DB</button>
                    <button className="btn btn-primary" style={{ marginRight: '5px' }}>Start OHIF</button>
                    <button className="btn btn-primary">Export Excel</button>
                  </div>
                  <div className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="textBox" className="form-label" style={{ marginRight: '3px' }}>Reports Template Label</label>
                    <select className="form-select" style={{ marginRight: '-10px', width:'200px'} } >
                      <option value="option1" >Option 1</option>
                      <option value="option2">Option 2</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>
                  <div className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="textBox" className="form-label" style={{ marginRight: '3px' }}>Receipt Format</label>
                  </div>
                  <div>
                    <input type="radio" id="format1" name="receiptFormat" value="format1" style={{ display: 'inline-block', marginRight: '10px' }} />
                    <label htmlFor="format1" style={{ display: 'inline-block', marginRight: '10px' }}>Regular</label>

                    <input type="radio" id="format2" name="receiptFormat" value="format2" style={{ display: 'inline-block', marginRight: '10px' }} />
                    <label htmlFor="format2" style={{ display: 'inline-block', marginRight: '10px' }}>Pdf</label>

                    <input type="radio" id="format3" name="receiptFormat" value="format3" style={{ display: 'inline-block' }} />
                    <label htmlFor="format3" style={{ display: 'inline-block' }}>Doc</label>
                  </div>
                  <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="textBox" className="form-label" style={{ marginRight: '3px' }}>SMS Options</label> 
                  </div>
                  <div className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="textBox" className="form-label" style={{ marginRight: '3px' }}>Send To.</label>
                  </div>
                  <div className="form-check mt-0">
                    <input className="form-check-input" type="checkbox" id="checkbox1" />
                    <label className="form-check-label" htmlFor="checkbox1" style={{ fontSize: '12px' }}>
                      Patient, After Registration
                    </label>
                  </div>
                  <div className="form-check mt-0">
                    <input className="form-check-input" type="checkbox" id="checkbox1" />
                    <label className="form-check-label" htmlFor="checkbox1" style={{ fontSize: '12px' }}>
                      Patient, Appointment SMS ON Registration
                    </label>
                  </div>
                  <div className="form-check mt-0">
                    <input className="form-check-input" type="checkbox" id="checkbox1" />
                    <label className="form-check-label" htmlFor="checkbox1" style={{ fontSize: '12px' }}>
                      Doctor & Patient, Before Appointment
                    </label>
                  </div>
                  <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Phone No</label>
                    <input type="text" className="form-control" id="city" style={{ width: '170px', marginRight: '10px' }} />
                    <button className="btn btn-success">Filter</button>
                  </div>
                  <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Email Id</label>
                    <input type="text" className="form-control" id="city" style={{ width: '200px', marginRight: '10px' }} />     
                  </div>
                  <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>OwnerName</label>
                    <input type="text" className="form-control" id="city" style={{ width: '200px', marginRight: '10px' }} />
                  </div>
                  <div className="mb-1" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>AppointmentInfoMsg After Register</label>
                  </div>
                  <div>
                    <input type="text" className="form-control" id="city" style={{ width: '350px', height: '70px', marginRight: '10px' }} />
                  </div>
                  <div className="mb-1 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>BillGoogleSheetpath2022</label>
                  </div>
                  <div>
                    <input type="text" className="form-control" id="city" style={{ width: '350px', height: '70px', marginRight: '10px' }} />
                  </div>
                  <div className="mb-3 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '0px' }}>Payment Description: </label>
                    <input type="text" className="form-control" id="city" style={{ width: '200px', marginRight: '10px' }} value="Monthly" />
                  </div>
                  <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>UPIiD</label>
                    <input type="text" className="form-control" id="city" style={{ width: '200px', marginRight: '10px' }} />
                  </div>
                  <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Billing WA No: </label>
                    <input type="text" className="form-control" id="city" style={{ width: '200px', marginRight: '10px' }} />
                  </div>
                  <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>Billing EmailID: </label>
                    <input type="text" className="form-control" id="city" style={{ width: '200px', marginRight: '10px' }} />
                  </div>
                  <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>First Login: </label>
                    <input type="date" className="form-control" id="city" style={{ width: '200px', marginRight: '10px' }} placeholder="Select a date" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lastLogin" className="form-label">Last Login:</label> 
                  </div>
                </form>
                <div style={{ borderBottom: '3px solid black', marginBottom: '10px', width: '55vw', position: 'relative', left: '-400px' }}></div>
                </div>
              <div style={{ position: 'absolute', top: '0', bottom: '0', left: '100%' }}></div>
              
                <form>
                <div className="data-grid" style={{ position: 'absolute', top: '1180px', left: '-40px', width: '400px', height: '200px', overflowX: 'auto', overflowY: 'auto' }}>
                <table className="table" style={{ borderCollapse: 'collapse', border: '1px solid black' }} >
                      <thead>
                        <tr>
                          <th>Month</th>
                          <th>Invoice Amount</th>
                          <th>Date</th>
                          <th>Received Amount</th>
                          <th>Bank Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>January</td>
                          <td>$1000</td>
                          <td>2024-01-01</td>
                          <td>$800</td>
                          <td>Bank of America</td>
                        </tr>
                        <tr>
                          <td>February</td>
                          <td>$1200</td>
                          <td>2024-02-15</td>
                          <td>$1000</td>
                          <td>Chase Bank</td>
                        </tr>
                        <tr>
                          <td>March</td>
                          <td>$1500</td>
                          <td>2024-03-05</td>
                          <td>$1300</td>
                          <td>Wells Fargo</td>
                        </tr>
                        {/* Add more rows as needed */}
                      </tbody>
                    </table>
                </div>
            </form>
            <div style={{ position: 'absolute', top: 0, left: '10px', marginLeft: '-70px', marginTop: '1450px' }}>
                  <div className="mt-5" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>InvoiceAmt: </label>
                    <input type="text" className="form-control" id="city" style={{ width: '200px', marginRight: '10px' }} />
                  </div>
                  <div className="mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="name" className="form-label" style={{ marginRight: '10px' }}>ReceivedAmt: </label>
                    <input type="text" className="form-control" id="city" style={{ width: '200px', marginRight: '10px' }} />
                  </div>
                  <div className="mb-3 mt-3">
                    <label htmlFor="lastLogin" className="form-label" style={{ display: 'inline-block' }}>Month: </label>
                    <input type="month" className="form-control" id="monthInput" style={{ width: '200px', marginLeft: '10px', display: 'inline-block' }} />
                  </div>
                  <div className="mb-3 mt-3">
                    <label htmlFor="lastLogin" className="form-label" style={{ display: 'inline-block', marginRight:'5px' }}>Date: </label>
                    <input type="date" className="form-control" id="city" style={{ width: '200px', marginRight: '10px', display: 'inline-block' }} placeholder="Select a date" />
                  </div>
                
              </div>
              <div style={{ position: 'absolute', top: '0', bottom: '0', left: '100%' }}></div>
              <div style={{ position: 'absolute', top: 0, left: '100px', marginLeft: '300px', marginTop: '1200px' }}>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <button className="btn btn-secondary" style={{ marginRight: '10px' }}>Add</button>
                  <button className="btn btn-secondary" style={{ marginRight: '10px' }}>Update</button>
                  <button className="btn btn-secondary" style={{ marginRight: '10px' }}>Delete</button>
                  <button className="btn btn-secondary" style={{ marginRight: '10px', width: '150px' }}>DeleteReceivedAmt </button>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <button className="btn btn-secondary" style={{ marginRight: '10px' }}>RenameGSheetNames</button>
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginRight: '10px' }}>
                    V
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li><button className="dropdown-item" type="button">Add Summary GSheet</button></li>
                    <li><button className="dropdown-item" type="button">Update Summary From Others</button></li>
                    <li><button className="dropdown-item" type="button">Update Summary From AllInst</button></li>
                  </ul>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <button className="btn btn-secondary" style={{ marginRight: '10px' }}>OpenGSheet</button>
                  <button className="btn btn-secondary" style={{ marginRight: '10px' }}>ImportGSheetInvAmt</button>
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginRight: '10px' }}>
                    V
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li><button className="dropdown-item" type="button">Approve Inv In MonthlySummary</button></li>
                    <li><button className="dropdown-item" type="button">Approve All Inv In MonthlySummary</button></li>
                  </ul>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <button className="btn btn-secondary" style={{ marginRight: '10px' }}>All ImportGSheetInvAmt</button>
                  <input type="text" className="form-control" id="city" style={{ width: '170px', marginRight: '10px' }} />
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <button className="btn btn-secondary" style={{ marginRight: '10px' }}>Bring MonthlySummary</button>
                </div>
                <div className="mb-3 mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="textBox" className="form-label" style={{ marginRight: '3px', fontSize: '12px' }}>Inserted Successfully</label>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight:'5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '122px' }} htmlFor="checkbox1">
                    And
                  </label>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '120px' }} htmlFor="checkbox1">
                    Empty Payment Desc
                  </label>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '60px' }} htmlFor="checkbox1">
                    MonthlyPaying
                  </label>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '120px' }} htmlFor="checkbox1">
                    Not Using
                  </label>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '113px' }} htmlFor="checkbox1">
                    Using
                  </label>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '120px' }} htmlFor="checkbox1">
                    Inv NotSent
                  </label>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '98px' }} htmlFor="checkbox1">
                    Inv Sent
                  </label>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '120px' }} htmlFor="checkbox1">
                    Payment Pending
                  </label>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '42px' }} htmlFor="checkbox1">
                    Payment Received
                  </label>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '120px' }} htmlFor="checkbox1">
                    NotPaying
                  </label>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '105px' }} htmlFor="checkbox1">
                    Paying
                  </label>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '120px' }} htmlFor="checkbox1">
                    Not Log in From Month
                  </label>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '100px' }} htmlFor="checkbox1">
                    Log In From Month
                  </label>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '120px' }} htmlFor="checkbox1">
                    None of MonthlySummaryPresent
                  </label>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '95px' }} htmlFor="checkbox1">
                    Any Monthly
                  </label>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '120px' }} htmlFor="checkbox1">
                    Sell MonthlySummary Data Not Received
                  </label>
                </div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <input className="form-check-input" style={{ fontSize: '12px', marginRight: '5px' }} type="checkbox" id="checkbox1" />
                  <label className="form-check-label" style={{ fontSize: '12px', marginRight: '120px' }} htmlFor="checkbox1">
                    Sell MonthlySummary Data Received
                  </label>
                </div>
              </div>
            </div>
          
        </div>
      </div>
    </>
  );
}
