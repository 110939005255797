import React from 'react';
import Sidebar from './Sidebar';

function GACal() {
  return (
    <div style={{ display: 'flex', userSelect: 'none' }}>
      <div>
        <Sidebar />
      </div>
      <div className="dvpatreg"
        style={{ flex: '1', width: '200px', padding: '40px' }}>
        <div style={{ width: '900px', height: '400px', backgroundColor: '#d4e3ea', borderRadius: '4px', border: '5px double white' }}>
          <div style={{ padding: '10px', display: 'flex', width:'400px', marginLeft:'300px' }}>
            <label>GA(Weeks)</label>
            <label style={{ marginLeft: '40px' }}>GA(Days)</label>
            <label style={{ marginLeft: '60px' }}>EDD</label>
          </div>
          <div style={{ padding: '10px', display: 'flex' }}>
            <label style={{marginTop:'3px'} }>
              <input type="radio" style={{marginRight:'4px'}} />LMP
            </label>
            <label style={{marginLeft:'82px'} }>LMP Date
              <input className="" type="date" style={{ width: '85px', height: '25px', fontSize: '11px', paddingLeft: '2px', marginLeft: '10px', paddingRight: '14px', borderRadius: '5px' }} />
            </label>
            <label style={{ marginLeft: '23px' }}>
              <input type="text" style={{ marginLeft: '10px', width:'60px', height:'25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '60px', height: '25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '80px', height: '25px' }}></input>
            </label>
          </div>
          <div style={{ padding: '10px', display: 'flex' }}>
            <label style={{ marginTop: '3px' }}>
              <input type="radio" style={{ marginRight: '4px' }} />CRL
            </label>
              <label style={{ marginLeft: '88px' }}>CRL Date
                <input className="" type="date" style={{ width: '85px', height: '25px', fontSize: '11px', paddingLeft: '2px', marginLeft: '10px', paddingRight: '14px', borderRadius: '5px' }} />
              </label>
            <label style={{ marginLeft: '23px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '60px', height: '25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '60px', height: '25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '80px', height: '25px' }}></input>
            </label>
          </div>
          <div style={{ padding: '10px', display: 'flex' }}>
            <label style={{ marginTop: '3px' }}>
              Calc LMP:
            </label>
            <label style={{ marginLeft: '86px' }}>CRL W
              <input type="text" style={{ marginLeft: '10px', width:'30px', height:'25px' }}></input>
            </label>
            <label style={{ marginLeft: '10px' }}>D
              <input type="text" style={{ marginLeft: '10px', width: '25px', height: '25px' }}></input>
            </label>
          </div>
          <div style={{ padding: '10px', display: 'flex' }}>
            <label style={{ marginTop: '3px' }}>
              <input type="radio" style={{ marginRight: '4px' }} />Biometry(AUA)
            </label>
            <label style={{ marginLeft: '194px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '60px', height: '25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '60px', height: '25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '80px', height: '25px' }}></input>
            </label>
          </div>
          <div style={{ padding: '10px', display: 'flex' }}>
            <label style={{ marginTop: '3px' }}>
              <input type="radio" style={{ marginRight: '4px' }} />Corrected EDD
            </label>
            <label style={{ marginLeft: '30px' }}>
            Calc LMP              
            </label>
            <label style={{ marginLeft: '115px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '60px', height: '25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '60px', height: '25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input className="" type="date" style={{ width: '85px', height: '25px', fontSize: '11px', paddingLeft: '2px', marginLeft: '10px', paddingRight: '14px', borderRadius: '5px' }} />
            </label>
          </div>
          <div style={{ padding: '10px', display: 'flex' }}>
            <label style={{ marginTop: '3px' }}>
              <input type="radio" style={{ marginRight: '4px' }} />IVF Embryo Transfer Date
            </label>
            <label style={{ marginLeft: '20px' }}>
              <input className="" type="date" style={{ width: '85px', height: '25px', fontSize: '11px', paddingLeft: '2px', marginLeft: '10px', paddingRight: '14px', borderRadius: '5px' }} />
            </label>
            <label style={{ marginLeft: '20px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '60px', height: '25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '60px', height: '25px' }}></input>
            </label>
            <label style={{ marginLeft: '25px' }}>
              <input type="text" style={{ marginLeft: '10px', width: '80px', height: '25px' }}></input>
            </label>
          </div>
          <div style={{ padding: '10px', display: 'flex', marginLeft:'230px' }}>
            <label style={{ marginTop: '3px', marginLeft:'10px' }}>
              <input type="radio" style={{ marginRight: '4px' }} />0
            </label>
            <label style={{ marginTop: '3px', marginLeft:'10px' }}>
              <input type="radio" style={{ marginRight: '4px' }} />3
            </label>
            <label style={{ marginTop: '3px', marginLeft:'10px' }}>
              <input type="radio" style={{ marginRight: '4px' }} />5 days
            </label>
          </div>
          <div style={{ display: 'flex', marginLeft: '270px' }}>
            <button className="gradientButton" style={{ marginLeft: '10px' }} >Save</button>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default GACal;
