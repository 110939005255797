import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import './modalitytest.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ModalityTest() {
  const [showPathoColumn, setShowPathoColumn] = useState(false);
  const [modalityData, setModalityData] = useState([]);
  const [selectedModalityRow, setSelectedModalityRow] = useState(null);
  const [uniqueModalityNames, setUniqueModalityNames] = useState([]);
  const [deleteBtnVisibility, setDeleteBtnVisibility] = useState(true);
  const [isHealthPanelColVisibility, setIsHealthPanelColVisibility] = useState(false);
  const [isDefaultPanelColVisibility, setIsDefaultPanelColVisibility] = useState(false);
  const [selectedOption, setSelectedOption] = useState('MultipleTest');
  const [selectedModalityTests, setSelectedModalityTests] = useState([]);
  const apiHostLink = localStorage.getItem("apiHostLink");
  const instituteId = localStorage.getItem("instituteId");
  const [modalityName, setModalityName] = useState('');
  const [modalityList, setModalityList] = useState([]);
  const [selectedModality, setSelectedModality] = useState({});
  const [selectedPanel, setSelectedPanel] = useState('');
  const [selectedPanelId, setSelectedPanelId] = useState('');

  const [testList, setTestList] = useState([]);
  const [selectedTest, setSelectedTest] = useState('');
  const [testName, setTestName] = useState('');
  const [productName, setProductName] = useState([]);
  const [testPrice, setTestPrice] = useState('');
  const [testId, setTestId] = useState('');
  const [testExposureSign, setTestExposureSign] = useState(0);
  const [isPathoModality, setIsPathoModality] = useState(false);
  const [selectedModalityName, setSelectedModalityName] = useState('');
  const [panelName, setPanelName] = useState([]);
  const [panelId, setPanelId] = useState([]);
  const [newPanelName, setNewPanelName] = useState('');
  const [file, setFile] = useState(null);
  const sessionUid = localStorage.getItem("sessionUid");
  const [tests, setTests] = useState([]);
  const [selectedRowForMultipleTest, setSelectedRowForMultipleTest] = useState(null);
  const [totalTestPrice, setTotalTestPrice] = useState(0);
  const [concession, setConcession] = useState(0);
  const [concessionApplied, setConcessionApplied] = useState(false);
  const [concessionIsPercentage, setConcessionIsPercentage] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [inventoryItems, setInventoryItems] = useState({});
  const [currentInventory, setCurrentInventory] = useState([]);
  const [selectedRowForInventory, setSelectedRowForInventory] = useState(null);
  const navigate = useNavigate();
  const storedRowData = localStorage.getItem('selectedRowData');
  const userId = localStorage.getItem("userId");
  const [isExporting, setIsExporting] = useState(false);


  const PANEL_STORAGE_KEY = 'sortedPanels';


  useEffect(() => {
    const savedInventoryItems = JSON.parse(localStorage.getItem('inventoryItems')) || {};
    setInventoryItems(savedInventoryItems);
    const initialTestId = Object.keys(savedInventoryItems)[0]; // Assuming you have a default testId to start with
    setCurrentInventory(savedInventoryItems[initialTestId] || []);
  }, []);

  // Save inventory items to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('inventoryItems', JSON.stringify(inventoryItems));
  }, [inventoryItems]);

  useEffect(() => {
    fetchPanelData();
    //fetchModalityData();
    fetchTestName();
    fetchInventoryProductName();
  }, []);

  useEffect(() => {
    // Calculate the total test price whenever the tests state changes
    const total = tests.reduce((acc, test) => acc + parseFloat(test.testPriceStr), 0);
    setTotalTestPrice(total);
  }, [tests]);

  useEffect(() => {
    const sortedPanels = JSON.parse(localStorage.getItem(PANEL_STORAGE_KEY));
    if (sortedPanels) {
      setPanelName(sortedPanels.names);
      setPanelId(sortedPanels.ids);
    }
  }, []);

  useEffect(() => {
    if (panelName.length > 0) {
      setSelectedPanel(panelName[0]); // Initialize selectedPanel with the first panelName
      setSelectedPanelId(panelId[0]);
    }
  }, [panelName, panelId]);

  useEffect(() => {
    if (selectedPanel) {
      fetchModalityData(selectedPanel, selectedPanelId); // Load modality data for the initially selected panel
    }
  }, [selectedPanel, selectedPanelId]);

  useEffect(() => {
    if (modalityList && Object.keys(modalityList).length > 0) {
      const firstModalityName = Object.keys(modalityList)[0];
      handleRowClick(firstModalityName);
    }
  }, [modalityList]);

  const fetchPanelData = async () => {
    try {
      const response = await axios.get(`${apiHostLink}PR_Select_PanelInstitueModalityTest?instituteId=${instituteId}&panelInstituteId=-1`);
      if (response.data) {
        const panelData = response.data;
        const panelNames = Object.keys(response.data); // Extract panel names
        const panelIds = Object.values(panelData); 
        const sortedPanels = panelNames
          .map((name, index) => ({ name, id: panelIds[index] }))
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        // Extract sorted names and IDs
        const sortedPanelNames = sortedPanels.map(panel => panel.name);
        const sortedPanelIds = sortedPanels.map(panel => panel.id);

        setPanelName(sortedPanelNames); // Set panel names in state
        setPanelId(sortedPanelIds);
        localStorage.setItem(PANEL_STORAGE_KEY, JSON.stringify({ names: sortedPanelNames, ids: sortedPanelIds }));
        console.log(response.data);
      } else {
        console.error('Response data is undefined or null');
      }
    } catch (error) {
      console.error('Error fetching Panel data:', error);
    }
  };

  const fetchModalityData = async (selectedPanelName, selectedPanelId, currentSelectedModality = null) => {
    try {
      const response = await axios.get(`${apiHostLink}PR_Select_PanelInstitueModalityTest?instituteId=${instituteId}&panelInstituteId=${selectedPanelId}`);
      if (response.data && response.data[selectedPanelName]) {
        setModalityList(response.data[selectedPanelName]);
        console.log(response.data);

        // Maintain the current selected modality if it exists in the updated data
        if (currentSelectedModality && response.data[selectedPanelName][currentSelectedModality]) {
          setSelectedModality([response.data[selectedPanelName][currentSelectedModality]]);
          setSelectedModalityName(currentSelectedModality);
        } else {
          // Fallback to the first modality if the current selection does not exist
          const firstModalityName = Object.keys(response.data[selectedPanelName])[0];
          setSelectedModality([response.data[selectedPanelName][firstModalityName]]);
          setSelectedModalityName(firstModalityName);
        }
      } else {
        console.error('Response data or selected panel data is undefined or null');
        setModalityList([]);
        setSelectedModality({});
        setSelectedModalityName('');
      }
    } catch (error) {
      console.error('Error fetching Modality data:', error);
      setModalityList([]);
      setSelectedModality({});
      setSelectedModalityName('');
    }
  };



  const handlePanelRowClick = (panel, panelIndex) => {
    setSelectedPanel(panel);
    setSelectedPanelId(panelId[panelIndex]); // Use panelIndex to access the corresponding panelId
    setNewPanelName(panel);
    console.log(`Selected Panel: ${panel}, Selected Panel ID: ${panelId[panelIndex]}`);
    setModalityList([]);
    if (!modalityList[panel]) {
      setModalityName(''); // Clear the modalityName state
    }
    fetchModalityData(panel, panelId[panelIndex]); // Pass selectedPanelId to fetchModalityData
  };




  const handleRowClick = (modalityName) => {
    setSelectedModality([modalityList[modalityName]]);
    setModalityName(modalityName);
    setSelectedModalityName(modalityName);
  };

  const fetchTestName = async () => {
    try {
      const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/FetchTestNames`, { instituteId: instituteId, userId: userId });
      setTestList(response.data);
    } catch (error) {
      console.error('Error fetching Test data:', error);
    }
  };


  const fetchInventoryProductName = async () => {
    try {
      const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/FillInventoryProduct`, {userId: userId});
      setProductName(response.data);
    } catch (error) {
      console.error('Error fetching Product Name:', error);
    }
  };



  const handleRowClickForTestData = (testName, price, exposureSign, testId) => {
    setSelectedTest(testName);
    setTestName(testName);
    setTestPrice(price);
    setTestExposureSign(exposureSign);
    setTestId(testId);

    const testInventory = inventoryItems[testId] || [];
    setCurrentInventory(testInventory);
    //setInventoryItems([]);
  };

  const handleAddModality = async () => {
    try {
      const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/AddModality`, { instituteId: instituteId, tspId: -1, modality: modalityName, panelName: selectedPanel, isTSPInstitute: false, userId: userId });
      const newModality = {
        [modalityName]: [{
          price: 0,
          exposureSign: 0,
          isPathoModality: false
        }]
      };
      const updatedModalityList = { ...modalityList, ...newModality };

      // Sort the modality list alphabetically
      const sortedModalityList = Object.keys(updatedModalityList).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).reduce((sorted, key) => {
        sorted[key] = updatedModalityList[key];
        return sorted;
      }, {});

      setModalityList(sortedModalityList);
      setModalityName('');
    } catch (error) {
      console.error('Error adding Modality:', error);
    }
  };

  const handleUpdateModality = async () => {
    try {
      if (modalityName.trim() === '' || modalityName === selectedModalityName) {
        console.log('No changes detected or modality name is empty. Update operation aborted.');
        return;
      }

      if (!selectedModality[0]) {
        console.error('No modality selected.');
        return;
      }

      const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/UpdateModality`, {
        InstituteId: instituteId,
        OldModalityName: selectedModalityName,
        NewModalityName: modalityName,
        PanelName: selectedPanel,
        userId: userId
      });

      const updatedModalityList = { ...modalityList };
      updatedModalityList[modalityName] = updatedModalityList[selectedModalityName];
      delete updatedModalityList[selectedModalityName];

      const sortedModalityList = Object.keys(updatedModalityList).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).reduce((sorted, key) => {
        sorted[key] = updatedModalityList[key];
        return sorted;
      }, {});

      setModalityList(updatedModalityList);

      // Update the selectedModality and selectedModalityName
      setSelectedModality([updatedModalityList[modalityName]]);
      setSelectedModalityName(modalityName);

      setModalityName('');

      // Ensure the modality data is up-to-date and keep the current selected modality
      await fetchModalityData(selectedPanel, selectedPanelId, modalityName);

    } catch (error) {
      console.error('Error updating Modality name:', error);
    }
  };




  const handleAddTest = async () => {
    try {
      if (testName.trim() === '') {
        console.error('Test name cannot be empty');
        return;
      }
      if (!selectedModalityName) {
        console.error('Please select a modality');
        return;
      }

      // Store the index of the selected row
      const selectedRowIndex = Object.keys(modalityList).indexOf(selectedModalityName);

      await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/AddTest`, {
        instituteId: instituteId,
        testPrice: testPrice,
        exposure: testExposureSign,
        testName: testName,
        modalityName: selectedModalityName,
        panelName: selectedPanel,
        isTSPInstitute: false,
        tspId: -1,
        userId: userId
      });

      // Update the local state instead of fetching modality data again
      const updatedModalityList = { ...modalityList };
      updatedModalityList[selectedModalityName][testName] = [{
        price: testPrice,
        exposureSign: testExposureSign,
        isPndtTest: false, // Add other necessary properties here
      }];
      setModalityList(updatedModalityList);

      // Preserve the selected modality name
      setSelectedModalityName(selectedModalityName);

      // Set the selected row back to the original row after updating state
      const updatedModalityKeys = Object.keys(updatedModalityList);
      const newSelectedModalityName = updatedModalityKeys[selectedRowIndex];
      setSelectedModality([updatedModalityList[newSelectedModalityName]]);

      // Update the selected modality list
      setSelectedModalityName(newSelectedModalityName);

      setTestName('');
      setTestPrice(0);
      setTestExposureSign(0);
    } catch (error) {
      console.error('Error adding Test:', error);
    }
  };








  const handleUpdateTest = async () => {
    try {
      if (!selectedModalityName || !selectedTest) {
        console.error('Please select a modality and a test');
        return;
      }

      // Check if no changes have been made
      const selectedTestData = modalityList[selectedModalityName][selectedTest];
      if (
        testName === selectedTest &&
        testPrice === selectedTestData[0]?.price &&
        testExposureSign === selectedTestData[0]?.exposureSign
      ) {
        console.log('No changes made. Skipping update.');
        return;
      }

      // Store the index of the selected row
      const selectedRowIndex = Object.keys(modalityList).indexOf(selectedModalityName);

      const selectedModalityId = modalityList[selectedModalityName]?.ModalityId?.[0];
      await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/UpdateTest`, {
        instituteId: instituteId,
        modId: selectedModalityId,
        testName: selectedTest,
        updatedTestName: testName,
        updatedTestPrice: testPrice,
        oldDiscPrice: 0,
        updatedExposure: testExposureSign,
        panelName: selectedPanel,
        dayNTimeOfPerforming: "",
        reportDelivery: "",
        prerequisite: "",
        technicianInstruction: "",
        userId: userId
      });

      // Update the local state instead of fetching modality data again
      const updatedModalityList = { ...modalityList };
      updatedModalityList[selectedModalityName][testName] = [{
        price: testPrice,
        exposureSign: testExposureSign,
        isPndtTest: false, // Add other necessary properties here
      }];
      if (testName !== selectedTest) {
        delete updatedModalityList[selectedModalityName][selectedTest]; // Delete the old test entry
      }
      setModalityList(updatedModalityList);

      // Preserve the selected modality name
      setSelectedModalityName(selectedModalityName);
      setSelectedTest(testName);

      handleRowClickForTestData(testName, testPrice, testExposureSign);

      // Set the selected row back to the original row after updating state
      const updatedModalityKeys = Object.keys(updatedModalityList);
      const newSelectedModalityName = updatedModalityKeys[selectedRowIndex];
      setSelectedModality([updatedModalityList[newSelectedModalityName]]);

      // Update the selected modality list
      setSelectedModalityName(newSelectedModalityName);

      // Reset the input fields only if an actual update was made
      setTestName('');
      setTestPrice(0);
      setTestExposureSign(0);
    } catch (error) {
      console.error('Error updating Test:', error);
    }
  };









  const handleDeleteModality = async () => {
    try {
      if (!selectedModalityName) {
        console.error('No modality selected.');
        return;
      }
      const selectedModalityId = modalityList[selectedModalityName]?.ModalityId?.[0];
      await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/DeleteModality`, {
        instituteId: instituteId,
        modId: selectedModalityId, 
        panelName: selectedPanel,
        userId: userId
      });

      const updatedModalityList = { ...modalityList };
      delete updatedModalityList[selectedModalityName];
      setModalityList(updatedModalityList);
      setSelectedModality({});
      setModalityName('');

    } catch (error) {
      console.error('Error deleting Modality:', error);
    }
  };


  const handleDeleteTest = async () => {
    try {
      if (!selectedModalityName) {
        console.error('No modality selected.');
        return;
      }

      // Store the index of the selected row
      const selectedRowIndex = Object.keys(modalityList).indexOf(selectedModalityName);

      const selectedModalityId = modalityList[selectedModalityName]?.ModalityId?.[0];
      const selectedTestId = modalityList[selectedModalityName]?.[selectedTest]?.[0]?.testId;
      await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/DeleteTest`, {
        instituteId: instituteId,
        modId: selectedModalityId,
        testId: selectedTestId,
        panelName: selectedPanel,
        userId: userId
      });

      // Update the local state instead of fetching modality data again
      const updatedModalityList = { ...modalityList };
      delete updatedModalityList[selectedModalityName][selectedTest];
      setModalityList(updatedModalityList);

      // Preserve the selected modality name
      setSelectedModalityName(selectedModalityName);

      // Set the selected row back to the original row after updating state
      const updatedModalityKeys = Object.keys(updatedModalityList);
      const newSelectedModalityName = updatedModalityKeys[selectedRowIndex];
      setSelectedModality([updatedModalityList[newSelectedModalityName]]);

      // Update the selected modality list
      setSelectedModalityName(newSelectedModalityName);

    } catch (error) {
      console.error('Error deleting Test:', error);
    }
  };

  const handleAddPanel = async () => {
    if (newPanelName.trim() === '') {
      console.error('Panel name cannot be empty');
      return;
    }

    try {
      const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/AddPanel`, {
        instituteId: instituteId,
        panelName: newPanelName,
        userId: userId  
      });

      // Assuming the response contains the new panel ID, modify accordingly if needed
      const newPanelId = response.data.panelId;

      // Add the new panel name and ID to temporary arrays
      const updatedPanelNames = [...panelName, newPanelName];
      const updatedPanelIds = [...panelId, newPanelId];

      // Sort the panel names and IDs alphabetically
      const sortedPanels = updatedPanelNames
        .map((name, index) => ({ name, id: updatedPanelIds[index] }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      // Extract sorted names and IDs
      const sortedPanelNames = sortedPanels.map(panel => panel.name);
      const sortedPanelIds = sortedPanels.map(panel => panel.id);

      // Update the state with the sorted arrays
      setPanelName(sortedPanelNames);
      setPanelId(sortedPanelIds);
      setNewPanelName('');
      localStorage.setItem(PANEL_STORAGE_KEY, JSON.stringify({ names: sortedPanelNames, ids: sortedPanelIds }));
    } catch (error) {
      console.error('Error adding panel:', error);
    }
  };



  const handleUpdatePanel = async () => {
    if (newPanelName.trim() === '') {
      console.error('Panel name cannot be empty');
      return;
    }

    try {
      await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/UpdatePanel`, {
        instituteId: instituteId,
        oldPanelName: selectedPanel,
        newPanelName: newPanelName,
        userId: userId
      });

      const updatedPanelNames = [...panelName];
      const updatedPanelIndex = updatedPanelNames.findIndex(name => name === selectedPanel);
      updatedPanelNames[updatedPanelIndex] = newPanelName;
      setPanelName(updatedPanelNames);
      
      setPanelId(selectedPanelId);

      await fetchModalityData(newPanelName, selectedPanelId);
      
      window.location.reload();
    } catch (error) {
      console.error('Error updating panel:', error);
    }
  };


  const handleDeletePanel = async () => {
    try {
      const deletePanelData = {
        panelInstituteId: selectedPanelId,
        loggedInInstituteId: instituteId,
        isDeletePanel: true,
        userId: userId
      };

      const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/DeletePanel`, deletePanelData);

      if (response.status === 200) {
        const updatedPanelNames = panelName.filter(panel => panel !== selectedPanel);
        setPanelName(updatedPanelNames);
        setSelectedPanel('');
        setSelectedPanelId('');
        setNewPanelName('');
        window.location.reload();
      } else {
        console.error('Error deleting panel:', response.data);
      }
    } catch (error) {
      console.error('Error deleting panel:', error);
    }
  };





//const fetchModalityData = () => {
//  fetch(`${apiHostLink}PR_Select_PanelInstitueModalityTest?instituteId=${instituteId}&panelInstituteId=9`)
//    .then(response => response.json())
//    .then(data => {
//      const modalityNames = data.map(item => item.modalityName);
//      const uniqueNames = Array.from(new Set(modalityNames));
//      setModalityData(data);
//      setUniqueModalityNames(uniqueNames);
//    })
//    .catch(error => {
//      console.error('Error fetching modality data:', error);
//    });
//};

//  const handleModalitySelect = (modality) => {
//    setModalityName(modality);
//    const tests = modalityData.filter(item => item.modalityName === modality);
//    setSelectedModalityTests(tests);
//  };

//  const handleModalityTableRowClick = (index) => {
//    if (selectedModalityRow === index) {
//      setSelectedModalityRow(null);
//      setModalityName('');
//    } else {
//      setSelectedModalityRow(index);
//      setModalityName(modalityData[index].modalityName);
//      handleModalitySelect(modalityData[index].modalityName);
//    }
//  };

  const handleExportClick = async  (event) => {
    if (event.ctrlKey) {
      setDeleteBtnVisibility(!deleteBtnVisibility);
      return;
    }
    setIsExporting(true);
    try {
      const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/ExportCsv`, {
        instituteId: instituteId,
        userId: userId,
      }, {
        responseType: 'blob'
      });
      console.log("Button Clicked");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ModalityTest.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
      setIsExporting(false); // Re-enable the button once the process completes
    }
  };


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      console.log("Selected file:", selectedFile.name);
      console.log("File size:", selectedFile.size);
      console.log("File type:", selectedFile.type);
      setFile(selectedFile);
      //handleImportClick(); // Assuming you want to trigger import after selecting the file
    }
  };


  useEffect(() => {
    if (file) {
      handleImportClick();
    }
  }, [file]);


  const handleImportClick = async () => {
    try {
      if (!file) {
        console.error('No file selected.');
        alert("Select Xlsx file first");
        return;
      }
      const selectedRowIndex = Object.keys(modalityList).indexOf(selectedModalityName);

      const selectedModalityId = modalityList[selectedModalityName]?.ModalityId?.[0];
      const selectedTestId = modalityList[selectedModalityName]?.[selectedTest]?.[0]?.testId;


      const formData = new FormData();
      formData.append('panelInstituteId', -1);
      formData.append('loggedInInstituteId', instituteId);
      formData.append('modalityId', -1);
      formData.append('panelName', "All");
      formData.append('File', file);
      formData.append('sessionUid', sessionUid);
      formData.append('userId', userId);

      const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/ImportCsv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Import successful:', response.data);
      alert("Import Successfully");
      window.location.reload();
    } catch (error) {
      console.error('Error importing file:', error);
      alert("Import Failed");
    }
  };


  const handleAddMultipleTest = async () => {
    try {
      const adjustedPrice = concessionApplied
        ? testPrice
        : (concessionIsPercentage
          ? testPrice - (testPrice * (parseFloat(concession) / 100))
          : testPrice - (parseFloat(concession) || 0));

      const addMultipleTestData = {
        testId: testId,
        testName: testName,
        testPriceStr: adjustedPrice, // Keep two decimal places for prices
        originalPrice: testPrice,
        modalityName: selectedModalityName,
        userId: userId,
      };

      const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/AddMultipleTest`, addMultipleTestData);
      console.log(response.data);

      const updatedTests = [...tests, addMultipleTestData];
      setTests(updatedTests);

      setTestId('');
      setTestName('');
      setTestPrice('');
      setConcession(0);

      if (!concessionApplied && parseFloat(concession) > 0) {
        setConcessionApplied(true);
      }

      // Get data from local storage
      const selectedRowData = JSON.parse(localStorage.getItem('selectedRowData'));

      if (selectedRowData) {
        // Update the selected row data with the current modality and test information
        const updatedRowData = {
          ...selectedRowData,
          modality: updatedTests.map(test => test.modalityName),
          cbTests: updatedTests.map(test => test.testName),
          scheduledDate: new Date().toISOString()
        };

        // Save the updated row data to local storage
        localStorage.setItem('selectedRowData', JSON.stringify(updatedRowData));
        console.log("UpdatedRowData is: ", updatedRowData);
      }



    } catch (error) {
      console.error('Error adding multiple test:', error);
    }
  };

  const handleDeleteMultipleTest = async () => {
    try {
      if (selectedRowForMultipleTest !== null && selectedRowForMultipleTest !== undefined) {
        const selectedTest = tests[selectedRowForMultipleTest];
        const deleteMultipleTestData = {
          testId: selectedTest.testId,
          testPriceStr: selectedTest.testPriceStr,
          userId: userId
        };

        const response = await axios.post(`${apiHostLink}PR_Select_PanelInstitueModalityTest/DeleteMultipleTest`, deleteMultipleTestData);
        console.log(response.data);

        // Remove the deleted test from the state
        const updatedTests = tests.filter((test, index) => index !== selectedRowForMultipleTest);
        setTests(updatedTests);
        setSelectedRowForMultipleTest(null); // Clear selected row

        // Reset concessionApplied if all tests are deleted
        if (updatedTests.length === 0) {
          setConcessionApplied(false);
        }
      } else {
        console.error('No row selected for deletion.');
      }
    } catch (error) {
      console.error('Error deleting multiple test:', error);
    }
  };



  const handleRowClickForMultipleTest = (index) => {
    setSelectedRowForMultipleTest(index); // Set selected row index

    if (index !== null && index !== undefined) {
      const selectedTest = tests[index];
      setTestId(selectedTest.testId);
      setTestPrice(selectedTest.testPriceStr);
    }
  };



  const handleConcessionChange = (e) => {
    setConcession(e.target.value);
  };


  const handleConcessionCheckboxChange = (e) => {
    setConcessionIsPercentage(e.target.checked);
  };

  const calculateTotalWithConcession = () => {
    const total = tests.reduce((acc, test) => acc + parseFloat(test.testPriceStr), 0);

    if (!concessionApplied && concession > 0) {
      if (concessionIsPercentage) {
        return total - (total * (concession / 100));
      } else {
        return total - parseFloat(concession);
      }
    }

    return total;
  };


  const handleAddPatient = async () => {
    const updatedRowData = JSON.parse(localStorage.getItem('selectedRowData'));

    try {
      for (let i = 0; i < updatedRowData.cbTests.length; i++) {
        const testName = updatedRowData.cbTests[i];
        const modalityName = updatedRowData.modality[i];

        const testResponse = await axios.post(`${apiHostLink}Add_Test_/AddTestClick`, {
          fName: updatedRowData.fName,
          mName: updatedRowData.mName,
          sName: updatedRowData.sName,
          patientId: updatedRowData.patientId,
          isUrgentPatient: false,
          drReporting: updatedRowData.drReporting,
          refPhy1: updatedRowData.refPhy1,
          refPhy2: updatedRowData.refPhy2,
          modality: modalityName,
          cbTests: testName, // Pass only the current testName in an array
          scheduledDate: updatedRowData.scheduledDate,
          instituteId: instituteId,
          UserId: userId,
        });

        console.log(`Added test ${testName}:`, testResponse.data);
      }
      localStorage.removeItem('selectedRowData');
      // Show alert after all tests have been added
      alert("Now it will navigate to pCareHome Page");
      navigate('/pcarehome', { state: { shouldFocus: true } });
    } catch (error) {
      console.error('Error adding tests:', error);
      // Handle error, such as displaying a message or logging additional details
    }
  };




  const handleAddProduct = () => {
    if (selectedTest && selectedProductName) {
      const newItem = {
        productName: selectedProductName,
        quantity: quantity || 0,
      };
      const updatedInventoryItems = {
        ...inventoryItems,
        [testId]: [...(inventoryItems[testId] || []), newItem],
      };
      setInventoryItems(updatedInventoryItems);
      setCurrentInventory(updatedInventoryItems[testId]);
      setSelectedProductName('');
      setQuantity(0);
    }
  };


  const handleInventoryRowClick = (index) => {
    setSelectedProductName(currentInventory[index].productName);
    setQuantity(currentInventory[index].quantity);
    setSelectedRowForInventory(index); // Set selected row index
  };


  const handleUpdateProduct = () => {
    if (selectedRowForInventory !== null && quantity !== '') {
      const updatedInventory = [...currentInventory];
      updatedInventory[selectedRowForInventory] = {
        ...updatedInventory[selectedRowForInventory],
        quantity: parseInt(quantity),
      };
      setCurrentInventory(updatedInventory);
      setQuantity(0); // Reset quantity input after update
      setSelectedRowForInventory(null); // Clear selected row after update

      // Update inventoryItems with the modified currentInventory
      const updatedInventoryItems = {
        ...inventoryItems,
        [testId]: updatedInventory,
      };
      setInventoryItems(updatedInventoryItems);
    }
  };


  const handleDeleteProduct = () => {
    if (selectedRowForInventory !== null) {
      // Create a copy of currentInventory excluding the selected row
      const updatedInventory = currentInventory.filter((item, index) => index !== selectedRowForInventory);
      setCurrentInventory(updatedInventory);

      // Update inventoryItems with the modified currentInventory
      const updatedInventoryItems = {
        ...inventoryItems,
        [testId]: updatedInventory,
      };
      setInventoryItems(updatedInventoryItems);

      // Reset selected fields
      setSelectedProductName('');
      setQuantity(0);
      setSelectedRowForInventory(null);
    }
  };



  const toggleIsHealthPanel = (event) => {
    if (event.ctrlKey) {
      setIsHealthPanelColVisibility(!isHealthPanelColVisibility);
    }
    if (event.shiftKey) {
      setIsDefaultPanelColVisibility(!isDefaultPanelColVisibility);
    }
  };

  const handleRadioClick = (value) => {
    setSelectedOption(value);
  };

  return (
    <div style={{ padding: '10px', marginLeft: '40px', marginTop: '30px', userSelect: 'none' }}>
      <label style={{ display: 'flex', flexDirection: 'col', fontSize: '12px', width: '450px' }}>Search TestName :
        <select style={{ marginLeft: '10px', width: '150px' }}>
          <option value=""></option>
          {testList.map(testName => (
            <option key={testName}>{testName}</option>
          ))}
        </select>
        <button className="gradientButton" style={{ marginLeft: '10px' }}>Search</button>
      </label>

      <div style={{ display: 'flex' }}>

        <div style={{ backgroundColor: '#d4e3ea', width: '250px', height: '400px', marginTop: '30px', border: '5px double white', borderRadius: '4px' }}>

          <div className="table-container-1" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '290px', width: '220px' }}>
            <table>
              <thead style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                <tr>
                  <th style={{ backgroundColor: '#f2f9fc', width: '300px', textAlign: 'left', paddingLeft: '10px' }}>Name</th>
                  {isDefaultPanelColVisibility && <th style={{ backgroundColor: '#f2f9fc', width: '300px', textAlign: 'left', paddingLeft: '10px' }}>IsDefaultPanel</th>}
                  {isHealthPanelColVisibility && <th style={{ backgroundColor: '#f2f9fc', width: '300px', textAlign: 'left', paddingLeft: '10px' }}>IsHealthPanel</th>}
                </tr>
              </thead>
              <tbody>
                {panelName.map((panel, index) => (
                  <tr key={index} onClick={() => handlePanelRowClick(panel, index)} style={{ backgroundColor: (selectedPanel === panel) ? '#d7fba0' : 'white' }}>
                    <td style={{ paddingLeft: '10px' }}>{panel}</td>
                    {isDefaultPanelColVisibility && <td style={{ paddingLeft: '10px' }}><input type="checkbox"></input></td>}
                    {isHealthPanelColVisibility && <td style={{ paddingLeft: '10px' }}><input type="checkbox"></input></td>}
                  </tr>
                ))}
              </tbody>
            </table>

          </div>

          <label style={{ fontSize: '12px', padding: '10px' }}> Name
            <input type="text" style={{ width: '170px', height: '27px', marginLeft: '10px' }} value={newPanelName} onChange={(e) => setNewPanelName(e.target.value)}>
            </input>
          </label>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddPanel}>Add</button>
            <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={toggleIsHealthPanel} onClick={handleUpdatePanel}>Update</button>
            {!deleteBtnVisibility && (
              <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleDeletePanel}>Delete</button>
            )}

          </div>

        </div>


        <div style={{ backgroundColor: '#d4e3ea', width: '250px', height: '400px', marginTop: '30px', border: '5px double white', borderRadius: '4px' }}>

          <div className="table-container-2" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '290px', width: '220px' }}>
            <table>
              <thead style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                <tr>
                  <th style={{ backgroundColor: '#f2f9fc', width: '202px', textAlign: 'left' }}>ModalityName</th>
                  {showPathoColumn && <th style={{ backgroundColor: '#f2f9fc', width: '202px', textAlign: 'left' }}>IsPathoModality</th>}
                </tr>
              </thead>
              <tbody>
                {Object.keys(modalityList).map((modalityName, index) => (
                  <tr key={index} onClick={() => handleRowClick(modalityName)} style={{ backgroundColor: (selectedModalityName === modalityName) ? '#d7fba0' : (index % 2 === 0 ? 'white' : '#dff9ff') }}>
                    <td>{modalityName}</td>
                    {showPathoColumn && (
                      <td>
                        <input
                          type="checkbox"
                          checked={modalityList[modalityName][0]?.isPathoModality || false}
                          readOnly
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <label style={{ fontSize: '12px', padding: '10px' }}> Name
            <input className="disp_mod_name" type="text" value={modalityName} onChange={(e) => setModalityName(e.target.value)} style={{ width: '170px', height: '27px', marginLeft: '10px' }} />
          </label>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddModality}>Add</button>
            <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleUpdateModality}>Update</button>
            {!deleteBtnVisibility && (
              <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleDeleteModality}>Delete</button>
            )}
          </div>
          <div>
            <label style={{ fontSize: '12px', padding: '5px', width: '140px', textAlign: 'center', marginTop: '' }}>
              <input type="checkbox" style={{ marginRight: '5px' }} onChange={() => setShowPathoColumn(!showPathoColumn)}>
              </input>ShowPatho Column
            </label>
          </div>
        </div>
        <div style={{ backgroundColor: '#d4e3ea', width: '360px', height: '400px', marginTop: '30px', border: '5px double white', borderRadius: '4px', marginLeft: '0px' }}>

          <div className="table-container-3" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '285px', width: 'auto' }}>
            <table>
              <thead style={{ position: 'sticky', zIndex: '1' }}>
                <tr>
                  <th style={{ backgroundColor: '#f2f9fc', maxWidth: '50px', textAlign: 'left' }} title="TestName">TestName</th>
                  <th style={{ backgroundColor: '#f2f9fc', maxWidth: '35px', textAlign: 'center' }} title="Price">Price</th>
                  <th style={{ backgroundColor: '#f2f9fc', maxWidth: '25px', textAlign: 'center' }} title="Exposure">Exposure</th>
                  <th style={{ backgroundColor: '#f2f9fc', maxWidth: '50px', textAlign: 'center' }} title="IsPndtTest">IsPndtTest</th>
                  <th style={{ backgroundColor: '#f2f9fc', maxWidth: '50px', textAlign: 'center' }} title="Manual">Manual</th>
                  <th style={{ backgroundColor: '#f2f9fc', maxWidth: '50px', textAlign: 'center' }} title="TimeSpan">TimeSpan</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(selectedModality) && selectedModality.length > 0 && Object.keys(selectedModality[0])
                  .filter(key => key !== 'ModalityId') // Exclude ModalityId from sorting
                  .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                  .map((key) => (
                    Array.isArray(selectedModality[0][key]) ? (
                      selectedModality[0][key].map((item, index) => (

                         // Filter out the row where testName is "ModalityId"
                        <tr key={index} onClick={() => handleRowClickForTestData(key, item.price, item.exposureSign, item.testId)} style={{ backgroundColor: selectedTest === key ? '#d7fba0' : '', height: '20px' }}>
                          <td style={{ maxWidth: '70px', height: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', textAlign: 'left', paddingTop: '0px', paddingBottom: '0px' }} title={key}>{key}</td>
                          <td style={{ maxWidth: '80px', height: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', textAlign: 'right', paddingLeft: '10px', paddingTop: '0px', paddingBottom: '0px' }} title={item.price}>{item.price}</td>
                          <td style={{ maxWidth: '25px', height: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', textAlign: 'center', paddingTop: '0px', paddingBottom: '0px' }} title={item.exposureSign}>{item.exposureSign}</td>
                          <td style={{ maxWidth: '70px', height: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', textAlign: 'center', paddingTop: '0px', paddingBottom: '0px' }} ><input type="checkbox" checked={item.isPndtTest} /></td>
                          <td style={{ maxWidth: '70px', height: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', textAlign: 'center', paddingTop: '0px', paddingBottom: '0px' }} title={item.manual}>{item.manual}</td>
                          <td style={{ maxWidth: '70px', height: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', textAlign: 'center', paddingTop: '0px', paddingBottom: '0px' }} title={item.timeSpan}>{item.timeSpan}</td>
                        </tr>
                      ))
                    ) : null
                  ))}
              </tbody>
            </table>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>
            <label style={{ fontSize: '12px', padding: '0px', marginRight: '10px' }}> Name
              <br />
              <input type="text" value={testName} onChange={(e) => setTestName(e.target.value)} style={{ width: '130px', height: '27px', marginLeft: '0px' }} />
            </label>
            <label style={{ fontSize: '12px', padding: '0px', display: 'flex', flexDirection: 'column', marginRight: '10px' }}> Price
              <br />
              <input type="text" value={testPrice} style={{ width: '70px', height: '27px', marginLeft: '0px' }} onChange={(e) => setTestPrice(e.target.value)} />
            </label>
            <label style={{ fontSize: '12px', padding: '0px', display: 'flex', flexDirection: 'column' }}> Exposure
              <br />
              <input type="text" value={testExposureSign} onChange={(e) => setTestExposureSign(e.target.value)} style={{ width: '70px', marginLeft: '0px', height: '27px' }} />
            </label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
            <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddTest}>Add</button>
            <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleUpdateTest}>Update</button>
            {/* Conditionally rendering delete buttons based on deleteBtnVisibility state */}
            {!deleteBtnVisibility && (
              <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleDeleteTest}>Delete</button>
            )}
          </div>
        </div>


        <div style={{ fontSize: '12px' }}>
          <label style={{ display: 'inline', marginLeft: '18px' }}>
            <input
              type="radio"
              value="MultipleTest"
              checked={selectedOption === 'MultipleTest'}
              onChange={() => handleRadioClick('MultipleTest')}
            />
            Multiple Test
          </label>
          <label style={{ display: 'inline', marginLeft: '5px' }}>
            <input
              type="radio"
              value="Inventory"
              checked={selectedOption === 'Inventory'}
              onChange={() => handleRadioClick('Inventory')}
            />
            Inventory
          </label>

          <label style={{ display: 'inline', marginLeft: '5px' }}>
            <input
              type="radio"
              value="Information"
              checked={selectedOption === 'Information'}
              onChange={() => handleRadioClick('Information')}
            />
            Information
          </label>


          <div className="table-container-4" style={{ backgroundColor: '#d4e3ea', width: '250px', height: '400px', marginTop: '12px', marginLeft: '15px', border: '5px double white', borderRadius: '4px' }}>
            {selectedOption === 'MultipleTest' && (
              <div className="MultipleTest" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '270px', width: '220px' }}>
                <table>
                  <thead style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                    <tr>
                      <th style={{ backgroundColor: '#f2f9fc', width: '222px', textAlign: 'left', border: '1px solid white' }}>TestName</th>
                      <th style={{ backgroundColor: '#f2f9fc', width: '222px', textAlign: 'left', border: '1px solid white' }}>TestPrice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tests.map((test, index) => (
                      <tr key={index} onClick={() => handleRowClickForMultipleTest(index)} style={{ backgroundColor: selectedRowForMultipleTest === index ? '#d7fba0' : 'white', cursor: 'pointer' }}>
                        <td style={{ border: '1px solid white' }}>{test.testName}</td>
                        <td style={{ border: '1px solid white' }}>{test.originalPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {selectedOption === 'Inventory' && (
              <div className="Inventory" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '270px', width: '220px' }}>
                <table>
                  <thead style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                    <tr>
                      <th style={{ backgroundColor: '#f2f9fc', width: '222px', textAlign: 'left', border: '1px solid white' }}>Product</th>
                      <th style={{ backgroundColor: '#f2f9fc', width: '222px', textAlign: 'left', border: '1px solid white' }}>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentInventory) && currentInventory.map((item, index) => (
                      <tr key={index} onClick={() => handleInventoryRowClick(index)} style={{ backgroundColor: selectedRowForInventory === index ? '#d7fba0' : 'white', cursor: 'pointer' }}>
                        <td>{item.productName}</td>
                        <td>{item.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {selectedOption === 'Information' && (

              <div style={{ width: 'auto', height: '90px', marginTop: '5px' }}>
                <label style={{ display: 'flex', padding: '5px' }}>Day and Time
                  <textarea style={{ width: '180px', maxHeight: '80px', minHeight:'80px' }}></textarea>
                </label>
              </div>
            )}


            {selectedOption === 'MultipleTest' && (
              <div className="MultipleTest">
                <label style={{ marginLeft: '10px', marginTop: '20px' }}>Total: {calculateTotalWithConcession()}</label>
              </div>
            )}

            {selectedOption === 'Inventory' && (
              <div className="Inventory">
                <label style={{ marginLeft: '10px' }}>Product Name
                  <select style={{ marginLeft: '10px' }} value={selectedProductName} onChange={(e) => setSelectedProductName(e.target.value)}>
                    <option value=""></option>
                    {productName.map((productName, index) => (
                      <option key={index} value={productName}>{productName}</option>
                    ))}
                  </select>
                </label>
              </div>
            )}
            {selectedOption === 'Information' && (
              <div style={{ width: 'auto', height: '100px', marginTop: '5px' }}>
                <label style={{ display: 'flex', padding: '5px' }}>Day and Time
                  <textarea style={{ width: '180px', maxHeight: '80px', minHeight: '80px' }}></textarea>
                </label>
              </div>
            )}

            {selectedOption === 'MultipleTest' && (
              <div className="MultipleTest" style={{ display: 'inline' }}>
                <label style={{ fontSize: '12px', padding: '5px', display: 'flex' }}> Concession:
                  <label style={{ fontWeight: '900', fontSize: '14px' }}><input type="checkbox" style={{ display: 'inline', marginLeft: '10px' }} checked={concessionIsPercentage} onChange={handleConcessionCheckboxChange} >
                  </input>%
                  </label>
                  <input type="text" value={concession} onChange={handleConcessionChange} style={{ width: '100px', height: '27px', display: 'inline', marginLeft: '10px', marginTop: "-5px" }}>
                  </input>
                </label>
              </div>
            )}

            {selectedOption === 'Inventory' && (
              <div className="Inventory" style={{ marginTop: '10px', marginLeft: '10px' }}>
                <label>Quantity
                  <input type="number" style={{ width: '100px', height: '27px', display: 'inline', marginLeft: '10px' }} value={quantity} onChange={(e) => setQuantity(e.target.value)} ></input>
                </label>
              </div>
            )}
            {selectedOption === 'Information' && (
              <div style={{ width: 'auto', height: '100px' }}>
                <label style={{ display: 'flex', padding: '5px' }}>Day and Time
                  <textarea style={{ width: '180px', maxHeight: '80px', minHeight:'80px' }}></textarea>
                </label>
              </div>
            )}

            {selectedOption === 'MultipleTest' && (
              <div className="MultipleTest" style={{ display: 'flex', flexDirection: 'row', marginTop:'10px' }}>
                <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddMultipleTest}>Add</button>
                <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleDeleteMultipleTest}>Delete</button>
                <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddPatient}>AddPatient</button>  
              </div>
            )}

            {selectedOption === 'Inventory' && (
              <div className="Inventory" style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddProduct}>Add</button>
                <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleUpdateProduct}>Update</button>
                <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleDeleteProduct}>Delete</button>
              </div>
            )}
            {selectedOption === 'Information' && (
              <div style={{ width: 'auto', height: '100px' }}>
                <label style={{ display: 'flex', padding: '5px', paddingTop:'0px' }}>Day and Time
                  <textarea style={{ width: '180px', maxHeight: '80px', minHeight: '80px' }}></textarea>
                </label>
              </div>
            )}
          </div>


          <div style={{ backgroundColor: '#d4e3ea', width: '500px', height: '100px', marginTop: '30px', marginLeft: '-625px', border: '5px double white', borderRadius: '6px' }}>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px' }}>
              <label htmlFor="fileInput" className="import-button gradientButton" style={{ color: 'black', padding: '10px', paddingBottom: '0px', paddingTop: '3px', marginLeft: '10px' }}>
                Import
                <input type="file" id="fileInput" onChange={handleFileChange} style={{ display: 'none' }} />
              </label>
              <div className="uploading-line"></div>

              <button className="gradientButton" style={{ marginLeft: '10px', opacity: isExporting ? 0.5 : 1, cursor: isExporting ? 'not-allowed' : 'pointer', pointerEvents: isExporting ? 'none' : 'auto' }} onClick={!isExporting ? handleExportClick : null}>Export(.csv)</button>
              {/* Add your export logic here */}
              <input type="text" style={{ width: '120px', height: '25px', marginLeft: '10px' }} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
export default ModalityTest;
