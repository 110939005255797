import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UsersSidebar from './UsersSidebar';

const Template = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [L2Item, setL2] = useState([]);
  const [L3Item, setL3] = useState([]);
  const [selectedL2, setSelectedL2] = useState(null);
  const [L2Name, setL2Name] = useState('');
  const [selectedL3, setSelectedL3] = useState(null);
  const [L3Name, setL3Name] = useState('');
  const apiHostLink = localStorage.getItem("apiHostLink");
  const instituteId = localStorage.getItem("instituteId");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    axios.get(`${apiHostLink}L2Template_/PdfTitleTable?instituteId=${instituteId}&userId=${userId}`)
      .then(response => {
        const data = response.data;
        if (Array.isArray(data)) {
          setL2(data);
          if (data.length > 0) {
            setSelectedL2(data[0]); // Select the first L2 item by default
            setL2Name(data[0].L2); // Set the L2 name in the input field
          }
        } else {
          setL2([data]);
          setSelectedL2(data); // Select the only item if data is not an array
          setL2Name(data.L2); // Set the L2 name in the input field
        }
        console.log(data);
      })
      .catch(error => {
        console.error('There was an error fetching the online users!', error);
      });
  }, [apiHostLink, instituteId]);

  useEffect(() => {
    if (selectedL2) {
      axios.get(`${apiHostLink}L2Template_/PdfTitleTable2?instituteId=${instituteId}&L2Name=${selectedL2.L2}&userId=${userId}`)
        .then(response => {
          const data = response.data;
          if (Array.isArray(data)) {
            setL3(data);
          } else {
            setL3([data]);
          }
          console.log(data);
        })
        .catch(error => {
          console.error('There was an error fetching the online users!', error);
        });
    }
  }, [selectedL2, apiHostLink, instituteId]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleL2RowClick = (item) => {
    setSelectedL2(item);
    setL2Name(item.L2); // Set the L2 name in the input field
    console.log('Selected L2:', item);
  };

  const handleL3RowClick = (item) => {
    setSelectedL3(item);
    setL3Name(item.L3); // Set the L3 name in the input field
    console.log('Selected L3:', item);
  };

  const handleAddL2 = () => {
    const newL2Item = { L2: L2Name, instituteId: instituteId }; // Adjust as needed
    axios.post(`${apiHostLink}L2Template_/AddL2Click`, newL2Item)
      .then(response => {
        setL2([...L2Item, newL2Item]); // Update the state to include the new item
        setSelectedL2(newL2Item); // Select the new item
        setL2Name(''); // Clear the input field
        console.log('L2 item added:', response.data);
      })
      .catch(error => {
        console.error('There was an error adding the L2 item!', error);
      });
  };


  const handleAddL3 = () => {
    const newL3Item = { L2: L2Name, L3: L3Name, instituteId: instituteId }; // Adjust as needed
    axios.post(`${apiHostLink}L2Template_/AddL3Click`, newL3Item)
      .then(response => {
        setL3([...L3Item, newL3Item]); // Update the state to include the new item
        setSelectedL3(newL3Item); // Select the new item
        setL3Name(''); // Clear the input field
        console.log('L3 item added:', response.data);
      })
      .catch(error => {
        console.error('There was an error adding the L3 item!', error);
      });
  };


  return (
    <div>
      <UsersSidebar />
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '190px' : '60px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(45vw - 50px)' : 'calc(40vw - 20px)' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div style={{ display: 'flex' }}>
          <div style={{ backgroundColor: '#d4e3ea', width: '250px', height: '400px', marginTop: '30px', border: '5px double white', borderRadius: '4px' }}>
            <div className="table-container" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '290px', width: '220px', backgroundColor: '#f0f0f0', border: '1px solid #688caf' }}>
              <table>
                <thead style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                  <tr>
                    <th style={{ backgroundColor: '#f2f9fc', width: '222px', textAlign: 'left' }}>Main</th>
                  </tr>
                </thead>
                <tbody>
                  {L2Item.map((item) => (
                    <tr
                      key={item.L2}
                      onClick={() => handleL2RowClick(item)}
                      style={{ cursor: 'pointer', backgroundColor: selectedL2 === item ? '#d7fba0' : '' }}
                    >
                      <td>{item.L2}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <label style={{ fontSize: '12px', padding: '10px' }}> Name
              <input
                type="text"
                value={L2Name}
                onChange={(e) => setL2Name(e.target.value)}
                style={{ width: '170px', marginLeft: '10px', height: '23px' }}
              />
            </label>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <button className="gradientButton" style={{ marginLeft: '5px', padding: '2px', paddingLeft: '5px', paddingRight: '5px' }} onClick={handleAddL2}>Add</button>
              <button className="gradientButton" style={{ marginLeft: '5px', padding: '2px', paddingLeft: '5px', paddingRight: '5px' }}>Update</button>
              <button className="gradientButton" style={{ marginLeft: '5px', padding: '2px', paddingLeft: '5px', paddingRight: '5px' }}>Delete</button>
              <select style={{ marginLeft: '5px', borderRadius: '2px', backgroundColor: '#f0f0f0', fontSize: '12px' }}>
                <option>Select</option>
                <option>Male</option>
                <option>Female</option>
                <option>Both</option>
              </select>
            </div>
          </div>

          <div style={{ backgroundColor: '#d4e3ea', width: '320px', height: '400px', marginTop: '30px', border: '5px double white', borderRadius: '4px', marginLeft: '15px' }}>
            <div className="table-container" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '285px', width: '290px', backgroundColor: '#f0f0f0', border: '1px solid #688caf' }}>
              <table>
                <thead style={{ position: 'sticky' }}>
                  <tr>
                    <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray' }}>Sub</th>
                    <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Gender</th>
                  </tr>
                </thead>
                <tbody>
                  {L3Item.map((item) => (
                    <tr
                      key={item.L3}
                      onClick={() => handleL3RowClick(item)}
                      style={{ cursor: 'pointer', backgroundColor: selectedL3 === item ? '#d7fba0' : "" }}
                    >
                      <td>{item.L3}</td>
                      <td>{item.Gender}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>
              <label style={{ fontSize: '12px' }}> Name:
                <input
                  type="text"
                  value={L3Name}
                  onChange={(e) => setL3Name(e.target.value)}
                  style={{ width: '230px', marginLeft: '10px', height: '24px', marginTop: '10px' }}
                />
              </label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
              <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddL3}>Add</button>
              <button className="gradientButton" style={{ marginLeft: '10px' }}>Update</button>
              <button className="gradientButton" style={{ marginLeft: '10px' }}>Delete</button>
              <button className="gradientButton" style={{ marginLeft: '10px' }}>Update</button>
            </div>
          </div>

          <div style={{ backgroundColor: '#d4e3ea', width: '425px', height: '60px', marginTop: '440px', marginLeft: '-590px', border: '5px double white', borderRadius: '6px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '13px' }}>
              <label htmlFor="fileInput" className="import-button gradientButton" style={{ color: 'black', padding: '10px', paddingBottom: '0px', paddingTop: '3px', marginLeft: '10px', cursor: 'pointer' }}>
                Import (.xls)
              </label>
              <input type="file" id="fileInput" style={{ display: 'none' }} />
              <button className="gradientButton" style={{ marginLeft: '10px', padding: '2px' }}>Export(.csv)</button>
              <button className="gradientButton" style={{ marginLeft: '10px', padding: '2px' }}>Upload2GDrive</button>
              <button className="gradientButton" style={{ marginLeft: '10px', padding: '2px' }}>Update Templates From Files</button>
            </div>
          </div>

          <div style={{ backgroundColor: '#d4e3ea', width: '170px', height: '50px', marginTop: '440px', marginLeft: '0px', border: '5px double white', borderRadius: '6px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '7px' }}>
              <button className="gradientButton" style={{ marginLeft: '10px', padding: '2px' }}>Bulk Template Processor</button>
            </div>
          </div>

          <div style={{ backgroundColor: '#d4e3ea', width: '600px', height: '90px', marginTop: '510px', marginLeft: '-595px', border: '5px double white', borderRadius: '6px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '7px' }}>
              <label style={{ fontSize: '12px', marginLeft: '10px' }}> Start
                <input type="text" style={{ width: '230px', marginLeft: '10px', height: '50px', marginTop: '10px' }} />
              </label>
              <label style={{ fontSize: '12px', marginLeft: '10px' }}> End
                <input type="text" style={{ width: '230px', marginLeft: '10px', height: '50px', marginTop: '10px' }} />
              </label>
            </div>
          </div>

          <div style={{ backgroundColor: '#d4e3ea', width: '120px', height: '45px', marginTop: '605px', marginLeft: '-600px', border: '5px double white', borderRadius: '6px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '7px' }}>
              <select style={{ marginLeft: '10px', borderRadius: '2px', backgroundColor: '#f0f0f0', fontSize: '12px', width: '90px' }}>
                <option>2Level</option>
              </select>
            </div>
          </div>

          <div style={{ backgroundColor: '#d4e3ea', width: '225px', height: '45px', marginTop: '605px', marginLeft: '0px', border: '5px double white', borderRadius: '6px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
              <button className="gradientButton" style={{ marginLeft: '5px', padding: '2px' }}>Update Organ Addin From Google Sheet</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;
