import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UsersSidebar from './UsersSidebar';

const DoctorReporting = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [image, setImage] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // Track the selected row index
  const [showDeleteButton, setShowDeleteButton] = useState(false); // State to control showing/hiding delete button
  const instituteId = localStorage.getItem("instituteId");
  const userId = localStorage.getItem("userId");
  const apiHostLink = localStorage.getItem("apiHostLink");
  const [doctorsReporting, setDoctorsReporting] = useState([]);

  useEffect(() => {
    fetchDoctorsReporting();
  }, []);

  const fetchDoctorsReporting = async () => {
    try {
      const response = await axios.post(`${apiHostLink}Fill_Reporting_Doctors_/DefaultValue`, {
        instituteId: instituteId,
        userId: userId,
      });

      console.log(response.data);
      setDoctorsReporting(response.data);
    } catch (error) {
      console.error('Error fetching Doctors detail Options:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleRowClick = (index) => {
    setSelectedDoctor(doctorsReporting[index]);
    setSelectedRowIndex(index);
  };

  const handleUpdateButtonClick = (event) => {
    if (event.ctrlKey) {
      setShowDeleteButton(!showDeleteButton); // Toggle the state to show/hide delete button
    }
    
  };


  return (
    <div style={{ width: '50vw', overflowx: 'hidden', marginLeft: isSidebarOpen ? '190px' : '65px', transition: 'margin-left 0.3s ease-in-out' }}>
      <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div>
        <div style={{ marginTop: '25px', overflowX: 'auto', overflowY: 'auto', height: '200px', backgroundColor: '#f0f0f0', border: '1px solid skyblue', width: isSidebarOpen ? 'calc(90vw - 200px)' : 'calc(90vw - 100px)', transition: 'width 0.3s ease-in-out', marginRight: '20px' }}>
          <table className="user-table" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title="DoctorName">DoctorName</th>
                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title="Designation">Designation</th>
                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title="RegNo">RegNo</th>
                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title="SpecialityType">SpecialityType</th>
                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title="Degree">Degree</th>
                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title="ShortName">ShortName</th>
                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title="DigiSignFile">DigiSignFile</th>
                <th style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title="DoctorName">SignAlign</th>
              </tr>
            </thead>
            <tbody>
              {doctorsReporting.map((doctor, index) => (
                <tr key={index} style={index === selectedRowIndex ? { backgroundColor: '#d7fba0' } : {}} onClick={() => handleRowClick(index)}>
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.DoctorName}>{doctor.DoctorName}</td>
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.Designation}>{doctor.Designation}</td>
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.RegNo}>{doctor.RegNo}</td>
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.SpecialityType}>{doctor.SpecialityType}</td>
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.Degree}>{doctor.Degree}</td>
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.ShortName}>{doctor.ShortName}</td>
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.DigiSignFile}>{doctor.DigiSignFile}</td>
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }} title={doctor.SignAlign}>{doctor.SignAlign}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {selectedDoctor && (
          <div style={{ width: '650px', height: 'auto', marginTop: '10px', padding: '10px', border: '1px solid skyblue', backgroundColor:'#d4e3ea', border:'4px double white', borderRadius:'7px' }}>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <label style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>Doctor Name:</label>
              <input type="text" style={{ width: '200px', height: '30px' }} value={selectedDoctor.DoctorName} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, DoctorName: e.target.value })} />
              <label style={{ marginLeft: '25px', whiteSpace: 'nowrap' }}>Speciality Type:</label>
              <input type="text" style={{ width: '200px', height: '30px', marginLeft: '10px' }} value={selectedDoctor.SpecialityType} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, SpecialityType: e.target.value })} />
            </div>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <label style={{ whiteSpace: 'nowrap' }}>Designation:</label>
              <input type="text" style={{ width: '200px', height: '30px', marginLeft: '16px' }} value={selectedDoctor.Designation} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, Designation: e.target.value })} />
              <label style={{ whiteSpace: 'nowrap', marginLeft: '25px' }}>Degree:</label>
              <input type="text" style={{ width: '200px', height: '30px', marginLeft: '53px' }} value={selectedDoctor.Degree} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, Degree: e.target.value })} />
            </div>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <label style={{ whiteSpace: 'nowrap' }}>RegNo:</label>
              <input type="text" style={{ width: '200px', height: '30px', marginLeft: '44px' }} value={selectedDoctor.RegNo} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, RegNo: e.target.value })} />
              <label style={{ whiteSpace: 'nowrap', marginLeft: '23px' }}>ShortName:</label>
              <input type="text" style={{ width: '200px', height: '30px', marginLeft: '30px' }} value={selectedDoctor.ShortName} onChange={(e) => setSelectedDoctor({ ...selectedDoctor, ShortName: e.target.value })} />
            </div>
          </div>
        )}

        <div style={{ display: 'flex', marginTop:'10px' }}>
          <div style={{ width: 'auto', height: 'auto' }}>
            <input
              type="file"
              accept=".png"
              style={{ border: '1px dotted black' }}
              onChange={handleImageUpload}
            />
          </div>
          <div style={{ width: '320px', height: '110px', border: '2px solid black', display: 'flex', marginLeft: '10px' }}>
            {image && <img src={image} alt="Imported" style={{ marginLeft: '4px', marginTop: '3px', width:'400px', height:'100px' }} />}
          </div>
          <label style={{ marginTop: '80px', marginLeft: '10px' }}>.png</label>
        </div>

        <div style={{ display: 'flex', marginTop: '30px' }}>
          <label>Show Digital Sign In-None/Report/PDF:</label>

          <label style={{ marginLeft: '10px' }}>
            <input type="radio" style={{ marginRight: '5px' }} />None
          </label>
          <label style={{ marginLeft: '10px' }}>
            <input type="radio" style={{ marginRight: '5px' }} />Report
          </label>
          <label style={{ marginLeft: '10px' }}>
            <input type="radio" style={{ marginRight: '5px' }} />PDF
          </label>
        </div>

        <div>
          <button className="gradientButton" style={{ marginTop: '20px', marginLeft: '100px' }}>Add</button>
          <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleUpdateButtonClick}>Update</button>
          {showDeleteButton && <button className="gradientButton" style={{ marginLeft: '10px' }}>Delete</button>}
        </div>

      </div>
    </div>
  );
};

export default DoctorReporting;
