import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ImgViewer() {
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const accNumWithSymbols = params.get('an');
    //http://10.91.1.26:85/viewImages?an=@@accession@@&user=rad&getPdf=false

    if (accNumWithSymbols) {
      const accNum = accNumWithSymbols.replace(/@/g, ''); // Removing all @ symbols
      //alert("Hello, " + accNum + "!");
      getImgLink(accNum);
    } else {
      alert('Accession number is missing.');
      console.error('Accession number is missing.');
    }

  }, []);

  const getImgLink = async (accNum) => {
    try {
      const response = await axios.post(`${window.AppSettings.ApiHostLink}Study/Get_ImgLink_From_AccNo`, { accessionNumber: accNum, UserId: userId });
      const resp = response.data;
      window.open(resp, '_self');
    } catch (error) {
      console.error('Error creating report:', error);
    }
  }

  return (
    <p> Welcome to pCare imgViewer page</p>
  );
}

export default ImgViewer;
