import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'

export default function TelegramLogin() {
  return (
    <div className="container-fluid d-flex p-0"> {/* Use d-flex for flexbox layout */}

      {/*Sidebar */}
      <Sidebar />
      <div className="d-flex justify-content-start flex-column w-100 mt-4 align-items-start " style={{ marginLeft: '-1080px' }}>
        <form>
          <div className="d-flex">
            <label htmlFor="exampleInputEmail1" className="form-label" style={{ marginRight: '5px' }} >ApiId: </label>
            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginRight: '50px', width:'200px' }} />
            <label htmlFor="exampleInputEmail1" className="form-label" style={{ marginRight: '5px' }} >ApiHash: </label>
            <input type="email" className="form-control" aria-describedby="emailHelp" style={{width:'300px' }} />
          </div>
          <div className="d-flex mt-5">
            <label htmlFor="exampleInputEmail1" className="form-label" style={{ marginRight: '5px' }} >Mobile No: </label>
            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginRight: '50px', width: '200px' }} />
            <button className="btn btn-success" style={{ marginRight: '30px' }}>Log-In</button>
            <button className="btn btn-danger" style={{ marginRight: '5px' }}>Close</button>
          </div>
          <div className="d-flex mt-5">
            <label htmlFor="exampleInputEmail1" className="form-label" style={{ marginRight: '5px', marginTop:'10px' }} >DestinationInfo: </label>
          </div>
        </form>
      </div>
    </div>
  )
}
