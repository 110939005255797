import React, { useState } from 'react';
import UsersSidebar from './UsersSidebar';


const Expenses = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <UsersSidebar />
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '100px' : '10px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(100vw - 50px)' : 'calc(100vw - 20px)' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div style={{ backgroundColor: '#d4e3ea', width: '650px', height: '100px', marginTop: '20px', marginLeft: '125px', border: '5px double white', borderRadius: '6px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '27px' }}>

          <div>
            <label style={{marginLeft:'10px'} }>From
              <input className="form-control" type="date" style={{ width: '100px', height: '30px', fontSize:'12px' }}></input>
            </label>
          </div>
          <div>
            <label style={{ marginLeft: '10px' }}>To
              <input className="form-control" type="date" style={{ width: '100px', height: '30px', fontSize: '12px' }}></input>
            </label>
          </div>
         

          <div style={{ marginTop:'-15px' }}>
            <button className="gradientButton" style={{ marginLeft: '10px' }}>Export(.csv)</button><br />
            <button className="gradientButton" style={{ marginLeft: '10px', marginTop:'15px' }}>Export(.csv)</button>
          </div>

          <label style={{marginLeft:'20px', marginTop:'-10px'} }>Total: </label>
          <label style={{ marginLeft: '70px', marginTop: '-10px' }}>Cash: </label>
          <label style={{ marginLeft: '-40px', marginTop: '30px' }}> Digital: </label>
        </div>
      </div>

      <div style={{ backgroundColor: '#f0f0f0', width: '750px', height: '290px', marginTop: '30px', borderRadius: '2 px', marginLeft:'100px' }}>

        <div className="table-container" style={{ overflowX: 'auto', overflowY: 'auto', height: '290px', width: 'auto' }}>
          <table>
            <thead style={{ position: 'sticky', top: '0' }}>
              <tr style={{ backgroundColor: '#e3f7ff' }} >
                <th style={{  width: '300px', textAlign: 'left', paddingLeft: '10px',zIndex:'1' }}>Date</th>
                <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex:'2' }}>Description</th>
                <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex:'3' }}>Amount</th>
                <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex:'4' }}>ByCash</th>
                <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex:'' }}>IsGeneral</th>
              </tr>
            </thead>
            <tbody>
              
            </tbody>
          </table>
        </div>
      </div>

      <div style={{ backgroundColor: '#d4e3ea', width: '430px', height: '150px', marginTop: '20px', marginLeft: '125px', border: '5px double white', borderRadius: '6px' }}>

        <label style={{ fontSize:'12px', marginLeft:'10px', marginTop:'10px' }}>Description
          <input type="text" style={{width:'150px', marginLeft:'10px'} }>
          </input>
        </label>
        <label style={{ fontSize: '12px', marginLeft: '10px', marginTop: '10px' }}>Amount
          <input type="text" style={{ width: '150px', marginLeft: '30px', height:'25px' }}>
          </input>
        </label>

        <button className="gradientButton" style={{ marginLeft: '10px', marginTop: '15px' }}>Add</button>
        <button className="gradientButton" style={{ marginLeft: '10px', marginTop: '15px' }}>Delete</button><br />

        <label style={{ marginLeft: '245px', marginTop:'-110px', fontSize:'12px' }}>
          By: </label>
        <label style={{ marginLeft: '270px', marginTop: '-15px', fontSize: '12px' }}>
          <input type="radio" value="Cash" style={{ marginLeft: '10px', marginRight:'10px' }}></input>
          Cash
        </label>
        <label style={{ marginLeft: '350px', marginTop: '-18px', fontSize: '12px' }}>
          <input type="radio" value="Cash" style={{ marginLeft: '10px', marginRight: '10px' }}></input>
          Cash
        </label>

        <label style={{ marginLeft: '270px', marginTop: '15px', fontSize: '12px' }}>
          <input type="radio" value="Cash" style={{ marginLeft: '10px', marginRight: '10px' }}></input>
          Staff
        </label>
        <label style={{ marginLeft: '350px', marginTop: '-18px', fontSize: '12px' }}>
          <input type="radio" value="Cash" style={{ marginLeft: '10px', marginRight: '10px' }}></input>
          Doctor
        </label>
        <input className="form-control" type="date" style={{ marginLeft: '280px', marginTop: '20px', width: '100px', fontSize:'12px' }}></input>
      </div>
      </div>
    </div>
  )
}

export default Expenses;
