import React, { useState } from 'react';
import UsersSidebar from './UsersSidebar';


const PDFSettings = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <UsersSidebar />
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '60px' : '10px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(100vw - 50px)' : 'calc(100vw - 20px)' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <div style={{marginLeft:'200px'} }>
        {/*XRay Setting*/}
        <div style={{ backgroundColor: '#d4e3ea', width: '550px', height: '100px', marginTop: '20px', marginLeft: '200px', border: '5px double white', borderRadius: '6px', display:'flex' }}>
          <div style={{ display: 'flex', padding: '10px', marginTop: '23px', marginLeft: '30px', border: '4px double white', width:'300px', borderRadius:'4px', height:'50px' }}>
            <label><input type="radio" style={{marginRight:'5px'}} />Top</label>
            <label style={{marginLeft:'20px'} }><input type="radio" style={{ marginRight: '5px' }} />Bottom</label>
            <label style={{ marginLeft: '30px' }}><input type="checkbox" style={{ marginRight: '10px' }} />Show TestName</label>
            
          </div>
          <div style={{ display: 'flex' }}>
            <button className="gradientButton" style={{ marginLeft: '30px', marginTop: '30px' }}>Update</button>
            <label className="gradientButton" style={{ paddingTop: '3px', marginLeft: '15px', marginTop: '30px' }}><input type="file" style={{ display: 'none' }} />Import</label>
            <label className="gradientButton" style={{ paddingTop: '3px', marginLeft: '15px', marginTop: '30px' }}><input type="file" style={{ display: 'none' }} />Export</label>
          </div>
        </div>

        <div style={{ backgroundColor: '#f0f0f0', width: '750px', height: '350px', marginTop: '30px', borderRadius: '2 px', marginLeft: '100px' }}>

          <div className="table-container" style={{ overflowX: 'auto', overflowY: 'auto', height: '350px', width: 'auto' }}>
            <table>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr style={{ backgroundColor: '#e3f7ff' }} >
                  <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '1' }}>TotalBmpCount</th>
                  <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '2' }}>PagesCount</th>
                  <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '3' }}>InitialPageDimX</th>
                  <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '4' }}>InitialPageDimY</th>
                  <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '' }}>IsLandScapeIP</th>
                  <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '' }}>LastPageDimX</th>
                  <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '' }}>LastPageDimY</th>
                  <th style={{ width: '300px', textAlign: 'left', paddingLeft: '10px', zIndex: '' }}>IsLandscapeLP</th>
                </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
          </div>
          </div>
        </div>

        
      </div>
    </div>
  )
}

export default PDFSettings;
