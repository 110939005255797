import React, { useState, useEffect } from 'react';
import UsersSidebar from './UsersSidebar';
import axios from 'axios';

const PFSettings = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const instituteId = localStorage.getItem("instituteId");
  const apiHostLink = localStorage.getItem("apiHostLink");
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");

  const [pfCatId, setPfCatId] = useState(null);
  const [panelId, setPanelId] = useState(null);
  const [modId, setModId] = useState(null);
  const [testId, setTestId] = useState(null);

  const [modBasis, setModBasis] = useState(null);
  const [selectedModBasis, setSelectedModBasis] = useState(null);
  const [modValue, setModValue] = useState('');

  const [testBasis, setTestBasis] = useState(null);
  const [selectedTestBasis, setSelectedTestBasis] = useState(null);
  const [testValue, setTestValue] = useState('');

  const [selectedPFCat, setSelectedPFCat] = useState('');
  const [selectedPanPF, setSelectedPanPF] = useState('');
  const [selectedModPF, setSelectedModPF] = useState('');
  const [selectedTestPF, setSelectedTestPF] = useState('');
  
  const [isReportingFees, setIsReportingFees] = useState(false);
  const [pfCatAddName, setPfCatAddName] = useState('');

  const [pfCategory, setPfCategory] = useState([]);
  const [panelPf, setPanelPf] = useState([]);
  const [modalityPF, setModalityPF] = useState([]);
  const [testPF, setTestPF] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
 
  useEffect(() => {
    GetData();
  }, []);

  useEffect(() => {
    if (pfCatId !== null && pfCatId !== undefined && panelId !== null && panelId !== undefined && modId !== null && modId !== undefined) {
      getPanelPF();
      getModalityPF();
      getTestPF();
    }
  }, [pfCatId]);

  useEffect(() => {
    if (panelId !== null && panelId !== undefined && modId !== null && modId !== undefined) {
      getModalityPF();
      getTestPF();
    }
  }, [panelId]);

  useEffect(() => {
    if (modId !== null && modId !== undefined) {
      getTestPF();
    }
  }, [modId]);  

  const GetData = async () => {

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/GetData`, {
        instituteId: instituteId,
        userType: userType,
        userId: userId,
      });

      const resp = response.data;
      setPfCategory(JSON.parse(resp.pfCat));
      setPfCatId(JSON.parse(resp.pfCat)[0].Id);
      setSelectedPFCat(JSON.parse(resp.pfCat)[0].Name);

      setPanelPf(JSON.parse(resp.panelPF));
      setPanelId(JSON.parse(resp.panelPF)[0].PanelId);
      setSelectedPanPF(JSON.parse(resp.panelPF)[0].Name);

      setModalityPF(JSON.parse(resp.modalityPF));
      setModId(JSON.parse(resp.modalityPF)[0].ModalityID);
      setSelectedModPF(JSON.parse(resp.modalityPF)[0].ModalityName);

      setTestPF(JSON.parse(resp.testPF));
      setTestId(JSON.parse(resp.testPF)[0].TestID);
      setSelectedTestPF(JSON.parse(resp.testPF)[0].Testname);

      setModBasis(resp.basis);
      setTestBasis(resp.basis);
      console.log("Data");
      console.log(resp);
      /*setPfCategory(resp);
      setPfCatId(resp[0].Id);*/
    } catch (error) {
      console.log(error);
    }
  }

  const getPFCategory = async () => {

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/PFCategory`, {
        instituteId: instituteId,
        userType: userType,
        userId: userId,
      });

      const resp = response.data;
      console.log("PFCategory Data");
      console.log(resp);
      setPfCategory(resp);
    } catch (error) {
      console.log(error);
    }
  }

  const getPanelPF = async () => {

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/PanelPF`, {
        instituteId: instituteId,
        userType: userType,
        pfCatId: pfCatId,
        userId: userId
      });

      const resp = response.data;
      console.log("PanelPF Data");
      console.log(resp);
      setPanelPf(resp);
    } catch (error) {
      console.log(error);
    }
  }

  const getModalityPF = async () => {

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/ModalityPF`, {
        instituteId: instituteId,
        userType: userType,
        pfCatId: pfCatId,
        panelId: panelId,
        userId: userId
      });

      const resp = response.data;
      setSelectedModPF(resp[0].ModalityName);
      console.log("ModalityPF Data");
      console.log(resp);
      setModalityPF(resp);
    } catch (error) {
      console.log(error);
    }
  }

  const getTestPF = async () => {

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/TestPF`, {
        instituteId: instituteId,
        userType: userType,
        pfCatId: pfCatId,
        panelId: panelId,
        modId: modId,
        userId: userId
      });

      const resp = response.data;
      setSelectedTestPF(resp[0].Testname);
      console.log("TestPF Data");
      console.log(resp);
      setTestPF(resp);
    } catch (error) {
      console.log(error);
    }
  }  

  const handlePFCatClick = (userData) => {
    setSelectedPFCat(userData.Name);
    console.log("Selected PF Category Setting Details:", userData);
    setPfCatId(userData.Id);
    setIsReportingFees(userData.IsReportingFees);
  };

  const handlePanPFClick = (userData) => {
    setSelectedPanPF(userData.Name);
    console.log("Selected Panel PF Setting Details:", userData);
    setPanelId(userData.PanelId);
  };

  const handleModPFClick = (userData) => {
    setSelectedModPF(userData.ModalityName);
    console.log("Selected Modality PF Setting Details:", userData);
    setModId(userData.ModalityID);
  };

  const handleTestPFClick = (userData) => {
    setSelectedTestPF(userData.Testname);
    console.log("Selected Test PF Setting Details:", userData);
    setTestId(userData.TestID);
  };

  const handleModBasisChange = (event) => {
    setSelectedModBasis(event.target.value);
  };

  const handleTestBasisChange = (event) => {
    setSelectedTestBasis(event.target.value);
  };

  const handleMakeReportingFeesClick = async () => {
    if (selectedPFCat.toLowerCase() === "default" && !isReportingFees) {
      alert("Default category cannot be made as Reporting Fees. Kindly select any other PF Category");
      return;
    }

    if (!selectedPFCat) {
      alert("Select a Category First!");
      return;
    }

    const request = {
      instituteId: instituteId,
      pfCatId: pfCatId,
      pfCatName: selectedPFCat,
      isReportingFees: isReportingFees,
      userId: userId
    }
    console.log("Sending request with payload: ");
    console.log(request);

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/MakeReportingFees`, request);

      const resp = response.data;
      console.log(resp);
      getPFCategory();
      alert(resp);
    } catch (error) {
      console.log(error);
    }
  }

  const handlePfCatAddNameChange = (event) => {
    setPfCatAddName(event.target.value);
  };

  const handleModPFValueChange = (event) => {
    setModValue(event.target.value);
  };

  const handleTestPFValueChange = (event) => {
    setTestValue(event.target.value);
  };
  
  const handlePfCatAddClick = async () => {
    if (!pfCatAddName) {
      alert("Enter a PF Category Name First");
      return;
    }

    const exists = pfCategory.some(category => category.Name.toLowerCase() === pfCatAddName.toLowerCase());

    if (exists) {
      alert(`The category '${pfCatAddName}' already exists.`);
      return;
    }

    const request = {
      instituteId: instituteId,
      pfCatAddName: pfCatAddName,
      userId: userId,
    }

    console.log("Sending request with payload: ");
    console.log(request);

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/AddCat`, request);

      const resp = response.data;
      console.log(resp);
      getPFCategory();
      alert(resp);
      setPfCatAddName('');
    } catch (error) {
      console.log(error);
    }
  }
  
  const handlePfCatDeleteClick = async () => {
    if (!pfCatId) {
      return;
    }

    const confirmDelete = window.confirm("Are you sure to Delete PFCat : " + selectedPFCat);
    if (!confirmDelete) {
      return;
    }

    const request = {
      instituteId: instituteId,
      pfCatId: pfCatId,
      userId: userId
    }

    console.log("Sending request with payload: ");
    console.log(request);

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/DeleteCatPF`, request);

      const resp = response.data;
      console.log(resp);
      getPFCategory();
      alert(resp);
    } catch (error) {
      console.log(error);
    }
  }

  const handleAddPanelPFClick = async () => {
    if (!pfCatId || !pfCatId) {
      return;
    }

    const request = {
      instituteId: instituteId,
      pfCatId: pfCatId,
      panelId: panelId,
      userId: userId
    }

    console.log("Sending request with payload: ");
    console.log(request);

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/AddPanelPF`, request);

      const resp = response.data;
      console.log(resp);
      getPFCategory();
      getPanelPF();
      alert(resp);
    } catch (error) {
      console.log(error);
    }
  }

  const handleUpdateModalityPFClick = async () => {
    if (!selectedModPF) {
      return;
    }

    if (!modValue) {
      alert('Enter a Modality Value');
      return;
    }

    const request = {
      instituteId: instituteId,
      pfCatId: pfCatId,
      modId: modId,
      modValue: modValue,
      modBasis: selectedModBasis,
      panelId: panelId,
      userId: userId
    }

    console.log("Sending request with payload: ");
    console.log(request);

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/UpdateModalityPF`, request);

      const resp = response.data;
      console.log(resp);
      getModalityPF();
      alert(resp);
      setModValue('');
    } catch (error) {
      console.log(error);
    }
  }

  const handleUpdateTestPFClick = async () => {
    if (!selectedTestPF) {
      return;
    }

    if (!testValue) {
      alert('Enter a Test Value');
      return;
    }

    const request = {
      instituteId: instituteId,
      pfCatId: pfCatId,
      testId: testId,
      testValue: testValue,
      testBasis: selectedTestBasis,
      panelId: panelId,
      userId: userId
    }

    console.log("Sending request with payload: ");
    console.log(request);

    try {
      const response = await axios.post(`${apiHostLink}PFSettings_/UpdateTestPF`, request);

      const resp = response.data;
      console.log(resp);
      getTestPF();
      alert(resp);
      setTestValue('');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <UsersSidebar />
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '190px' : '60px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(100vw - 50px)' : 'calc(40vw - 20px)' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div style={{ display: 'flex' }}>

        {/*PF Category*/}
          <div style={{ backgroundColor: '#d4e3ea', width: '250px', height: '400px', marginTop: '30px', border: '5px double white', borderRadius: '4px' }}>

            <div className="table-container" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '290px', width: '220px', backgroundColor: '#f0f0f0', border: '1px solid #688caf' }}>
              <table>
                <thead style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                  <tr>
                    <th style={{ backgroundColor: '#f2f9fc', width: '222px', textAlign: 'left' }}>Name</th>

                  </tr>
                </thead>
                <tbody>
                  {pfCategory.map((pfCategory, index) => (
                    <tr key={index} onClick={() => handlePFCatClick(pfCategory)} style={{ cursor: 'default', backgroundColor: selectedPFCat === pfCategory.Name ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                      <td style={{ color: pfCategory.IsReportingFees ? 'green' : 'black' }}>{pfCategory.Name}</td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <button className="gradientButton" onClick={handleMakeReportingFeesClick} style={{ marginLeft: '15px', padding: '2px', paddingLeft: '5px', paddingRight: '5px', marginTop: '10px' }}>{isReportingFees ? "RemoveReportingFees" : "MakeReportingFees"}</button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop:'10px' }}>
              <label style={{ fontSize: '12px', marginLeft:'10px' }}> Name</label>
              <input type="text" style={{ width: '100px', height: '23px', marginLeft: '5px' }} value={pfCatAddName} onChange={handlePfCatAddNameChange} />
              <button className="gradientButton" onClick={handlePfCatAddClick} style={{marginLeft:'10px'} }>Add</button>
            </div>
          </div>

          {/*PanelPF*/}
          <div style={{ backgroundColor: '#d4e3ea', width: '200px', height: '400px', marginTop: '30px', border: '5px double white', borderRadius: '4px', marginLeft: '15px', padding: '10px', paddingLeft:'0px' }}>

            <div style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '330px', width: 'auto', backgroundColor: '#f0f0f0', border: '1px solid #688caf' }}>
              <table>
                <thead style={{ position: 'sticky' }}>
                  <tr>
                    <th style={{ backgroundColor: '#f2f9fc', width: '100px', border: '1px solid slategray' }}>PanelName</th>
                  </tr>
                </thead>
                <tbody> 
                  {panelPf.map((panelPf, index) => (
                    <tr key={index} onClick={() => handlePanPFClick(panelPf)} style={{ cursor: 'default', backgroundColor: selectedPanPF === panelPf.Name ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                      <td>{panelPf.Name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
              <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleAddPanelPFClick}>Add TestPF</button>
            
            </div>
          </div>

          {/*ModalityPF*/}
          <div style={{ backgroundColor: '#d4e3ea', width: '320px', height: '400px', marginTop: '30px', border: '5px double white', borderRadius: '4px', marginLeft: '15px' }}>

            <div className="table-container" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '340px', width: '290px', backgroundColor: '#f0f0f0', border: '1px solid #688caf' }}>
              <table>
                <thead style={{ position: 'sticky' }}>
                  <tr>
                    <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray' }}>ModalityName</th>
                    <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Value</th>
                    <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Basis</th>
                  </tr>
                </thead>
                <tbody>
                  {modalityPF.map((modalityPF, index) => (
                    <tr key={index} onClick={() => handleModPFClick(modalityPF)} style={{ cursor: 'default', backgroundColor: selectedModPF === modalityPF.ModalityName ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                      <td>{modalityPF.ModalityName}</td>
                      <td>{modalityPF.Value}</td>
                      <td>{modalityPF.Basis === "" ? "None" : modalityPF.Basis}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>
              <label style={{ fontSize: '12px' }}> Value:
                <input type="text" value={modValue} onChange={handleModPFValueChange} style={{ width: '70px', marginLeft: '10px', height: '24px', marginTop: '10px' }} />
              </label>

              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', marginLeft:'10px' }}>
                <label>Basis</label>
                <select style={{ width: '100px', marginLeft: '5px' }} value={selectedModBasis}  onChange={handleModBasisChange}>
                  {modBasis && modBasis.map(option => (
                    <option key={option} value={option} >{option}</option>
                  ))}
                </select>
                <button className="gradientButton" onClick={handleUpdateModalityPFClick} style={{ marginLeft: '10px' }}>Update</button>
              </div>
              
            </div>
          </div>

          {/*TestPF*/}
          <div style={{ backgroundColor: '#d4e3ea', width: '320px', height: '400px', marginTop: '30px', border: '5px double white', borderRadius: '4px', marginLeft: '15px' }}>

            <div className="table-container" style={{ marginLeft: '10px', marginTop: '5px', overflowX: 'auto', overflowY: 'auto', height: '340px', width: '290px', backgroundColor: '#f0f0f0', border: '1px solid #688caf' }}>
              <table>
                <thead style={{ position: 'sticky' }}>
                  <tr>
                    <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray' }}>TestName</th>
                    <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray' }}>Price</th>
                    <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Value</th>
                    <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Basis</th>
                    <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Exposure</th>
                  </tr>
                </thead>
                <tbody> 
                  {testPF.map((testPF, index) => (
                    <tr key={index} onClick={() => handleTestPFClick(testPF)} style={{ cursor: 'default', backgroundColor: selectedTestPF === testPF.Testname ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                      <td>{testPF.Testname}</td>
                      <td>{testPF.Price}</td>
                      <td>{testPF.Value}</td>
                      <td>{testPF.basis}</td>
                      <td>{testPF.Exposure}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>
              <label style={{ fontSize: '12px' }}> Value:
                <input type="text" value={testValue} onChange={handleTestPFValueChange} style={{ width: '70px', marginLeft: '10px', height: '24px', marginTop: '10px' }} />
              </label>

              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', marginLeft: '10px' }}>
                <label>Basis</label>
                <select style={{ width: '100px', marginLeft: '5px' }} value={selectedTestBasis} onChange={handleTestBasisChange}>
                  {testBasis && testBasis.map(option => (
                    <option key={option} value={option} >{option}</option>
                  ))}
                </select>
                <button className="gradientButton" style={{ marginLeft: '10px' }} onClick={handleUpdateTestPFClick}>Update</button>
              </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}
export default PFSettings;

