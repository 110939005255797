import React, { useState } from 'react';
import './Info_Display.css';

const Info_Display = () => {
  



  return (
    <>
      <div style={{ overflow: 'hidden', transition: 'margin-left 0.3s ease', marginTop: '-15px', userSelect:'none' }}>
        <label style={{
          textAlign: 'center',
          fontSize: '4.7vw',
          fontFamily: 'initial',
          fontWeight: '900',
          background: `linear-gradient(to top, DarkBlue, Blue)`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}>
          Arogyaneer Multi-Speciality Hospital
        </label>

        <div className="info-disp-tbl-container" style={{ marginTop: '0px', display: 'flex', flexDirection: 'column', height: '87vh' }}>
          <table style={{ flex: 1, width: '100%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th style={{ fontSize: '60px', textAlign: 'center', fontFamily: 'initial', fontWeight: '900', width: '11%', whiteSpace: 'nowrap' }}>Room No.</th>
                <th style={{ fontSize: '70px', textAlign: 'center', fontFamily: 'initial', fontWeight: '900', width: '49%', whiteSpace: 'nowrap' }}>Doctor</th>
                <th style={{ fontSize: '70px', textAlign: 'center', fontFamily: 'initial', fontWeight: '900', width: '29%', whiteSpace: 'nowrap' }}>Speciality</th>
                <th style={{ fontSize: '60px', textAlign: 'center', fontFamily: 'initial', fontWeight: '900', width: '11%', whiteSpace: 'nowrap' }}>Token No.</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ padding:'0px'} }>
                <td style={{ fontSize: '70px', textAlign: 'left', fontWeight: '500', paddingLeft: '10px' }}>101</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>Dr. Mamta Pandey</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>X-RAY</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>22</td>
              </tr>
              <tr>
                <td style={{ fontSize: '70px', textAlign: 'left', fontWeight: '500', paddingLeft: '10px' }}>102</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>Dr. Preeti Pandey</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>CT-MRI</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>30</td>
              </tr>
              <tr>
                <td style={{ fontSize: '70px', textAlign: 'left', fontWeight: '500', paddingLeft:'10px'}}>103</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>Dr. Aniket Pandey</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>USG</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>8</td>
              </tr>
              <tr>
                <td style={{ fontSize: '70px', textAlign: 'left', fontWeight: '500', paddingLeft: '10px' }}>104</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>Dr. S.S. Samaiyar</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>OBS</td>
                <td style={{ fontSize: '70px', textAlign: 'center', fontWeight: '500'}}>10</td>
              </tr>
            </tbody>
          </table>
        </div>


      </div>
    </>
  );
};

export default Info_Display;
