import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import UsersTable from './UsersTable';

export default function Home() {
  return (
    <div className="container-fluid d-flex p-0">
      <Sidebar />
      <div className="text-top-center w-100 mt-5" style={{ marginRight: '300px', whiteSpace: 'nowrap' }}>
        <h1 style={{
          animationName: 'disco',
          animationDuration: '1s',
          animationIterationCount: 'infinite'
        }}><b>WELCOME TO THE CAS DASHBOARD</b></h1>
      </div>
      <style>
        {`
          @keyframes disco {
            0% { color: red; }
            25% { color: blue; }
            50% { color: green; }
            75% { color: yellow; }
            100% { color: magenta; }
          }
        `}
      </style>
    </div>
  );
}

