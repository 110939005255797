import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Login.css";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [version, setVersion] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    //fetchApiHostLink();
    localStorage.setItem("apiHostLink", window.AppSettings.ApiHostLink);
    const savedUsername = localStorage.getItem("username");
    const savedPassword = localStorage.getItem("password");

    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
    }
    fetchVersion();
  }, []);

  const fetchVersion = async () => {
    try {
      const response = await axios.get(`${window.AppSettings.ApiHostLink}VersionFetching_/getVersion`);
      setVersion(response.data);
    } catch (error) {
      console.error("Error fetching version:", error);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Send authentication request to backend
      const response = await axios.post(`${window.AppSettings.ApiHostLink}Users/authenticate`, {
        UserName: username,
        UserPassword: password,
      });

      // If authentication successful, navigate to home page
      navigate("/pcarehome");

      // Save username and password to local storage
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
      localStorage.setItem("instituteId", response.data.instituteId);
      localStorage.setItem("templateLevel", response.data.templateLevel);
      localStorage.setItem("imgWebLinkAndPort", response.data.imgWebLinkAndPort);
      localStorage.setItem("userId", response.data.userId);
      localStorage.setItem("sessionUid", response.data.sessionUid);
      localStorage.setItem("instituteName", response.data.instituteName);
      localStorage.setItem("userType", response.data.userType);
      localStorage.setItem("showHomeTableSerialNo", response.data.showHomeTableSerialNo);
      localStorage.setItem("isShowPatientNameTitle", response.data.isShowPatientNameTitle); // Save the setting

      const userPermissionsString = JSON.stringify(response.data.perm);
      sessionStorage.setItem('userPermissions', userPermissionsString);

      //localStorage.setItem("DBConfig", JSON.stringify(response.data.DBConfig));

    } catch (error) {

      // If authentication fails, display error message
      if (error.message === 'Request failed with status code 400') {
        if (error.response.data.includes('RunDesktop')) {
          alert(error.response.data);
        } else {
          alert('Invalid Username or Password');
        }
      } else if (error.message === 'Request failed with status code 500') {
        alert('Database login failed. Kindly check sql connection string');//
      } else if (error.message.includes("Network error")) {
        alert(error + " \n\n Backend connection failed");
      } else {
        alert(error);
        console.log(error);
      }
      //setError("Incorrect username or password");
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        <label>{version}</label>
        <div className="form-group">
          <label htmlFor="username" style={{ width:'100%' }}>Username<br />
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            required
            /></label>
        </div>
        <div className="form-group">
          <label htmlFor="password" style={{ width: '100%' }}>Password<br/>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            /></label>
        </div>
        <button type="submit" className="btn-login">
          Login
        </button>
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
}

export default Login;
