import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar';

export default function SoapApi() {
  return (
    <div className="container-fluid d-flex p-0" style={{position:'fixed'} }> {/* Use d-flex for flexbox layout */}
      <Sidebar />
      <div className="d-flex justify-content-start flex-column w-100 mt-4 align-items-start " style={{ marginLeft: '-1000px' }}>
        <button className="btn btn-primary mb-3 align-self-start">Send</button> {/* Use text-left class */}
        <textarea className="form-control text-left" style={{ width: '80%' }} rows="5" placeholder="Enter your description here..."></textarea>
      </div>
    </div>
  );
}