import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { To } from '../../../../node_modules/react-router-dom/dist/index';


function CheckboxesDropdown({ options, selectedOptions, handleSelectOption }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (isOpen && !event.target.closest('.dropdown')) {
        setIsOpen(false);
      }
    }
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="dropdown" style={{ position: 'relative', width: '100px', height: 'auto', marginTop: '15px', marginLeft: '4px' }}>
      <button className="btn btn-light dropdown-toggle" type="button" onClick={toggleDropdown} style={{ width: '100%', fontSize: '12px' }}>
        Select Options
      </button>
      {isOpen && (
        <div className="dropdown-menu" style={{ display: 'block', width: '100px', maxHeight: '200px', overflowY: 'auto' }}>
          {options.map((option, index) => (
            <div key={index} className="form-check">
              <input
                type="checkbox"
                id={`checkbox${index}`}
                className="form-check-input"
                value={option}
                checked={selectedOptions.includes(option)}
                onChange={() => handleSelectOption(option)}
              />
              <label htmlFor={`checkbox${index}`} className="form-check-label" style={{ marginLeft: '5px' }}>{option}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default function Checkaccounts() {
  const [modalityList, setModalityList] = useState([]);
  const [selectedModality, setSelectedModality] = useState('');
  const [paymentOptions, setPaymentOptions] = useState([]);
  const instituteId = localStorage.getItem("instituteId");
  const apiHostLink = localStorage.getItem("apiHostLink");
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
  const [userName, setUserName] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState('');
  var [fromDate, setFromDate] = useState(new Date());
  var [toDate, setToDate] = useState(new Date());
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [searchedData, setSearchedData] = useState([]);
  const [panelList, setPanelList] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState('');
  const [patientOptions, setPatientOptions] = useState([]);
  const [reportType, setReportType] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState('');
  const [totalAmountPaid, setTotalAmountPaid] = useState("0");
  const [totalCashPaid, setTotalCashPaid] = useState("0");
  const [totalDigitalPaid, setTotalDigitalPaid] = useState("0");
  const [totalPending, setTotalPending] = useState("0");
  const [payment, setPayment] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const loggedInUserType = localStorage.getItem("userType");
  const loggedInUsername = localStorage.getItem("username");
  const userId = localStorage.getItem("userId");
  const instituteName = localStorage.getItem("instituteName");
  const DateTime = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 16);
  const [goBackInTime, setGoBackInTime] = useState(true);
  const [showPendingPayment, setShowPendingPayment] = useState(true);
  const [dateTime, setDateTime] = useState(new Date());
  const [time, setTime] = useState(dateTime.toISOString().slice(11, 16));
  const [pdfColumns, setPdfColumns] = useState([]);
  const [selectedPdfColumns, setSelectedPdfColumns] = useState('');
  const [disable, setDisable] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  //let AccountTypeStr = localStorage.getItem("AccountTypeStr");

  /*if (AccountTypeStr === 'undefined' || AccountTypeStr === null) {
    localStorage.setItem("AccountTypeStr", "[]");
    AccountTypeStr = "[]";
  }*/
   
  //useEffect(() => {
  //  const localStorageArray = JSON.parse(AccountTypeStr);
  //  setSelectedPdfColumns(localStorageArray);
  //}, []); 

  const fetchSavedData = async (reportType) => {

    if (["DailyAccounts", "RefPhyWise", "CustomReport"].includes(reportType)) {
      try {
        const response = await axios.post(`${apiHostLink}CheckAccount_/FetchSavedData`, {
          instituteId: instituteId,
          ReportType: reportType,
          userId: userId,
        });

        setSelectedPdfColumns(response.data);
        console.log(reportType + " fetched: " + response.data);
      }
      catch (error) {
        console.error('Error fetching Modality data:', error);
      }
    }
  };

  useEffect(() => {
    setTime(dateTime.toTimeString().slice(0, 5));
  }, [dateTime]);

  useEffect(() => {
    const fetchData123 = async () => {
      if (instituteId) {
        //await fetchLastDateWithStudies();
        //await fetchDataWithDates();
        fetchModalityData();
        fetchPaymentOptions();
        fetchUserName();
        fetchPanelData();
        fetchPatientNames();
        fetchReportType();
        fetchPayment();
        //fetchPdfColumns();
        //handleSearch();
      }
    };
    fetchData123();

  }, [instituteId]);

  useEffect(() => {
    if (modalityList.length > 0) {
      // Set selected modality to the first value in the modality list
      setSelectedModality(modalityList[0].modalityName);
    }
  }, [modalityList]);

  useEffect(() => {
    if (panelList.length > 0) {
      setSelectedPanel(panelList[0].name);
    }
  }, [panelList]);

  // useEffect to set selectedPaymentOption to the first payment type when component mounts
  useEffect(() => {
    if (paymentOptions.length > 0) {
      setSelectedPaymentOption(paymentOptions[0].paymentType);
    }
  }, [paymentOptions]);

  useEffect(() => {
    if (paymentOptions.length > 0) {
      setSelectedPaymentOption(paymentOptions[0].paymentType);
    }
  }, [paymentOptions]);

  useEffect(() => {
    if (userName.length > 0) {
      setSelectedUserName(userName[0]);
    }
  }, [userName]);

  useEffect(() => {
    if (selectedReportType) {
      fetchPdfColumns(selectedReportType);
    }
  }, [selectedReportType]);

  const fetchModalityData = async () => {
    try {
      const response = await axios.get(`${apiHostLink}PR_Select_ModalityNameUsingInstituteId_?instituteId=${instituteId}`);
      console.log('Modality data:', response.data);

      const sortedModalityList = response.data.sort((a, b) => {
        if (a.modalityName === 'All') return -1;
        if (b.modalityName === 'All') return 1;
        return a.modalityName.match(/^\d/) ? 1 : -1;
      });

      setModalityList(sortedModalityList);
    } catch (error) {
      console.error('Error fetching Modality data:', error);
    }
  };

  const handleModalityChange = (event) => {
    console.log('Selected Modality:', event.target.value);
    setSelectedModality(event.target.value);
  };





  const fetchPanelData = async () => {
    try {
      const response = await axios.get(`${apiHostLink}Panel_?instituteId=${instituteId}`);
      setPanelList(response.data);
      console.log('Fetched Panel List:', response.data);
    } catch (error) {
      console.error('Error fetching Panel data:', error);
    }
  };

  const handlePanelChange = (event) => {
    setSelectedPanel(event.target.value);
  };






  const fetchPaymentOptions = async () => {
    try {

      const response = await axios.get(`${apiHostLink}PaymentOption_/FillcbPaymentMode?userId=${userId}`);

      setPaymentOptions(response.data);

    } catch (error) {
      console.error('Error fetching Payment Options:', error);
    }
  };

  const handleSelectPaymentChange = (event) => {
    setSelectedPaymentOption(event.target.value);
  };




  const fetchUserName = async () => {



    try {

      const response = await axios.post(`${apiHostLink}User_/GetUsersList`, {
        instituteId: instituteId,
        UserName: loggedInUsername,
        UserType: loggedInUserType,
        TspName: '',
        userId: userId
      });

      setUserName(response.data);
      console.log("Usernames are:" + response.data)

    } catch (error) {
      console.error('Error fetching UserName:', error);
    }
  };


  const handleSelectUserName = (event) => {
    setSelectedUserName(event.target.value);
  };




  const formatDateDDMMYYYY = (date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const parseDateDDMMYYYY = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      //console.error('Invalid date string:', dateString);
      return null;
    }

    return new Date(year, month - 1, day);
  };

  const formatDateddmmyyyy = (dateString) => {
    const date = parseDateDDMMYYYY(dateString);

    if (!date || isNaN(date.getTime())) {
      return dateString;
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };


  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const sec = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hour}:${min}:${sec}`;
  };


  const handlePreviousDay = () => {
    setFromDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    });
    setToDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    });
  };

  const handleNextDay = () => {
    setFromDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
    setToDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
  };




  //const handleGoButtonClick = () => {
  //  fetchDataOnGoClick();
  //  //fetchDataWithDates();
  //};



  const handleSelectPatient = (e) => {
    setSelectedPatient(e.target.value);
  };


  const handleSelectPatientId = (e) => {
    setSelectedPatientId(e.target.value);
  };




  const fetchPatientNames = async () => {
    try {

      const response = await axios.post(`${apiHostLink}HomePage/TopNPatientNames?userId=${userId}&instituteId=${instituteId}`);
      setPatientOptions(response.data);
    } catch (error) {
      console.error('Error fetching data without dates:', error);
    }
  };



  //const handleSearch = async () => {
  //  try {
  //    const response = await axios.get(`${apiHostLink}PR_FilterStudiesWithoutDates_ForSearch_?instituteId=${instituteId}&patientName=${selectedPatient}`);
  //    setSearchedData(response.data); // Set searched data
  //  } catch (error) {
  //    console.error('Error searching:', error);
  //  }
  //};



  const fetchReportType = async () => {
    try {

      const response = await axios.get(`${apiHostLink}ReportType_/populate`, {userId: userId});

      setReportType(response.data);
      
    } catch (error) {
      console.error('Error fetching Payment Options:', error);
    }
  };

  const handleSelectReportTypeChange = (event) => {
    const selectedType = event.target.value
    setSelectedReportType(selectedType);
    fetchPdfColumns(selectedType);
    fetchSavedData(selectedType);
  };



  const fetchPdfColumns = async (cbReportType) => {
    try {

      const response = await axios.get(`${apiHostLink}CheckAccount_/FillPdfColumns?userId=${userId}&cbReportType=${cbReportType}`);

      setPdfColumns(response.data);

    } catch (error) {
      console.error('Error fetching Pdf Detail Options:', error);
    }
  };

  const handleSelectPdfColumns = (option) => {
 const selectedIndex = selectedPdfColumns.indexOf(option);
    
    if (selectedIndex === -1) {                //when ticked
      setSelectedPdfColumns([...selectedPdfColumns, option]);

      //localStorage.setItem('AccountTypeStr', JSON.stringify([...selectedPdfColumns, option]));
    } else {    //when unticked
      const updatedOptions = [...selectedPdfColumns];
      updatedOptions.splice(selectedIndex, 1);
      setSelectedPdfColumns(updatedOptions);

      //localStorage.setItem('AccountTypeStr', JSON.stringify(updatedOptions));
    }
  };




  const fetchPayment = async () => {
    try {

      const response = await axios.get(`${apiHostLink}PR_Select_FromPaymentOptions_`);

      setPayment(response.data);

    } catch (error) {
      console.error('Error fetching Payment Options:', error);
    }
  };

  const handleSelectPaymentChange2 = (event) => {
    setSelectedPayment(event.target.value);
  };




  const handleSearch = async () => {
    try {
      setIsButtonDisabled(true);
      // Adjust dates if fromDate and toDate are on the same day
      if (fromDate.toDateString() === toDate.toDateString()) {
        fromDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00
        toDate.setHours(23, 59, 59, 999);  // Set time to 23:59:59
      } else {
        fromDate.setHours(0, 0, 0, 0);  // Ensure fromDate starts at 00:00:00
        toDate.setHours(23, 59, 59, 999);  // Ensure toDate ends at 23:59:59
      }

      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);

      const requestBody = {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        user: selectedUserName,
        showAccount: "All",
        payment: selectedPaymentOption,
        showPendingPayment: showPendingPayment,
        GoBackInTimeOnCheckAccounts: goBackInTime,
        userId: userId
      };

      console.log("Sending request with payload:", requestBody);

      const response = await axios.post(`${apiHostLink}CheckAccount_/SearchClick`, requestBody);

      console.log("Received response:", response.data.SearchDt);

      if (response.data.SearchDt && response.data.SearchDt.length > 0) {

        setSearchedData(response.data.SearchDt);
        setTotalPending(response.data.MonthlyPending);
        const total = response.data.SearchDt.reduce((acc, row) => {
          const amount = parseFloat(row.AmountPaid);
          return isNaN(amount) ? acc : acc + amount;
        }, 0);

        setTotalAmountPaid(Math.floor(total).toString());

        const totalCash = response.data.SearchDt.reduce((acc, row) => {
          if (row.PaymentMode === 'Cash') {
            const amount = parseFloat(row.AmountPaid);
            return isNaN(amount) ? acc : acc + amount;
          }
          return acc;
        }, 0);

        setTotalCashPaid(Math.floor(totalCash).toString());

        const totalDigital = response.data.SearchDt.reduce((acc, row) => {
          if (row.PaymentMode !== 'Cash') {
            const amount = parseFloat(row.AmountPaid);
            return isNaN(amount) ? acc : acc + amount;
          }
          return acc;
        }, 0);

        setTotalDigitalPaid(Math.floor(totalDigital).toString());
        setButtonClicked(false);

      } else {
        console.warn("Received empty data from the server");
        setSearchedData([]);
        setTotalAmountPaid("0");
        setTotalCashPaid("0");
        setTotalDigitalPaid("0");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch data. Please try again.");
    } finally {
      setIsButtonDisabled(false);
    }
  };





  const handleSearchClick = async () => {
    try {

      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);

      const requestBody = {
        instituteId: instituteId,
        patientName: selectedPatient,
        modality: selectedModality,
        patientId: selectedPatientId,
        showAccounts: "All",
        user: selectedUserName,
        payment: selectedPaymentOption,
        showPendingPayment: showPendingPayment,
        GoBackInTimeOnCheckAccounts: goBackInTime,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        userId: userId,
      };

      console.log("Sending request with payload2:", requestBody);

      const response = await axios.post(`${apiHostLink}PatientIdAndNameSearchClick_/search`, requestBody);

      console.log("Received response2:", response.data);

      if (response.data && response.data.length > 0) {

        setSearchedData(response.data);
        console.log("Updated searchedData state:", searchedData);
        setButtonClicked(false);

      } else {
        console.warn("Received empty data from the server2");
        setSearchedData([]);
      }
    } catch (error) {
      console.error("Error fetching data2:", error);
      alert("Failed to fetch data. Please try again2.");
    }
  };



  const handleMonthlySummarySearchClick = async () => {
    try {

      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);

      const requestBody = {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        user: selectedUserName,
        modality: selectedModality,
        userId: userId,
      };

      console.log("Sending request with payload3:", requestBody);

      const response = await axios.post(`${apiHostLink}MonthlySummaryButton_/GenerateSummary`, requestBody);

      console.log("Received response3:", response.data);

      if (response.data && response.data.length > 0) {

        setSearchedData(response.data);
        setButtonClicked(true);
        console.log("Updated searchedData state:", searchedData);

      } else {
        console.warn("Received empty data from the server2");
        setSearchedData([]);
        setButtonClicked(false);
      }
    } catch (error) {
      console.error("Error fetching data3:", error);
      alert("Failed to fetch data. Please try again3.");
      setButtonClicked(false);
    }
  };

  const handleCreatePDFExcel = async (event) => {
    setDisable(false);
    var fileType = "";
    const btnText = event.target.textContent.toLowerCase();
    if (btnText.includes("pdf")) {
      if (selectedReportType === "MonthlyReport" || selectedReportType === "YearlyReport") {
        alert("Kindly Create Excel");
        return;
      }
      fileType = "pdf";
      console.log("Creating PDF...");
    } else if (btnText.includes("excel")) {
      fileType = "excel";
      console.log("Creating Excel...");
    } else {
      console.error("Unexpected button text:", btnText);
    }

    var commaSeparatedValue = '';
    if (["DailyAccounts", "RefPhyWise", "CustomReport"].includes(selectedReportType)) {
      commaSeparatedValue = selectedPdfColumns.join(',');
    }
    
    try {
      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);

      const requestBody = {
        instituteId: instituteId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        modality: selectedModality,
        user: selectedUserName,
        showAccount: "All",
        payment: selectedPaymentOption,
        InstituteName: instituteName,
        AccountingType: selectedReportType,
        AccountingColumnStr: commaSeparatedValue,
        panel: selectedPanel,
        paymentType: selectedPaymentOption,
        IsRef2: false,
        doctorName: "All",

        patientName: selectedPatient,   //just passed
        patientId: selectedPatientId,
        showPendingPayment: showPendingPayment,   //excel
        GoBackInTimeOnCheckAccounts: goBackInTime,
        reportType: fileType,
        tableContents: searchedData,
        userId: userId,
      };

      console.log("Sending request with payload:", requestBody);

      const response = await axios.post(`${apiHostLink}CheckAccount_/CreatePdfOrExcel`, requestBody, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });

      const parts = DateTime.split(/[-T]/);
      var dateStr = parts.join("_");

      var ext = "";
      var app = "";
      if (btnText.includes("pdf")) {
        ext = ".pdf";
        app = "pdf";
        console.log("Pdf Created:", response.data);
        var a = response.data.type;
      } else if (btnText.includes("excel")) {
        //ext = ".xlsx";
        //app = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";    //vnd.ms-excel
        //if (response.data.type === "text/csv") {
          ext = ".csv";
          app = "csv";
        //}
        console.log("Excel Created:", response.data);
      }


      const zipFileName = selectedReportType + '_' + dateStr + ext;
      alert(zipFileName + " file is downloading");
      const blob = new Blob([response.data], { type: 'application/' + app });   //application/vnd.ms-excel, application/pdf, application/txt application/msdoc
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = zipFileName;
      link.click();
      setDisable(true);
    } catch (error) {
      console.error('Error creating Pdf or Excel:', error);
      alert('Pdf or Excel file not created');
      setDisable(true); 
    }
  }

  function handleGoBackTime() {
    const goBackTimeCheckbox = document.getElementById('goBackTimeCheckbox');
    setGoBackInTime(goBackTimeCheckbox.checked);
  }

  function handlePendingPayment() {
    const PendingPaymentCheckbox = document.getElementById('showPendingPayment');
    setShowPendingPayment(PendingPaymentCheckbox.checked);
  }

  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    setTime(newTime);
    const newDate = new Date(dateTime.toISOString().slice(0, 10) + 'T' + newTime);
    setDateTime(newDate);
  };

  return (
    <div style={{ padding: '10px', marginLeft: '30px', userSelect: 'none' }}>

      <div style={{ display: 'flex' }}>
        <div style={{ backgroundColor: '#d4e3ea', width: 'auto', height: '120px', marginTop: '10px', border: '5px double white', borderRadius: '4px', display: 'inline' }}>
          <div style={{ display: 'flex', marginTop: '0px', marginLeft: '0px', padding: '5px' }}>
            <div style={{ position: 'fixed', display: 'flex', alignItems: 'center', marginLeft: '5px', fontSize: '14px', marginTop: '5px' }}>
              <label>
                <button className="gradientButton" style={{ width: '25px', height: '20px', padding: '5px', position: 'relative', paddingTop: '0px', paddingLeft: '4px', marginRight: '20px' }} onClick={handlePreviousDay}> {"<<"} </button>From
              </label>
              <label style={{ marginLeft: '40px' }}>To
                <button className="gradientButton" style={{ width: '25px', height: '20px', padding: '5px', position: 'relative', paddingTop: '0px', paddingLeft: '4px', paddingRight: '4px', marginLeft: '25px' }} onClick={handleNextDay}> {">>"} </button>
              </label>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', fontSize: '14px', marginTop: '30px' }}>
              <label>
                <input className="custom-date-input form-control" type="date" style={{ height: '20px', marginLeft:'0px', marginTop:'0px', borderRadius:'2px', border:'1px solid gray' }} value={formatDateDDMMYYYY(fromDate)} onChange={(e) => setFromDate(new Date(e.target.value))} />
              </label>
              <label>
                <input className="custom-date-input form-control" type="date" style={{ height: '20px', marginLeft: '15px', marginTop: '0px', borderRadius: '2px', border: '1px solid gray' }} value={formatDateDDMMYYYY(toDate)} onChange={(e) => setToDate(new Date(e.target.value))} />
              </label>
            </div>

            <div style={{ display: 'flex', marginLeft: '20px' }}>
              <label>Panel<br />
                <select style={{ fontSize: '10px', width: '70px', height: '20px', padding: '3px' }}
                  value={selectedPanel}
                  onChange={handlePanelChange} >
                  <option value=""></option>
                  {panelList.map((panel, index) => (
                    <option key={index} value={panel.name}>
                      {panel.name}
                    </option>
                  ))}
                </select>
              </label>
              <label style={{ marginLeft: '10px' }}>User<br />
                <select style={{ fontSize: '10px', width: '70px', height: '20px', padding: '3px' }}
                  value={selectedUserName}
                  onChange={handleSelectUserName} >
                  <option value=""></option>
                  {userName && userName.map((option, index) => (
                    <option key={index} style={{ fontSize: '10px' }} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div style={{ display: 'flex', marginLeft: '10px', marginTop: '10px' }}>
              <button className="gradientButton" style={{ fontSize: '13px', cursor: isButtonDisabled ? 'not-allowed' : 'pointer', opacity: isButtonDisabled ? 0.5 : 1 }} onClick={handleSearch} disabled={isButtonDisabled}>Search</button>
              <select style={{ fontSize: '14px', width: '60px', height: '20px', marginLeft: '10px', marginTop: '2px' }}>
                <option value="Expand"></option>
              </select>
            </div>
          </div>

          <div style={{ display: 'flex', marginLeft: '10px' }}>
            <div>
              <input className="t-1" type="time" value="00:00" style={{ marginLeft: '0px', height:'20px' }}></input>
              <input className="t-2" type="time" style={{ marginLeft: '40px', height:'20px' }} value={time} onChange={handleTimeChange} />

            </div>

            <div style={{ display: 'flex', marginLeft: '21px', marginTop:'-10px' }}>
              <label>Modality<br />
                <select style={{ fontSize: '10px', width: '70px', height: '20px', padding: '0px' }}
                  value={selectedModality}
                  onChange={handleModalityChange} >
                  <option value=""></option>
                  {modalityList.map((modality, index) => (
                    <option key={index} value={modality.modalityName}>
                      {modality.modalityName}
                    </option>
                  ))}
                </select>
              </label>

              <label style={{ marginLeft: '10px' }}>Payment Type<br />
                <select style={{ fontSize: '10px', width: '80px', height: '20px', padding: '0px' }}
                  onChange={handleSelectPaymentChange}
                  value={selectedPaymentOption}> {/* Set value to selectedPaymentOption */}
                  <option value=""></option>
                  {paymentOptions.map((option, index) => (
                    <option key={index} style={{ fontSize: '10px' }} value={option.paymentType} >
                      {option.paymentType}
                    </option>
                  ))}
                </select>
              </label>

              <button className="gradientButton" style={{ fontSize: '13px', marginLeft: '10px', marginRight:'7px', marginTop:'13px' }} onClick={handleMonthlySummarySearchClick}>Monthly Summary</button>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#d4e3ea', width: '280px', height: '120px', marginTop: '10px', border: '5px double white', borderRadius: '4px' }}>
          <div style={{ display: 'flex' }}>
            <label style={{ marginTop: '5px', marginLeft: '5px' }}>TotalRcvd: {totalAmountPaid}</label>
            <label style={{ marginTop: '5px', marginLeft: '25px' }}>Cash: {totalCashPaid}</label>
          </div>
          <div style={{ display: 'flex' }}>
            <label style={{ marginTop: '5px', marginLeft: '5px', color: 'red' }}>Pending: {totalPending}</label>
            <label style={{ marginTop: '5px', marginLeft: '25px', color: 'blue' }}>Digital: {totalDigitalPaid}</label>
          </div>
        </div>
        <div className="table-container" style={{ marginLeft: '10px', marginTop: '10px', overflowX: 'auto', overflowY: 'auto', height: '225px', width: 'calc(42vw)', backgroundColor: '#f0f0f0', border: '1px solid #688caf' }}>
          <table>
            <thead style={{ position: 'sticky' }}>
              <tr>
                <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray' }}>StartDtTime</th>
                <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>EndDtTime</th>
                <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray' }}>Cash</th>
                <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Digital</th>
                <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Total</th>
              </tr>
            </thead>
            <tbody>

            </tbody>
          </table>
        </div>
        <div style={{ marginTop: '240px', marginLeft: '-620px', display: 'flex' }}>
          <label>
            <input type="checkbox" id="goBackTimeCheckbox" onChange={handleGoBackTime} checked={goBackInTime}></input> Go Back in Time : {goBackInTime ? "True" : "False"}
          </label>
          <button className="gradientButton" style={{ fontSize: '13px', height: 'auto', marginLeft: '10px' }} >Reset AC CheckTime</button>
          <button className="gradientButton" style={{ fontSize: '13px', height: 'auto', marginLeft: '10px' }} >Mark Accounts Clear</button>
        </div>
      </div>


      <div style={{ display: 'flex', marginTop: '-130px', width: '800px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#d4e3ea', width: '210px', height: '100px', border: '5px double white', borderRadius: '4px' }}>

          <label style={{ marginLeft: '5px', marginTop: '8px' }} onChange={handleSelectPatientId}>Patient ID
            <input type="text" style={{ width: '70px', height: '25px', marginLeft: '10px' }} defaultValue="0"></input>
            <button className="gradientButton" style={{ fontSize: '13px', width: 'auto', height: 'auto', marginLeft: '10px' }} onClick={handleSearchClick}>Search</button>
          </label>

          <label style={{ marginLeft: '5px', marginTop: '10px' }}>Patient Name
            <select onChange={handleSelectPatient}
              value={selectedPatient}
              style={{ width: '100px', height: '25px', fontSize: '12px', marginLeft: '10px' }}
            >
              <option value=""></option>
              {patientOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </label>
        </div>


        <div style={{ backgroundColor: '#d4e3ea', width: '210px', height: '100px', border: '5px double white', borderRadius: '4px' }}>
          <div style={{ display: 'flex' }} >
            <input type="text" style={{ display: 'flex', width: '90px', marginLeft: '10px', marginTop: '10px', height: '25px' }}></input>
            <select
              style={{
                display: 'flex',
                width: '90px',
                height: '25px',
                marginLeft: '5px',
                marginTop: '10px',
                fontSize: '10px'
              }}
              onChange={handleSelectPaymentChange2}
              defaultValue={payment.length > 0 ? payment[0].paymentType : ""}>
              {payment.map((option, index) => (
                <option key={index} style={{ fontSize: '10px' }} value={option.paymentType} >
                  {option.paymentType}
                </option>
              ))}
            </select>

          </div>
          <div>
            <button className="gradientButton" style={{ fontSize: '13px', width: 'auto', height: 'auto', paddingTop: '2px', paddingBottom: '1px', paddingLeft: '2px', paddingRight: '2px', marginLeft: '10px', marginTop: '10px' }} >Edit Payment</button>
          </div>
        </div>


        <div style={{ backgroundColor: '#d4e3ea', width: '200px', height: '100px', border: '5px double white', borderRadius: '4px' }}>
          <div style={{ display: 'flex' }} >
            <select
              style={{
                display: 'flex',
                width: '90px',
                height: '25px',
                marginLeft: '5px',
                marginTop: '10px',
                fontSize: '14px'
              }}
              onChange={handleSelectReportTypeChange}
              value={selectedReportType}
            >
              <option value=""></option>
              {reportType.map((option, index) => (
                <option key={index} style={{ fontSize: '14px' }} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <button className="gradientButton" style={{ display: 'flex', fontSize: '13px', marginLeft: '10px', marginTop: '10px', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? handleCreatePDFExcel : undefined} disabled={!disable}>Create Pdf</button>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ visibility: (selectedReportType === 'RefPhyWise' || selectedReportType === 'DailyAccounts' || selectedReportType === 'CustomReport') ? 'visible' : 'hidden' }}>
              <CheckboxesDropdown options={pdfColumns} selectedOptions={selectedPdfColumns} handleSelectOption={handleSelectPdfColumns} />        
            </div>
            <button className="gradientButton" style={{ display: 'flex', fontSize: '13px', height: '25px', marginLeft: '0px', marginTop: '20px', opacity: disable ? 1 : 0.5, cursor: disable ? 'pointer' : 'not-allowed' }} onClick={disable ? handleCreatePDFExcel : undefined} disabled={!disable}>Create Excel</button>
            </div>
        </div>


        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#d4e3ea', width: '160px', height: '100px', border: '5px double white', borderRadius: '4px' }}>
          <label style={{ marginLeft: '3px', marginTop: '3px' }} >
            <input type="checkbox" style={{ marginLeft: '5px', marginRight: '3px' }}></input>ShowNarration
          </label>
          <label style={{ marginLeft: '3px', marginTop: '3px' }} >
            <input type="checkbox" style={{ marginLeft: '5px', marginRight: '3px' }}></input>ShowPF
          </label>
          <label style={{ marginLeft: '3px', marginTop: '3px' }} >
            <input type="checkbox" style={{ marginLeft: '5px', marginRight: '3px' }}></input>VerifyAll
          </label>
          <label style={{ marginLeft: '3px', marginTop: '3px', whiteSpace: 'nowrap', maxWidth: '70px', textOverflow: 'clip' }} title="ShowPendingPayment In AccountPdf" >
            <input type="checkbox" id="showPendingPayment" onChange={handlePendingPayment} style={{ marginLeft: '5px', marginRight: '3px' }} checked={showPendingPayment}></input>ShowPendingPayment
          </label>
        </div>
      </div>




      <div className="table-container" style={{ overflowX: 'auto', overflowY: 'auto', height: 'calc(100vh - 300px)', marginTop:'40px', width: 'calc(100vw - 70px)', backgroundColor: '#f0f0f0', border: '1px solid #688caf' }}>
        <table>
          <thead style={{ position: 'sticky' }}>
            <tr>
              {buttonClicked ? (
                <>
                  <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray' }}>Date</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>CollectedBy</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>#Studies</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>TestPrice</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>ExtraRs</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Concession</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>AmtReceivable</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>AmountPaid</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>Pending</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>PrevPendingReceived</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>TotalReceived</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>PF</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray' }}>FinalAmount</th>
                </>
              ) : (
                <>
                    <th style={{ backgroundColor: '#f2f9fc', width: '0px', border: '1px solid slategray', textAlign: 'center', maxWidth: '80px' }} title='Collection Date'>CollectionDate</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray', textAlign: 'center' }} title='Study Date'>StudyDate</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray', textAlign: 'center' }} title='Patient ID'>PatientId</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray', textAlign: 'center' }} title='Patient Name'>PatientName</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray', textAlign: 'center' }} title='Modality'>Modality</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray', textAlign: 'center' }} title='Test'>Test</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray', textAlign: 'center' }} title='Amount Paid'>AmountPaid</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray', textAlign: 'center' }} title='Payemnt Mode'>PaymentMode</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray', textAlign: 'center' }} title='Installments'>Installments</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '80px', border: '1px solid slategray', textAlign: 'center' }} title='Collected By'>CollectedBy</th>
                  <th style={{ backgroundColor: '#f2f9fc', width: '210px', border: '1px solid slategray', textAlign: 'center' }} title='Verified'>Verified</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {searchedData.map((selRow, index) => (
              <tr key={index}>
                <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow:'clip', maxWidth: '30px' }} title={buttonClicked ? selRow.Date : selRow.CollectionDate}>{buttonClicked ? selRow.Date : selRow.CollectionDate}</td>
                <td>{buttonClicked ? selRow.CollectedBy : selRow.StudyDate ? formatDateddmmyyyy(selRow.StudyDate) : ''}</td>
                <td style={{ textAlign: 'center' }}>{buttonClicked ? (selRow['#Studies'] ? selRow['#Studies'] : '-') : selRow.PatientId}</td>
                <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '0px', textAlign: 'center' }} title={buttonClicked ? selRow.TestPrice : selRow.PatientName}>{buttonClicked ? selRow.TestPrice : selRow.PatientName}</td>
                <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '0px', textAlign: 'center' }} title={buttonClicked ? selRow.ExtraRs : selRow.Modality}>{buttonClicked ? selRow.ExtraRs : selRow.Modality}</td>
                <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '0px',textAlign: 'center' }} title={buttonClicked ? selRow.Concession : selRow.Test}>{buttonClicked ? selRow.Concession : selRow.Test}</td>
                <td style={{ textAlign: 'right' }}>{buttonClicked ? selRow.AmtRecievable : selRow.AmountPaid}</td>
                <td style={{ textAlign: 'center' }}>{buttonClicked ? selRow.AmountPaid : selRow.PaymentMode}</td>
                <td style={{ textAlign: 'center' }}>{buttonClicked ? selRow.Pending : selRow.Installments}</td>
                <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textAlign: 'center', maxWidth:'0px' }} title={buttonClicked ? selRow.PrevPendingReceived : selRow.CollectedBy}>{buttonClicked ? selRow.PrevPendingReceived : selRow.CollectedBy}</td>
                <td style={{ textAlign: 'center' }}><input type="checkbox" defaultChecked={buttonClicked ? selRow.TotalReceived : selRow.Verified}/></td>
                <td>{selRow.PF}</td>
                <td>{selRow.FinalAmount}</td>
              </tr>
            ))
            }
          </tbody >
        </table >
      </div >
    </div >
  )
}
