import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Sidebar from '../Sidebar'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

export default function InvoiceSummary() {
  const apiHostLink = localStorage.getItem("apiHostLink");
  const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), 0, 1)); // January 1st of the current year
  const [toDate, setToDate] = useState(new Date());
  var [fromDate2, setFromDate2] = useState(new Date());
  var [toDate2, setToDate2] = useState(new Date());
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState('All');
  const [selectedInvoice2, setSelectedInvoice2] = useState('All');
  const [length, setLength] = useState(0);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [lastIndex, setLastIndex] = useState(0);
  const today = new Date();
  const sessionUid = localStorage.getItem("sessionUid");
  useEffect(() => {
    
    const startOfYear = new Date(today.getFullYear(), 0, 1); // January 1st of the current year

    setFromDate(startOfYear);
    setToDate(today);
    setFromDate2(today);
    setToDate2(today);

    fillInvoiceSummary(startOfYear,today);
    fillInvSumInstInfo(today,today);
  }, []);

  const formatDateDDMMYYYY = (date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
    //const yyyy = date.getFullYear();// String(date.getFullYear());//.substring((2, 2);
    //return `${dd}-${mm}-${yyyy}`;
  };

  const handlePreviousDay = (event) => {
    event.preventDefault();
    const newFromDate = new Date(fromDate2);
    const newToDate = new Date(toDate2);

    newFromDate.setDate(newFromDate.getDate() - 1);
    newToDate.setDate(newToDate.getDate() - 1);

    setFromDate2(newFromDate);
    setToDate2(newToDate);

    fillInvSumInstInfo(newFromDate, newToDate);
    //getData(newFromDate, newToDate, true);
  };


  const handleNextDay = (event) => {
    event.preventDefault();
    const newFromDate = new Date(fromDate2);
    const newToDate = new Date(toDate2);

    newFromDate.setDate(newFromDate.getDate() + 1);
    newToDate.setDate(newToDate.getDate() + 1);

    setFromDate2(newFromDate);
    setToDate2(newToDate);

    fillInvSumInstInfo(newFromDate, newToDate);
    //getData(newFromDate, newToDate, true);
  };

  const fillInvoiceSummary = async (frDate, tDate) => {
    var fD, tD;
    if (frDate || tDate) {
      fD = frDate;
      tD = tDate;
    }

    if (fromDate || toDate) {
      fD = fromDate;
      tD = toDate;
    } else {
      return;
    }

    try {
      const response = await axios.post(`${apiHostLink}InvoiceSummary_/FillInvoiceSummary`, {
        fromDate: fD,
        toDate: tD
      });

      const resp = response.data;
      setData(resp);
      console.log('Data fetched: ', resp);

    } catch (error) {
      console.error('Error fetching Data :', error);
    }
  };

  const handleSearchClick = async () => {
    fillInvoiceSummary();
  }

  const handleSearchClick2 = async () => {
    fillInvSumInstInfo();
  }

  const fillInvSumInstInfo = async (newFrom, newTo) => {
    if (newFrom && newTo) {
      fromDate2 = newFrom;
      toDate2 = newTo;
    }

    /*if (!fromDate2 || !toDate2) {
      return;
    }*/

    try {
      const response = await axios.post(`${apiHostLink}InvoiceSummary_/FillInvSumInstInfo`, {
        fromDate2: fromDate2,
        toDate2: toDate2
      });

      const resp = response.data;
      setData2(resp);
      setLength(resp.length);
      console.log('Data fetched: ', resp);

    } catch (error) {
      console.error('Error fetching Data :', error);
    }
  };

  const handleInvoiceClick = (invoiceData, index) => {
    setSelectedInvoice(invoiceData);
    //setLastIndex(index);
    console.log("Selected Invoice Details:", invoiceData);
  };

  const handleInvoiceClick2 = (invoiceData, index) => {
    setSelectedInvoice2(invoiceData);
    setLastIndex(index);
    console.log("Selected Invoice Details:", invoiceData);
  };

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    const monthNumber = String(date.getMonth() + 1).padStart(2, '0');
    return {
      monthYear: `${month}-${year}`,
      monthNumber: monthNumber,
    };
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${formattedHours}:${minutes} ${ampm}`;
    const formattedDate = `${day}-${month}-${year}`;
    return {
      formattedDate: formattedDate,
      formattedTime: formattedTime,
    };
  };

  const handleRowRightClick = (e, selRow, index) => {
    handleInvoiceClick2(selRow);
    setLastIndex(index);
    e.preventDefault();
    setSelectedInvoice2(selRow);
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setContextMenuVisible(true);
  };

  const handleContextMenuClick = (e, data) => {
    // Handle context menu item click here
    console.log('Clicked on', data);
  };

  const hideContextMenu = () => {
    setContextMenuVisible(false);
  };
  
  const handleOpenInvoiceClick = async () => {

    try {
      const response = await axios.post(`${apiHostLink}InvoiceSummary_/menuOpenInv`, {
        fromDate2: fromDate2,
        toDate2: toDate2,
        indexNum: lastIndex,
        sessionUid: sessionUid
      });

      const resp = response.data;
      console.log('Data fetched: ', resp);

    } catch (error) {
      console.error('Error fetching Data :', error);
      alert(error.response.data);
    }
  };

  return (
    <div onClick={hideContextMenu}>
      <div className="container-fluid d-flex p-0"> {/* Use d-flex for flexbox layout */}
        {/*Sidebar */}
        <Sidebar />
      </div>

      <div style={{ marginTop: '-930px', marginLeft: '120px' }}>
        <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '15px', fontSize: '14px', display: 'inline' }}>From</text>
        <input type="date" className="form-control" id="exampleInputDate" style={{ width: '120px', height: '30px', marginRight: '30px', display: 'inline' }} value={formatDateDDMMYYYY(fromDate)} onChange={(e) => setFromDate(new Date(e.target.value))} />
        <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '10px', fontSize: '14px', display: 'inline' }}>To</text>
        <input type="date" className="form-control" id="exampleInputDate" style={{ width: '120px', height: '30px', marginRight: '30px', display: 'inline' }} value={formatDateDDMMYYYY(toDate)} onChange={(e) => setToDate(new Date(e.target.value))} />
        <button className="btn btn-primary btn-sm me-2 ms-0" onClick={handleSearchClick}>Search</button>
      </div>

      <div style={{ position: 'absolute', top: '60px', width: '180vh', height: '2px', left: '100px', borderTop: '2px solid black' }}></div>
      <div style={{ position: 'absolute', top: '70px', left: '80px', width: 'calc(100% - 150px)', height: '350px', overflowX: 'auto', overflowY: 'auto', marginLeft: '5px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black', fontSize: '13.2px' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>SrNo</th>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>MonthName</th>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>MonthId</th>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Total Institute</th>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Prepared Invoice</th>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Sent Invoice</th>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Payment Received</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const { monthYear, monthNumber } = formatDate(item.InvMonth);
              return (
                <tr key={index} onClick={() => handleInvoiceClick(item, index)} style={{ cursor: 'default', backgroundColor: selectedInvoice === item ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.SrNo}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{monthYear}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{monthNumber}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.InstCount}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.InvApprove}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.InvShow2Cust}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.PayReceived}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div style={{ marginTop: '400px', marginLeft: '120px' }}>
        <button className="btn btn-secondary" style={{ marginLeft: '2px', fontSize: '14px', width: '40px', height: '30px', marginRight: '20px' }} onClick={handlePreviousDay}> {"<<"} </button>
        <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '10px', fontSize: '14px', display: 'inline' }}>From</text>
        <input type="date" className="form-control" id="exampleInputDate" style={{ width: '120px', height: '30px', marginRight: '30px', display: 'inline' }} value={formatDateDDMMYYYY(fromDate2)} onChange={(e) => setFromDate2(new Date(e.target.value))} />
        <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '10px', fontSize: '14px', display: 'inline' }}>To</text>

        <input type="date" className="form-control" id="exampleInputDate" style={{ width: '120px', height: '30px', marginRight: '30px', display: 'inline' }} value={formatDateDDMMYYYY(toDate2)} onChange={(e) => setToDate2(new Date(e.target.value))} />
        <button className="btn btn-secondary" style={{ marginLeft: '2px', fontSize: '14px', width: '40px', height: '30px', marginRight: '20px' }} onClick={handleNextDay}> {">>"} </button>
        <button className="btn btn-success btn-sm me-2 ms-0" style={{ width: '120px' }} onClick={handleSearchClick2}>Search</button>
        <text htmlFor="exampleInputPassword2" className="form-label" style={{ marginRight: '10px', fontSize: '14px', display: 'inline' }}>Row Count: {length}</text>
      </div>
      <div style={{ position: 'absolute', top: '495px', width: '180vh', height: '2px', left: '100px', borderTop: '2px solid black' }}></div>
      <div style={{ position: 'absolute', top: '505px', left: '80px', width: 'calc(100% - 150px)', height: '430px', overflowX: 'auto', overflowY: 'auto', marginLeft: '5px' }}>
        <ContextMenuTrigger id="contextmenu">
          <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black', fontSize: '13.2px' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>SrNo</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>InstId</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Institute</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>MonthName</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>MonthId</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Time</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>UserName</th>
              </tr>
            </thead>
            <tbody>
              {data2.map((item, index) => {
                const { monthYear, monthNumber } = formatDate(item.InvMonth);
                const { formattedDate, formattedTime } = formatDateTime(item.Date);
                return (
                  <tr key={index} onContextMenu={(e) => handleRowRightClick(e, item, index)} onClick={() => handleInvoiceClick2(item, index)} style={{ cursor: 'default', backgroundColor: selectedInvoice2 === item ? '#d7fba0' : index % 2 === 0 ? '#dff9ff' : 'white' }}>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.SrNo}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.InstId}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} title={item.Institute}>{item.Institute}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{monthYear}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{monthNumber}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{formattedDate}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{formattedTime}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.UserName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ContextMenuTrigger>
      </div>
      {contextMenuVisible && (
        <ContextMenu id="contextmenu" className="context-menu" style={{ left: contextMenuPosition.x, top: contextMenuPosition.y }}>
          <MenuItem className="menu-item" onClick={handleContextMenuClick}>
            <span style={{ paddingLeft: '20px' }} /*onClick={handleOpenInvoiceClick}*/>Open Invoice</span>
          </MenuItem>
        </ContextMenu>
      )}
    </div>
  )
}