import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UsersSidebar from './UsersSidebar';

const PaymentOption = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [options, setOptions] = useState([]);
  const apiHostLink = localStorage.getItem("apiHostLink");
  const username = localStorage.getItem("username");
  const instituteId = localStorage.getItem("instituteId");
  const userId = localStorage.getItem("userId");
  const [selectedRow, setSelectedRow] = useState(null); // State for selected row

  useEffect(() => {
    axios.get(`${apiHostLink}PaymentOption_/PaymentOptions`, {userId: userId})
      .then(response => {
        const data = response.data;
        // Check if data is an array or a single object
        if (Array.isArray(data)) {
          setOptions(data);
        } else {
          setOptions([data]);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the online users!', error);
      });
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleRowClick = (options) => {
    setSelectedRow(options);
    console.log(options);
  };

  return (
    <>
      <div style={{ overflow: 'hidden', marginLeft: isSidebarOpen ? '350px' : '120px', transition: 'margin-left 0.3s ease', marginTop: '30px', width: isSidebarOpen ? 'calc(50vw - 380px)' : 'calc(50vw - 380px)', userSelect:'none' }}>
        <UsersSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className={`table-container ${isSidebarOpen ? 'margin-left-expanded' : 'margin-left'}`}>
          <table>
            <thead>
              <tr>
                <th>PaymentType</th>
              </tr>
            </thead>
            <tbody>
              {options.map(options => (
                <tr
                  key={options.PaymentType}
                  onClick={() => handleRowClick(options)}
                  style={{ backgroundColor: selectedRow === options ? '#d7fba0' : '' } }
                >
                  <td>{options.PaymentType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <label style={{ display: 'flex', marginTop: '20px' }}>Payment Type:
          <input type="text" value={selectedRow ? selectedRow.PaymentType : ''}></input>
        </label>
        <button className="gradientButton" style={{ marginLeft: '10px', marginTop: '20px', fontSize:'16px' }} disabled>Add </button>
        <button className="gradientButton" style={{ marginLeft: '10px', fontSize: '16px' }} disabled>Update</button>
      </div>
        
    </>
  );
};

export default PaymentOption;
